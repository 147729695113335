
/* eslint-disable  */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { bindActionCreators } from "redux";
import "../../css/Auth/Auth.css";
import { authActionCreators } from "../../service/action-creators";
import Loader from "../Loader";

function ResetPassword() {
  const dispatch = useDispatch();
  const history = useHistory()
  const { ResetPassword, ResetResponse } = bindActionCreators(
    authActionCreators,
    dispatch
  );
  const auth = useSelector((state) => state?.auth);
  const response = auth?.response;
  const isLoading = auth?.isLoading;
console.log('response', response)
  const [formData, setFormData] = useState({});
  const query = new URLSearchParams(useLocation()?.search);
  console.log("query", query.get("token"));

  const handleSubmit = (e) => {
    e.preventDefault();
    const Payload = {
      ...formData,
      token: decodeURIComponent(query.get("token")),
    };
    if(formData?.newPassword !== "" || formData?.confirmPassword !== ""){
      ResetPassword(Payload);
    }else{
      toast.error('Make sure to enter new password and confirm password')
    }
  };
  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

    useEffect(() => {
    if (response?.state === "SUCCESS") {
      toast.success(response?.message);
      setTimeout(() => {
          ResetResponse();
          history.replace('/')
      }, 1500);
    } else if (response?.state === "ERROR") {
      toast.error(response?.message);
      setTimeout(() => {
        ResetResponse();
      }, 1500);
    }
  }, [response?.state]);

  return (
    <div className="ResetPassword">
        {isLoading && <Loader/>}
      <div className="overlay">
        <h2>Reset Password</h2>
        <form className="form">
          <div className="newPassword-form-group form-group">
            <label htmlFor="newPassword">New Password</label>
            <input
              id="newPassword"
              name="newPassword"
              type="password"
              required
              className="form-control"
              onChange={(e) => handleInputChange(e)}
            />
          </div>
          <div className="confirmPassword-form-group">
            <label htmlFor="confirmPassword">Confirm Password</label>
            <input
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              className="form-control"
              required
              onChange={(e) => handleInputChange(e)}
            />
          </div>
          <button className="button mb2 mt1" onClick={handleSubmit}>
            Reset Password
          </button>
        </form>
      </div>
      <ToastContainer autoClose={3000}/>
    </div>
  );
}

export default ResetPassword;
