/* eslint-disable  */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { bindActionCreators } from "redux";
import { authActionCreators } from "../../service/action-creators";
import Loader from "../Loader";
import "./SentEmailForVerification.css";
function ResendEmailVerificationLink() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { ResetResponse, ResendEmailTokenToUser } = bindActionCreators(
    authActionCreators,
    dispatch
  );

  const auth = useSelector((state) => state?.auth);

  console.log("auth", auth);

  useEffect(() => {
    if (auth?.response?.state === "SUCCESS") {
      toast.success(auth?.response?.message);
      // return <Redirect to={'/account/email-sent'}/>
      history.push("/account/email-sent");
      setTimeout(() => {
        ResetResponse();
      }, 3000);
    } else if (auth?.response?.state === "ERROR") {
      toast.error(auth?.response?.message ?? "Error occured");
      setTimeout(() => {
        ResetResponse();
      }, 3000);
    }
  }, [auth?.response?.state]);

  return (
    <>
    {auth?.isLoading && <Loader/>}
      <div className="SentEmailForVerification">
        <div className="wrapper">
          <div
            className="side-1"
            style={{
              background:
                "url(/images/dev/background.jpg) no-repeat center center/cover",
            }}
          >
            <div className="overlay"></div>
          </div>
          <div className="side-2">
            <div className="imgCover">
              <img src="/images/dev/email-icon.png" alt="email" />
            </div>
            <h3>Resend new verification link to your email account</h3>
            <p>
              Please click on link that will be send to your email account to
              verify your account.
            </p>
            <button
              className="button mt-4"
              onClick={() => ResendEmailTokenToUser(auth?.user?.email)}
            >
              Resend Email Link
            </button>
          </div>
        </div>
      </div>
      <ToastContainer autoClose={3000} />
    </>
  );
}

export default ResendEmailVerificationLink;
