import React from "react";
import "./SentEmailForVerification.css";
function SentEmailForVerification() {
  return (
    <div className="SentEmailForVerification">
      <div className="wrapper">
        <div
          className="side-1"
          style={{
            background:
              "url(/images/dev/background.jpg) no-repeat center center/cover",
          }}
        >
          <div className="overlay"></div>
        </div>
        <div className="side-2">
          <div className="imgCover">
            <img src="/images/dev/email-icon.png" alt="email" />
          </div>
          <h3>A verification link has been sent to your email account</h3>
          <p>
            Please click on link that has just been sent to your email account
            to verify your email and continue
          </p>
        </div>
      </div>
    </div>
  );
}

export default SentEmailForVerification;
