/* eslint-disable  */
import { IconButton } from "@material-ui/core";
import { ShoppingBasketOutlined } from "@material-ui/icons";
import { debounce } from "lodash";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
import { productActionCreators, userDataActionCreators } from "../../../../service/action-creators";
import { GetUserProfile } from "../../../../service/action-creators/authAction";
import { GetNotifications } from "../../../../service/action-creators/notificationAction";
import Loader from "../../../Loader";
import RenderHome from "../../home/RenderHome";
import "./SearchedProduct.css";


function SearchedProduct() {
  const dispatch = useDispatch();
  const history = useHistory();
  const {  GetFeaturedProduct , SearchProducts, ResetProductResponse} = bindActionCreators(
    productActionCreators,
    dispatch
  );
  const { AddProductToCart, ResetUserDataResponse, GetCartProducts} = bindActionCreators(
    userDataActionCreators,
    dispatch
  );

  const product = useSelector((state) => state?.product);
  const userData = useSelector((state) => state?.user);
  // const isLoading = product?.isLoading;

  const {products, isLoading, paginate, response} = product
 
  
  // const electronicsCategory = product?.electronicsCategory;
  // const products = product?.products;
//   console.log("latestCategory", latestCategory);
  const [search, setSearch] = useState(null);
  const [searchValue, setSearchValue] = useState(history.location.state.searchValue);

  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 15;
  const [currentPage, setCurrentPage] = useState(0);
  // const data = useSelector((state) => state.data);
 

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;

    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setCurrentItems(products?.slice(itemOffset, endOffset));
    setPageCount(Number(paginate?.total));
  }, [itemOffset, itemsPerPage, products]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    console.log("event", event);

  
    SearchProducts(searchValue,true,1, 15)

    console.log("currentPage", currentPage);

    const newOffset = (event.selected * itemsPerPage) % paginate?.total;

    setItemOffset(newOffset);
  };

  console.log('history', history)

//   const searchValuePassed = history.location.state.searchValue
//   console.log('searchValuePassed', searchValuePassed)

  useEffect(() => {
    // GetLatestCategory("Latest Products");
    GetFeaturedProduct();
   dispatch(GetUserProfile())
   dispatch(GetNotifications())
   GetCartProducts()
    
  }, []);

  useEffect(() => {
    SearchProducts(searchValue,true,1, 15)
  }, [searchValue])

  console.log('products', products)

//   useEffect(() => {
//     setSearchValue(null)
//   }, [])

  const handleSearchButton = (e) => {
    e.preventDefault()
    if (search) {
      //call the search
      SearchProducts(search, true, 1, 15)
      console.log("trigger search");
    } else {
        GetFeaturedProduct();
      //call your existing data
    }
  };

  const handleAddToBasket = (e, product) => {
    e.preventDefault()
    const defaultDuration = 3
    const percentChange = 1.1
    const adminCharge = 3 / 100;
    const retailPrice = Number(product?.new_price)
    const adminAmount = retailPrice * adminCharge;
    const initialAmount = (retailPrice * percentChange) + adminAmount;
    const selectedPeriod = Number(initialAmount / defaultDuration).toFixed(2)
   
    const payload = {
      size: '',
      color: '',
      quantity: 1,
      product_id: product?._id,
      period: defaultDuration,
      period_price: selectedPeriod,
    }
    // alert(product_id)
   dispatch(AddProductToCart(payload))
  }

  const onSearchProduct = debounce((e) => {
    if (e?.target?.value) {
      setSearch(e?.target?.value ?? null);
    } else {
      setSearch(null);
      // GetBusiness(true, 1, 12);
      GetFeaturedProduct();
    }
  }, 800);

  console.log("search", search);

  useEffect(() => {
    if (response?.state === "SUCCESS" || userData?.response?.state === "SUCCESS") {
      // toast.success(response?.message);
      GetCartProducts();
      setTimeout(() => {
        ResetUserDataResponse();
        ResetProductResponse()
      }, 1500);
    } else if (response?.state === "ERROR" || userData?.response?.state === "ERROR") {
      // toast.error(response?.message);
      setTimeout(() => {
        ResetUserDataResponse();
        ResetProductResponse()
      }, 1500);
    }
  }, [response?.state || userData.response.state]);

  return (
    <div className="SearchedProduct">
      <RenderHome>
        {isLoading || userData.isLoading && <Loader />}
        {/* search Component */}
        <button className="backBtn" onClick={() =>history.goBack()}>Back</button>
            <form>
        <div className="searchContainer">
          <div className="search">
            <input
              className="searchInput"
              type={"search"}
              placeholder="Search for your product here..."
              name="search"
              defaultValue={search}
              onChange={(e) => onSearchProduct(e)}
            />
            {search && (
              <button type="submit" className="button" onClick={handleSearchButton}>
                Search
              </button>
            )}
          </div>
        </div>
            </form>
        <div className="searchedContainer">
          <>
            {products?.map((product, index) => {
              console.log("product", product._id);
              return (
                <div className="ProductCard" key={index}>
                  <div className="card">
                    <div className="productInfo">
                      <div
                        className="productImage cursor-pointer"
                        onClick={() => {
                          history.push({
                            pathname: "/product-detailed",
                            state: { product: product },
                          });
                        }}
                      >
                        <img
                          className="image"
                          src={product?.images[0]?.url}
                          alt="product"
                        />
                      </div>
                      <div
                        className="productDetailes cursor-pointer"
                        onClick={() => {
                          history.push({
                            pathname: "/product-detailed",
                            state: { product: product },
                          });
                        }}
                      >
                        <h3>{product?.product_name.length > 20 ? product?.product_name?.slice(0, 20) : product?.product_name}</h3>
                        {/* <p>{product?.short_description} </p> */}
                      </div>
                      <div className="ProductPrice">
                        <div className="price">Price:</div>
                        <div className="priceContent">
                          <h3>
                            {" "}
                            <span>{product?.new_price} &#8373;</span>{" "}
                            <small>{product?.old_price} &#8373;</small>
                          </h3>
                          <div
                            className="addToCatBtn"
                            // onClick={() => {
                            //   // RemoveFavoriteProducts(product?._id);
                            // }}
                          >
                           <IconButton onClick={(e) => handleAddToBasket(e, product)}>
                           <ShoppingBasketOutlined className="icon" />
                           </IconButton>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="addFavourite"
                  style={{fontSize: '.7rem'}}
                    >
                      {`${product?.percent}%`}
                      {/* <FavoriteBorderOutlined className="icon" /> */}
                    </div>
                  </div>
                </div>
              );
            })}
          </>

        </div>
          {products.length > 0 && (
                <ReactPaginate
                  breakLabel="..."
                  nextLabel=">"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                  previousLabel="<"
                  renderOnZeroPageCount={null}
                  containerClassName="pagination"
                  pageLinkClassName="page-num"
                  previousLinkClassName="page-num"
                  nextLinkClassName="page-num"
                  activeLinkClassName="active"
                />
              )}
      </RenderHome>
    </div>
  );
}

export default SearchedProduct;
