/* eslint-disable  */
import { IconButton } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { PhotoCamera } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { bindActionCreators } from "redux";
import "../../../css/Pages/Pages.css";
import { authActionCreators } from "../../../service/action-creators";
import { GetNotifications } from "../../../service/action-creators/notificationAction";
import { GetCartProducts } from "../../../service/action-creators/userDataAction";
import Loader from "../../Loader";
import RenderHome from "../home/RenderHome";
import './UserProfile.css'
import Resizer from "react-image-file-resizer";


function UserProfile() {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    UpdateProfile,
    UpdateProfileImage,
    UpdateCoverImage,
    ResetResponse,
    GetUserProfile,
  } = bindActionCreators(authActionCreators, dispatch);
  const auth = useSelector((state) => state?.auth);
  const user = auth?.user;

  let initialFormData = {
    username: "",
    email: "",
    description: "",
    phone: "",
  };

  console.log("initialFormData", initialFormData);

  const [formData, setFormData] = useState(initialFormData);
  const [coverImagePreview, setCoverImagePreview] = useState(null);
  const [profileImagePreview, setProfileImagePreview] = useState(null);
  const [userData] = useState(user);
  const [profileUrl, setProfileUrl] = useState("")
  let timestamp = new Date().getTime();

  const isLoading = useSelector((state) => state?.auth?.isLoading);
  const handlechange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    // setFormData((prevState) => {
    //   return {
    //     ...prevState,
    //     [e.target.name]: e.target.value,
    //   };
    // });
  };
  console.log("userData", userData);

  const handleCoverImageChange = (e) => {
    if (e?.target.files[0]) {
      const file = e?.target.files[0];
      const reader = new FileReader();
      reader.onload = (ev) => {
        setCoverImagePreview(ev?.target?.result);
        try {
          Resizer.imageFileResizer(
            e.target.files[0],
            500,
            200,
            "png",
            100,
            0,
            (uri) => {
              console.log(uri);
              // setResizedProfileImage(uri)
              UpdateCoverImage(uri, user?.cover_image_key ?? '');
             
            },
            "file",
            200,
            200
          );
    
        } catch (err) {
          console.log(err);
        }
       
      };
      reader.readAsDataURL(file);
    }
  };
  const handleProfileImageChange = (e) => {
    if (e?.target.files[0]) {
      const file = e?.target.files[0];
      const reader = new FileReader();
      reader.onload = (ev) => {
        setProfileImagePreview(ev?.target?.result);
        try {
          Resizer.imageFileResizer(
            e.target.files[0],
            500,
            200,
            "png",
            100,
            0,
            (uri) => {
              console.log(uri);
              UpdateProfileImage(uri, user?.profile_image_key ?? '');
             
            },
            "file",
            200,
            200
          );
    
        } catch (err) {
          console.log(err);
        }
       
      };
      reader.readAsDataURL(file);
    }
  };


  console.log("isLoading", isLoading);
  // console.log("user", user);

  // console.log("coverImagePreview", coverImagePreview);
  // console.log("profileImagePreview", profileImagePreview);

  const handleSubmit = (e) => {
    e.preventDefault();
    const userImages = {
      profileImage: profileImagePreview,
      coverImage: coverImagePreview,
    };
    console.log("formData", formData);
    console.log("userImages", userImages);
    // UpdateProfile(formData, userImages);
  };

  useEffect(() => {
    GetUserProfile();
    dispatch(GetNotifications());
    dispatch(GetCartProducts());
    if (user) {
      setFormData(user);
    }
  }, []);

  useEffect(() => {
    setProfileUrl(user?.profile_image+'?'+timestamp)
  },[user?.profile_image])

  const refresh = () => window.location.reload(true);

  useEffect(() => {
    if (auth?.response?.state === "SUCCESS") {
      // toast.success(auth?.response?.message);
      setTimeout(() => {
        GetUserProfile();
        ResetResponse();
        refresh();
      }, 1500);
    }else if(auth?.response?.state === "FILE_UPLOADED"){
      GetUserProfile();
      ResetResponse();
      
    } else if (auth?.response?.state === "ERROR") {
      toast.error(auth?.response?.message);
      setTimeout(() => {
        ResetResponse();
      }, 1500);
    }
  }, [auth?.response?.state]);
  const Input = styled("input")({
    display: "none",
  });
  return (
    <div className="ProfilePage">
      <RenderHome title={"My Profile"}>
        {isLoading ? <Loader /> : ""}

        <div className="photos">
          <div
            className="coverPhoto"
            style={{
              // "/images/car.jpg"
              background: `url(${
                coverImagePreview ? coverImagePreview : user?.cover_image?.toString()
              }) no-repeat center center/cover`,
            }}
          >
              <img src={ coverImagePreview
                        ? coverImagePreview
                        : user?.cover_image} alt="" className=""/>
              {/* <img src={  coverImagePreview ? coverImagePreview : user?.cover_image } alt="" className="rounded-full"/> */}
            <label className="iconUploadButton" htmlFor="icon-button-file">
              <Input
                accept="image/*"
                id="icon-button-file"
                type="file"
                onChange={(e) => handleCoverImageChange(e)}
              />
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="span"
              >
                <PhotoCamera />
              </IconButton>
            </label>
          </div>
          <div className="profileImage">
            <div className="profile">
              <div className="pCover">
                <div
                  className="profileImgCover"
                  style={{
                    background: `url(${
                      profileImagePreview
                        ? profileImagePreview
                        : `${profileUrl}`
                    }) no-repeat center center/cover`,
                  }}
                >
                  <img src={ profileImagePreview
                        ? profileImagePreview
                        : user?.profile_image+'?'+timestamp} alt="" className=""/>
                  
                  <label className="iconUploadButton" htmlFor="profile">
                    <Input
                      accept="image/*"
                      name="profileImage"
                      id="profile"
                      type="file"
                      onChange={(e) => handleProfileImageChange(e)}
                    />
                    <IconButton color="primary" aria-label="" component="span">
                      <PhotoCamera />
                    </IconButton>
                  </label>
                </div>
              </div>

              <div>
                <h2 className="text-2xl font-bold">Profile</h2>
                <p className="text-gray-500">
                  Update your profile picture, cover photo and personal details
                </p>
              </div>
            </div>
            <div className="profile_btn">
              <button
                className="button_cancel cancelBtn"
                onClick={(e) => {
                  history.push("/home");
                }}
              >
                Cancel
              </button>
              <button className="button" onClick={handleSubmit}>
                Save Details
              </button>
            </div>
          </div>
        </div>
        <div className="profileDetails">
          <h2 className="font-bold">Profile Details</h2>
          <div className="username gridBox">
            <div className="text-gray-500">Username</div>
            <input
              type={"text"}
              name="username"
              defaultValue={user?.name?.split(' ')[1]}
              // onChange={(e) => handlechange(e)}
              readOnly
              className="form-control"
            />
          </div>
          <div className="row">
            <div className="email gridBox col-md-6 col-lg-6 col-sm-12">
              <div className="text-gray-500">Email address</div>
              <input
                type={"email"}
                // name="email"
                readOnly
                defaultValue={user?.email}
                className="form-control"
                placeholder="example@gmail.com"
                onChange={(e) => handlechange(e)}
                // value={user?.email}
              />
            </div>
            <div className="email gridBox col-md-6 col-lg-6 col-sm-12">
              <div className="text-gray-500">Date of birth</div>
              <input
                type={"date"}
                // name="dob"
                defaultValue={user?.dob}
                readOnly
                className="form-control"
                placeholder=""
                onChange={(e) => handlechange(e)}
                // value={user?.email}
              />
            </div>
          </div>

          <div className="phone_number gridBox">
            <div className="text-gray-500">Contact Number</div>
            <input
              type={"tel"}
              name="phone_number"
              className="form-control"
              placeholder=""
              defaultValue={user?.phone_number}
              onChange={(e) => handlechange(e)}
            />
          </div>
          <div className="email gridBox">
            <div>
              <div className="text-gray-500">Description</div>
              {/* Write a brief info about you */}
            </div>
            <textarea
              className="form-control"
              name="description"
              defaultValue={user?.description}
              onChange={(e) => handlechange(e)}
            ></textarea>
          </div>

          <h3 className="font-bold">Non Editable</h3>
          <div className="username gridBox">
            <div className="text-gray-500">Staff ID</div>
            <input
              type={"text"}
              defaultValue={user?.staff_id}
              readOnly
              name=""
              className="form-control"
            />
          </div>
          <div className="username gridBox">
            <div className="text-gray-500">Fullname</div>
            <input
              type={"text"}
              defaultValue={user?.name}
              readOnly
              name="name"
              className="form-control"
            />
          </div>
          <div className="username gridBox">
            <div className="text-gray-500">Facility</div>
            <input
              type={"text"}
              // value={user?.facility}
              defaultValue={user?.facility}
              readOnly
              name="name"
              className="form-control"
            />
          </div>
          <div className="username gridBox">
            <div className="text-gray-500">Department</div>
            <input
              type={"text"}
              // value={user?.facility}
              defaultValue={user?.department}
              readOnly
              name=""
              className="form-control"
            />
          </div>
          <div className="username gridBox">
            <div className="text-gray-500">Region</div>
            <input
              type={"text"}
              // value={user?.facility}
              defaultValue={user?.region}
              readOnly
              name=""
              className="form-control"
            />
          </div>
          <div className="username gridBox">
            <div className="text-gray-500">District</div>
            <input
              type={"text"}
              // value={user?.facility}
              defaultValue={user?.district}
              readOnly
              name="name"
              className="form-control"
            />
          </div>
        </div>
        <ToastContainer autoClose={3000} />
      </RenderHome>
    </div>
  );
}

export default UserProfile;
