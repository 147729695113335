/* eslint-disable  */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { userDataActionCreators } from "../../../service/action-creators";
import Loader from "../../Loader";
import RenderHome from "../home/RenderHome";
import Resizer from "react-image-file-resizer";
import { toast } from "react-toastify";

function EditMartForm() {
  const history = useHistory();
  const dispatch = useDispatch();

  const { GetAppliedOrderDetailed, ResetUserDataResponse, UpdateOrderTempIDCardImageUpload,UpdateOrderTempPassportImageUpload, EditMartApplicationFormByMember } = bindActionCreators(
    userDataActionCreators,
    dispatch
  );
  const [formData, setFormData] = useState({});
  const [age, setAge] = useState(null);
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [formError, setFormError] = useState({});
  const [seletedFiles, setSeletedFiles] = useState([]);
  const [previewFrontCard, setPreviewFrontCard] = useState(null);
  const [previewBackCard, setPreviewBackCard] = useState(null);
  const [previewPassportImage, setPreviewPassportImage] = useState(null);
  
  const order_id = history.location.state.order_id;

  const userData = useSelector((state) => state?.user);
  // const isLoading = useSelector((state) => state?.user?.isLoading);
  const auth = useSelector((state) => state?.auth);
  const user = auth?.user;

  const { response, isLoading, order } = userData;
  let timestamp = new Date().getTime();

  const handleFormChange = (e) => {
    e.preventDefault();
    setFormData({ ...formData, [e?.target?.name]: e?.target?.value });
  };

  // const handleDoBChange = (e) => {
  //   const today = new Date();
  //   const year = today.getFullYear();
  //   const dob = e?.target?.value.split("-")[0];

  //   const newAge = parseInt(year) - parseInt(dob);
  //   setAge(newAge);
  //   setDateOfBirth(e?.target?.value);
  //   setFormData({ ...formData, dateOfBirth: e?.target?.value, age: newAge });
  // };

  const handleGhanaCardUpload = (e) => {
    e.preventDefault();
    if (e?.target?.files[0]) {
      console.log("name", e?.target?.name);
      const file = e?.target?.files[0];
      const reader = new FileReader();
      reader.onload = (ev) => {
        // setPreviewBackCard(ev?.target?.result);
        setPreviewFrontCard(ev?.target?.result);

        try {
          Resizer.imageFileResizer(
            file,
            400,
            400,
            "png",
            100,
            0,
            (uri) => {
              console.log(uri);
              UpdateOrderTempIDCardImageUpload(
                uri, // file, 
                order?.frontIDImage_key ? order?.frontIDImage_key : "",
                order?._id,
                "main"
              );
            },
            "file",
            // 200,
            // 200
          );

        } catch (err) {
          console.log(err);
        }


      };
      setSeletedFiles([...seletedFiles, file]);
      reader.readAsDataURL(file);

      // formDatas.append("file", e.target.files[0]);
    }
  };
  const handlePassportChange = (e) => {
    e.preventDefault();
    if (e?.target?.files[0]) {
      console.log("name", e?.target?.name);
      const file = e?.target?.files[0];
      const reader = new FileReader();
      reader.onload = (ev) => {
        setPreviewPassportImage(ev?.target?.result);
        console.log("file", file);
        try {
          Resizer.imageFileResizer(
            file,
            400,
            400,
            "png",
            100,
            0,
            (uri) => {
              UpdateOrderTempPassportImageUpload(
                uri,
                order?.passportImage_key ? order?.passportImage_key : "",
                order?._id,
                "main"
              );
            },
            "file",
            // 200,
            // 200
          );

        } catch (err) {
          console.log(err);
        }

      };

      reader.readAsDataURL(file);

      // formDatas.append("file", e.target.files[0]);
    }
  };

  const handleFormEditSubmit = (e) => {
    e.preventDefault();
    // alert('12')
    console.log("formData", formData);
    EditMartApplicationFormByMember(formData, order?._id);
  };

  console.log('order', order)

  useEffect(() => {
    GetAppliedOrderDetailed(order_id);
  }, [order_id]);

  useEffect(() => {
    if (response?.state === "SUCCESS") {
      history.push("/my-orders-and-purchase")
    } else if (response?.state === "ERROR") {
      toast.error(response?.message);
      // GetAppliedLoanDetailed(loan_id)
      setTimeout(() => {
        ResetUserDataResponse();
      }, 1500);
    }else if(response?.state === "UPLOAD_SUCCESS"){
      toast.success(response?.message);
      window.location.reload(false);
      setTimeout(() => {
        ResetUserDataResponse();
      }, 1500);
    }
  }, [response?.state, response?.message]);

  return (
    <div className="EditMartForm">
     <RenderHome title={"Edit Loan Application Form"}>
          {isLoading ?? <Loader />}
     <form action="">
        <div className="form_area form1">
          <h3 className="formTitle pt-4 font-bold pb-2 text-md">Personal Information</h3>
          <div className="form-group row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <label htmlFor="fullname">
                Full Name<span className="bto-lb-required">*</span>
              </label>
              <input
                className="form-control"
                type="text"
                name="fullname"
                placeholder=""
                readOnly
                defaultValue={order?.name}
                id="fullname"
              />
            </div>

            {/* <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <label htmlFor="fullname">
                          Choose Your Region
                          <span className="bto-lb-required">*</span>
                        </label>
                        <select
                          name="sector"
                          className="form-control"
                          onChange={handleFormChange}
                        >
                         
                          <option value={""}></option>
                          <option value={"Southern"}>Greater Accra</option>
                          <option value={"Southern"}>Eastern</option>
                          <option value={"Southern"}>Central</option>
                          <option value={"Southern"}>Bono</option>
                          <option value={"Southern"}>Bono East</option>
                          <option value={"Southern"}>Ashanti</option>
                          <option value={"Southern"}>Ahafo</option>

                          <option value={"Northern"}>Western</option>
                          <option value={"Northern"}>Western North</option>
                          <option value={"Northern"}>Volta</option>
                          <option value={"Northern"}>Upper West</option>
                          <option value={"Northern"}>Upper East</option>
                          <option value={"Northern"}>Savannah</option>
                          <option value={"Northern"}>Oti</option>
                          <option value={"Northern"}>Northern</option>
                          <option value={"Northern"}>North East</option>
                        </select>
                      </div> */}
          </div>

          <div className="form-group row">
            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
              <label htmlFor="dateOfBirth">
                Date of Birth
                <span className="bto-lb-required">*</span>
              </label>
              <input
                className="form-control"
                type="date"
                name="dateOfBirth"
                id="dateOfBirth"
                defaultValue={order?.dateOfBirth}
                placeholder="Date of Birth"
                required
                aria-required="true"
                onChange={(e) => handleDoBChange(e)}
              />
            </div>
            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
              <label htmlFor="age">
                Age<span className="bto-lb-required"></span>
              </label>
              <input
                className="form-control"
                type="text"
                name="age"
                defaultValue={order?.age}
                id="age"
                required
                // value={age}
                readOnly
                placeholder=""
                aria-required="true"
                onChange={(e) => handleFormChange(e)}
              />
              {/* <span className="form-error">{formError.age}</span> */}
            </div>
            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
              <label htmlFor="ssnit">
                SSNIT<span className="bto-lb-required">*</span>
              </label>
              <input
                className="form-control"
                type="text"
                name="ssnit"
                id="ssnit"
                placeholder=""
                defaultValue={order?.ssnit}
                required
                aria-required="true"
                onChange={(e) => handleFormChange(e)}
              />
              {/* <span className="form-error">{formError.ssnit}</span> */}
            </div>
          </div>

          <div className="form-group row">
            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
              <label htmlFor="IdCardType">
                ID Type<span className="bto-lb-required">*</span>
              </label>
              <select
                name="IdCardType"
                id="IdCardType"
                value={"Ghana National Identification"}
                className="form-control"
                onChange={(e) => handleFormChange(e)}
              >
                <option value="Ghana National Identification">
                  Ghana National Identification{" "}
                </option>
              </select>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
              <label htmlFor="id_number">
                ID Number<span className="bto-lb-required">*</span>
              </label>
              <input
                className="form-control"
                type="text"
                name="IDCardNumber"
                placeholder=""
                defaultValue={order?.IDCardNumber}
                id="id_number"
                aria-required="true"
                onChange={(e) => handleFormChange(e)}
              />
              {/* <span className="form-error">
                          {formError.IDCardNumber}
                        </span> */}
            </div>
            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
              <label htmlFor="gender">
                Gender<span className="bto-lb-required">*</span>
              </label>
              <select
                name="gender"
                id="gender"
                // value={gender}
                onChange={(e) => handleFormChange(e)}
                className="form-control"
              >
                <option value={order?.gender}>{order?.gender}</option>
                <option value="Female">Female</option>
                <option value="Female">Male</option>
                <option value="Other">Other</option>
              </select>
              {/* <span className="form-error">{formError.gender}</span> */}
            </div>
          </div>

          <div className="form-group row">
            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
              <label>
                Residential Address
                <span className="bto-lb-required">*</span>
              </label>

              <input
                className="form-control"
                type="text"
                name="residential_address1"
                id="residential_address1"
                defaultValue={order?.residential_address1}
                aria-required="true"
                onChange={(e) => handleFormChange(e)}
              />
              <input
                className="form-control"
                type="text"
                name="residential_address2"
                id="residential_address2"
                aria-required="true"
                defaultValue={order?.residential_address2}
                onChange={(e) => handleFormChange(e)}
              />
              {/* <span className="form-error">
                          {formError.residential_address1}
                        </span> */}
            </div>
            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
              <label htmlFor="work_address1">
                Work Address<span className="bto-lb-required">*</span>
              </label>
              <input
                className="form-control form-group"
                type="text"
                name="work_address1"
                id="work_address1"
                defaultValue={order?.work_address1}
                aria-required="true"
                onChange={(e) => handleFormChange(e)}
              />
              <input
                className="form-control form-group"
                type="text"
                name="work_address2"
                defaultValue={order?.work_address2}
                aria-required="true"
                onChange={(e) => handleFormChange(e)}
              />
              {/* <span className="form-error">
                          {formError.work_address1}
                        </span> */}
            </div>
          </div>
        </div>

        <>
          {/* THE CONTACT DETAILS SECTION */}
          <h3 className="formTitle  pt-4 font-bold pb-2 text-md">Contact Details</h3>

          <div className="form-group row  pb-3">
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <label>
                Phone Number<span className="bto-lb-required">*</span>
              </label>
              <input
                className="form-control"
                type="text"
                name="personal_phone"
                defaultValue={order?.personal_phone}
                aria-required="true"
                onChange={(e) => handleFormChange(e)}
              />
           
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <label>
                Work Phone<span className="bto-lb-required">*</span>
              </label>
              <input
                className="form-control"
                type="text"
                defaultValue={order?.work_phone}
                name="work_phone"
                aria-required="true"
                onChange={(e) => handleFormChange(e)}
              />
          
            </div>
          </div>
          <div className="form-group row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <label>
                Email Address
                <span className="bto-lb-required">*</span>
              </label>
              <input
                className="form-control"
                type="email"
                name="email"
                defaultValue={order?.email}
                placeholder="example@gmail.com"
                aria-required="true"
                onChange={(e) => handleFormChange(e)}
              />
              {/* <span className="form-error">{formError.email}</span> */}
            </div>
          </div>
          <div className="form-group row">
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <label>
                Mandate Number 1
                <span className="bto-lb-required">*</span>
              </label>
              <input
                className="form-control"
                type="text"
                name="mandate_number"
                defaultValue={order?.mandate_number}
                aria-required="true"
                onChange={(e) => handleFormChange(e)}
              />
              {/* <span className="form-error">{formError.email}</span> */}
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <label>
                OTP Number 
                <span className="bto-lb-required">*</span>
              </label>
              <input
                className="form-control"
                type="text"
                name="otp_number"
                defaultValue={order?.otp_number}
                aria-required="true"
                onChange={(e) => handleFormChange(e)}
              />
              {/* <span className="form-error">{formError.email}</span> */}
            </div>
          </div>
          <div className="form-group row">
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <label>
                Mandate Number 2
                <span className="bto-lb-required">*</span>
              </label>
              <input
                className="form-control"
                type="text"
                name="mandate_number"
                defaultValue={order?.mandate_number2}
                aria-required="true"
                onChange={(e) => handleFormChange(e)}
              />
              {/* <span className="form-error">{formError.email}</span> */}
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <label>
                OTP Number 
                <span className="bto-lb-required">*</span>
              </label>
              <input
                className="form-control"
                type="text"
                name="otp_number2"
                defaultValue={order?.otp_number2}
                // placeholder="example@gmail.com"
                aria-required="true"
                onChange={(e) => handleFormChange(e)}
              />
              {/* <span className="form-error">{formError.email}</span> */}
            </div>
          </div>

        </>

        <div className="form_area form2">
          <div className="form-group row">
            <h3 className="formTitle pt-4 font-bold pb-2 text-md">Guarantors 1</h3>
            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
              <label>
                Name<span className="bto-lb-required">*</span>
              </label>
              <input
                className="form-control"
                type="text"
                name="guarantor_name"
                defaultValue={
                  order?.guarantor_name
                  // ||
                  // (loanTemp?.guarantor_name ?? loanTemp?.guarantor_name)
                }
                required
                aria-required="true"
                onChange={(e) => handleFormChange(e)}
              />
   
            </div>
            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
              <label>
                Staff ID<span className="bto-lb-required">*</span>
              </label>
              <input
                className="form-control"
                type="text"
                name="guarantor_staff_id"
                defaultValue={
                  order?.guarantor_staff_id
                  // ||
                  // (loanTemp?.guarantor_staff_id ??
                  //   loanTemp?.guarantor_staff_id)
                }
                lettersonly=""
                aria-required="true"
                onChange={(e) => handleFormChange(e)}
              />
          
            </div>
            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
              <label>
                Phone Number<span className="bto-lb-required">*</span>
              </label>
              <input
                className="form-control"
                type="text"
                required
                name="guarantor_phone"
                placeholder=""
                defaultValue={
                  order?.guarantor_phone
                  // ||
                  // (loanTemp?.guarantor_phone ?? loanTemp?.guarantor_phone)
                }
                aria-required="true"
                onChange={(e) => handleFormChange(e)}
              />
     
            </div>
          </div>

          <div className="form-group row">
            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
              <label>
                Ghana Card Number<span className="bto-lb-required">*</span>
              </label>
              <input
                className="form-control"
                type="text"
                name="guarantor_ghana_card"
                defaultValue={
                  order?.guarantor_ghana_card
                  // ||
                  // (loanTemp?.guarantor_ghana_card ??
                  //   loanTemp?.guarantor_ghana_card)
                }
                required
                aria-required="true"
                onChange={(e) => handleFormChange(e)}
              />
 
            </div>
            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
              <label>
                GPS Address<span className="bto-lb-required">*</span>
              </label>
              <input
                className="form-control"
                type="text"
                name="guarantor_gps"
                required
                defaultValue={
                  order?.guarantor_gps
                  // ||
                  // (loanTemp?.guarantor_gps ?? loanTemp?.guarantor_gps)
                }
                aria-required="true"
                onChange={(e) => handleFormChange(e)}
              />
          
            </div>

            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
              <label>
                Occupation
                <span className="bto-lb-required">*</span>
              </label>
              <input
                className="form-control"
                type="text"
                required
                name="guarantor_occupation"
                defaultValue={
                  order?.guarantor_occupation
                  // ||
                  // (loanTemp?.guarantor_occupation ??
                  //   loanTemp?.guarantor_occupation)
                }
                aria-required="true"
                onChange={(e) => handleFormChange(e)}
              />
     
            </div>
          </div>
          <div className="form-group row">
            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
              <label>
                Mandate Number<span className="bto-lb-required">*</span>
              </label>
              <input
                className="form-control"
                type="text"
                name="guarantor_mandate_number"
                defaultValue={
                  order?.guarantor_mandate_number
                  // ||
                  // (loanTemp?.guarantor_mandate_number ??
                  //   loanTemp?.guarantor_mandate_number)
                }
                required
                aria-required="true"
                onChange={(e) => handleFormChange(e)}
              />
              {/* <span className="error-message">
                    {formError.guarantor_mandate_number}
                  </span> */}
            </div>
            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
              <label>
                OTP Number<span className="bto-lb-required">*</span>
              </label>
              <input
                className="form-control"
                type="text"
                required
                name="guarantor_otp_number"
                defaultValue={
                  order?.guarantor_otp_number
                  //  ||
                  // (loanTemp?.guarantor_otp_number ??
                  //   loanTemp?.guarantor_otp_number)
                }
                aria-required="true"
                onChange={(e) => handleFormChange(e)}
              />
              {/* <span className="error-message">
                    {formError.guarantor_otp_number}
                  </span> */}
            </div>
            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
              <label>
                Relationship
                <span className="bto-lb-required">*</span>
              </label>
              <input
                className="form-control"
                type="text"
                required
                name="guarantor_relationship"
                defaultValue={
                  order?.guarantor_relationship
                  // ||
                  // (loanTemp?.guarantor_relationship ??
                  //   loanTemp?.guarantor_relationship)
                }
                aria-required="true"
                onChange={(e) => handleFormChange(e)}
              />
              {/* <span className="error-message">
                    {formError.guarantor_relationship}
                  </span> */}
            </div>
          </div>
          {/* SECOND GUARANTOR SECTION */}
          <div className="form-group row">
            <h3 className="formTitle pt-4 font-bold pb-2 text-md">Guarantors 2</h3>
            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
              <label>
                Name<span className="bto-lb-required">*</span>
              </label>
              <input
                className="form-control"
                type="text"
                name="guarantor_name2"
                defaultValue={
                  order?.guarantor_name2
                  // ||
                  // (loanTemp?.guarantor_name2 ?? loanTemp?.guarantor_name2)
                }
                required
                aria-required="true"
                onChange={(e) => handleFormChange(e)}
              />
              {/* <span className="error-message">
                    {formError.guarantor_name2}
                  </span> */}
            </div>
            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
              <label>
                Staff ID<span className="bto-lb-required">*</span>
              </label>
              <input
                className="form-control"
                type="text"
                name="guarantor_staff_id2"
                defaultValue={
                  order?.guarantor_staff_id2
                  // ||
                  // (loanTemp?.guarantor_staff_id2 ??
                  //   loanTemp?.guarantor_staff_id2)
                }
                aria-required="true"
                onChange={(e) => handleFormChange(e)}
              />
              {/* <span className="error-message">
                    {formError.guarantor_staff_id2}
                  </span> */}
            </div>
            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
              <label>
                Phone Number<span className="bto-lb-required">*</span>
              </label>
              <input
                className="form-control"
                type="text"
                required
                name="guarantor_phone2"
                placeholder=""
                defaultValue={
                  order?.guarantor_phone2
                  // ||
                  // (loanTemp?.guarantor_phone2 ?? loanTemp?.guarantor_phone2)
                }
                aria-required="true"
                onChange={(e) => handleFormChange(e)}
              />
              {/* <span className="error-message">
                    {formError.guarantor_phone2}
                  </span> */}
            </div>
          </div>

          <div className="form-group row">
            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
              <label>
                Ghana Card Number<span className="bto-lb-required">*</span>
              </label>
              <input
                className="form-control"
                type="text"
                name="guarantor_ghana_card2"
                defaultValue={
                  order?.guarantor_ghana_card2
                  // ||
                  // (loanTemp?.guarantor_ghana_card2 ??
                  //   loanTemp?.guarantor_ghana_card2)
                }
                required
                aria-required="true"
                onChange={(e) => handleFormChange(e)}
              />
              {/* <span className="error-message">
                    {formError.guarantor_ghana_card2}
                  </span> */}
            </div>
            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
              <label>
                GPS Address<span className="bto-lb-required">*</span>
              </label>
              <input
                className="form-control"
                type="text"
                name="guarantor_gps2"
                required
                defaultValue={
                  order?.guarantor_gps2
                  // ||
                  // (loanTemp?.guarantor_gps2 ?? loanTemp?.guarantor_gps2)
                }
                aria-required="true"
                onChange={(e) => handleFormChange(e)}
              />

            </div>

            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
              <label>
                Occupation
                <span className="bto-lb-required">*</span>
              </label>
              <input
                className="form-control"
                type="text"
                required
                name="guarantor_occupation2"
                defaultValue={
                  order?.guarantor_occupation2
                  // ||
                  // (loanTemp?.guarantor_occupation2 ??
                  //   loanTemp?.guarantor_occupation2)
                }
                aria-required="true"
                onChange={(e) => handleFormChange(e)}
              />

            </div>
          </div>
          <div className="form-group row">
            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
              <label>
                Mandate Number<span className="bto-lb-required">*</span>
              </label>
              <input
                className="form-control"
                type="text"
                name="guarantor_mandate_number2"
                defaultValue={
                  order?.guarantor_mandate_number2
                  // (loanTemp?.guarantor_mandate_number2 ??
                  //   loanTemp?.guarantor_mandate_number2)
                }
                required
                aria-required="true"
                onChange={(e) => handleFormChange(e)}
              />

            </div>
            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
              <label>
                OTP Number<span className="bto-lb-required">*</span>
              </label>
              <input
                className="form-control"
                type="text"
                required
                name="guarantor_otp_number2"
                defaultValue={
                  order?.guarantor_otp_number2
                  // ||
                  // (loanTemp?.guarantor_otp_number2 ??
                  //   loanTemp?.guarantor_otp_number2)
                }
                aria-required="true"
                onChange={(e) => handleFormChange(e)}
              />
            </div>



            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
              <label>
                Relationship
                <span className="bto-lb-required">*</span>
              </label>
              <input
                className="form-control"
                type="text"
                required
                name="guarantor_relationship2"
                defaultValue={
                  order?.guarantor_relationship2
                  // ||
                  // (loanTemp?.guarantor_relationship2 ??
                  //   loanTemp?.guarantor_relationship2)
                }
                aria-required="true"
                onChange={(e) => handleFormChange(e)}
              />
            </div>
          </div>
        </div>

        <div>
          {/* THE CONTACT DETAILS SECTION */}

          <div className="form-group">
            <h3 className="formTitle pt-4 font-bold pb-2 text-md">Employment Details</h3>
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <label>
                      Employer
                      <span className="bto-lb-required">*</span>
                    </label>

                    <select
                      className="form-control"
                      type="text"
                      name="employer_name"
                      aria-required="true"
                      onChange={(e) => handleFormChange(e)}
                    >
                      <option value={order?.employer_name}>
                        {order?.employer_name}
                      </option>
                      <option value={"MOH"}>MOH</option>
                      <option value={"GHS"}>GHS</option>
                      <option value={"CHAG"}>CHAG</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label>
                  Facility<span className="bto-lb-required">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  name="facility"
                  required
                  readOnly
                  value={order?.facility}
                  aria-required="true"
                  onChange={(e) => handleFormChange(e)}
                />
              </div>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
              <label>
                Staff ID<span className="bto-lb-required">*</span>
              </label>
              <input
                className="form-control"
                type="text"
                name="staff_id"
                readOnly
                value={order?.staff_id}
                required
                aria-required="true"
                onChange={(e) => handleFormChange(e)}
              />
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <label>
                Employment Start Date
                <span className="bto-lb-required">*</span>
              </label>
              <input
                className="form-control"
                type="date"
                name="employment_start_date"
                defaultValue={order?.employment_start_date}
                required
                aria-required="true"
                onChange={(e) => handleFormChange(e)}
              />
              {/* <span className="form-error">
                          {formError.employment_start_date}
                        </span> */}
            </div>
          </div>

          <div className="form-group row">
            {/* <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
              <label>
                Division<span className="bto-lb-required">*</span>
              </label>
              <input
                className="form-control"
                type="text"
                name="division"
                defaultValue={order?.division}
                required
                aria-required="true"
                onChange={(e) => handleFormChange(e)}
              />
             
            </div> */}
            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
              <label>
                Occupation<span className="bto-lb-required"></span>
              </label>
              <input
                className="form-control"
                type="text"
                name="occupation"
                defaultValue={order?.occupation}
                aria-required="true"
                onChange={(e) => handleFormChange(e)}
              />
            </div>
            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
              <label>
                Employer Location.
                <span className="bto-lb-required">*</span>
              </label>
              <input
                className="form-control"
                type="text"
                required
                name="employer_location"
                defaultValue={order?.employer_location}
                aria-required="true"
                onChange={(e) => handleFormChange(e)}
              />
              {/* <span className="form-error">
                          {formError.employer_location}
                        </span> */}
            </div>
          </div>
        </div>

        <>
          <h3 className="formTitle">Item Details </h3>
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <label>
                Affordablility
                <span className="bto-lb-required">*</span>
              </label>
              <input
                className="form-control"
                type="text"
                name="affordability"
                required
                defaultValue={order.affordability}
                //  value={user?.facility}
                aria-required="true"
                onChange={(e) => handleFormChange(e)}
              />
              {/* <span className="form-error">{formError.affordability}</span> */}
            </div>
          </div>

          <div className="row ">
                      <h3 className="formTitle">Product Info</h3>
                      <div className="productContainer">
                        <table className="table table-sm ">
                          <thead>
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">Product name</th>
                              <th scope="col">Qty</th>
                              <th scope="col">Terms (m)</th>
                              <th scope="col">instalment p/m</th>
                            </tr>
                          </thead>
                          <tbody>
                            {order?.order?.map((cart, index) => {
                              console.log("cart", cart);
                              return (
                                <tr key={index}>
                                  <th scope="row">{index + 1}</th>
                                  <td>{cart?.product_id?.product_name}</td>
                                  <td>{cart?.quantity}</td>
                                  <td>{cart?.period}</td>
                                  <td>{cart?.period_price}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>

              
                    <div className="form_area form4">
                  <h3 className="formTitle">Upload ID card</h3>
                  <div className="description">
                    Only Ghana National Identification card is accept. Other Id
                    cards will be rejected.
                  </div>
                  <div className="flex justify-around gap-6">
                  <div className="card_container">
                    <div className="image-card">
                      <img
                        src={
                          previewFrontCard ||
                          (order?.frontIDImage ?? order?.frontIDImage+'?'+timestamp)
                        }
                        alt="front-id"
                      />
                    </div>
                    <div className="labelAndInput">
                      <div className="label">Upload front card</div>
                      <input
                        type={"file"}
                        name="frontCard"
                        onChange={(e) => handleGhanaCardUpload(e)}
                      />
                    </div>
                  </div>

                  <div className="card_container">
                    <div className="image-card">
                      <img
                        src={
                          previewPassportImage ||
                          (order?.passportImage ?? order?.passportImage+'?'+timestamp)
                        }
                        alt="Passport Photo"
                      />
                    </div>
                    <div className="labelAndInput">
                      <div className="label">Upload Back</div>
                      <input
                        type={"file"}
                        name="passport"
                        onChange={(e) => handlePassportChange(e)}
                      />
                    </div>
                  </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "1rem",
                    }}
                  >
                    <button
                      className="button_cancel backBtn"
                      // type="button"
                      onClick={() => history.push("/home")}
                    >
                      Home
                    </button>
                    <button
                      className="button"
                      // type="button"
                      onClick={(e) => {
                        handleFormEditSubmit(e);
                      }}
                    >
                      Save and re-submit
                    </button>
                  </div>
                </div>
        </>
      </form>
     </RenderHome>
    </div>
  );
}

export default EditMartForm;
