/* eslint-disable  */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
// import "../../../css/Dashboard/home/Home.css";
import {
  messagesActionCreators,
  userDataActionCreators,
} from "../../../service/action-creators";
import { GetUserProfile } from "../../../service/action-creators/authAction";
import { GetNotifications } from "../../../service/action-creators/notificationAction";
import { GetCartProducts } from "../../../service/action-creators/userDataAction";
import Loader from "../../Loader";
import Modal from "../../Modal/Modal";
import RenderHome from "../home/RenderHome";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "./MessageAdmin.css";
import { Delete, Remove } from "@material-ui/icons";
function MessageAdmin() {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    GetConversations,
    GetUsersForChat,
    MarkMessageAsRead,
    ResetMessagesResponse,
    addMessages,
    getMessages,
  } = bindActionCreators(messagesActionCreators, dispatch);

  const auth = useSelector((state) => state.auth);
  const chat = useSelector((state) => state.messages);

  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({});
  const [formError, setFormError] = useState({});

  const { usersForChat, response, messages, isLoading } = chat;

  console.log("chat", chat);
  console.log("messages", messages);

  useEffect(() => {
    dispatch(GetUserProfile());
    dispatch(GetNotifications());
    dispatch(GetCartProducts());
    GetUsersForChat();
    // getMessages('authhere new');
    // GetConversations(auth?.user?._id);
  }, []);

  useEffect(() => {
    getMessages(auth?.user?._id);
    // GetConversations(auth?.user?._id);
  }, [auth?.user?._id]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e?.target?.name]: e?.target.value });
  };
  const validateForm = () => {
    let err = {};
 
    if (formData.system_user === "" || !formData.system_user) {
      err.system_user = "Please choose department";
    }
    if (formData.message === "" || !formData.message) {
      err.message = "Message field is required";
    }

    // if (formData.subject === "" || !formData.subject) {
    //   err.subject = "Subject field is required";
    // }
    

    setFormError({ ...err });
    const keys = Object.keys(err);
    return Object.keys(err).length < 1;
    // return false
  };

  const handleSubmit = () => {
    let isValid = validateForm();
    if(isValid){
      console.log(formData);
      const payload = {
        ...formData,
        user_id: auth?.user?._id,
        member_profile: auth?.user?.profile_image,
        reference: "",
      };
      addMessages(payload);
      setOpen(false);
    }

  };

  const handleDeleteDelete = () => {
    alert("delete");
  };

  useEffect(() => {
    if (response.state === "SUCCESS") {
      getMessages(auth?.user?._id);
      // toast.success(response?.message ?? "Notifications loaded");
      setTimeout(() => {
        ResetMessagesResponse();
      }, 1500);
    } else if (response.state === "ERROR") {
      // toast.error(response?.message ?? "unable to get notification");
      setTimeout(() => {
        ResetMessagesResponse();
      }, 1500);
    }
  }, [response.state]);

  const device = () => {
    return window.innerWidth < 1080;
  };

  const [isMobile, setIsMobile] = useState(device);
  useEffect(() => {
    window.addEventListener("resize", () => {
      setIsMobile(device);
    });
    return 0;
  }, []);

  return (
    <>
      <div className="MessagesPage">
        <RenderHome title={"Messages"}>
          {isLoading ? <Loader /> : ""}
          <div className="searchAndGridList">
            <div className="searchCover">
              <input
                type={"search"}
                name="searchProduct"
                placeholder="search by conversation"
                //   onChange={handleSearch}
              />
            </div>
            <div className="listNdGridIcons">
              <div
                onClick={() => setOpen(true)}
                className="button cursor-pointer"
              >
                Start Chat
              </div>
              {/* <FiDownloadCloud className="icon"/> */}
            </div>
          </div>

          <div className="card messageTable">
            <table className="table table-stripe">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Subject</th>
                  <th scope="col">Department</th>
                  {/* <th scope="col">Status</th> */}
                  <th scope="col">last update</th>
                  {/* <th scope="col"></th> */}
                </tr>
              </thead>
              <tbody>
                {messages?.length > 0 ? (
                  messages?.map((message, index) => {
                    let chatArr = message?.chat;
                    let last = chatArr?.at(-1);
                    let color = message?.isOpen ? "bg-blue-400" : "bg-red-200";
                    return (
                      <tr
                        key={index}
                        onClick={() => {
                          MarkMessageAsRead(message?._id, last?._id);
                          history.push({
                            pathname: "/message-detailed",
                            state: { chat_id: message?._id },
                          });
                        }}
                      >
                        <td className={`${last?.member_read ? '' : 'font-bold'}`}>{message?.reference}</td>
                        <td>
                          <div className={`${last?.member_read ? '' : 'font-bold'}`}>{message?.subject}</div>
                          <p className="text-gray-400">
                            {last?.message}
                            {/* {chatArr[chatArr.length - 1]} */}
                          </p>
                        </td>
                        <td>{message?.system_user}</td>
                        {/* <td className="rounded-full bg-red-400">
                          <span className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                            <span
                              aria-hidden
                              className={`absolute inset-0 ${color} rounded-full`}
                            ></span>
                            <span className="relative">
                              {message?.isOpen ? "Open" : "Closed"}
                            </span>
                          </span>
                        </td> */}
                        <td>
                          {moment(message?.updated_at).format("MMM Do YY")}
                        </td>
                        {/* <td>
                          <span onClick={handleDeleteDelete}>
                            <Delete />
                          </span>
                        </td> */}
                      </tr>
                    );
                  })
                ) : (
                  <tr>No Message Available</tr>
                )}
              </tbody>
            </table>
          </div>

          {open && (
            <Modal
              title={"Start new conversation"}
              width={isMobile ? "85%" : "35%"}
              closeModal={setOpen}
            >
              <div className="modalBody">
                {/* <div className="row"> */}
                <div className="">
                  <label htmlFor="fullname">
                    Choose Department<span className="bto-lb-required">*</span>
                  </label>
                  <select
                    className="form-control"
                    name="system_user"
                    onChange={handleChange}
                  >
                    <option value={""}></option>
                    {/* <option value={"System Support"}>System support</option> */}
                    <option value={"Mart Officer"}>Mart Officer</option>
                    <option value={"Loan Officer"}>Loan Officer</option>
                  </select>
                  <span className="error-message">{formError.system_user}</span>
                </div>

                {/* <div>
                  <label htmlFor="newPassword">Subject:</label>
                  <input
                    type={"text"}
                    placeholder={""}
                    name="subject"
                    id="subject"
                    onChange={handleChange}
                    className="form-control mb-4"
                  />
                  <span className="error-message">{formError.subject}</span>

                </div> */}

                <div>
                  <label htmlFor="confirmPassword">Message:</label>
                  <textarea
                    name="message"
                    onChange={handleChange}
                    className="form-control"
                    placeholder="write your message here..."
                  ></textarea>
                  <span className="error-message">{formError.message}</span>

                </div>
              </div>
              <div className="modalButton">
                <div className="btnCover">
                  <button
                    type="button"
                    className="modalBtn"
                    onClick={handleSubmit}
                  >
                    Send Message
                  </button>

                  <button
                    data-modal-toggle=""
                    type="button"
                    onClick={() => setOpen(false)}
                    className="cancelBtn"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Modal>
          )}

          {/*           
        {loans.length > 0 && (
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel=">"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                   containerClassName="pagination"
                    pageLinkClassName="page-num"
                    previousLinkClassName="page-num"
                    nextLinkClassName="page-num"
                    activeLinkClassName="active"
                  />
                )} */}
        </RenderHome>
        <ToastContainer autoClose={3000} />
      </div>
    </>
  );
}

export default MessageAdmin;
