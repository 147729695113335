/* eslint-disable  */
import React, { useEffect, useState } from 'react'
import RenderHome from '../home/RenderHome'
import './FundIncrement.css'
import { bindActionCreators } from 'redux';
import { userDataActionCreators } from '../../../service/action-creators';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../Loader';
import { ToastContainer, toast } from 'react-toastify';
import { Avatar } from '@material-ui/core';
import { useHistory } from "react-router-dom";
import Resizer from "react-image-file-resizer";
import { GetUserProfile } from '../../../service/action-creators/authAction';

function FundIncrement() {
    const history = useHistory()
    const dispatch = useDispatch()
    const { IncreaseFund, ResetUserDataResponse, GetMyIncreaseFund, } =
        bindActionCreators(userDataActionCreators, dispatch);
    const [formData, setFormData] = useState({})
    const [payslipImagePrev, setPayslipImagePrev] = useState(null);
    const [passportImagePrev, setPassportImagePrev] = useState(null);

    const [selectedImageFiles, setSelectedImageFiles] = useState([]);
    const [ghanaCardPrev, setGhanaCardPrev] = useState(null);
    const [formError, setFormError] = useState({});

    const user = useSelector((state) => state.user);
    const { isLoading, response, fund_increment } = user;
    const auth = useSelector((state) => state?.auth);
    // const user = auth?.user;

    const handleFormChange = (e) => {
        e.preventDefault();
        setFormData({ ...formData, [e?.target?.name]: e?.target?.value });
    };

    const handleIncreaseFundSubmit = (e) => {
        e.preventDefault()

        console.log('formData', formData)
        console.log('selectedImageFiles', selectedImageFiles)
        let isValid = validateForm();
        console.log('isValid', isValid)
        if (isValid) {
            IncreaseFund({...formData, staff_id: auth?.user?.staff_id})
        }
    }

    useEffect(()=>{
        dispatch(GetUserProfile());
    },[])

    const validateForm = () => {
        let err = {};

        if (formData.name === "" || !formData.name) {
            err.name = "Please enter the name on your  payslip";
        }

        // if (formData.staff_id === "" || !formData.staff_id) {
        //     err.staff_id = "Please staff id is required";
        // }

        if (formData.phone_number === "" || !formData.phone_number) {
            err.phone_number = "Please phone number is required";
        }
        if (formData.facility === "" || !formData.facility) {
            err.facility = "Please facility is required";
        }
        if (formData.mandate_number === "" || !formData.mandate_number) {
            err.mandate_number = "Please mandate number is required";
        }
        if (formData.dob === "" || !formData.dob) {
            err.dob = "Please date of birth is required";
        }
        if (formData.mandate_number === "" || !formData.mandate_number) {
            err.mandate_number = "Please mandate_number is required";
        }

        if (formData.next_of_kin === "" || !formData.next_of_kin) {
            err.next_of_kin = " Next of kin is required";
        }
        if (formData.IDCardNumber === "" || !formData.IDCardNumber) {
            err.IDCardNumber = " ID card number is required";
        }
        if (formData.current_contribution === "" || !formData.current_contribution) {
            err.current_contribution = " Current contribution is required";
        }
        if (formData.requested_increment === "" || !formData.requested_increment) {
            err.requested_increment = " Requested increment is required";
        }
      

        setFormError({ ...err });
        const keys = Object.keys(err);
        return Object.keys(err).length < 1;
        // return false
    };

    console.log('payslipImagePrev', payslipImagePrev)

    // const handlePassportImageChange = (e) => {
    //     if (e?.target.files[0]) {
    //         const file = e?.target.files[0];
    //         const reader = new FileReader();

    //         reader.onload = (ev) => {
    //             setPassportImagePrev(ev?.target?.result);
    //             console.log('prof', ev?.target?.result)
    //             try {
    //                 Resizer.imageFileResizer(
    //                     e.target.files[0],
    //                     100,
    //                     100,
    //                     "png",
    //                     100,
    //                     0,
    //                     (uri) => {
    //                         console.log(uri);
    //                         setSelectedImageFiles(prevState => [...prevState, uri])
    //                     },
    //                     "file",
    //                     200,
    //                     200
    //                 );

    //             } catch (err) {
    //                 console.log(err);
    //             }

    //         };
    //         reader.readAsDataURL(file);
    //     }
    // };
    // const handleGhanaCardImageChange = (e) => {
    //     if (e?.target.files[0]) {
    //         const file = e?.target.files[0];
    //         const reader = new FileReader();

    //         reader.onload = (ev) => {
    //             setGhanaCardPrev(ev?.target?.result);
    //             console.log('prof', ev?.target?.result)
    //             try {
    //                 Resizer.imageFileResizer(
    //                     e.target.files[0],
    //                     100,
    //                     100,
    //                     "png",
    //                     100,
    //                     0,
    //                     (uri) => {
    //                         console.log(uri);
    //                         setSelectedImageFiles(prevState => [...prevState, uri])
    //                     },
    //                     "file",
    //                     200,
    //                     200
    //                 );

    //             } catch (err) {
    //                 console.log(err);
    //             }

    //         };
    //         reader.readAsDataURL(file);
    //     }
    // };
    // const handlePayslipChange = (e) => {
    //     if (e?.target.files[0]) {
    //         const file = e?.target.files[0];
    //         const reader = new FileReader();

    //         setSelectedImageFiles(prevState => [...prevState, file])
    //         setPayslipImagePrev(file)

        
    //     }
    // };

    useEffect(() => {
        GetMyIncreaseFund()
    }, [])

    useEffect(() => {
        if (
            response?.state === "SUCCESS"
        ) {
            // toast.success(response?.message);
            GetMyIncreaseFund()
            setTimeout(() => {
                ResetUserDataResponse()
            }, 1500);
        } else if (
            response?.state === "ERROR"
        ) {
            // toast.error(response?.message);
            setTimeout(() => {
                ResetUserDataResponse();
            }, 1500);
        }
    }, [response?.state]);
    return (
        <RenderHome>
            <div className='FundIncrement'>
                {isLoading && <Loader />}
                <h1 className='pageTitle'>Fund Increment / Decrement</h1>
                <div className='pageWrapper'>
                    <form>

                        <div className="form-group row mb-2">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <label>
                                    Name (As appears on payslip)<span className="bto-lb-required">*</span>
                                </label>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="name"
                                    aria-required="true"
                                    onChange={(e) => handleFormChange(e)}
                                />
                                <span className="error-message">{formError.name}</span>
                            </div>
                        </div>

                        <div className="form-group row mb-2">
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                <label htmlFor="staff_id">
                                    Staff ID<span className="bto-lb-required">*</span>
                                </label>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="staff_id"
                                    id="staff_id"
                                    required
                                    readOnly
                                    defaultValue={auth?.user?.staff_id}
                                    placeholder=""
                                    aria-required="true"
                                    onChange={(e) => handleFormChange(e)}
                                />
                            
                            </div>

                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                <label htmlFor="phone_number">
                                    Phone Number<span className="bto-lb-required">*</span>
                                </label>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="phone_number"
                                    id="phone_number"
                                    required
                                    placeholder=""
                                    aria-required="true"
                                    onChange={(e) => handleFormChange(e)}
                                />
                                <span className="error-message">{formError.phone_number}</span>
                            </div>




                        </div>
                        <div className="form-group row mb-2">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <label>
                                    Facility <span className="bto-lb-required">*</span>
                                </label>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="facility"
                                    aria-required="true"
                                    onChange={(e) => handleFormChange(e)}
                                />
                                <span className="error-message">{formError.facility}</span>
                            </div>
                        </div>

                        <div className="form-group row mb-4">
                            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                                <label htmlFor="dob">
                                    Date of Birth<span className="bto-lb-required">*</span>
                                </label>
                                <input
                                    className="form-control"
                                    type="date"
                                    name="dob"
                                    id="dob"
                                    required
                                    placeholder=""
                                    aria-required="true"
                                    onChange={(e) => handleFormChange(e)}
                                />
                                <span className="error-message">{formError.dob}</span>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                                <label htmlFor="mandate_number">
                                    Mandate Number<span className="bto-lb-required">*</span>
                                </label>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="mandate_number"
                                    id="mandate_number"
                                    required
                                    placeholder=""
                                    aria-required="true"
                                    onChange={(e) => handleFormChange(e)}
                                />
                                <span className="error-message">{formError.mandate_number}</span>
                            </div>

                            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                                <label htmlFor="next_of_kin">
                                    Next of Kin<span className="bto-lb-required">*</span>
                                </label>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="next_of_kin"
                                    id="next_of_kin"
                                    placeholder=""
                                    required
                                    aria-required="true"
                                    onChange={(e) => handleFormChange(e)}
                                />
                                <span className="error-message">{formError.next_of_kin}</span>
                            </div>
                        </div>

                        <div className="form-group row mb-4">
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                <label htmlFor="IdCardType">
                                    ID Type<span className="bto-lb-required">*</span>
                                </label>
                                <select
                                    name="IdCardType"
                                    id="IdCardType"
                                    value={"Ghana National Identification"}
                                    className="form-control"
                                    onChange={(e) => handleFormChange(e)}
                                >
                                    <option value="Ghana National Identification">
                                        Ghana National Identification{" "}
                                    </option>
                   
                                </select>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                <label htmlFor="id_number">
                                    ID Number<span className="bto-lb-required">*</span>
                                </label>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="IDCardNumber"
                                    placeholder=""
                                    id="id_number"
                                    aria-required="true"
                                    onChange={(e) => handleFormChange(e)}
                                />
                                <span className="error-message">{formError.IDCardNumber}</span>
                            </div>

                        </div>

                        <div className="form-group row mb-4">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <label>
                                    Current contribution (Amount in Ghana Cedis)<span className="bto-lb-required">*</span>
                                </label>
                                <input
                                    className="form-control"
                                    type="number"
                                    name="current_contribution"
                                    aria-required="true"
                                    onChange={(e) => handleFormChange(e)}
                                />
                                <span className="error-message">{formError.current_contribution}</span>
                            </div>
                        </div>
                        <div className="form-group row mb-4">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <label>
                                    Requested Increment / Decrease Amount (GHC)<span className="bto-lb-required">*</span>
                                </label>
                                <input
                                    className="form-control"
                                    type="number"
                                    name="requested_increment"
                                    aria-required="true"
                                    onChange={(e) => handleFormChange(e)}
                                />
                                <span className="error-message">{formError.requested_increment}</span>
                            </div>
                        </div>
                        <div>

                   
                        </div>
                        {/* <label htmlFor="id_number">
                            Uploads<span className="bto-lb-required">*</span>
                        </label>
                        <div className='uploadWrapper'>
                            <div className="flex items-center justify-center w-full">
                                <label
                                    htmlFor="payslipImage"
                                    className="flex flex-col items-center justify-center w-full h-20 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                                >
                                    <div className="flex flex-col items-center justify-center pt-0 pb-0">
                                        <svg
                                            aria-hidden="true"
                                            className="w-7 h-6 mb-1 text-gray-400"
                                            fill="none"
                                            stroke="currentColor"
                                            viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                                            ></path>
                                        </svg>
                                        <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                        <span className="font-small text-xs font-bold text-black">
                                                Click to upload recent payslip
                                            </span>
                                        </p>
                                      
                                    </div>
                                    <input
                                        id="payslipImage"
                                        onChange={handlePayslipChange}
                                        type="file"
                                        accept='application/pdf'
                                        className="hidden"
                                    />
                                </label>
                                <div
                                    style={{
                                        width: "150px",
                                        height: "100px",
                                        overflow: 'hidden',
                               
                                    }}

                                    className="ml-2  border-2 border-gray-300 border-dotted "

                                >
                                    {payslipImagePrev?.name}
                               
                                </div>
                            </div>
                            <div className="flex items-center justify-center w-full">
                                <label
                                    htmlFor="ghanaCard"
                                    className="flex flex-col items-center justify-center w-full h-20 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                                >
                                    <div className="flex flex-col items-center justify-center pt-0 pb-0">
                                        <svg
                                            aria-hidden="true"
                                            className="w-7 h-6 mb-1 text-gray-400"
                                            fill="none"
                                            stroke="currentColor"
                                            viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                                            ></path>
                                        </svg>
                                        <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                        <span className="font-small text-xs font-bold text-black">
                                                Click to upload ID Card
                                            </span>
                                        </p>
                                        
                                    </div>
                                    <input
                                        id="ghanaCard"
                                        onChange={handleGhanaCardImageChange}
                                        type="file"
                                        className="hidden"
                                    />
                                </label>
                                <div
                                    style={{
                                        width: "150px",
                                        height: "100px",
                                        overflow: 'hidden',
                                 
                                    }}

                                    className="ml-2  border-2 border-gray-300 border-dotted "

                                >
                                    <img
                                        style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                                        src={
                                            ghanaCardPrev
                                                ? ghanaCardPrev.toString()
                                                : null
                                        }
                                    />
                                  
                                </div>
                            </div>
                            <div className="flex items-center justify-center w-full">
                                <label
                                    htmlFor="passportImage"
                                    className="flex flex-col items-center justify-center w-full h-20 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                                >
                                    <div className="flex flex-col items-center justify-center pt-0 pb-0">
                                        <svg
                                            aria-hidden="true"
                                            className="w-7 h-6 mb-1 text-gray-400"
                                            fill="none"
                                            stroke="currentColor"
                                            viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                                            ></path>
                                        </svg>
                                        <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                            <span className="font-small text-xs font-bold text-black">
                                                Click to upload passport image
                                            </span>
                                        </p>
                                       
                                    </div>
                                    <input
                                        id="passportImage"
                                        onChange={handlePassportImageChange}
                                        type="file"
                                        className="hidden"
                                    />
                                </label>
                                <div
                                    style={{
                                        width: "150px",
                                        height: "100px",
                                        overflow: 'hidden',
                                      
                                    }}

                                    className="ml-2  border-2 border-gray-300 border-dotted "

                                >
                                    <img
                                        style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                                        src={
                                            passportImagePrev
                                                ? passportImagePrev.toString()
                                                : null
                                        }
                                    />
                                   
                                </div>
                            </div>

                        </div> */}

                        <div className='submitButtonContainer'>
                            <button
                                className="button"
                                style={{ background: 'var(--primaryColor' }}
                                name=""
                                type="submit"
                                onClick={(e) => {
                                    handleIncreaseFundSubmit(e)
                                }}
                            >
                                Submit Application
                            </button>
                        </div>
                    </form>
                    <div className='incrementDisplay'>

                        {fund_increment.length > 0 && (
                            <>
                                {fund_increment?.map((fund, index) => {
                                    return <div key={index} className="statusContainer  cursor-pointer">
                                        <div className="flex">
                                            {/* <Avatar src="/images/loan (1).png" className="icon" /> */}
                                            <Avatar src={auth?.user?.profile_image} className="icon mr-3" />
                                            <div>
                                                <div><strong>NB: </strong> Please this will take effect after 3months</div>
                                                <h5 className="notif_title pb-1">Requested Amount: <span className='text-bold'>{fund?.requested_increment}</span></h5>
                                                <h5 className="notif_title pb-1">Current contribution: <span>{fund?.current_contribution}</span></h5>
                                                <div className="notif_msg pb-1">Start date: {fund?.created_at}</div>
                                                
                                                {/* <div className="status"><span className='px-2 rounded-full' style={{ background: `${fund?.status === 'Approved' ? 'var(--success)' : 'var(--warning)' }`, color: `${fund?.status === 'Pending' ? 'white' : 'white' }` }}>{fund?.status}</span></div> */}
                                            </div>
                                        </div>

                                    </div>
                                })}
                            </>
                        )}


                    </div>
                </div>

                <ToastContainer autoClose={3000} />
            </div>
        </RenderHome>
    )
}

export default FundIncrement