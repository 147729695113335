/* eslint-disable */
import { IconButton } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { debounce } from "lodash";
import React, { useEffect, useState } from "react";
import { BsCartPlus } from "react-icons/bs";
import { MdFavorite, MdOutlineFavoriteBorder } from "react-icons/md";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { bindActionCreators } from "redux";
import { productActionCreators, userDataActionCreators } from "../../../../../service/action-creators";
import { GetUserProfile } from "../../../../../service/action-creators/authAction";
import { GetNotifications } from "../../../../../service/action-creators/notificationAction";
import { GetCartProducts } from "../../../../../service/action-creators/userDataAction";
import CidiSymbol from "../../../../CidiSymbol";
import Loader from "../../../../Loader";
import NotFoundComponent from "../../../../NotFoundComponent";
import RenderHome from "../../../home/RenderHome";
import "./ViewAllProductInACategory.css";
function ViewAllProductInACategory() {
  const dispatch = useDispatch();
  const history = useHistory();
  const query = new URLSearchParams(useLocation()?.search);
  const { AddFavoriteProducts,  AddProductToCart, ResetUserDataResponse } = bindActionCreators(
    userDataActionCreators,
    dispatch
  );
  const { GetProductsByCategorName,  } = bindActionCreators(
    productActionCreators,
    dispatch
  );
  const [searchValue, setSetSearchValue] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const productStore = useSelector((state) => state.product);
  // const isLoading = productStore.isLoading;
  // const products = productStore.products;

  const {isLoading, products, paginate, response} = productStore

  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 15;
  const [currentPage, setCurrentPage] = useState(0);
  const user = useSelector((state) => state.user);
  const carts = user?.cart;
  const [limit, setLimit] = useState(15)


  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;

    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setCurrentItems(products?.slice(itemOffset, endOffset));
    setPageCount(Number(paginate?.total));
  }, [itemOffset, itemsPerPage, products]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    console.log("event", event);

  
    GetProductsByCategorName(true, 1, event.selected + 1, query.get("query"));

    console.log("currentPage", currentPage);

    const newOffset = (event.selected * itemsPerPage) % paginate?.total;

    setItemOffset(newOffset);
  };

  const favProducts = user.favoriteProducts
  const favProductIds = []
  favProducts.map((fav) => favProductIds.push(fav?.product_id?._id))
console.log('favProductIds', favProductIds)


  console.log("products.products", products);

  useEffect(() => {
    GetProductsByCategorName(true, 10, 1, query.get("query"));
    dispatch(GetCartProducts());
    dispatch(GetNotifications());
    dispatch(GetUserProfile());
  }, []);

 



  const handleSearchButton = (e) => {
    e.preventDefault()
    console.log('searchValue', searchValue)
    if (searchValue) {
      GetProductsByCategorName(true, 15, 1, query.get("query"), `${searchValue ? "&search=" + searchValue : null}`);
      // SearchProducts(searchValue);
      setIsModalOpen(false);
      setSetSearchValue(null);
    } else {
      GetProductsByCategorName(true, 10, 1, query.get("query"));
    }
  };

  const handleAddToCart = (e, product) => {
    e.preventDefault()
    if(carts?.length > 0){
      // alert(carts[0]?.period)
      let selectedDuration;
      let percentChange;

      if (Number(carts[0]?.period) === 24) {
        percentChange = 1.2
        selectedDuration = Number(carts[0]?.period);
      } else if (Number(carts[0]?.period) === 18) {
        percentChange = 1.145
        selectedDuration = Number(carts[0]?.period);
      } else if (
        // Number(carts[0].period) == 1 ||
        Number(carts[0]?.period) === 3 ||
        Number(carts[0]?.period) === 6 ||
        Number(carts[0]?.period) === 12
      ) {
        percentChange = 1.1
        selectedDuration = Number(carts[0]?.period); // 10%
      }

      const adminCharge = 3 / 100;
      const retailPrice = Number(product?.new_price)
      const adminAmount = retailPrice * adminCharge;
      const initialAmount = (retailPrice * percentChange) + adminAmount;
  
  
      console.log('retailPrice', retailPrice)
     
  
      // setChangedPrice(Number(initialAmount / selectedDuration).toFixed(2))
      // setChangedPrice(product?.new_price)
  
      // OLD PRICE
      // const retailOldPrice = Number(product?.old_price)
      // const adminOldAmount = retailOldPrice * adminCharge;
      // const initialOldAmount = (retailOldPrice * percentChange) + adminOldAmount;
  
  
         const selectedPeriod = Number(initialAmount / selectedDuration).toFixed(2)
   
         const payload = {
          size: '',
          color: '',
          quantity: 1,
          product_id: product?._id,
          period: selectedDuration,
          period_price: selectedPeriod,
    
        }
        AddProductToCart(payload)
  
      // setChangedOldPrice(Number(initialOldAmount / selectedDuration).toFixed(2))
  
    }else{
      
    const defaultDuration = 3
    const percentChange = 1.1
    const adminCharge = 3 / 100;
    const retailPrice = Number(product?.new_price)
    const adminAmount = retailPrice * adminCharge;
    const initialAmount = (retailPrice * percentChange) + adminAmount;
    const selectedPeriod = Number(initialAmount / defaultDuration).toFixed(2)

    const payload = {
      size: '',
      color: '',
      quantity: 1,
      product_id: product?._id,
      period: defaultDuration,
      period_price: selectedPeriod,

    }
    AddProductToCart(payload)
   
    }



  }
  const handleAddToFavourite =(product_id) => {
    console.log('fav', product_id)
    AddFavoriteProducts(product_id)
  }

  const onSearchProduct = debounce((e) => {
    if (e.target.value) {
      setSetSearchValue(e.target.value ?? null);
    } else {
      setSetSearchValue(e.target.value);
    }
  }, 800);

  useEffect(() => {
    if (response?.state === "SUCCESS") {
      toast.success(response?.message);
      dispatch(GetCartProducts());
      setTimeout(() => {
        ResetUserDataResponse();
      }, 1500);
    } else if (response?.state === "ERROR") {
      toast.error(response?.message);
      setTimeout(() => {
        ResetUserDataResponse();
      }, 1500);
    }
  }, [response?.state]);

  return (
    <div>
      <RenderHome>
        <span className="backbtn" onClick={() => history.goBack()}>back</span>
        {isLoading && <Loader />}
        
        <div className="Productilters">
          <div className="product_sort">
            <div className="sortContainer">
           
              {/* <select id="sort" className="form-control">
                <option>sort by</option>
              </select> */}
               <select
                id="sort"
              className="form-control"
              onChange={(e) => setLimit(e.target.value)}
              >
              <option>Limit</option>
              <option value={15}>15</option>
              <option value={25}>25</option>
              <option value={30}>30</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
              <option value={200}>200</option>
              <option value={300}>300</option>
            </select>
          

            </div>
          
            <div>
      
            </div>
          </div>

          <div className="price_filters">
          
               
            {/* <div className="prices">
              <input
                type={"number"}
                className="priceInput"
                name="min_price"
                placeholder="Min Price"
              />
              <input
                type={"number"}
                className="priceInput"
                name="max_price"
                placeholder="Max Price"
              />
            </div> */}
           
          </div>
          <div className="search">
            <IconButton onClick={() => setIsModalOpen(true)}>
              <Search />
            </IconButton>
          </div>
        </div>

        <div className="ViewAllProductInACategory">
          {products.length > 0 ? (
            <>
              {products.map((product, idx) => {
                console.log("product", product);
                return (
                  <div className="item" key={idx}>
                    <a
                      onClick={() =>
                        history.push({
                          pathname: `/product-detailed`,
                          state: { product: product },
                        })
                      }
                    >
                      <div className="imgCover">
                        <img
                          className="image"
                          src={product?.images[0]?.url}
                          alt={product?.product_name.slice(0, 6)}
                        />
                      </div>
                      <div className="info">
                        <div className="font-bold title">
                          {product?.product_name}
                        </div>
                        <div className="price">
                          <CidiSymbol /> {product?.new_price}
                        </div>
                      </div>
                    </a>
                    <div className="buttons">
                      <div className="addToCart" onClick={(e) => handleAddToCart(e, product)}>
                        <BsCartPlus />
                        <div>Add to cart</div>
                      </div>
                      {favProductIds?.includes(product?._id) ? (
                         <div onClick={() => handleAddToFavourite(product?._id)}>
                          <MdFavorite color="red"/>
                       </div>
                      ) : (
                        <div onClick={() => handleAddToFavourite(product?._id)}>
                        <MdOutlineFavoriteBorder />
                      </div>
                      )}
                     
                    </div>
                  </div>
                );
              })}


            </>
          ) : (
            <NotFoundComponent title={"Products not found"} />
          )}
        </div>

        {products.length > 0 && (
                <ReactPaginate
                  breakLabel="..."
                  nextLabel=">"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                  previousLabel="<"
                  renderOnZeroPageCount={null}
                  containerClassName="pagination"
                  pageLinkClassName="page-num"
                  previousLinkClassName="page-num"
                  nextLinkClassName="page-num"
                  activeLinkClassName="active"
                />
              )}

        {isModalOpen && (
          <div className="searchProductComponent">
            <div className="searchCard">
              <div className="search mb-3">
                <input
                  className="searchInput"
                  type={"search"}
                  placeholder="Search for your product here..."
                  name="search"
                  defaultValue={searchValue}
                  onChange={onSearchProduct}
                />
                <Search className="searchIcon" />
              </div>
              {searchValue && (
                <button  className="button mb-10" onClick={handleSearchButton}>
                  Search
                </button>
              )}

              <div className="mt-3">What are you looking for?</div>
              <p>You have products with very affordable price</p>

              <button
                className="button_cancel mt-3"
                onClick={() => setIsModalOpen(false)}
              >
                Cancel search
              </button>
            </div>
          </div>
        )}

        <ToastContainer autoClose={3000}/>
      </RenderHome>
    </div>
  );
}

export default ViewAllProductInACategory;
