/* eslint-disable  */
import { Favorite, ShoppingCart } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useHistory, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { bindActionCreators } from "redux";
import { productActionCreators, userDataActionCreators } from "../../../../service/action-creators";
import { GetUserProfile } from "../../../../service/action-creators/authAction";
import { GetNotifications } from "../../../../service/action-creators/notificationAction";
import Loader from "../../../Loader";
import RenderHome from "../../home/RenderHome";

function ProductDetailed() {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory()

  const { AddFavoriteProducts, AddProductToCart, ResetUserDataResponse, GetCartProducts } = bindActionCreators(
    userDataActionCreators,
    dispatch
  );
  const { ResetProductResponse, GetProductDetailed } = bindActionCreators(
    productActionCreators,
    dispatch
  );


  const user = useSelector((state) => state?.user);
  const carts = user?.cart;
  const productData = useSelector((state) => state?.product);
  const { isLoading, product_detailed } = productData

  const product_id = new URLSearchParams(useLocation()?.search).get('product_id')


  const product = location?.state?.product;
  console.log('product_detailed', product)

  //  const product = product_detailed;
  const favProducts = user.favoriteProducts
  const favProductIds = []
  favProducts.map((fav) => favProductIds.push(fav?.product_id?._id))
  console.log('favProductIds', favProductIds)
  const [selectedSize, setSelectedSize] = useState({
    size: "",
    index: null
  })
  const [selectedColor, setSelectedColor] = useState({
    color: "",
    index: null
  })
  const [quantity, setQuantity] = useState(1)
  const [changedPrice, setChangedPrice] = useState(null)
  const [changedOldPrice, setChangedOldPrice] = useState(null)
  const [selectedPeriod, setSelectedPeriod] = useState(3)

  useEffect(() => {
    if(carts?.length > 0){
      // alert(carts[0]?.period)
      let selectedDuration;
      let percentChange;

      if (Number(carts[0]?.period) === 24) {
        percentChange = 1.2
        selectedDuration = Number(carts[0]?.period);
      } else if (Number(carts[0]?.period) === 18) {
        percentChange = 1.145
        selectedDuration = Number(carts[0]?.period);
      } else if (
        // Number(carts[0].period) == 1 ||
        Number(carts[0]?.period) === 3 ||
        Number(carts[0]?.period) === 6 ||
        Number(carts[0]?.period) === 12
      ) {
        percentChange = 1.1
        selectedDuration = Number(carts[0]?.period); // 10%
      }

      const adminCharge = 3 / 100;
      const retailPrice = Number(product?.new_price)
      const adminAmount = retailPrice * adminCharge;
      const initialAmount = (retailPrice * percentChange) + adminAmount;
  
  
      console.log('retailPrice', retailPrice)
     
  
      setChangedPrice(Number(initialAmount / selectedDuration).toFixed(2))
      // setChangedPrice(product?.new_price)
  
      // OLD PRICE
      const retailOldPrice = Number(product?.old_price)
      const adminOldAmount = retailOldPrice * adminCharge;
      const initialOldAmount = (retailOldPrice * percentChange) + adminOldAmount;
  
  
  
      setChangedOldPrice(Number(initialOldAmount / selectedDuration).toFixed(2))
  
    }else{
      console.log('carts[0].period ', carts[0]?.period )
      const defaultDuration = 3
      const percentChange = 1.1
      const adminCharge = 3 / 100;
      const retailPrice = Number(product?.new_price)
      const adminAmount = retailPrice * adminCharge;
      const initialAmount = (retailPrice * percentChange) + adminAmount;
  
  
      console.log('retailPrice', retailPrice)
     
  
      setChangedPrice(Number(initialAmount / defaultDuration).toFixed(2))
      // setChangedPrice(product?.new_price)
  
      // OLD PRICE
      const retailOldPrice = Number(product?.old_price)
      const adminOldAmount = retailOldPrice * adminCharge;
      const initialOldAmount = (retailOldPrice * percentChange) + adminOldAmount;
  
  
  
      setChangedOldPrice(Number(initialOldAmount / defaultDuration).toFixed(2))
  
    }

  }, [carts])



  console.log("size", selectedSize);
  console.log("color", selectedColor);



  const handleAddToCart = (e) => {
    e.preventDefault()
    const payload = {
      size: selectedSize?.size,
      color: selectedColor?.color,
      quantity: quantity,
      product_id: product?._id,
      period: carts.length > 0 ? Number(carts[0].period) : selectedPeriod,
      // period: selectedPeriod,
      period_price: changedPrice,
    }

    AddProductToCart(payload)
    // console.log('payload', payload)
  }

  const handlePriceChange = (e) => {
    let adminCharge = 3 / 100; //3%
    let value = null
    if (e.target) {
      value = e.target.value
      setSelectedPeriod(e.target.value)
    }
    let percentChange = 1.1
    // let convertedDuration = 0;
    if (Number(e.target.value) === 24) {
      percentChange = 1.2
      // convertedDuration = 2;
    } else if (Number(e.target.value) === 18) {
      percentChange = 1.145
      // convertedDuration = 3;
    } else if (
      // Number(e.target.value) == 1 ||
      Number(e.target.value) === 3 ||
      Number(e.target.value) === 6 ||
      Number(e.target.value) === 12
    ) {
      percentChange = 1.1
      // convertedDuration = 1; // 10%
    }

    const retailPrice = Number(product?.new_price)
    const adminAmount = retailPrice * adminCharge;
    const initialAmount = (retailPrice * percentChange) + adminAmount;

    setChangedPrice(Number(initialAmount / e.target.value).toFixed(2))


    // OLD PRICE
    const retailOldPrice = Number(product?.old_price)
    const adminOldAmount = retailOldPrice * adminCharge;
    const initialOldAmount = (retailOldPrice * percentChange) + adminOldAmount;



    setChangedOldPrice(Number(initialOldAmount / e.target.value).toFixed(2))

  }

  useEffect(() => {
    GetCartProducts()
    dispatch(GetUserProfile())
    dispatch(GetNotifications())

  }, [])
  // useEffect(() => {

  //   GetProductDetailed(product_id)

  // }, [product_id])


  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  useEffect(() => {
    if (user?.response?.state === "SUCCESS" || productData?.response?.state === "SUCCESS") {
      toast.success(productData?.response?.message);
      GetCartProducts();
      setTimeout(() => {
        ResetUserDataResponse();
        ResetProductResponse()
      }, 1500);
    } else if (user?.response?.state === "ERROR" || productData?.response?.state === "ERROR") {
      toast.error(productData?.response?.message);
      setTimeout(() => {
        ResetUserDataResponse();
        ResetProductResponse()
      }, 1500);
    }
  }, [user?.response?.state || productData.response.state]);
  return (
    <div className="ProductDetailed">
      <RenderHome>

        <button className="backBtn" onClick={() => history.goBack()} >Back</button>
        {user?.isLoading || isLoading && <Loader />}
        <div className="imageAndInfo">
          <div className="imageSection">
            <Carousel
              autoPlay={false}
              infiniteLoop
              labels={false}
              showIndicators={false}
              showThumbs={true}
              showStatus={false}
              className="slider"
            >
              {product?.images?.map((img, index) => {
                console.log('img', img)
                return (
                  <div className="imgCover" key={index}>
                    <img src={img?.url} alt="product" />
                  </div>
                );
              })}
              {/* <p className="legend">Legend 1</p> */}
              {/* <div>
                <img src="/images/chat.png" />
                <p className="legend">Legend 2</p>
              </div> */}
            </Carousel>
          </div>
          <div className="smallInfoSection">
            <h2 className="small_description">{product?.product_name}</h2>
            {/* <h2 className="productTitle">{product?.product_name}</h2> */}
            {/* <p className="small_description">{product?.short_description}</p> */}
            {product?.quantity > 0 ? (
              <p className="stockAvialable">Available In stock</p>
            ) : (
              <p className="outOfStock"> Out of stock</p>
            )}

            <div className="price">
              <div className="formGroup">
                {/* <div className="currentPrice">&#8373;{product?.new_price}</div> */}
                <div className="currentPrice">&#8373;{changedPrice} p/m</div>
                <div className="oldPrice">&#8373;{changedOldPrice}</div>
                {/* <div className="oldPrice">&#8373;{product?.old_price}</div> */}
              </div>
              <div className="priceDuration">
                <select onChange={handlePriceChange}>
                  {carts.length > 0 ? <option value={carts[0]?.period}>{carts[0]?.period} months</option> : (<>
                        <option value={3}>3 months</option>
                       <option value={6}>6 months</option>
                       <option value={12}>12 months</option>
                       <option value={18}>18 months</option>
                       <option value={24}>24 months</option>
                        
                    </>) }
               
            
                 
                </select>
              </div>
            </div>
            <div className="sizeFilters">
                <h6>Choose size</h6>
              {product?.sizes?.length > 0 ? (<>
                <div className="sizes">
                  {product?.sizes?.map((size, index) => {
                    return <div key={index} style={{ border: index === selectedSize?.index && '3px solid var(--primaryColor)' }} onClick={() => {
                      // set
                      console.log('index', index)
                      setSelectedSize({ ...selectedSize, size: size, index: index })
                    }}>{size}</div>;
                  })}

                </div>
              </>): <div>N/A</div>}

            </div>

            <div className="colorFilters">
              <h6 className="title">Choose colors</h6>
              {product?.colors?.length > 0 ? (
            
                  <div className="colors">
                    {product?.colors?.map((color, index) => {
                      return <div key={index} style={{ border: index === selectedColor?.index && '3px solid var(--primaryColor)' }} onClick={() => {
                        // set
                        console.log('index', index)
                        setSelectedColor({ ...selectedColor, color: color, index: index })
                      }}>{color}</div>;
                    })}


                  </div>
          
              ): <div>N/A</div>}

            </div>
            <div className="quantity">
              <div>Quantity</div>
              <input
                type={"number"}
                min="1"
                defaultValue={"1"}
                className="form-control"
                onChange={(e) => {
                  setQuantity(e?.target?.value)
                }}
              />
            </div>
            <div className="cartBtn">
              <button
                className="favourite"
                onClick={(e) => {
                  AddFavoriteProducts(product?._id);
                }}
              >
                <span>
                  {favProductIds?.includes(product?._id) ? (<span><Favorite style={{ color: 'red' }} color="" />   Added to Fav</span>) : (<span><Favorite style={{ color: 'white' }} color="" />   Add to Fav</span>)}

                </span>{" "}

              </button>
              <button className="" onClick={(e) => handleAddToCart(e)}>
                <span>
                  <ShoppingCart />
                </span>{" "}
                Add to cart
              </button>
            </div>
          </div>
        </div>
        <div className="detailDescription">
          <br />
          {/* <h4>Detailed Description</h4> */}
          <div dangerouslySetInnerHTML={{ __html: product?.description }} />
          {/* <p>{product?.description}</p> */}
        </div>
        <div className="productImages">
          <h4>Product Images</h4>

          {product?.images?.map((img, index) => {
            return (
              <div key={index}>
                <img src={img?.url} />
              </div>
            );
          })}


        </div>
        <ToastContainer autoClose={3000} />
      </RenderHome>
    </div>
  );
}

export default ProductDetailed;
