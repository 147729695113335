/* eslint-disable  */
import { debounce } from "lodash";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import React, { useEffect, useState } from "react";
import { BsCartPlus } from "react-icons/bs";
import { MdFavorite, MdOutlineFavoriteBorder } from "react-icons/md";
import OwlCarousel from "react-owl-carousel";
import { useDispatch, useSelector } from "react-redux";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link, useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { bindActionCreators } from "redux";
import "../../../../css/Pages/Shopping.css";
import { productActionCreators, userDataActionCreators } from "../../../../service/action-creators";
import { GetUserProfile } from "../../../../service/action-creators/authAction";
import { GetNotifications } from "../../../../service/action-creators/notificationAction";
import Loader from "../../../Loader";
import RenderHome from "../../home/RenderHome";
import "./Products.css";
import { HeightTwoTone, Phone, ShoppingBasket } from "@material-ui/icons";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";

function Products() {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    GetFeaturedProduct,
    GetElectronicesCategory,
    GetComputingCategory,
    GetTelevisionCategory,
    GetHomeAndAppliancesCategory,
    GetProductCategory,
    GetBannerProducts,
    ResetProductResponse,
  } = bindActionCreators(productActionCreators, dispatch);
  const { AddFavoriteProducts, AddProductToCart, ResetUserDataResponse, GetCartProducts, GetFavoriteProducts } = bindActionCreators(
    userDataActionCreators,
    dispatch
  );

  const product = useSelector((state) => state?.product);
  const user = useSelector((state) => state?.user);

  const isLoading = product?.isLoading;
  const featuredProduct = product?.featured;
  const electronicsCategory = product?.electronicsCategory;
  const computingProducts = product?.computingProducts;
  const televisionProducts = product?.televisionProducts;
  const homeAndApplianceProducts = product?.homeAndApplianceProducts;
  const category = product?.category;


  const favProducts = user.favoriteProducts
  const favProductIds = []
  favProducts.map((fav) => favProductIds.push(fav?.product_id?._id))
  console.log('televisionProducts', televisionProducts)

  // const  = "Latest Products";
  const electronics = "Electronics";
  const homeAndAppliance = "Home & Appliances";
  const phones_and_tablet = "Phones & Tablet";

  const [search, setSearch] = useState("");
  const [changedPrice, setChangedPrice] = useState(null)
  const [changedOldPrice, setChangedOldPrice] = useState(null)




  console.log("product?.featured", featuredProduct);
  console.log("computingProducts", computingProducts);
  console.log("electronicsCategory", electronicsCategory);
  console.log("category", category);
  console.log("televisionProducts", televisionProducts);
  console.log("homeAndApplianceProducts", homeAndApplianceProducts);
  console.log("product-banner", product);






  const handleChange = debounce((e) => {
    if (e.target.value) {
      setSearch(e.target.value ?? null);
    } else {
      setSearch(null);
    }
  }, 800);

  const handleBannerItemClick = (product) => {
    // alert(JSON.stringify(product))
    // Navigate to the product detail page and pass the product as a state
    history.push({
      pathname: "/product-detailed",
      state: { product: product },
    });
  };

  console.log("search", search);
  console.log("featured", featuredProduct);
  console.log("banner", product?.banner);

  const handleAddToCart = (e, product_id) => {
    e.preventDefault()
    const payload = {
      size: '',
      color: '',
      quantity: 1,
      period: 6,
      // period_price: ,
      product_id: product_id
    }
    // alert(product_id)
    AddProductToCart(payload)
  }

  // useEffect(() => {
  //   GetProductCategory();
  //   GetFeaturedProduct();
  // }, []);

  useEffect(() => {
    GetProductCategory();
    GetFeaturedProduct();
    GetComputingCategory('Computing & Phones', 10);
    GetTelevisionCategory('Televisions', 10);
    GetElectronicesCategory(electronics, 5);
    GetHomeAndAppliancesCategory(homeAndAppliance, 5)
    dispatch(GetUserProfile())
    dispatch(GetNotifications())
    GetCartProducts()
    GetBannerProducts()
    GetFavoriteProducts()
    // GetElectronicesCategory(computing);
  }, []);

  useEffect(() => {
    if (user.response?.state === "SUCCESS" || product?.response?.state === "SUCCESS") {
      // toast.success(response?.message);
      GetCartProducts();
      GetFavoriteProducts()
      setTimeout(() => {
        ResetUserDataResponse();
        ResetProductResponse()
      }, 1500);
    } else if (user?.response?.state === "ERROR" || product?.response?.state === "ERROR") {
      // toast.error(response?.message);
      setTimeout(() => {
        ResetProductResponse();
        ResetProductResponse()
      }, 1500);
    }
  }, [user?.response?.state || product?.response.state]);

  const device = () => {
    return window.innerWidth < 1080;
  };
  const [isMobile, setIsMobile] = useState(device);
  useEffect(() => {
    window.addEventListener("resize", () => {
      setIsMobile(device);
    });
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Add smooth scrolling behavior for a better user experience
    });
    return 0;


  }, []);



  return (
    <div className="ProductsPage">
      <RenderHome>
        {isLoading || user?.isLoading && <Loader />}
        <div className="">
          {/* search Component */}
          <form>
          <div className="searchContainer">
            <div className="search">
              <input
                className="searchInput"
                type={"search"}
                placeholder="Search product by name"
                name="search"
                defaultValue={search}
                onChange={handleChange}
              />
            </div>
            <div
              onClick={() =>
                history.push({
                  pathname: "/searchedProducts",
                  state: { searchValue: search },
                })
              }
            >
              <button className="button">Search</button>
            </div>
            {/* {search && (
              <div onClick={() => history.push({pathname: '/searchedProducts', state:{searchValue: search}})}>
              <button className="button">Search</button>
              </div>
            )} */}
          </div>
          </form>
          <section className="productShowcaseWrapper">
            <div className="sideOne">
              <div className="header">Categories</div>
              <hr />
              <ul>

                <li onClick={() => history.push(`/category-products?query=featured`)}>Our Featured Products</li>
                <li onClick={() => history.push(`/category-products?query=Electronics`)}>Electronics</li>
                <li onClick={() => history.push(`/category-products?query=Computing & Phones`)}>Computing & Phones</li>
                <li onClick={() => history.push(`/category-products?query=Home & Appliances`)}>Home & Appliances</li>
                <li onClick={() => history.push(`/category-products?query=Televisions`)}>Televisions</li>
                <li>Computing & Accessories</li>
              </ul>
            </div>
            <div className="ProductSlider">
              {/* <Carousel autoPlay={true} infiniteLoop showThumbs={false} className="slider" interval={3000} stopOnHover={true} > */}
              <Carousel autoPlay interval={5000} showThumbs={false} infiniteLoop >
                {product?.banner?.map((product, index) => (
                  <div key={index} onClick={() => handleBannerItemClick(product)}>
                    <img src={product.banner_image} alt={product.title} />
                  </div>
                ))}
              </Carousel>
              <div></div>
            </div>
            <div className="sideThree">
              <div className="info">
                <div className="item">
                  <Phone className="icon" />
                  <div className="content">
                    <div className="font-bold">CALL THE MART</div>
                    <div>0372099642</div>
                  </div>
                </div>
                <div className="item">
                  <HeightTwoTone className="icon" />
                  <div className="content">
                    <div className="font-bold">REQUEST YOUR ORDER</div>
                    <div onClick={()=> history.push('/request-order')}>Request now</div>
                  </div>
                </div>
                <div className="item">
                  <ShoppingBasket className="icon" />
                  <div className="content">
                    <div className="font-bold">VIEW YOUR CART</div>
                    <div onClick={()=> history.push('/my-cart')}>Check your cart</div>
                  </div>
                </div>
              </div>
             
              <div className="smallBanner">
             
                {/* <img onClick={handleBannerItemClick} src="https://gh.jumia.is/cms/0-Weekly-CP/2023/WK35/Slider/payday_w35_d.jpg" alt="small-banner" /> */}
                 <img className="cursor-pointer" onClick={() => handleBannerItemClick(product?.banner[0])} src={product?.banner[0]?.banner_image} alt={product?.banner[0]?.title} />
              </div>
            </div>

          </section>

          <section className="featuredSection">
            <div className="header">
              <div className="topBar">
                <div>{"Featured Products"}</div>
                <div
                  onClick={() => {
                    history.push(`/category-products?query=featured`);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  See All
                </div>
              </div>
            </div>

            <div className="items">
              <OwlCarousel
                className="owl-theme "
                id="owl-carousel"
                loop
                margin={10}
                style={{ width: "100%" }}
                autoplay
                dots
                items={!isMobile ? 5 : 2}
                nav={true}

              >
                {featuredProduct?.map((featured, index) => {
                  console.log("feature", featured);
                  return (
                    <div className="itemContainer" key={index}>
                      <Link
                        to={{
                          // pathname: `/product-detailed?product_id=${featured?._id}`,
                          pathname: `/product-detailed`,
                          state: { product: featured },
                        }}
                      >
                        <div className="imgCover">
                          <img
                            className="image"
                            src={`${featured?.images[0]?.url}`}
                            alt="product"
                          />
                        </div>
                        <div className="info">
                          <p className="short_decription">
                            {featured?.product_name.length <= 60
                              ? featured?.product_name
                              : `${featured?.product_name.slice(0, 60) + "..."
                              }`}
                          </p>
                          <p>
                            <span className="new_price">
                              &#8373; {featured?.new_price}
                            </span>{" "}
                            <span className="old_price">
                              {featured?.old_price}
                            </span>
                          </p>
                        </div>
                      </Link>

                      {/* <div className="buttons">
                        <div className="addToCart" onClick={(e) => handleAddToCart(e, featured?._id)}>
                          <BsCartPlus />
                          <div>Add to cart</div>
                        </div>
                        <div onClick={() => AddFavoriteProducts(featured?._id)}>
                          {favProductIds.includes(featured?._id) ? <MdFavorite color="red"/> : <MdOutlineFavoriteBorder />}
                         
                        </div>
                      </div> */}
                    </div>
                  );
                })}
              </OwlCarousel>
            </div>
          </section>

          <section className="vehicles_section">
            <div className=" vehicles_section_cover">
              <div
                className="header"
                style={{
                  background:
                    "linear-gradient(to bottom, #01a94b 20%, #015f39 100%)",
                  color: "white",
                  padding: "0rem .5rem",
                }}
              >
                <div
                  className="topBar"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <div>{electronics}</div>
                  <div
                    onClick={() => {
                      history.push(`/category-products?query=${electronics}`);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    See All
                  </div>
                </div>
              </div>
              <div className="box_container_grid">
                {electronicsCategory?.length > 0 ? (
                  <>
                    {electronicsCategory.map((product, index) => {
                      return (
                        <div
                          key={index}
                          className="box"
                          onClick={() =>
                            history.push({
                              pathname: "/product-detailed",
                              state: { product: product },
                            })
                          }
                        >
                          <div className="imgCover">
                            <span className="percent_off">
                              {product?.percent}%
                            </span>
                            <div className="imgMainCover">
                              <img
                                src={product?.images[0]?.url}
                                alt="product"
                              />
                            </div>
                            <div className="descr_price">
                              <a
                                onClick={() =>
                                  history.push({
                                    pathname: "/product-detailed",
                                    state: { product: product },
                                  })
                                }
                              >
                                <div className="descr">
                                  {product?.product_name.length <= 60
                                    ? product?.product_name
                                    : product?.product_name.slice(0, 60) +
                                    "..."}
                                </div>
                                <div className="price">
                                  <div>Gh&#8373;{product?.new_price}</div>
                                  <small><span style={{ marginRight: '10px', textDecoration: "line-through", color: 'red' }}>Gh&#8373;{product?.old_price}</span></small>
                                </div>
                                {/* <div className="price">
                                
                                  Gh&#8373; {product?.old_price}
                                </div> */}
                              </a>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </section>
          <section className="vehicles_section">
            <div className=" vehicles_section_cover">
              <div
                className="header"
                style={{
                  background:
                    "linear-gradient(to bottom, #01a94b 20%, #015f39 100%)",
                  color: "white",
                  padding: "0rem .5rem",
                }}
              >
                <div
                  className="topBar"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <div>{homeAndAppliance}</div>
                  <div
                    onClick={() => {
                      history.push(`/category-products?query=${homeAndAppliance}`);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    See All
                  </div>
                </div>
              </div>
              <div className="box_container_grid">
                {homeAndApplianceProducts?.length > 0 ? (
                  <>
                    {homeAndApplianceProducts.map((product, index) => {
                      return (
                        <div
                          key={index}
                          className="box"
                          onClick={() =>
                            history.push({
                              pathname: "/product-detailed",
                              state: { product: product },
                            })
                          }
                        >
                          <div className="imgCover">
                            <span className="percent_off">
                              {product?.percent}%
                            </span>
                            <div className="imgMainCover">
                              <img
                                src={product?.images[0]?.url}
                                alt="product"
                              />
                            </div>
                            <div className="descr_price">
                              <a
                                onClick={() =>
                                  history.push({
                                    pathname: "/product-detailed",
                                    state: { product: product },
                                  })
                                }
                              >
                                <div className="descr">
                                  {product?.product_name.length <= 60
                                    ? product?.product_name
                                    : product?.product_name.slice(0, 60) +
                                    "..."}
                                </div>
                                <div className="price">
                                  <div>Gh&#8373;{product?.new_price}</div>
                                  <small><span style={{ marginRight: '10px', textDecoration: "line-through", color: 'red' }}>Gh&#8373;{product?.old_price}</span></small>
                                </div>
                                {/* <div className="price">
                                
                                  Gh&#8373; {product?.old_price}
                                </div> */}
                              </a>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </section>

          <section className="vehicles_section">
            <div className=" vehicles_section_cover">
              <div
                className="header"
                style={{
                  background:
                    "linear-gradient(to bottom, #01a94b 20%, #015f39 100%)",
                  color: "white",
                  padding: "0rem .5rem",
                }}
              >
                <div
                  className="topBar"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <div>{'Computing and Phones'}</div>
                  <div
                    onClick={() => {
                      history.push(`/category-products?query=${'Computing & Phones'}`);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    See All
                  </div>
                </div>
              </div>
              <div className="box_container_grid">
                {computingProducts?.length > 0 ? (
                  <>
                    {computingProducts.slice(0, 10).map((product, index) => {
                      return (
                        <div
                          key={index}
                          className="box"
                          onClick={() =>
                            history.push({
                              pathname: "/product-detailed",
                              state: { product: product },
                            })
                          }
                        >
                          <div className="imgCover">
                            <span className="percent_off">
                              {product?.percent}%
                            </span>
                            <div className="imgMainCover">
                              <img
                                src={product?.images[0]?.url}
                                alt="product"
                              />
                            </div>
                            <div className="descr_price">
                              <a
                                onClick={() =>
                                  history.push({
                                    pathname: "/product-detailed",
                                    state: { product: product },
                                  })
                                }
                              >
                                <div className="descr">
                                  {product?.product_name.length <= 60
                                    ? product?.product_name
                                    : product?.product_name.slice(0, 60) +
                                    "..."}
                                </div>
                                <div className="price">
                                  Gh&#8373; {product?.new_price}
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <></>
                )}


              </div>
            </div>
          </section>

          <section className="featuredSection">
            <div className="header">
              <div className="topBar">
                <div>{"Televisions"}</div>
                <div
                  onClick={() => {
                    history.push(`/category-products?query=${'Televisions'}`);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  See All
                </div>
              </div>
            </div>

            <div className="items">
              <OwlCarousel
                className="owl-theme "
                id="owl-carousel"
                loop
                margin={10}
                style={{ width: "100%" }}
                autoplay
                dots
                items={!isMobile ? 5 : 2}
                nav={true}
              >
                {televisionProducts?.map((product, index) => {
                  console.log("feature", product);
                  return (
                    <div className="itemContainer" key={index}>
                      <Link
                        to={{
                          // pathname: `/product-detailed?product_id=${product?._id}`,
                          pathname: `/product-detailed`,
                          state: { product: product },
                        }}
                      >
                        <div className="imgCover">
                          <img
                            className="image"
                            src={`${product?.images[0]?.url}`}
                            alt="product"
                          />
                        </div>
                        <div className="info">
                          <p className="short_decription">
                            {product?.product_name.length <= 60
                              ? product?.product_name
                              : `${product?.product_name.slice(0, 60) + "..."
                              }`}
                          </p>
                          <p>
                            <span className="new_price">
                              &#8373; {product?.new_price}
                            </span>{" "}
                            <span className="old_price">
                              {product?.old_price}
                            </span>
                          </p>
                        </div>
                      </Link>
                      {/*                       
                      <div className="buttons">
                        <div className="addToCart" onClick={(e) => handleAddToCart(e, shoesAndSneakers?._id)}>
                          <BsCartPlus />
                          <div>Add to cart</div>
                        </div>
                        <div onClick={() => AddFavoriteProducts(shoesAndSneakers?._id)}>
                          {favProductIds.includes(shoesAndSneakers?._id) ? <MdFavorite color="red"/> : <MdOutlineFavoriteBorder />}
                         
                        </div>
                      </div> */}
                    </div>
                  );
                })}
              </OwlCarousel>
            </div>
          </section>
        </div>
        <ToastContainer autoClose={3000} />
      </RenderHome>
    </div>
  );
}

export default Products;
