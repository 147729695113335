import { messagesConstants } from "../constants/messagesConstants";

const initialState = {
  conversations: [],
  unreadMessagesTotal: 0,
  messages: [],
  messageDetailed: {},
  usersForChat: [],
  isLoading: false,
  response: {
    state: null,
    message: "",
    action: "",
  },
};

const messagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case messagesConstants.LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case messagesConstants.GET_CONVERSATIONS:
      return {
        ...state,
        conversations: action.payload.conversations,
      };
    case messagesConstants.GET_MESSAGES:
      return {
        ...state,
        messages: action.payload.messages,
      };
    case messagesConstants.GET_MESSAGES_UNREAD_TOTAL:
      return {
        ...state,
        unreadMessagesTotal: action.payload.unreadMessagesTotal,
      };
    case messagesConstants.GET_MESSAGE_DETAILED:
      return {
        ...state,
        messageDetailed: action.payload.messageDetailed,
      };
    case messagesConstants.GET_USERS_FOR_CHAT:
      return {
        ...state,
        usersForChat: action.payload.usersForChat,
      };

    case messagesConstants.RESPONSE_STATE:
      return {
        ...state,
        response: {
          state: action?.response?.state,
          message: action?.response?.message,
        },
      };

    default:
      return state;
  }
};

export default messagesReducer;
