import { authConstants } from "../constants/AuthConstants";

const initialState = {
  token: null,
  isLoading: false,
  authenticated: false,
  authenticating: false,
  isEmailVerified: false,
  user: {},
  userById: null,
  response: {
      state: null,
      message: '',
      action: ''
  }
};

const AuthRducer = (state = initialState, action) => {
  console.log(action);
  switch (action.type) {
    case authConstants.LOADING:
        return {
            ...state,
            isLoading: action.isLoading
        }
    case authConstants.LOGIN_REQUEST:
      return {
        ...state,
        authenticating: true
      };
    case authConstants.LOGIN_SUCCESS:
      return {
          ...state,
          user: action.payLoad.user,
          token: action.payLoad.token,
          authenticated: true,
          authenticating: false
      }
    case authConstants.GET_USER_BY_ID:
      return {
          ...state,
          userById: action.payLoad.userById,
     
      }
    case authConstants.RESPONSE_STATE:
        return {
            ...state,
            response: {
                state: action.response.state,
                message: action.response.message
            }
        }
    case authConstants.LOG_OUT:
        return {
            ...initialState,
        }
    default:
      return state;
  }
};

export default AuthRducer;
