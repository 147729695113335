/* eslint-disable  */
import {
  Fab,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { Cancel } from "@material-ui/icons";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { bindActionCreators } from "redux";
import {
  authActionCreators,
  userDataActionCreators,
} from "../../../../service/action-creators";
import Loader from "../../../Loader";
import RenderHome from "../../home/RenderHome";
import "./ApplyLoans.css";
import Loan_chip from "./components/Loan_chip";

function Guarantor() {
  const dispatch = useDispatch();
  const history = useHistory();
  const signatureRef = useRef({});

  const {
    SubmitLoanFormOne,
    SubmitUpdateLoanFormOne,
    ResetUserDataResponse,
    GetFormOneAppliedLoanTemp,
    CancelLoanApplicationTemp,
  } = bindActionCreators(userDataActionCreators, dispatch);

  const { GetUserProfile } = bindActionCreators(authActionCreators, dispatch);
  const [formState, setFormState] = useState(2);
  const [previewFrontCard, setPreviewFrontCard] = useState(null);
  const [previewBackCard, setPreviewBackCard] = useState(null);
  const [privacyChecked, setPrivacyChecked] = useState(false);

  const [formData, setFormData] = useState({});
  const [loanAmount, setLoanAmount] = useState(0);
  const [affordability, seAffordability] = useState(0);
  const [loanTerm, setLoanTerm] = useState(null);
  const [monthlyDeduction, setMonthlyDeduction] = useState(0);
  const [age, setAge] = useState(null);
  const [dateOfBirth, setDateOfBirth] = useState(null);

  const [formError, setFormError] = useState({});

  const response = useSelector((state) => state?.user?.response);
  const isLoading = useSelector((state) => state?.user?.isLoading);
  const auth = useSelector((state) => state?.auth);
  const user = auth?.user;
  const userData = useSelector((state) => state?.user);

  console.log("formData", formData);
  console.log("userData", userData);

  const { loanTemp } = userData;
  useEffect(() => {
    window.scrollTo(0,0)
   }, [])
   
  //   console.log("previewFrontCard", previewFrontCard);
  console.log("formData", formData);

  const handleFormChange = (e) => {
    e.preventDefault();
    setFormData({ ...formData, [e?.target?.name]: e?.target?.value });
  };

  const handleCancelLoanApplication = () => {
    CancelLoanApplicationTemp(loanTemp?._id);
  };

  const pattern = /^GHA-\d{9}-\d$/;
  const validateForm = () => {
    let err = {};

    if (formData.guarantor_name === "" || !formData.guarantor_name) {
      err.guarantor_name = "Guarantor name is required";
    }
    if (formData.guarantor_staff_id === "" || !formData.guarantor_staff_id) {
      err.guarantor_staff_id = "Guarantor Staff ID is required";
    }
    if (formData.guarantor_phone === "" || !formData.guarantor_phone) {
      err.guarantor_phone = "Guarantor phone number is required";
    }
    if (
      formData.guarantor_ghana_card === "" ||
      !formData.guarantor_ghana_card
    ) {
      err.guarantor_ghana_card = "National ID number of guarantor is required";
    } else {
      // if (!pattern.test(formData.guarantor_ghana_card)) {
      //   err.guarantor_ghana_card = "National ID number is invalid";
      // }
      // if (formData.guarantor_ghana_card?.length < 15) {
      //   err.guarantor_ghana_card = "National ID number is invalid";
      // }
    }

    if (formData.guarantor_gps === "" || !formData.guarantor_gps) {
      err.guarantor_gps = "Guarantor GPS address is required";
    }
    if (
      formData.guarantor_occupation === "" ||
      !formData.guarantor_occupation
    ) {
      err.guarantor_occupation = "Guarantor occupation is required";
    }
    if (
      formData.guarantor_mandate_number === "" ||
      !formData.guarantor_mandate_number
    ) {
      err.guarantor_mandate_number = "Guarantor Mandate number is required";
    }
    if (
      formData.guarantor_otp_number === "" ||
      !formData.guarantor_otp_number
    ) {
      err.guarantor_otp_number = "Guarantor OTP number is required";
    }
    if (
      formData.guarantor_relationship === "" ||
      !formData.guarantor_relationship
    ) {
      err.guarantor_relationship = "Guarantor relationship is required";
    }
    // GUARANTOR 2
    if (formData.guarantor_name2 === "" || !formData.guarantor_name2) {
      err.guarantor_name2 = "Guarantor name is required";
    }
    if (formData.guarantor_staff_id2 === "" || !formData.guarantor_staff_id2) {
      err.guarantor_staff_id2 = "Guarantor Staff ID is required";
    }
    if (formData.guarantor_phone2 === "" || !formData.guarantor_phone2) {
      err.guarantor_phone2 = "Guarantor phone number is required";
    }
    if (
      formData.guarantor_ghana_card2 === "" ||
      !formData.guarantor_ghana_card2
    ) {
      err.guarantor_ghana_card2 = "National ID number of guarantor is required";
    } else {
      // if (!pattern.test(formData.guarantor_ghana_card2)) {
      //   err.guarantor_ghana_card2 = "National ID number is invalid";
      // }
      // if (formData.guarantor_ghana_card2?.length < 15) {
      //   err.guarantor_ghana_card2 = "National ID number is invalid";
      // }
    }

    if (formData.guarantor_gps2 === "" || !formData.guarantor_gps2) {
      err.guarantor_gps2 = "Guarantor GPS address is required";
    }
    if (
      formData.guarantor_occupation2 === "" ||
      !formData.guarantor_occupation2
    ) {
      err.guarantor_occupation2 = "Guarantor occupation is required";
    }
    if (
      formData.guarantor_mandate_number2 === "" ||
      !formData.guarantor_mandate_number2
    ) {
      err.guarantor_mandate_number2 = "Guarantor Mandate number is required";
    }
    if (
      formData.guarantor_otp_number2 === "" ||
      !formData.guarantor_otp_number2
    ) {
      err.guarantor_otp_number2 = "Guarantor OTP number is required";
    }
    if (
      formData.guarantor_relationship2 === "" ||
      !formData.guarantor_relationship2
    ) {
      err.guarantor_relationship2 = "Guarantor relationship is required";
    }

    setFormError({ ...err });
    return Object.keys(err).length < 1;
  };

  const handleFormSubmitHandler = (e) => {
    e.preventDefault();

    console.log("formData sub", formData);
    console.log("loanTemp?._id", loanTemp?._id);

    let isValid = validateForm();
    if (isValid) {
      SubmitUpdateLoanFormOne(
        {
          ...formData,
        },
        loanTemp?._id
      );
      console.log("formData", formData);
    }
  };

  const employer_name = user?.facility?.split(" ")[1].split(":")[0];
  useEffect(() => {
    GetUserProfile();
    GetFormOneAppliedLoanTemp();

    setFormData({
      ...formData,
      staff_id: user?.staff_id,
      facility: user?.facility,
      employer_name: employer_name,

      guarantor_name: loanTemp?.guarantor_name,
      guarantor_staff_id: loanTemp?.guarantor_staff_id,
      guarantor_phone: loanTemp?.guarantor_phone,
      guarantor_ghana_card: loanTemp?.guarantor_ghana_card,
      guarantor_gps: loanTemp?.guarantor_gps,
      guarantor_occupation: loanTemp?.guarantor_occupation,
      guarantor_mandate_number: loanTemp?.guarantor_mandate_number,
      guarantor_otp_number: loanTemp?.guarantor_otp_number,
      guarantor_relationship: loanTemp?.guarantor_relationship,

      guarantor_name2: loanTemp?.guarantor_name2,
      guarantor_staff_id2: loanTemp?.guarantor_staff_id2,
      guarantor_phone2: loanTemp?.guarantor_phone2,
      guarantor_ghana_card2: loanTemp?.guarantor_ghana_card2,
      guarantor_gps2: loanTemp?.guarantor_gps2,
      guarantor_occupation2: loanTemp?.guarantor_occupation2,
      guarantor_mandate_number2: loanTemp?.guarantor_mandate_number2,
      guarantor_otp_number2: loanTemp?.guarantor_otp_number2,
      guarantor_relationship2: loanTemp?.guarantor_relationship2,
      // refFirstName: loanTemp?.refFirstName,
      // refSurname: loanTemp?.refSurname,
      // refMiddleName: loanTemp?.refMiddleName,
      // refContact1: loanTemp?.refContact1,
      // refContact2: loanTemp?.refContact2,
      // RefGhanaNationIdentificationNo: loanTemp?.RefGhanaNationIdentificationNo,
      // employment_start_date: loanTemp?.employment_start_date,
    });
  }, []);

  
  useEffect(() => {
    GetFormOneAppliedLoanTemp();
  },[])

  useEffect(() => {
    setFormData({
      ...formData,
      staff_id: user?.staff_id,
      facility: user?.facility,
      employer_name: employer_name,

      guarantor_name: loanTemp?.guarantor_name,
      guarantor_staff_id: loanTemp?.guarantor_staff_id,
      guarantor_phone: loanTemp?.guarantor_phone,
      guarantor_ghana_card: loanTemp?.guarantor_ghana_card,
      guarantor_gps: loanTemp?.guarantor_gps,
      guarantor_occupation: loanTemp?.guarantor_occupation,
      guarantor_mandate_number: loanTemp?.guarantor_mandate_number,
      guarantor_otp_number: loanTemp?.guarantor_otp_number,
      guarantor_relationship: loanTemp?.guarantor_relationship,

      guarantor_name2: loanTemp?.guarantor_name2,
      guarantor_staff_id2: loanTemp?.guarantor_staff_id2,
      guarantor_phone2: loanTemp?.guarantor_phone2,
      guarantor_ghana_card2: loanTemp?.guarantor_ghana_card2,
      guarantor_gps2: loanTemp?.guarantor_gps2,
      guarantor_occupation2: loanTemp?.guarantor_occupation2,
      guarantor_mandate_number2: loanTemp?.guarantor_mandate_number2,
      guarantor_otp_number2: loanTemp?.guarantor_otp_number2,
      guarantor_relationship2: loanTemp?.guarantor_relationship2,

      // refFirstName: loanTemp?.refFirstName,
      // refSurname: loanTemp?.refSurname,
      // refMiddleName: loanTemp?.refMiddleName,
      // refContact1: loanTemp?.refContact1,
      // refContact2: loanTemp?.refContact2,
      // RefGhanaNationIdentificationNo: loanTemp?.RefGhanaNationIdentificationNo,
      // employment_start_date: loanTemp?.employment_start_date,
    });
  }, [loanTemp]);


  console.log('loan id', loanTemp)

  useEffect(() => {
    if (response?.state === "SUCCESS") {
      toast.success(response?.message);
      ResetUserDataResponse();
      history.push("/apply-loan-employment");
      // setTimeout(() => {
      //   ResetUserDataResponse();
      // }, 1500);
    } else if (response?.state === "ERROR") {
      toast.error(response?.message);
      setTimeout(() => {
        ResetUserDataResponse();
      }, 1500);
    } else if (response?.state === "DELETED") {
      toast.success(response?.message);
      history.push("/apply-loan-personal_info");

      setTimeout(() => {
        ResetUserDataResponse();
      }, 1500);
    }
  }, [response?.state, response?.message, response?.action]);

  return (
    <div className="ApplyLoans">
      <RenderHome title={"Apply Loan"}>
        {isLoading ?? <Loader />}
        {/* <hr /> */}
        {/* {loanTemp && (
          <div className="flex justify-end fixed right-0 mr-4">
            <Fab
              onClick={handleCancelLoanApplication}
              title="Cancel Application"
              className="cancelIcon"
              size="small"
              color="primary"
              aria-label="add"
            >
              <Cancel />
            </Fab>
          </div>
        )} */}

        <Loan_chip formState={formState} />

        <form action="">
          {formState && (
            <div className="form_area form2">
              <div className="form-group row">
                <h3 className="formTitle"><strong>First Guarantor</strong></h3>
                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                  <label>
                    Name<span className="bto-lb-required">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    name="guarantor_name"
                    defaultValue={
                      formData?.guarantor_name ||
                      (loanTemp?.guarantor_name ?? loanTemp?.guarantor_name)
                    }
                    required
                    aria-required="true"
                    onChange={(e) => handleFormChange(e)}
                  />
                  <span className="error-message">
                    {formError.guarantor_name}
                  </span>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                  <label>
                    Staff ID<span className="bto-lb-required">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    name="guarantor_staff_id"
                    defaultValue={
                      formData?.guarantor_staff_id ||
                      (loanTemp?.guarantor_staff_id ??
                        loanTemp?.guarantor_staff_id)
                    }
                    lettersonly=""
                    aria-required="true"
                    onChange={(e) => handleFormChange(e)}
                  />
                  <span className="error-message">
                    {formError.guarantor_staff_id}
                  </span>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                  <label>
                    Phone Number<span className="bto-lb-required">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    required
                    name="guarantor_phone"
                    placeholder=""
                    defaultValue={
                      formData?.guarantor_phone ||
                      (loanTemp?.guarantor_phone ?? loanTemp?.guarantor_phone)
                    }
                    aria-required="true"
                    onChange={(e) => handleFormChange(e)}
                  />
                  <span className="error-message">
                    {formError.guarantor_phone}
                  </span>
                </div>
              </div>

              <div className="form-group row">
                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                  <label>
                    Ghana Card Number<span className="bto-lb-required">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    name="guarantor_ghana_card"
                    defaultValue={
                      formData?.guarantor_ghana_card ||
                      (loanTemp?.guarantor_ghana_card ??
                        loanTemp?.guarantor_ghana_card)
                    }
                    required
                    aria-required="true"
                    onChange={(e) => handleFormChange(e)}
                  />
                  <span className="error-message">
                    {formError.guarantor_ghana_card}
                  </span>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                  <label>
                    GPS Address<span className="bto-lb-required">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    name="guarantor_gps"
                    required
                    defaultValue={
                      formData?.guarantor_gps ||
                      (loanTemp?.guarantor_gps ?? loanTemp?.guarantor_gps)
                    }
                    aria-required="true"
                    onChange={(e) => handleFormChange(e)}
                  />
                  <span className="error-message">
                    {formError.guarantor_gps}
                  </span>
                </div>

                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                  <label>
                    Occupation
                    <span className="bto-lb-required">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    required
                    name="guarantor_occupation"
                    defaultValue={
                      formData?.guarantor_occupation ||
                      (loanTemp?.guarantor_occupation ??
                        loanTemp?.guarantor_occupation)
                    }
                    aria-required="true"
                    onChange={(e) => handleFormChange(e)}
                  />
                  <span className="error-message">
                    {formError.guarantor_occupation}
                  </span>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                  <label>
                    Mandate Number<span className="bto-lb-required">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    name="guarantor_mandate_number"
                    defaultValue={
                      formData?.guarantor_mandate_number ||
                      (loanTemp?.guarantor_mandate_number ??
                        loanTemp?.guarantor_mandate_number)
                    }
                    required
                    aria-required="true"
                    onChange={(e) => handleFormChange(e)}
                  />
                  <span className="error-message">
                    {formError.guarantor_mandate_number}
                  </span>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                  <label>
                    OTP Number<span className="bto-lb-required">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    required
                    name="guarantor_otp_number"
                    defaultValue={
                      formData?.guarantor_otp_number ||
                      (loanTemp?.guarantor_otp_number ??
                        loanTemp?.guarantor_otp_number)
                    }
                    aria-required="true"
                    onChange={(e) => handleFormChange(e)}
                  />
                  <span className="error-message">
                    {formError.guarantor_otp_number}
                  </span>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                  <label>
                    Relationship
                    <span className="bto-lb-required">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    required
                    name="guarantor_relationship"
                    defaultValue={
                      formData?.guarantor_relationship ||
                      (loanTemp?.guarantor_relationship ??
                        loanTemp?.guarantor_relationship)
                    }
                    aria-required="true"
                    onChange={(e) => handleFormChange(e)}
                  />
                  <span className="error-message">
                    {formError.guarantor_relationship}
                  </span>
                </div>

                <div>
                      <p className="py-2 pr-4">I <span className="font-bold underline">{`${formData?.guarantor_name ||
                        (loanTemp?.guarantor_name ??
                          loanTemp?.guarantor_name) || ''}`}</span> hereby declare that in the event the borrower fails to make payments
                        or honor the contractual agreement herein signed, I guarantee to make full payment to UPNMG-FUND in the same way as if I were the original entity of the said
                        agreement except the default is in respect of death. (GUARANTOR MUST BE A MEMBER OF UPNMG IN GOOD STANDING)</p>
                    </div>
              </div>
              {/* SECOND GUARANTOR SECTION */}
              <div className="form-group row">
                <h3 className="formTitle"><strong>Second Guarantor</strong></h3>
                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                  <label>
                    Name<span className="bto-lb-required">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    name="guarantor_name2"
                    defaultValue={
                      formData?.guarantor_name2 ||
                      (loanTemp?.guarantor_name2 ?? loanTemp?.guarantor_name2)
                    }
                    required
                    aria-required="true"
                    onChange={(e) => handleFormChange(e)}
                  />
                  <span className="error-message">
                    {formError.guarantor_name2}
                  </span>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                  <label>
                    Staff ID<span className="bto-lb-required">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    name="guarantor_staff_id2"
                    defaultValue={
                      formData?.guarantor_staff_id2 ||
                      (loanTemp?.guarantor_staff_id2 ??
                        loanTemp?.guarantor_staff_id2)
                    }
                    aria-required="true"
                    onChange={(e) => handleFormChange(e)}
                  />
                  <span className="error-message">
                    {formError.guarantor_staff_id2}
                  </span>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                  <label>
                    Phone Number<span className="bto-lb-required">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    required
                    name="guarantor_phone2"
                    placeholder=""
                    defaultValue={
                      formData?.guarantor_phone2 ||
                      (loanTemp?.guarantor_phone2 ?? loanTemp?.guarantor_phone2)
                    }
                    aria-required="true"
                    onChange={(e) => handleFormChange(e)}
                  />
                  <span className="error-message">
                    {formError.guarantor_phone2}
                  </span>
                </div>
              </div>

              <div className="form-group row">
                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                  <label>
                    Ghana Card Number<span className="bto-lb-required">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    name="guarantor_ghana_card2"
                    defaultValue={
                      formData?.guarantor_ghana_card2 ||
                      (loanTemp?.guarantor_ghana_card2 ??
                        loanTemp?.guarantor_ghana_card2)
                    }
                    required
                    aria-required="true"
                    onChange={(e) => handleFormChange(e)}
                  />
                  <span className="error-message">
                    {formError.guarantor_ghana_card2}
                  </span>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                  <label>
                    GPS Address<span className="bto-lb-required">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    name="guarantor_gps2"
                    required
                    defaultValue={
                      formData?.guarantor_gps2 ||
                      (loanTemp?.guarantor_gps2 ?? loanTemp?.guarantor_gps2)
                    }
                    aria-required="true"
                    onChange={(e) => handleFormChange(e)}
                  />
                  <span className="error-message">
                    {formError.guarantor_gps2}
                  </span>
                </div>

                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                  <label>
                    Occupation
                    <span className="bto-lb-required">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    required
                    name="guarantor_occupation2"
                    defaultValue={
                      formData?.guarantor_occupation2 ||
                      (loanTemp?.guarantor_occupation2 ??
                        loanTemp?.guarantor_occupation2)
                    }
                    aria-required="true"
                    onChange={(e) => handleFormChange(e)}
                  />
                  <span className="error-message">
                    {formError.guarantor_occupation2}
                  </span>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                  <label>
                    Mandate Number<span className="bto-lb-required">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    name="guarantor_mandate_number2"
                    defaultValue={
                      formData?.guarantor_mandate_number2 ||
                      (loanTemp?.guarantor_mandate_number2 ??
                        loanTemp?.guarantor_mandate_number2)
                    }
                    required
                    aria-required="true"
                    onChange={(e) => handleFormChange(e)}
                  />
                  <span className="error-message">
                    {formError.guarantor_mandate_number2}
                  </span>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                  <label>
                    OTP Number<span className="bto-lb-required">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    required
                    name="guarantor_otp_number2"
                    defaultValue={
                      formData?.guarantor_otp_number2 ||
                      (loanTemp?.guarantor_otp_number2 ??
                        loanTemp?.guarantor_otp_number2)
                    }
                    aria-required="true"
                    onChange={(e) => handleFormChange(e)}
                  />
                  <span className="error-message">
                    {formError.guarantor_otp_number2}
                  </span>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                  <label>
                    Relationship
                    <span className="bto-lb-required">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    required
                    name="guarantor_relationship2"
                    defaultValue={
                      formData?.guarantor_relationship2 ||
                      (loanTemp?.guarantor_relationship2 ??
                        loanTemp?.guarantor_relationship2)
                    }
                    aria-required="true"
                    onChange={(e) => handleFormChange(e)}
                  />
                  <span className="error-message">
                    {formError.guarantor_relationship2}
                  </span>
                </div>

                <div>
                      <p className="py-2">I <span className="font-bold underline">{`${formData?.guarantor_name2 ||
                        (loanTemp?.guarantor_name2 ??
                          loanTemp?.guarantor_name2) || ''}`}</span> hereby declare that in the event the borrower fails to make payments
                        or honor the contractual agreement herein signed, I guarantee to make full payment to UPNMG-FUND in the same way as if I were the original entity of the said
                        agreement except the default is in respect of death.</p>
                    </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "1rem",
                }}
              >
                <div
                  className="button_cancel cursor-pointer backBtn"
                  onClick={() => history.push("/apply-loan-personal_info")}
                >
                  Back
                </div>
                <button
                  className="button"
                  // type="button"

                  onClick={(e) => {
                    e.preventDefault();
                    handleFormSubmitHandler(e);
                 
                  }}
                >
                  Save and continue
                </button>
              </div>
            </div>
          )}
        </form>
      </RenderHome>
      <ToastContainer autoClose={3000} />
    </div>
  );
}

export default Guarantor;
