/* eslint-disable  */
import { IconButton } from "@material-ui/core";
import { Delete, MessageTwoTone } from "@material-ui/icons";
import { TbMessageCircle } from "react-icons/tb";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import "../../../css/Pages/Pages.css";
import { userDataActionCreators } from "../../../service/action-creators";
import { GetUserProfile } from "../../../service/action-creators/authAction";
import { GetNotifications } from "../../../service/action-creators/notificationAction";
import Loader from "../../Loader";
import NotFoundComponent from "../../NotFoundComponent";
import RenderHome from "../home/RenderHome";
import Modal from "../../Modal/Modal";
import { useHistory } from "react-router-dom";
function MyAppliedLoans() {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    GetAppliedLoans,
    GetMyOrders,
    DeleteMyAppliedLoans,
    GetCartProducts,
    ResetUserDataResponse,
  } = bindActionCreators(userDataActionCreators, dispatch);

  const device = () => {
    return window.innerWidth < 1080;
  };

  const myAppliedLoans = useSelector((state) => state.user?.myAppliedLoans);
  const orders = useSelector((state) => state.user?.orders);
  const isLoading = useSelector((state) => state.user?.isLoading);
  const response = useSelector((state) => state?.user?.response);
  const [statusAvailable, setStatusAvailable] = useState(false);
  const [openMessageModal, setOpenMessageModal] = useState(false);
  const [messageEditDetailed, setMessageEditDetailed] = useState("");
  const [isMobile, setIsMobile] = useState(device);

  console.log("myAppliedLoans", myAppliedLoans);
  console.log("orders", orders);
  console.log("statusAvailable", statusAvailable);
  useEffect(() => {
    GetAppliedLoans();
    dispatch(GetUserProfile());
    // GetMyOrders();
    dispatch(GetNotifications());
    GetCartProducts();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setIsMobile(device);
    });
    return 0;
  }, []);

  useEffect(() => {
    if (myAppliedLoans?.length > 0) {
      setStatusAvailable(true);
    }
  }, [myAppliedLoans, dispatch]);

  useEffect(() => {
    if (response?.state === "SUCCESS") {
      GetAppliedLoans();
      setTimeout(() => {
        ResetUserDataResponse();
      }, 1500);
    } else if (response?.state === "ERROR") {
      setTimeout(() => {
        ResetUserDataResponse();
      }, 1500);
    }
  }, [response?.state]);

  return (
    <div className="MyOrdersNdPurchases">
      <RenderHome>
        {isLoading ?? <Loader />}
        <h3 className="pageTitle">Loan Summary</h3>
        {myAppliedLoans?.length > 0 ? (
          <div className="summary">
            {myAppliedLoans?.map((loan, index) => {
              return (
                <div key={index} className="item">
                  <div className="item1">
                    <div className="flex">
                      <div className="title">Amount Requested:</div>
                      <div className="value">&#8373; {loan?.loanAmount}</div>
                    </div>
                    <div className="flex">
                      <div className="title">Monthly Deduction:</div>
                      <div className="value">
                        &#8373; {loan?.monthlyDeduction}
                      </div>
                    </div>

                    <div className="flex">
                      <div className="title">Loan Term:</div>
                      <div className="value">{loan?.loanTerm} months</div>
                    </div>

                    <div className="status">
                      <div className="card">
                        <div className="status_name">Submitted</div>
                        {loan?.submitted?.map((submit) => {
                          if (submit.status) {
                            return (
                              <>
                                <div className="status_date">
                                  {moment(submit.date).format("MMM Do YY")}{" "}
                                </div>
                                <div
                                  className="status_tracker"
                                  style={{ border: "5px solid #01a94b" }}
                                ></div>
                              </>
                            );
                          } else {
                            return (
                              <>
                                <div className="status_date"> N/A</div>
                                <div className="status_tracker"></div>
                              </>
                            );
                          }
                        })}
                      </div>
                      <div className="card">
                        <div className="status_name">Booked</div>
                        {loan?.booked?.map((book) => {
                          if (book.status) {
                            return (
                              <>
                                <div className="status_date">
                                  {moment(book.date).format("MMM Do YY")}{" "}
                                </div>
                                <div
                                  className="status_tracker"
                                  style={{ border: "5px solid #01a94b" }}
                                ></div>
                              </>
                            );
                          } else {
                            return (
                              <>
                                <div className="status_date"> N/A</div>
                                <div className="status_tracker"></div>
                              </>
                            );
                          }
                        })}
                      </div>
                      <div className="card">
                        <div className="status_name">Authorized</div>
                        {loan?.authorized?.map((authorize) => {
                          if (authorize.status) {
                            return (
                              <>
                                <div className="status_date">
                                  {moment(authorize.date).format("MMM Do YY")}{" "}
                                </div>
                                <div
                                  className="status_tracker"
                                  style={{ border: "5px solid #01a94b" }}
                                ></div>
                              </>
                            );
                          } else {
                            return (
                              <>
                                <div className="status_date"> N/A</div>
                                <div className="status_tracker"></div>
                              </>
                            );
                          }
                        })}
                      </div>
                      <div className="card">
                        <div className="status_name">Initiated</div>
                        {loan?.initiated?.map((initiate) => {
                          if (initiate.status) {
                            return (
                              <>
                                <div className="status_date">
                                  {moment(initiate.date).format("MMM Do YY")}{" "}
                                </div>
                                <div
                                  className="status_tracker"
                                  style={{ border: "5px solid #01a94b" }}
                                ></div>
                              </>
                            );
                          } else {
                            return (
                              <>
                                <div className="status_date"> N/A</div>
                                <div className="status_tracker"></div>
                              </>
                            );
                          }
                        })}
                      </div>
                      <div className="card">
                        <div className="status_name">Paid</div>
                        {loan?.paid?.map((pay) => {
                          if (pay.status) {
                            return (
                              <>
                                <div className="status_date">
                                  {moment(pay.date).format("MMM Do YY")}{" "}
                                </div>
                                <div
                                  className="status_tracker"
                                  style={{ border: "5px solid #01a94b" }}
                                ></div>
                              </>
                            );
                          } else {
                            return (
                              <>
                                <div className="status_date"> N/A</div>
                                <div className="status_tracker"></div>
                              </>
                            );
                          }
                        })}
                      </div>
                      {loan?.status == "paid" ? (
                        ""
                      ) : (
                        <div
                          className="card"
                          onClick={() => {
                            loan?.rejected?.map((rej) => {
                              if (rej.status) {
                                setMessageEditDetailed(loan);
                                setOpenMessageModal(true);
                              }
                            });
                          }}
                        >
                          <div className="status_name">Rejected</div>
                          {loan?.rejected?.map((reject) => {
                            if (reject.status) {
                              return (
                                <>
                                  <div className="readMessageContainer">
                                    <TbMessageCircle className="messageIcon" />
                                  </div>
                                  <div className="readMsg">Read message</div>
                                  <div className="status_date cursor-pointer">
                                    {moment(reject.date).format("MMM Do YY")}{" "}
                                  </div>
                                  <div
                                    className="status_tracker cursor-pointer"
                                    style={{ border: "5px solid red" }}
                                  ></div>
                                </>
                              );
                            } else {
                              return (
                                <>
                                  <div className="status_date"> N/A</div>
                                  <div className="status_tracker"></div>
                                </>
                              );
                            }
                          })}
                        </div>
                      )}
                    </div>
                  </div>
                  {/* <div className="deleteBtn">
                    {loan?.status == "submitted" && (
                      <IconButton
                        onClick={() =>
                          DeleteMyAppliedLoans(
                            loan?._id,
                            loan?.frontIDImage_public_id,
                            loan?.backIDImage_public_id
                          )
                        }
                      >
                        <Delete color="secondary" />
                      </IconButton>
                    )}
                  </div> */}
                </div>
              );
            })}
          </div>
        ) : (
          <NotFoundComponent title={"No Applied loan available"} />
        )}

        {openMessageModal && (
          <Modal
            title={"Rejected Message"}
            width={isMobile ? "85%" : "35%"}
            closeModal={setOpenMessageModal}
          >
            <div className="modalBody">
              <div className="message">
                <p>{messageEditDetailed?.rejected[0]?.message}</p>
                {messageEditDetailed?.rejected[0]?.editFormChecked && (
                  <button
                    onClick={() =>
                      history.push({
                        pathname: "/edit-loan-form",
                        state: { loan_id: messageEditDetailed?._id },
                      })
                    }
                    type="button"
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 mt-4 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                  >
                    Click here to edit form
                  </button>
                )}
              </div>
            </div>
            <div className="modalButton">
              <div className="btnCover">
                {/* <button type="button" className="modalBtn" onClick={handleSubmit}>
                Change
              </button> */}

                <button
                  data-modal-toggle=""
                  type="button"
                  onClick={() => setOpenMessageModal(false)}
                  className="cancelBtn"
                >
                  Close
                </button>
              </div>
            </div>
          </Modal>
        )}
      </RenderHome>
    </div>
  );
}

export default MyAppliedLoans;
