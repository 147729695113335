/* eslint-disable  */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
// import AlertDialogSlide from './ForgotPasswordDialog';
import { toast, ToastContainer } from "react-toastify";
import { bindActionCreators } from "redux";
import "../../css/Auth/Auth.css";
import { authActionCreators } from "../../service/action-creators";
import Loader from "../Loader";
import AlertDialogSlide from "./ForgotPasswordDialog";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import VideoPlayerDialog from "./VideoPlayerDialog";

function Login() {
  const dispatch = useDispatch();
  const { loginUser, ResetResponse, ResendEmailTokenToUser } =
    bindActionCreators(authActionCreators, dispatch);
  const [formData, setFormData] = useState({});
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [openVideo, setOpenVideo] = React.useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const auth = useSelector((state) => state?.auth);
  useEffect(() => {
    if (auth?.response?.state === "SUCCESS") {
      toast.success(auth?.response?.message);
      setTimeout(() => {
        ResetResponse();
      }, 3000);
    } else if (auth?.response?.state === "ERROR") {
      toast.error(auth?.response?.message ?? "Error occured");
      setTimeout(() => {
        ResetResponse();
      }, 3000);
    }
  }, [auth?.response?.state]);



  // if (auth.authenticated && auth.user_type === "OWNER") {
  //   isMobile ? Navigation("") : Navigation("account");
  //   return <Redirect to={`/user/pet-owner`} />;
  // } else if (auth.authenticate && auth.user_type === "BUSINESS") {
  //   if (business && business?.plan_id?.alias === "PREMIUM") {
  //     isMobile ? Navigation("") : Navigation("account");
  //     return <Redirect to={`/user/p/pet-business`} />;
  //   } else if (business && business?.plan_id?.alias === "FREE") {
  //     isMobile ? Navigation("") : Navigation("account");
  //     return <Redirect to={`/user/f/pet-business`} />;
  //   }
  // }


  if (auth.authenticated && auth.user.changed_password === false && auth?.user?.user_type === 'NURSE') {
    return <Redirect to={"/change-password-email"} />;
  }
  // else if (
  //   auth?.user?.is_email_verified === false &&
  //   auth?.user?.is_account_active
  // ) {
  // ResendEmailTokenToUser(formData.email);
  // } 
  else if (auth.authenticated && auth.user.changed_password && auth?.user?.is_email_verified && auth?.user?.user_type === 'NURSE') {
    return <Redirect to={"/home"} />;
  } else {
    console.log('auth.user.email', auth.user.email)
    // return <Redirect to={"/account/resend-email-verification-link"} />;
    // alert('Send token again')
    // ResendEmailTokenToUser(formData.email)
  }

  //Send Token again if email have not verified
  if (auth.authenticated && auth.user.changed_password && auth?.user?.is_email_verified === false && auth?.user?.user_type === 'NURSE') {
    //send token again
    return <Redirect to={"/account/resend-email-verification-link"} />;
  }



  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleVideoClose = () => {
    setOpenVideo(false);
  };
  const handleVideOpen = () => {
    setOpenVideo(true);
  };
  const handleLogin = (e) => {
    e.preventDefault();
    loginUser(formData);
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };


  console.log("formData", formData);
  return (
    <>
      {auth?.isLoading ? <Loader /> : ""}

      <div className="loginPage">
        <form
          className="section_one"
          style={{
            background:
              "url(/images/dev/background.jpg) no-repeat center center/cover",
          }}
        >
          <div className="overlay">
            <h1 className="title">Login</h1>
            <div className="email-form-group form-group">
              <label htmlFor="email">Email / Staff ID</label>
              <input
                id="email"
                name="email"
                required
                type="email"
                className="form-control"
                onChange={(e) => handleInputChange(e)}
              />
            </div>
            <div className="password-form-group">
              <label htmlFor="password">Password</label>
              <div className="inputGroup">
                <input
                  type={`${showPassword ? "text" : "password"}`}
                  required
                  id="password"
                  name="password"
                  className="form-control"
                  onChange={(e) => handleInputChange(e)}
                />
                <span
                  className="icon"
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                >
                  {showPassword ? <FaEye /> : <FaEyeSlash />}
                </span>
              </div>

            </div>
            <button className="button mb2 mt1" onClick={handleLogin}>
              Login
            </button>
            <p className="forgot_password cursor-pointer" onClick={handleClickOpen}>
              Forgot password?
            </p>
            {/* <div className='trigger_register'>
          <Link to={'/register'}>Don't have an account? <span>Register</span></Link>
          </div> */}
          </div>
        </form>
        <div
          className="section_two"
          style={{
            background:
              "url(/images/dev/nurse-float.jpeg) no-repeat center center/cover",
          }}
        >
          <div className="overlay">
            <div className="content">
              <h1>Join the most amazing union today with</h1>
              <p>Over 35,000 members on this platform </p>

            
            <div className="videoContainer">
              <h2>First Time Login?</h2>
              <h3>Watch Video</h3>
              <img src="/images/dev/videoplayer.png" alt="video-player" onClick={handleVideOpen}/>
              {/* <video src={`https://s3.amazonaws.com/codecademy-content/courses/React/react_video-slow.mp4`} controls/> */}
              </div>
            </div>
            </div>
          </div>

          <AlertDialogSlide open={open} close={handleClose} />
          <VideoPlayerDialog open={openVideo} close={handleVideoClose} />
        </div>
        <ToastContainer autoClose={3000} />
      </>
      );
}

      export default Login;