import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide } from '@material-ui/core';
import * as React from 'react';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function VideoPlayerDialog(props) {
  const handleClose = () => {
    props.close(false);
  };
  return (
    <div>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Learn how to Access"}</DialogTitle>
        <DialogContent>
        {/* <video src={`https://s3.amazonaws.com/codecademy-content/courses/React/react_video-slow.mp4`} controls autoPlay={false}/> */}
        <video className='videoPlayer' src={`/images/dev/upnmg-login-tuts.mp4`} controls autoPlay={false} height={100}/>
        </DialogContent>
        <DialogActions>
         
          <Button style={{background: 'var(--grayColor)', color: 'black'}} onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>

     
   
    </div>
  );
}
