import { ProductContants } from "../constants/ProductContants";

const initialState = {
  isLoading: false,
  response: {
    state: null,
    message: "",
  },
  category: [],
  products: [],
  request_order: [],
  product_detailed: {},
  featured: [],
  banner: [],
  computingProducts: [],
  televisionProducts: [],
  homeAndApplianceProducts: [],
  electronicsCategory: [],
  paginate: {
    total: null,
    totalItems: null,
    page: null,
    size: null,
    currentPageSize: null,
    previousPage: null,
    previousNumber: null,
    nextPage: null,
    nextNumber: null,
  },
};

const ProductReducer = (state = initialState, action) => {
  switch (action.type) {
    case ProductContants.LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };

    case ProductContants.RESPONSE_STATE:
      return {
        ...state,
        response: {
          state: action?.response?.state,
          message: action?.response?.message,
        },
      };

    case ProductContants.GET_CATEGORY:
      return {
        ...state,
        category: action.payLoad.category,
      };
    case ProductContants.GET_COMPUTING_PRODUCTS:
      return {
        ...state,
        computingProducts: action.payLoad.computingProducts,
      };
    case ProductContants.GET_TELEVISION_PRODUCTS:
      return {
        ...state,
        televisionProducts: action.payLoad.televisionProducts,
      };
    case ProductContants.GET_HOME_AND_APPLIANCES_PRODUCTS:
      return {
        ...state,
        homeAndApplianceProducts: action.payLoad.homeAndApplianceProducts,
      };
    case ProductContants.GET_ELECTRONIC_CATEGORY:
      return {
        ...state,
        electronicsCategory: action.payLoad.electronicsCategory,
      };
    case ProductContants.GET_FEATURED_PRODUCTS:
      return {
        ...state,
        featured: action.payLoad.featured,
      };
    case ProductContants.GET_PRODUCT_DETAILED:
      return {
        ...state,
        product_detailed: action.payLoad.product_detailed,
      };
    case ProductContants.GET_BANNER_PRODUCTS:
      return {
        ...state,
        banner: action.payLoad.banner,
      };

    case ProductContants.GET_PRODUCTS:
      return {
        ...state,
        products: action.payLoad.products,
        paginate: action.payLoad.paginate,
      };

    case ProductContants.REQUEST_ORDER:
      return {
        ...state,
        request_order: action.payLoad.request_order,
      };

    default:
      return state;
  }
};

export default ProductReducer;
