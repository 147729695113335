/* eslint-disable  */
import { Avatar } from "@material-ui/core";
import React, { useEffect } from "react";
import  { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { format } from 'timeago.js';
import { notificationsActionCreators, userDataActionCreators } from "../../../../../service/action-creators";
import NotFoundComponent from "../../../../NotFoundComponent";
function SectionTwo() {
  const dispatch = useDispatch();
  const history = useHistory()
  const { GetNotifications } = bindActionCreators(
    notificationsActionCreators,
    dispatch
  );
  const { GetAppliedLoans, GetMyOrders } = bindActionCreators(
    userDataActionCreators,
    dispatch
  );

  const notifications = useSelector(
    (state) => state?.notification?.notifications
  );
  const user = useSelector(
    (state) => state?.user
  );
  const {myAppliedLoans, orders} = user
 
  console.log("notifications", notifications);

  useEffect(() => {
    GetNotifications();
    GetAppliedLoans();
    GetMyOrders();

  }, []);
  return (
    <div className="SectionTwoNotificationSection">
      <div className="NotificationPanel">


      <div className="notifications">
        <h3>Notifications</h3>
        {notifications?.length > 0 ? (
          <div className="notif_content">
            {notifications?.slice(0, 3)?.map((notification, index) => {
             return <div className="flex" key={index}>
                <Avatar className="icon" />
                <div>
                  <h5 className="notif_title">{notification?.title}</h5>
                  <div className="notif_msg">
                    {notification?.message.length > 80 ? notification?.message.slice(0, 80) + '...' : notification?.message}
                  </div>
                  {/* <div className="time">{moment(notification?.created_at).calendar()}</div> */}
                  <div className="time">{format(notification?.created_at)}</div>
                </div>
              </div>;
            })}
          </div>
        ) : (
          <>
          <NotFoundComponent width="130px"/>
          </>
        )}
      </div>
      <br />
      <div className="carStatusDisplay">
        <h3>Loan Status</h3>
        {myAppliedLoans?.length > 0 ? (
        <div className="notif_content">
        {myAppliedLoans?.map((loan, index) => {
             let loanStatusColor = ''
             if(loan?.status === 'submitted'){
               loanStatusColor = 'rgb(163, 163, 163)'
             }
             else if(loan?.status === 'initiated'){
               loanStatusColor = 'rgb(221, 118, 0)'
               
             }else if(loan?.status === 'rejected'){
               loanStatusColor = 'red'
               
             }else if(loan?.status === 'paid'){
               loanStatusColor = '#01a94b'
 
             }else if(loan?.status === 'booked'){
               loanStatusColor = 'rgb(0, 103, 221)'
             }
          console.log('loan', loan)
          return(
            <div key={index} className="statusContainer  cursor-pointer" onClick={() => history.push('/fund')}>
            <div className="flex">
              <Avatar src="/images/loan (1).png" className="icon" />
              {/* <Avatar src={loan?.frontIDImage} className="icon" /> */}
              <div>
                <h5 className="notif_title">Loan submited application</h5>
                <div className="notif_msg">Start date: {format(loan?.created_at)}</div>
                <div className="time ">{loan?.personal_phone}</div>
              </div>
            </div>
            <p className="status" style={{ background: loanStatusColor }}>
              {loan?.status}
            </p>
          </div>
          )
        })}
      </div>
        ) : (<> <NotFoundComponent width="130px"/></>)}


        <hr className="my-4"/>
        <h3>Order Status</h3>
        {orders?.length > 0 ?  (        <div className="notif_content">
          {orders?.map((order, index) => {
            let orderStatusColor = 'red'
            if(order?.status === 'submitted'){
              orderStatusColor = 'rgb(163, 163, 163)'
            }
            else if(order?.status === 'initiated'){
              orderStatusColor = 'rgb(221, 118, 0)'
              
            }else if(order?.status === 'rejected'){
              orderStatusColor = 'red'
              
            }else if(order?.status === 'approved'){
              orderStatusColor = '#01a94b'

            }else if(order?.status === 'shipping'){
              orderStatusColor = 'rgb(0, 103, 221)'
            }
            console.log('order', order)
            return(
              <div key={index} className="statusContainer cursor-pointer" onClick={() => history.push('/loans')}>
              <div className="flex">
              <Avatar src="/images/shopping-bag.png" className="icon" />
                {/* <Avatar src={order?.frontIDImage} className="icon" /> */}
                <div>
                  <h5 className="notif_title">Order submited application</h5>
                  <div className="notif_msg">Start date: {format(order?.created_at)}</div>
                  <div className="time ">{order?.personal_phone}</div>
                </div>
              </div>
              <p className="status" style={{ background: `${orderStatusColor}` }}>
              {order?.status}
              </p>
            </div>
            )
          })}
        </div>) : (<>
          <NotFoundComponent width="130px"/>
        </>)}

      </div>
      </div>
    </div>
  );
}

export default SectionTwo;
