/* eslint-disable  */
import {
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { debounce } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { BsPrinter } from "react-icons/bs";
import { FiDownloadCloud } from "react-icons/fi";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { bindActionCreators } from "redux";
// import "../../../css/Dashboard/home/Home.css";
import { userDataActionCreators } from "../../../service/action-creators";
import { GetUserProfile } from "../../../service/action-creators/authAction";
import { GetNotifications } from "../../../service/action-creators/notificationAction";
import { DownloadMemberFunds, GetCartProducts } from "../../../service/action-creators/userDataAction";
import axiosInstance from "../../../service/axios/axios";
import CidiSymbol from "../../CidiSymbol";
import Loader from "../../Loader";
import RenderHome from "../home/RenderHome";
import "./Fund.css";
import { SiMicrosoftexcel } from "react-icons/si";

function Fund() {
  const dispatch = useDispatch();
  const { GetFunds, GetFundsOpeningBalance, GetTotalFunds } =
    bindActionCreators(userDataActionCreators, dispatch);
  //   const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Funds Data",
  });

  const open = Boolean(anchorEl);
  const user = useSelector((state) => state.user);
  // const funds = useSelector((state) => state.user.funds);
  // const paginate = useSelector((state) => state.user.paginate);
  // const isLoading = useSelector((state) => state.user.isLoading);

  const { funds, paginate, isLoading, totalFunds, fundsOpeningBalance } = user;

  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 15;
  const [currentPage, setCurrentPage] = useState(0);
  // const data = useSelector((state) => state.data);

  console.log("fundsOpeningBalance", fundsOpeningBalance);
  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;

    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setCurrentItems(funds?.slice(itemOffset, endOffset));
    setPageCount(Number(paginate?.total));
  }, [itemOffset, itemsPerPage, funds]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    console.log("event", event);

    GetFunds(true, event.selected + 1, 15);
    

    console.log("currentPage", currentPage);

    const newOffset = (event.selected * itemsPerPage) % paginate?.total;

    setItemOffset(newOffset);
  };


  const downloadExcelData = async (data) => {
    // await axiosInstance.get('/funds/download').then((res) => res.data)
    // fetch('http://localhost:8000/api/v1/funds/download').then(res => {res.blob(); console.log('res.blob', res.blob())} ).then(blob => {
    //   const url = window.URL.createObjectURL(new Blob([blob]))
    //   console.log('blob', blob)
    //   const link = document.createElement('a')
    //   link.href = url
    //   link.setAttribute('download', 'funds.xlsx')
    //   document.body.appendChild(link)
    //   link.click()
    //   document.body.removeChild(link)
    // })

    dispatch(DownloadMemberFunds(false, 1, 15))


  }

  console.log("funds", funds);
  console.log("total_fund", totalFunds);
  console.log("paginate", paginate);
  useEffect(() => {
    // GetFunds(true, 1, 15);
    GetFunds(true, 1, 15);
    GetFundsOpeningBalance();
    GetTotalFunds();

    dispatch(GetUserProfile());
    dispatch(GetNotifications());
    dispatch(GetCartProducts());
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  //   const handleClose = () => {
  //     // setOpen(false);
  //   };
  const handleToggle = () => {
    // setOpen(!open);
  };

  const handleSearch = debounce((e) => {
    if (e.target.value) {
      GetFunds(true, 1, 15, `${e.target.value}`);
    } else {
      GetFunds(true, 1, 15);
    }
  }, 800);

  const device = () => {
    return window.innerWidth < 1080;
  };

  const [isMobile, setIsMobile] = useState(device);
  useEffect(() => {
    window.addEventListener("resize", () => {
      setIsMobile(device);
    });
    return 0;
  }, []);

  return (
    <>
      <div className="FundPage">
        <RenderHome title={"My Contributions"}>
          {isLoading ? <Loader /> : ""}
          <div className="searchAndGridList">
            <div className="searchCover">
              <input
                type={"search"}
                name="searchProduct"
                placeholder="search by period eg. JAN22"
                onChange={handleSearch}
              />
            </div>
            <div className="listNdGridIcons">
              <BsPrinter className="icon cursor-pointer" onClick={handlePrint} />
              <SiMicrosoftexcel className="icon cursor-pointer" onClick={downloadExcelData}/>
            </div>
          </div>

          <TableContainer
            component={Paper}
            className="fundsTableContainer"
            ref={componentRef}
          >
            <Table
              className="table"
              sx={{ minWidth: 650 }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell className="header">
                    {isMobile ? "Type" : "Transaction Type"}{" "}
                  </TableCell>
                  <TableCell className="header" align="right">
                    Deduction
                  </TableCell>
                  <TableCell className="header" align="right">
                    Period
                  </TableCell>
                  <TableCell className="header" align="right">
                    Balance
                  </TableCell>
                  <TableCell className="header" align="right">
                    {isMobile ? "Amount" : "Original Amount"}{" "}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fundsOpeningBalance && (
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    className="bg-green-200 text-white"
                  >
                    <TableCell className="data" component="th" scope="row">
                      {"OPENING BALANCE"}
                    </TableCell>
                    <TableCell className="data" align="right">
                      {fundsOpeningBalance?.deduction}
                    </TableCell>
                    <TableCell className="data" align="right">
                      {"Start - Nov 22"}
                    </TableCell>
                    <TableCell className="data" align="right">
                      {fundsOpeningBalance?.balance}
                    </TableCell>
                    <TableCell className="data" align="right">
                      {fundsOpeningBalance?.original_amount}
                    </TableCell>
                  </TableRow>
                )}

                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell className="data" component="th" scope="row">
                    {""}
                  </TableCell>
                  <TableCell className="data" align="right">
                    {""}
                  </TableCell>
                  <TableCell className="data" align="right">
                    {""}
                  </TableCell>
                  <TableCell className="data" align="right">
                    {""}
                  </TableCell>
                  <TableCell className="data" align="right">
                    {""}
                  </TableCell>
                </TableRow>

                {funds?.map((fund, index) => {
                  console.log(fund);
                  return (
                    <TableRow
                      key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell className="data" component="th" scope="row">
                        {fund?.transaction_type}
                      </TableCell>
                      <TableCell className="data" align="right">
                        {fund?.deduction}
                      </TableCell>
                      <TableCell className="data" align="right">
                        {fund?.period}
                      </TableCell>
                      <TableCell className="data" align="right">
                        {fund?.balance}
                      </TableCell>
                      <TableCell className="data" align="right">
                        {fund?.original_amount}
                      </TableCell>
                    </TableRow>
                  );
                })}
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell className="data" component="th" scope="row">
                    {" "}
                  </TableCell>
                  <TableCell className="data" align="right">
                    {""}
                  </TableCell>
                  <TableCell className="data" align="right">
                    {""}
                  </TableCell>
                  <TableCell className="data" align="right">
                    {""}
                  </TableCell>
                  <TableCell className="data" align="right">
                    {""}
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    className="data font-bold"
                    component="th"
                    scope="row"
                  >
                    <p style={{ fontWeight: "bold" }}> {"Total "}</p>
                  </TableCell>
                  <TableCell className="data font-bold" align="right">
                    <p style={{ fontWeight: "bold" }}>
                      <CidiSymbol /> {Number(totalFunds).toFixed(2)}
                    </p>
                  </TableCell>
                  <TableCell className="data" align="right">
                    {""}
                  </TableCell>
                  <TableCell className="data" align="right">
                    {""}
                  </TableCell>
                  <TableCell className="data" align="right">
                    {""}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          {funds.length > 0 && (
            <ReactPaginate
              breakLabel="..."
              nextLabel=">"
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel="<"
              renderOnZeroPageCount={null}
              containerClassName="pagination"
              pageLinkClassName="page-num"
              previousLinkClassName="page-num"
              nextLinkClassName="page-num"
              activeLinkClassName="active"
            />
          )}

          <Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <MenuItem onClick={handleClose}>Download PDF</MenuItem>
            <MenuItem onClick={handleClose}>Download Excel</MenuItem>
          </Menu>
        </RenderHome>
      </div>
    </>
  );
}

export default Fund;
