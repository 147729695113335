import axiosInstance from "../axios/axios";
import { messagesConstants } from "../constants/messagesConstants";

export const addMessages = (data) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: messagesConstants.LOADING,
        isLoading: true,
      });
      const response = await axiosInstance.post("/messages", data);
      if (response) {
        dispatch({
          type: messagesConstants.GET_MESSAGES,
          payload: response.data,
        });
        dispatch({
          type: messagesConstants.RESPONSE_STATE,
          response: {
            state: "SUCCESS",
            message: "Message sent",
          },
        });
        dispatch({
          type: messagesConstants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: messagesConstants.RESPONSE_STATE,
        response: {
          state: "ERROR",
          message: e?.response?.data.message ?? "Opps something bad happend",
        },
      });
      dispatch({
        type: messagesConstants.LOADING,
        isLoading: false,
      });
    }
  };
};

export const getMessages = (user) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: messagesConstants.LOADING,
        isLoading: true,
      });

      const response = await axiosInstance.get(`/messages/${user}`);
      if (response) {
        dispatch({
          type: messagesConstants.GET_MESSAGES,
          payload: { messages: response.data },
        });
        dispatch({
          type: messagesConstants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: messagesConstants.LOADING,
        isLoading: false,
      });
    }
  };
};
export const DeleteMessage = (message_id) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: messagesConstants.LOADING,
        isLoading: true,
      });

      const response = await axiosInstance.delete(
        `/messages/delete-by-member/${message_id}`
      );
      if (response) {
        dispatch({
          type: messagesConstants.RESPONSE_STATE,
          response: {
            state: "DELETED",
            message: "Message deleted",
          },
        });
        dispatch({
          type: messagesConstants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: messagesConstants.LOADING,
        isLoading: false,
      });
    }
  };
};
export const CloseMessage = (message_id) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: messagesConstants.LOADING,
        isLoading: true,
      });

      const response = await axiosInstance.put(
        `/messages/close-by-member/${message_id}`
      );
      if (response) {
        dispatch({
          type: messagesConstants.RESPONSE_STATE,
          response: {
            state: "DELETED",
            message: "Message closed",
          },
        });
        dispatch({
          type: messagesConstants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: messagesConstants.LOADING,
        isLoading: false,
      });
    }
  };
};
export const MarkMessageAsRead = (message_id, chat_id) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: messagesConstants.LOADING,
        isLoading: true,
      });

      const response = await axiosInstance.put(
        `/messages/mark-member-as-read/${message_id}?chat_id=${chat_id}`
      );
      if (response) {
        dispatch({
          type: messagesConstants.RESPONSE_STATE,
          response: {
            state: "SUCCESS",
            message: "Message marked as read",
          },
        });
        dispatch({
          type: messagesConstants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: messagesConstants.LOADING,
        isLoading: false,
      });
    }
  };
};
export const getMessageDetailed = (chat_id) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: messagesConstants.LOADING,
        isLoading: true,
      });

      const response = await axiosInstance.get(
        `/messages/member-message-detailed/${chat_id}`
      );
      if (response) {
        dispatch({
          type: messagesConstants.GET_MESSAGE_DETAILED,
          payload: { messageDetailed: response.data },
        });
        dispatch({
          type: messagesConstants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: messagesConstants.LOADING,
        isLoading: false,
      });
    }
  };
};
export const GetTotalUnreadMessages = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: messagesConstants.LOADING,
        isLoading: true,
      });

      const response = await axiosInstance.get(
        `/messages/membe-total`
      );
      if (response) {
        dispatch({
          type: messagesConstants.GET_MESSAGES_UNREAD_TOTAL,
          payload: { unreadMessagesTotal: response.data },
        });
        dispatch({
          type: messagesConstants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: messagesConstants.LOADING,
        isLoading: false,
      });
    }
  };
};

export const GetUsersForChat = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: messagesConstants.LOADING,
        isLoading: true,
      });

      const response = await axiosInstance.get(`/messages/system-users`);
      console.log("response.data", response.data);
      if (response) {
        dispatch({
          type: messagesConstants.GET_USERS_FOR_CHAT,
          payload: { usersForChat: response.data },
        });
        dispatch({
          type: messagesConstants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: messagesConstants.LOADING,
        isLoading: false,
      });
    }
  };
};

export const GetConversations = (user_id) => {
  return async (dispatch) => {
    try {
      console.log("user_id", user_id);
      dispatch({
        type: messagesConstants.LOADING,
        isLoading: true,
      });
      const response = await axiosInstance.get(
        `/messenger/conversation/${user_id}`
      );

      if (response) {
        // localStorage.setItem("conversations", JSON.stringify(response.data));
        dispatch({
          type: messagesConstants.GET_CONVERSATIONS,
          payload: { conversations: response.data },
        });
        dispatch({
          type: messagesConstants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: messagesConstants.LOADING,
        isLoading: false,
      });
    }
  };
};
export const DeleteConversations = (conversation_id) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: messagesConstants.LOADING,
        isLoading: true,
      });
      const response = await axiosInstance.delete(
        `/conversation/${conversation_id}`
      );

      if (response) {
        dispatch({
          type: messagesConstants.RESPONSE_STATE,
          response: {
            state: "SUCCESS",
            message: "Conversation deleted",
          },
        });
        dispatch({
          type: messagesConstants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: messagesConstants.LOADING,
        isLoading: false,
      });
    }
  };
};

export const CreateConversation = (payload, title) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: messagesConstants.LOADING,
        isLoading: true,
      });
      const response = await axiosInstance.post("/messenger/conversation", {
        members: payload,
        title: title,
      });
      if (response) {
        dispatch({
          type: messagesConstants.RESPONSE_STATE,
          response: {
            state: "SUCCESS",
            message: "New Conversation created",
          },
        });
        dispatch({
          type: messagesConstants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: messagesConstants.LOADING,
        isLoading: false,
      });
    }
  };
};

export const ResetMessagesResponse = () => {
  return async (dispatch) => {
    dispatch({
      type: messagesConstants.RESPONSE_STATE,
      response: {
        state: null,
        message: "",
      },
    });
  };
};
