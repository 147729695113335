/* eslint-disable  */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { bindActionCreators } from "redux";
import "../../../../../css/Pages/Shopping.css";
import { userDataActionCreators } from "../../../../../service/action-creators";
import { GetUserProfile } from "../../../../../service/action-creators/authAction";
import { GetNotifications } from "../../../../../service/action-creators/notificationAction";
import { ResetProductResponse } from "../../../../../service/action-creators/ProductAction";
import Loader from "../../../../Loader";
import NotFoundComponent from "../../../../NotFoundComponent";
import RenderHome from "../../../home/RenderHome";
import ProductCard from "./ProductCard";


function FavouriteProducts() {
  const dispatch = useDispatch();
  const { GetFavoriteProducts, GetCartProducts, ResetUserDataResponse } = bindActionCreators(
    userDataActionCreators,
    dispatch
  );
  const user = useSelector((state) => state?.user);
  const productData = useSelector((state) => state?.product);
  const isLoading = user?.isLoading;
  const response = user?.response;
  const favoriteProducts = user?.favoriteProducts;

  console.log("favoriteProducts", favoriteProducts);

  useEffect(() => {
    GetFavoriteProducts();
    GetCartProducts()
    dispatch(GetUserProfile())
    dispatch(GetNotifications())
   
  }, []);



 useEffect(() => {
    if (user?.response?.state === "SUCCESS" || productData?.response?.state === "SUCCESS") {
      // toast.success(response?.message);
      GetFavoriteProducts()
      GetCartProducts();
      setTimeout(() => {
        ResetUserDataResponse();
        dispatch(ResetProductResponse())
      }, 1500);
    } else if (user?.response?.state === "ERROR" || productData?.response?.state === "ERROR") {
      // toast.error(response?.message);
      setTimeout(() => {
        ResetUserDataResponse();
        dispatch(ResetProductResponse())
      }, 1500);
    }
  }, [user?.response?.state || productData.response.state]);
  return (
    <div className="FavouriteProducts">
      <RenderHome title={"My Favourites"}>
        {user?.isLoading || productData?.isLoading && <Loader />}
        {favoriteProducts?.length > 0 ? (
          <div className="favouriteContainer">
            <>
              {favoriteProducts?.map((product, index) => {
                return <ProductCard key={index} product={product} />;
              })}
            </>
          </div>
        ) : (
          <>
            <NotFoundComponent title={'No Favourites Available'}/>
          </>
        )}

        {/* <ProductCard/>
            <ProductCard/>
            <ProductCard/>
            <ProductCard/> */}
      </RenderHome>
      <ToastContainer autoClose={3000} />
    </div>
  );
}

export default FavouriteProducts;
