import { combineReducers } from "redux";
import AuthRducer from "./authReducer";
import messagesReducer from "./messagesReducer";
import navigationReducer from "./navigationReducer";
import notificationReducer from "./notificationReducer";
import ProductReducer from "./ProductReducer";
import quantityCounterReducer from "./quantityCounterReducer";
import userDataReducer from "./userDataReducer";

export const rootReducer = combineReducers({
    navigation: navigationReducer,
    auth: AuthRducer,
    user: userDataReducer,
    notification: notificationReducer,
    product: ProductReducer,
    quantityCounter: quantityCounterReducer,
    messages: messagesReducer
})