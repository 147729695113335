/* eslint-disable  */
import { Avatar, IconButton } from "@material-ui/core";
import moment from "moment";
import React, { useEffect } from "react";
import { FaTrash } from "react-icons/fa";
import { TiDelete } from "react-icons/ti";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { bindActionCreators } from "redux";
import { notificationsActionCreators } from "../../../service/action-creators";
import { GetUserProfile } from "../../../service/action-creators/authAction";
import Loader from "../../Loader";
import RenderHome from "../home/RenderHome";
import './Notifications.css';

function Notifications() {
  const dispatch = useDispatch();
  const { GetNotifications, ResetResponse, DeleteNotification } = bindActionCreators(
    notificationsActionCreators,
    dispatch
  );

  const notifications = useSelector(
    (state) => state?.notification?.notifications
  );
  const isLoading = useSelector((state) => state?.notification?.isLoading);
  const response = useSelector((state) => state?.notification?.response);
  console.log("notifications", notifications);

  useEffect(() => {
    GetNotifications();
    dispatch(GetUserProfile())
  }, []);

  useEffect(() => {
    if (response.state === "SUCCESS") {
      GetNotifications()
      // toast.success(response?.message ?? "Notifications loaded");
      setTimeout(() => {
        ResetResponse();
      }, 1500);
    } else if (response.state === "ERROR") {
      toast.error(response?.message ?? "unable to get notification");
      setTimeout(() => {
        ResetResponse();
      }, 1500);
    }
  }, [response.state]);
  return (
    <div className="Notifications">
      <RenderHome title={"Notifications"}>
        {isLoading && <Loader />}
        <div className="SectionTwoNotificationSection">
          <div className="notifications">
            {notifications.length > 0 ? (
              <div className="notif_content">
                {notifications?.map((notification, index) => {
                  const name = notification?.title.split(' ')[0].charAt(0).toUpperCase()
                  // const name = notification?.title.split(' ')[0].charAt(0) + ' ' + notification?.title.split(' ')[1].charAt(0)
                  return (
                    <div className="flex-items" key={index}>
                      <div className="flex">
                        {/* <Avatar className="icon" /> */}
                      
                        <Avatar className="icon"  >{name}</Avatar>
                        
                        <div>
                          <h5 className="notif_title  ">{notification?.title}</h5>
                          <div className="notif_msg text-gray-500">
                            {notification?.message}
                          </div>
                          <div className="time text-gray-300">
                            {moment(notification?.created_at).calendar()}
                          </div>
                        </div>
                      </div>
                      <div>
                        <IconButton onClick={(e) => {
                          DeleteNotification(notification?._id)
                        }}>
                        <TiDelete />
                        </IconButton>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        <ToastContainer autoClose={3000} />
      </RenderHome>
    </div>
  );
}

export default Notifications;
