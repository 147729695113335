/* eslint-disable  */
import { Delete } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import { authActionCreators, messagesActionCreators } from '../../../service/action-creators'
import axiosInstance from '../../../service/axios/axios'
import './Conversation.css'
import {RiDeleteBin2Line} from 'react-icons/ri'
function Conversation({conversation}) {
    const dispatch = useDispatch()
    const [user, setUser] = useState(null)
    // console.log('conversation', conversation)
    const {getUserById} = bindActionCreators(authActionCreators, dispatch)
    const {GetConversations, DeleteConversations, ResetMessagesResponse} = bindActionCreators(messagesActionCreators, dispatch)
    //get user
    const auth = useSelector(state => state?.auth)
    // console.log('auth', auth)
    const [active, setActive] = useState(false)
    const chat = useSelector((state) => state.messages);
    const { usersForChat, response } = chat;
    
    useEffect(() => {
        const otherUser = conversation?.members?.find(user => user !== auth?.user?._id)

        const getUser = async () => {
            try {
              const res =  await axiosInstance.get(`/users/user?user_id=${otherUser}`)
              setUser(res.data)
            } catch (error) {
                console.log('error', error)
            }
        }
        getUser()

    },[auth?.user, conversation])

    console.log('conver', conversation)
    useEffect(() => {
      GetConversations(auth?.user?._id);
    }, [])

    useEffect(() => {
      if (response?.state === "SUCCESS") {
        // toast.success(response?.message);
        setTimeout(() => {
          ResetMessagesResponse();
          GetConversations();
        }, 1500);
      } else if (response?.state === "ERROR") {
        // toast.error(response?.message);
        setTimeout(() => {
          ResetMessagesResponse();
        }, 1500);
      }
    }, [response?.state]);
  


  return (
    <div className={"Conversation"} onClick={() => {
    // <div className={active ? "Conversation  active" : "Conversation"} onClick={() => {
      if(conversation){
        setActive(true)
      }else{
        setActive(false)
      }
      console.log('conversation ac', conversation)
      } }>
        <div className='userProfile'>
        <img className='conversationImage' src={user?.profile_image ? user?.profile_image : "/images/dev/user.png"} alt="officer" />
        <div>
        <div className='title'>{conversation?.title} </div>
        <div className='username'>{user?.name}</div>
        <div className='user'>{user?.officer}</div>
        </div>
        </div>
        <RiDeleteBin2Line onClick={() => DeleteConversations(conversation?._id)}/>
    </div>
  )
}

export default Conversation