import React from 'react'
import RenderHome from '../home/RenderHome'

function HirePurchase() {
  return (
    <div>
        <RenderHome title={'Hire Purchase'}>
            <p>HirePurchase</p>
        </RenderHome>
    </div>
  )
}

export default HirePurchase