/* eslint-disable  */
import React, { useEffect } from "react";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Route, Switch } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import EmailVerify from "./components/Auth/EmailVerify";
import Login from "./components/Auth/Login";
import Logout from "./components/Auth/Logout";
import Register from "./components/Auth/Register";
import ResetPassword from "./components/Auth/ResetPassword";
import VerifyEmailError from "./components/Auth/VerifyEmailError";
import VerifyEmailSuccess from "./components/Auth/VerifyEmailSuccess";
import Home from "./components/Dashboad/home/Home";
import ApplyLoans from "./components/Dashboad/pages/ApplyLoans";
import ChangePasswordAndEmail from "./components/Dashboad/pages/ChangePasswordAndEmail";
import Faq from "./components/Dashboad/pages/Faq";
import Fund from "./components/Dashboad/pages/Fund";
import HirePurchase from "./components/Dashboad/pages/HirePurchase";
import Loans from "./components/Dashboad/pages/Loans";
import Messenger from "./components/Dashboad/pages/Messenger";
import MyOrdersNdPurchases from "./components/Dashboad/pages/MyOrdersNdPurchases.js";
import Notifications from "./components/Dashboad/pages/Notifications";
import ProductDetailed from "./components/Dashboad/pages/shopping/ProductDetailed.js";
import Products from "./components/Dashboad/pages/shopping/Products";
import SearchedProduct from "./components/Dashboad/pages/shopping/SearchedProduct";
import AllProductPage from "./components/Dashboad/pages/shopping/sub_component/AllProductPage";
import FavouriteProducts from "./components/Dashboad/pages/shopping/sub_component/FavouriteProducts";
import MyCart from "./components/Dashboad/pages/shopping/sub_component/MyCart";
import ViewAllProductInACategory from "./components/Dashboad/pages/shopping/sub_component/ViewAllProductInACategory";
import Support from "./components/Dashboad/pages/Support";
import UserProfile from "./components/Dashboad/pages/UserProfile";
import PrivateRoute from "./components/HOC/PrivateRoute";
import "./dist/output.css";
import MartTerms from "./components/Dashboad/pages/shopping/MartTerms";
import NotFound404 from "./components/Dashboad/pages/NotFound404";
import SentEmailForVerification from "./components/Auth/SentEmailForVerification";
import ResendEmailVerificationLink from "./components/Auth/ResendEmailVerificationLink";
import MyAppliedLoans from "./components/Dashboad/pages/MyAppliedLoans";
import MessageAdmin from "./components/Dashboad/pages/MessageAdmin";
import MessageDetailed from "./components/Dashboad/pages/MessageDetailed";
import LoanCalculator from "./components/Dashboad/pages/LoanCalculator";
import Employment from "./components/Dashboad/pages/ApplyLoans/Employment";
import PersonalInfo from "./components/Dashboad/pages/ApplyLoans/PersonalInfo";
import Guarantor from "./components/Dashboad/pages/ApplyLoans/Guarantor";
import LoanDetailed from "./components/Dashboad/pages/ApplyLoans/LoanDetailed";
import Uploads from "./components/Dashboad/pages/ApplyLoans/Uploads";
import Preview from "./components/Dashboad/pages/ApplyLoans/Preview";
import LoanTerms from "./components/Dashboad/pages/ApplyLoans/LoanTerms";
import EditMartForm from "./components/Dashboad/pages/EditMartForm";
import RequestOrder from "./components/Dashboad/pages/RequestOrder";
import FundIncrement from "./components/Dashboad/pages/FundIncrement.js";

const customTheme = createTheme({
  palette: {
    type: "light",
  },
});
function App() {
 
  useEffect(() => {
    window.scrollTo(0,0)
   }, [])
  return (
    <>
      <ThemeProvider theme={customTheme}>
        <div className="App">
          <Switch>
            <Route exact path={"/"} component={Login} />
            {/* <Route  path={"*"} component={NotFound404} /> */}
            <Route
              path={"/account/email-sent"}
              component={SentEmailForVerification}
            />
            <Route
              path={"/account/resend-email-verification-link"}
              component={ResendEmailVerificationLink}
            />
            <Route path={"/account/password-reset"} component={ResetPassword} />
            <Route path={"/account/verifyemail"} component={EmailVerify} />
            <Route
              path={"/account/email-verify-success"}
              component={VerifyEmailSuccess}
            />
            <Route
              path={"/account/email-verify-error"}
              component={VerifyEmailError}
            />

            <PrivateRoute path={"/logout"} component={Logout} />
            <PrivateRoute
              path={"/change-password-email"}
              component={ChangePasswordAndEmail}
            />
            <PrivateRoute path="/home" component={Home} />
            <PrivateRoute path={"/register"} component={Register} />
            <PrivateRoute path={"/notifications"} component={Notifications} />
            <PrivateRoute path={"/hire-purchase"} component={HirePurchase} />

            <PrivateRoute
              path={"/my-orders-and-purchase"}
              component={MyOrdersNdPurchases}
            />
            <PrivateRoute
              path={"/my-applied-loans"}
              component={MyAppliedLoans}
            />
            <PrivateRoute path={"/loans"} component={Loans} />
            <PrivateRoute path={"/fund-increment"} component={FundIncrement} />
            <PrivateRoute path={"/mart_terms"} component={MartTerms} />

            <PrivateRoute path={"/edit-loan-form"} component={ApplyLoans} />
            <PrivateRoute path={"/edit-order-form"} component={EditMartForm} />

            <PrivateRoute
              path={"/apply-loan-personal_info"}
              component={PersonalInfo}
            />
            <PrivateRoute
              path={"/apply-loan-guarantor"}
              component={Guarantor}
            />
            <PrivateRoute
              path={"/apply-loan-employment"}
              component={Employment}
            />
            <PrivateRoute
              path={"/apply-loan-loan_detailed"}
              component={LoanDetailed}
            />

    
            <PrivateRoute path={"/apply-loan-uploads"} component={Uploads} />
            <PrivateRoute path={"/apply-loan-preview"} component={Preview} />
            <PrivateRoute path={"/apply-loan-terms"} component={LoanTerms} />
            <PrivateRoute path={"/fund"} component={Fund} />
            <PrivateRoute path={"/calculator"} component={LoanCalculator} />

            <PrivateRoute path={"/profile"} component={UserProfile} />
            <PrivateRoute path={"/upnmg-shopping-mall"} component={Products} />
            <PrivateRoute path={"/all-products"} component={AllProductPage} />
            <PrivateRoute
              path={"/product-detailed"}
              component={ProductDetailed}
            />
            <PrivateRoute
              path={"/searchedProducts"}
              component={SearchedProduct}
            />
            <PrivateRoute
              path={"/category-products"}
              component={ViewAllProductInACategory}
            />
            <PrivateRoute
              path={"/request-order"}
              component={RequestOrder}
            />

            <PrivateRoute path={"/favourites"} component={FavouriteProducts} />
            <PrivateRoute path={"/messenger"} component={Messenger} />
            <PrivateRoute path={"/message"} component={MessageAdmin} />
            <PrivateRoute
              path={"/message-detailed"}
              component={MessageDetailed}
            />
            <PrivateRoute path={"/my-cart"} component={MyCart} />
            <PrivateRoute path={"/support"} component={Support} />
            <PrivateRoute path={"/faq"} component={Faq} />
            <PrivateRoute path={"*"} component={NotFound404} />
          </Switch>
        </div>
      </ThemeProvider>
    </>
  );
}

export default App;
