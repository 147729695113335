/* eslint-disable  */
import axiosInstance from "../axios/axios";
import { userDataConstants } from "../constants/userDataConstants";

export const GetFundsOpeningBalance = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: userDataConstants.LOADING,
        isLoading: true,
      });
      const response = await axiosInstance.get(`/funds/opening-balance`);
      if (response) {
        dispatch({
          type: userDataConstants.GET_FUNDS_OPENING_BALANCE,
          payLoad: {
            fundsOpeningBalance: response.data,
          },
        });

        dispatch({
          type: userDataConstants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: userDataConstants.RESPONSE_STATE,
        response: {
          state: "ERROR",
          message: e?.response?.data?.message ?? "Unable to get funds",
        },
      });
      dispatch({
        type: userDataConstants.LOADING,
        isLoading: false,
      });
    }
  };
};
export const IncreaseFund = (body) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: userDataConstants.LOADING,
        isLoading: true,
      });

      // let formData = new FormData();
   
      // formData.append("IDCardNumber", body.IDCardNumber);
      // formData.append("current_contribution", body.current_contribution);
      // formData.append("dob", body.dob);
      // formData.append("facility", body.facility);
      // formData.append("mandate_number", body.mandate_number);
      // formData.append("name", body.name);
      // formData.append("next_of_kin", body.next_of_kin);
      // formData.append("phone_number", body.phone_number);
      // formData.append("requested_increment", body.requested_increment);
      // formData.append("staff_id", body.staff_id);
      // formData.append("toDate", body.toDate);
      // formData.append("fromDate", body.fromDate);

      // for(const file of selectedImageFiles){
      //   formData.append("files", file, file.name);
      // }
  
      // body.map((data) => formData.append(data, data));


      const response = await axiosInstance.post(`/funds/fund-increment`, body);
      if (response) {
        dispatch({
          type: userDataConstants.RESPONSE_STATE,
          response: {
            state: "SUCCESS",
            message: "Fund request submitted"
          },
        });

        dispatch({
          type: userDataConstants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: userDataConstants.RESPONSE_STATE,
        response: {
          state: "ERROR",
          message: e?.response?.data?.message ?? "Unable to send fund request",
        },
      });
      dispatch({
        type: userDataConstants.LOADING,
        isLoading: false,
      });
    }
  };
};
export const GetMyIncreaseFund = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: userDataConstants.LOADING,
        isLoading: true,
      });
      const response = await axiosInstance.get(`/funds/my-fund-increment`);
      if (response) {
        dispatch({
          type: userDataConstants.GET_MY_FUNDS_INCREMENT,
          payLoad: {
            fund_increment: response.data,
          },
        });
        // dispatch({
        //   type: userDataConstants.RESPONSE_STATE,
        //   response: {
        //     state: "SUCCESS",
        //     message: "Fund increment  submit"
        //   },
        // });

        dispatch({
          type: userDataConstants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: userDataConstants.RESPONSE_STATE,
        response: {
          state: "ERROR",
          message: e?.response?.data?.message ?? "Unable to send fund increment",
        },
      });
      dispatch({
        type: userDataConstants.LOADING,
        isLoading: false,
      });
    }
  };
};
export const GetFunds = (isPaginated, page, size, search) => {
  console.log("page", page);
  console.log("size", size);
  return async (dispatch) => {
    try {
      dispatch({
        type: userDataConstants.LOADING,
        isLoading: true,
      });
      const response = await axiosInstance.get(
        `/funds/user?isPaginated=${isPaginated}&size=${size}&page=${page}${
          search ? `&search=` + search : ""
        }`
      );

      if (response) {
        const {
          docs,
          page,
          size,
          totalItems,
          totalPages,
          currentPageSize,
          links,
        } = response.data;
        console.log("res", response.data);
        dispatch({
          type: userDataConstants.GET_FUNDS,
          payLoad: {
            funds: docs,
            paginate: {
              total: totalPages,
              totalItems,
              page,
              size,
              previousPage: links?.previousPage,
              currentPageSize,
              nextPage: links?.nextPage,
              previousNumber: links?.previous,
              nextNumber: links?.next,
            },
          },
        });
        // dispatch({
        //   type: userDataConstants.RESPONSE_STATE,
        //   response: {
        //     state: "SUCCESS",
        //     message: "Funds loaded successfully",
        //   },
        // });
        dispatch({
          type: userDataConstants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: userDataConstants.RESPONSE_STATE,
        response: {
          state: "ERROR",
          message: e?.response?.data?.message ?? "Unable to get funds",
        },
      });
      dispatch({
        type: userDataConstants.LOADING,
        isLoading: false,
      });
    }
  };
};
export const DownloadMemberFunds = (isPaginated, page, size) => {
  console.log("page", page);
  console.log("size", size);
  return async (dispatch) => {
    try {
      dispatch({
        type: userDataConstants.LOADING,
        isLoading: true,
      });
      const response = await axiosInstance.get(
        `/funds/user-download?isPaginated=${isPaginated}&size=${size}&page=${page}`,
        {responseType: 'blob'}
      );

      if (response) {
        const blob = new Blob([response.data], {
          type: `application/octet-stream`,
        });
        let a = document.createElement("a");
        a.download = `fund-report.xlsx`;
        a.href = window.URL.createObjectURL(blob);
        a.click();
        window.URL.revokeObjectURL(a.href);

        dispatch({
          type: userDataConstants.LOADING,
          isLoading: false,
        });
      }

  
 
    } catch (e) {
      dispatch({
        type: userDataConstants.RESPONSE_STATE,
        response: {
          state: "ERROR",
          message: e?.response?.data?.message ?? "Unable to download funds",
        },
      });
      dispatch({
        type: userDataConstants.LOADING,
        isLoading: false,
      });
    }
  };
};


export const GetTotalFunds = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: userDataConstants.LOADING,
        isLoading: true,
      });
      const response = await axiosInstance.get(`/funds/member/total`);
      console.log("response.data", response.data);
      if (response) {
        const { total_fund } = response.data;
        dispatch({
          type: userDataConstants.GET_TOTAL_FUNDS,
          payLoad: response.data?.map((fundTotal) => fundTotal?.total_fund),
        });

        dispatch({
          type: userDataConstants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: userDataConstants.RESPONSE_STATE,
        response: {
          state: "ERROR",
          message: e?.response?.data?.message ?? "Unable to get funds",
        },
      });
      dispatch({
        type: userDataConstants.LOADING,
        isLoading: false,
      });
    }
  };
};
export const GetLoans = (isPaginated, page, size, search) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: userDataConstants.LOADING,
        isLoading: true,
      });
      const response = await axiosInstance.get(
        `/loans/user?isPaginated=${isPaginated}&page=${page}&size=${size}${
          search ? `&search=` + search : ""
        }`
      );
      if (response) {
        const {
          docs,
          totalItems,
          totalPages,
          currentPageSize,
          page,
          size,
          links,
        } = response.data;
        dispatch({
          type: userDataConstants.GET_LOANS,
          payLoad: {
            loans: docs,
            paginate: {
              total: totalPages,
              totalItems,
              page,
              size,
              currentPageSize,
              previousPage: links?.previousPage,
              previous: links?.previous,
              nextPage: links?.nextPage,
              next: links?.next,
            },
          },
        });
        // dispatch({
        //   type: userDataConstants.RESPONSE_STATE,
        //   response: {
        //     state: "SUCCESS",
        //     message: "Funds loaded successfully",
        //   },
        // });
        dispatch({
          type: userDataConstants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: userDataConstants.RESPONSE_STATE,
        response: {
          state: "ERROR",
          message: e?.response?.data?.message ?? "Unable to get loans",
        },
      });
      dispatch({
        type: userDataConstants.LOADING,
        isLoading: false,
      });
    }
  };
};


export const GetDues = (isPaginated, page, size) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: userDataConstants.LOADING,
        isLoading: true,
      });
      const response = await axiosInstance.get(
        `/dues/user?isPaginated=${isPaginated}&page=${page}&size=${size}`
      );
      if (response) {
        const {
          docs,
          totalItems,
          totalPages,
          page,
          size,
          currentPageSize,
          links,
        } = response.data;

        dispatch({
          type: userDataConstants.GET_DUES,
          payLoad: {
            dues: docs,
            paginate: {
              total: totalPages,
              totalItems,
              page,
              size,
              previousPage: links?.previousPage,
              currentPageSize,
              nextPage: links?.nextPage,
              previousNumber: links?.previous,
              nextNumber: links?.next,
            },
          },
        });
        // dispatch({
        //   type: userDataConstants.RESPONSE_STATE,
        //   response: {
        //     state: "SUCCESS",
        //     message: "Dues loaded successfully",
        //   },
        // });
        dispatch({
          type: userDataConstants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: userDataConstants.RESPONSE_STATE,
        response: {
          state: "ERROR",
          message: e?.response?.data?.message ?? "Unable to get dues",
        },
      });
      dispatch({
        type: userDataConstants.LOADING,
        isLoading: false,
      });
    }
  };
};

export const GetAppliedLoans = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: userDataConstants.LOADING,
        isLoading: true,
      });

      const response = await axiosInstance.get(`/loans/my-applied-loans`);
      if (response) {
        dispatch({
          type: userDataConstants.GET_APPLIED_LOANS,
          payLoad: {
            myAppliedLoans: response.data,
          },
          response: {
            state: "SUCCESS",
            message: "Applied loans loaded successfully",
          },
        });
        dispatch({
          type: userDataConstants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: userDataConstants.LOADING,
        isLoading: false,
      });
      dispatch({
        type: userDataConstants.RESPONSE_STATE,
        response: {
          state: "ERROR",
          message: e?.response?.data?.message ?? "Unable to get applied loans",
        },
      });
    }
  };
};
export const GetAppliedLoanDetailed = (loan_id) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: userDataConstants.LOADING,
        isLoading: true,
      });

      const response = await axiosInstance.get(`/loans/my-applied-loans/${loan_id}`);
      if (response) {
        dispatch({
          type: userDataConstants.GET_APPLIED_LOAN_DETAILED,
          payLoad: {
            loan: response.data,
          },
          response: {
            state: "SUCCESS",
            message: "Loan loaded successfully",
          },
        });
        dispatch({
          type: userDataConstants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: userDataConstants.LOADING,
        isLoading: false,
      });
      dispatch({
        type: userDataConstants.RESPONSE_STATE,
        response: {
          state: "ERROR",
          message: e?.response?.data?.message ?? "Unable to get applied loans",
        },
      });
    }
  };
};
export const GetAppliedOrderDetailed = (order_id) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: userDataConstants.LOADING,
        isLoading: true,
      });

      const response = await axiosInstance.get(`/orders/my-applied-orders/${order_id}`);
      if (response) {
        dispatch({
          type: userDataConstants.GET_APPLIED_ORDER_DETAILED,
          payLoad: {
            order: response.data,
          },
          response: {
            state: "SUCCESS",
            message: "Order loaded successfully",
          },
        });
        dispatch({
          type: userDataConstants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: userDataConstants.LOADING,
        isLoading: false,
      });
      dispatch({
        type: userDataConstants.RESPONSE_STATE,
        response: {
          state: "ERROR",
          message: e?.response?.data?.message ?? "Unable to get applied loans",
        },
      });
    }
  };
};
export const DeleteMyAppliedLoans = (
  loan_id,
  front_public_id,
  back_public_id
) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: userDataConstants.LOADING,
        isLoading: true,
      });

      const response = await axiosInstance.delete(
        `/loans/my-applied-loans/${loan_id}?front_public_id=${front_public_id}&back_public_id=${back_public_id}`
      );
      if (response) {
        dispatch({
          type: userDataConstants.RESPONSE_STATE,
          response: {
            state: "SUCCESS",
            message: "Loan deleted successfully",
          },
        });
        dispatch({
          type: userDataConstants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: userDataConstants.LOADING,
        isLoading: false,
      });
      dispatch({
        type: userDataConstants.RESPONSE_STATE,
        response: {
          state: "ERROR",
          message: e?.response?.data?.message ?? "Unable to delete loan",
        },
      });
    }
  };
};
export const DeleteMyAppliedOrder = (
  order_id,
) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: userDataConstants.LOADING,
        isLoading: true,
      });

      const response = await axiosInstance.delete(
        `/orders/my-applied-orders/${order_id}`
      );
      if (response) {
        dispatch({
          type: userDataConstants.RESPONSE_STATE,
          response: {
            state: "SUCCESS",
            message: "Order deleted successfully",
          },
        });
        dispatch({
          type: userDataConstants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: userDataConstants.LOADING,
        isLoading: false,
      });
      dispatch({
        type: userDataConstants.RESPONSE_STATE,
        response: {
          state: "ERROR",
          message: e?.response?.data?.message ?? "Unable to delete order",
        },
      });
    }
  };
};

export const GetFavoriteProducts = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: userDataConstants.LOADING,
        isLoading: true,
      });

      const response = await axiosInstance.get(`/products/favorites`);
      if (response) {
        dispatch({
          type: userDataConstants.GET_FAVORITE_PRODUCTS,
          payLoad: {
            favoriteProducts: response.data,
          },
          response: {
            state: "SUCCESS",
            message: "Your favorites products loaded",
          },
        });
        dispatch({
          type: userDataConstants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: userDataConstants.LOADING,
        isLoading: false,
      });
      dispatch({
        type: userDataConstants.RESPONSE_STATE,
        response: {
          state: "ERROR",
          message: e?.response?.data?.message ?? "Unable to get favorite",
        },
      });
    }
  };
};

export const AddFavoriteProducts = (product_id) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: userDataConstants.LOADING,
        isLoading: true,
      });

      const response = await axiosInstance.post(
        `/products/favorites/${product_id}`
      );
      if (response) {
        dispatch({
          type: userDataConstants.RESPONSE_STATE,
          response: {
            state: "SUCCESS",
            message: "Product added as favorite",
          },
        });
        dispatch({
          type: userDataConstants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: userDataConstants.LOADING,
        isLoading: false,
      });
      dispatch({
        type: userDataConstants.RESPONSE_STATE,
        response: {
          state: "ERROR",
          message:
            e?.response?.data?.message ?? "Unable to add product as a favorite",
        },
      });
    }
  };
};
export const RemoveFavoriteProducts = (favorite_id) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: userDataConstants.LOADING,
        isLoading: true,
      });

      const response = await axiosInstance.delete(
        `/products/favorites/${favorite_id}`
      );
      if (response) {
        dispatch({
          type: userDataConstants.RESPONSE_STATE,
          response: {
            state: "SUCCESS",
            message: "Product removed from favorite",
          },
        });
        dispatch({
          type: userDataConstants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: userDataConstants.LOADING,
        isLoading: false,
      });
      dispatch({
        type: userDataConstants.RESPONSE_STATE,
        response: {
          state: "ERROR",
          message:
            e?.response?.data?.message ??
            "Unable to remove product from favorite",
        },
      });
    }
  };
};

export const GetCartProducts = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: userDataConstants.LOADING,
        isLoading: true,
      });

      const response = await axiosInstance.get(`/products/user/cart`);
      if (response) {
        dispatch({
          type: userDataConstants.GET_CART_PRODUCTS,
          payLoad: {
            cart: response.data.cartItems,
            cartGrandTotal: response.data.grandTotal,
          },
          // response: {
          //   state: "SUCCESS",
          //   message: "Cart loaded",
          // },
        });
        dispatch({
          type: userDataConstants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: userDataConstants.LOADING,
        isLoading: false,
      });
      dispatch({
        type: userDataConstants.RESPONSE_STATE,
        response: {
          state: "ERROR",
          message: e?.response?.data?.message ?? "Unable to get cart",
        },
      });
    }
  };
};

export const RemoveProductFromCart = (product_id) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: userDataConstants.LOADING,
        isLoading: true,
      });

      const response = await axiosInstance.delete(
        `/products/cart/remove/${product_id}`
      );
      if (response) {
        dispatch({
          type: userDataConstants.RESPONSE_STATE,
          response: {
            state: "SUCCESS",
            message: "Product removed from cart",
          },
        });
        dispatch({
          type: userDataConstants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: userDataConstants.LOADING,
        isLoading: false,
      });
      dispatch({
        type: userDataConstants.RESPONSE_STATE,
        response: {
          state: "ERROR",
          message:
            e?.response?.data?.message ?? "Unable to remove product from cart",
        },
      });
    }
  };
};

export const AddProductToCart = (body) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: userDataConstants.LOADING,
        isLoading: true,
      });

      const response = await axiosInstance.post(`/products/cart/add`, body);
      if (response) {
        dispatch({
          type: userDataConstants.RESPONSE_STATE,
          response: {
            state: "SUCCESS",
            message: "Product added to your cart",
          },
        });
        dispatch({
          type: userDataConstants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: userDataConstants.LOADING,
        isLoading: false,
      });
      dispatch({
        type: userDataConstants.RESPONSE_STATE,
        response: {
          state: "ERROR",
          message: e?.response?.data?.message ?? "Unable to add to cart",
        },
      });
    }
  };
};

export const CreateOrder = (payLoad) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: userDataConstants.LOADING,
        isLoading: true,
      });
      console.log("payLoad", payLoad);
      const response = await axiosInstance.post(`/orders/user/create`, payLoad);
      if (response) {
        dispatch({
          type: userDataConstants.LOADING,
          isLoading: false,
        });
        dispatch({
          type: userDataConstants.RESPONSE_STATE,
          response: {
            state: "SUCCESS",
            message: "New order created",
          },
        });
      }
    } catch (e) {
      dispatch({
        type: userDataConstants.LOADING,
        isLoading: false,
      });
      dispatch({
        type: userDataConstants.RESPONSE_STATE,
        response: {
          state: "ERROR",
          message: e?.response?.data?.message ?? "Unable to create order",
        },
      });
    }
  };
};

export const UpdateCart = (cart_id, product_id, body) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: userDataConstants.LOADING,
        isLoading: true,
      });
      console.log("body", body);
      const response = await axiosInstance.put(
        `/products/cart/update/${cart_id}/${product_id}`,
        body
      );
      if (response) {
        dispatch({
          type: userDataConstants.RESPONSE_STATE,
          response: {
            state: "SUCCESS",
            message: "Cart updated successfully",
          },
        });
        dispatch({
          type: userDataConstants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: userDataConstants.LOADING,
        isLoading: false,
      });
      dispatch({
        type: userDataConstants.RESPONSE_STATE,
        response: {
          state: "ERROR",
          message: e?.response?.data?.message ?? "Unable to update cart",
        },
      });
    }
  };
};

export const GetMyOrders = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: userDataConstants.LOADING,
        isLoading: true,
      });

      const response = await axiosInstance.get(`/orders/user`);
      if (response) {
        dispatch({
          type: userDataConstants.GET_ORDERS,
          payLoad: {
            orders: response.data,
          },
        });
        dispatch({
          type: userDataConstants.RESPONSE_STATE,
          response: {
            state: "SUCCESS",
            // message: "Order loaded successfully",
          },
        });
        dispatch({
          type: userDataConstants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: userDataConstants.LOADING,
        isLoading: false,
      });
      dispatch({
        type: userDataConstants.RESPONSE_STATE,
        response: {
          state: "ERROR",
          message: e?.response?.data?.message ?? "Unable to get orders",
        },
      });
    }
  };
};

export const GetTemporaryOrder = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: userDataConstants.LOADING,
        isLoading: true,
      });

      const response = await axiosInstance.get(`/orders/user/temp_order`);
      if (response) {
        dispatch({
          type: userDataConstants.GET_TEMPORARY_ORDER,
          payLoad: {
            temp_order: response.data,
          },
        });
        dispatch({
          type: userDataConstants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: userDataConstants.LOADING,
        isLoading: false,
      });
      dispatch({
        type: userDataConstants.RESPONSE_STATE,
        response: {
          state: "ERROR",
          message: e?.response?.data?.message ?? "Unable to add orders",
        },
      });
    }
  };
};
export const AddOrder = (body) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: userDataConstants.LOADING,
        isLoading: true,
      });

      const response = await axiosInstance.post(`/orders/user/add`, body);
      if (response) {
        dispatch({
          type: userDataConstants.RESPONSE_STATE,
          response: {
            state: "SUCCESS",
            message: "Order added successfully",
          },
        });
        dispatch({
          type: userDataConstants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: userDataConstants.LOADING,
        isLoading: false,
      });
      dispatch({
        type: userDataConstants.RESPONSE_STATE,
        response: {
          state: "ERROR",
          message: e?.response?.data?.message ?? "Unable to add orders",
        },
      });
    }
  };
};
export const UpdateTemporaryOrder = (body, order_id) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: userDataConstants.LOADING,
        isLoading: true,
      });

      const response = await axiosInstance.put(`/orders/user/update/${order_id}`, body);
      if (response) {
        dispatch({
          type: userDataConstants.RESPONSE_STATE,
          response: {
            state: "SUCCESS",
            message: "Order updated successfully",
          },
        });
        dispatch({
          type: userDataConstants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: userDataConstants.LOADING,
        isLoading: false,
      });
      dispatch({
        type: userDataConstants.RESPONSE_STATE,
        response: {
          state: "ERROR",
          message: e?.response?.data?.message ?? "Unable to add orders",
        },
      });
    }
  };
};

export const GetFormOneAppliedLoanTemp = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: userDataConstants.LOADING,
        isLoading: true,
      });

      const response = await axiosInstance.get(`/loans/my-temp-applied-loan`);
      console.log("response.data", response.data);
      if (response) {
        dispatch({
          type: userDataConstants.GET_LOANS_FROM_TEMP,
          payLoad: {
            loanTemp: response.data,
          },
        });

        dispatch({
          type: userDataConstants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: userDataConstants.LOADING,
        isLoading: false,
      });
      console.log("e.message", e.message);
      console.log("e", e);
      dispatch({
        type: userDataConstants.RESPONSE_STATE,
        response: {
          state: "ERROR",
          message: e?.response?.data?.message ?? "Unable to get saved info",
        },
      });
    }
  };
};

export const SubmitLoanFormOne = (user) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: userDataConstants.LOADING,
        isLoading: true,
      });

      console.log("userLoanSubmit", user);
      // const response = await axiosInstance.post(``, data)
      const {
        dateOfBirth,
        email,
        IDCardNumber,
        IdCardType,
        residential_address1,
        residential_address2,
        age,
        gender,
        work_address1,
        work_address2,
        work_phone,
        sector,
        region,
        ssnit,
        staff_id,
        personal_phone,
      } = user;
      const response = await axiosInstance.post(`/loans/apply-loan-step-one`, {
        dateOfBirth,
        email,
        IDCardNumber,
        IdCardType,
        residential_address1,
        residential_address2,
        age,
        gender,
        work_address1,
        work_address2,
        work_phone,
        sector,
        region,
        ssnit,
        staff_id,
        personal_phone,
      });

      dispatch({
        type: userDataConstants.RESPONSE_STATE,
        response: {
          state: "SUCCESS",
          message: "",
          action: "STEP_ONE",
        },
      });
      dispatch({
        type: userDataConstants.LOADING,
        isLoading: false,
      });
    } catch (e) {
      dispatch({
        type: userDataConstants.LOADING,
        isLoading: false,
      });
      console.log("debug-e", e);
      console.log("debug-message", e.message);
      console.log("debug-string", JSON.stringify(e));
      dispatch({
        type: userDataConstants.RESPONSE_STATE,
        response: {
          state: "ERROR",
          message: e?.response?.data?.message ?? e?.message,
        },
      });
    }
  };
};
export const CancelLoanApplicationTemp = (loan_id) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: userDataConstants.LOADING,
        isLoading: true,
      });

      await axiosInstance.delete(`/loans/cancel-loan-temp/${loan_id}`);

      dispatch({
        type: userDataConstants.RESPONSE_STATE,
        response: {
          state: "DELETED",
          message: "Loan application cancelled",
        },
      });
      dispatch({
        type: userDataConstants.LOADING,
        isLoading: false,
      });
    } catch (e) {
      dispatch({
        type: userDataConstants.LOADING,
        isLoading: false,
      });
      console.log("debug-e", e);
      console.log("debug-message", e.message);
      console.log("debug-string", JSON.stringify(e));
      dispatch({
        type: userDataConstants.RESPONSE_STATE,
        response: {
          state: "ERROR",
          message: e?.response?.data?.message ?? e?.message,
        },
      });
    }
  };
};
export const SubmitUpdateLoanFormOne = (body, loan_id) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: userDataConstants.LOADING,
        isLoading: true,
      });

      await axiosInstance.put(`/loans/apply-loan-step-one/${loan_id}`, {
        ...body,
      });

      dispatch({
        type: userDataConstants.RESPONSE_STATE,
        response: {
          state: "SUCCESS",
          message: "Loan saved",
          action: "STEP_ONE",
        },
      });
      dispatch({
        type: userDataConstants.LOADING,
        isLoading: false,
      });
    } catch (e) {
      dispatch({
        type: userDataConstants.LOADING,
        isLoading: false,
      });
      console.log("debug-e", e);
      console.log("debug-message", e.message);
      console.log("debug-string", JSON.stringify(e));
      dispatch({
        type: userDataConstants.RESPONSE_STATE,
        response: {
          state: "ERROR",
          message: e?.response?.data?.message ?? e?.message,
        },
      });
    }
  };
};
export const EditLoanApplicationFormByMember = (body, loan_id) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: userDataConstants.LOADING,
        isLoading: true,
      });

      await axiosInstance.put(
        `/loans/edit-member-loan-application-form/${loan_id}`,
        {
          ...body,
        }
      );

      dispatch({
        type: userDataConstants.RESPONSE_STATE,
        response: {
          state: "SUCCESS",
          message: "Loan application edit and saved",
          action: "STEP_ONE",
        },
      });
      dispatch({
        type: userDataConstants.LOADING,
        isLoading: false,
      });
    } catch (e) {
      dispatch({
        type: userDataConstants.LOADING,
        isLoading: false,
      });
      console.log("debug-e", e);
      console.log("debug-message", e.message);
      console.log("debug-string", JSON.stringify(e));
      dispatch({
        type: userDataConstants.RESPONSE_STATE,
        response: {
          state: "ERROR",
          message: e?.response?.data?.message ?? e?.message,
        },
      });
    }
  };
};
export const EditMartApplicationFormByMember = (body, order_id) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: userDataConstants.LOADING,
        isLoading: true,
      });

      await axiosInstance.put(
        `/orders/edit-member-order-application-form/${order_id}`,
        {
          ...body,
        }
      );

      dispatch({
        type: userDataConstants.RESPONSE_STATE,
        response: {
          state: "SUCCESS",
          message: "Order application edit and saved",
          action: "STEP_ONE",
        },
      });
      dispatch({
        type: userDataConstants.LOADING,
        isLoading: false,
      });
    } catch (e) {
      dispatch({
        type: userDataConstants.LOADING,
        isLoading: false,
      });
      console.log("debug-e", e);
      console.log("debug-message", e.message);
      console.log("debug-string", JSON.stringify(e));
      dispatch({
        type: userDataConstants.RESPONSE_STATE,
        response: {
          state: "ERROR",
          message: e?.response?.data?.message ?? e?.message,
        },
      });
    }
  };
};
// export const SubmitUpdateLoanTempImageUpload = (body, images, loan_id) => {
export const UpdateIDCardUpload = (image, key, loan_id, place='') => {
  return async (dispatch) => {
    try {
      dispatch({
        type: userDataConstants.LOADING,
        isLoading: true,
      });

      const formData = new FormData();

      formData.append("file", image);

      console.log("files-upload", image);
      const response = await axiosInstance.put(
        `/loans/update-id-card-for-loan/${loan_id}?key=${key}&place=${place}`,
        formData
      );

      dispatch({
        type: userDataConstants.RESPONSE_STATE,
        response: {
          state: "UPLOAD_SUCCESS",
          message: "File saved successfully",
          action: "STEP_ONE",
        },
      });
      dispatch({
        type: userDataConstants.LOADING,
        isLoading: false,
      });
    } catch (e) {
      dispatch({
        type: userDataConstants.LOADING,
        isLoading: false,
      });
      console.log("debug-e", e);
      console.log("debug-message", e.message);
      console.log("debug-string", JSON.stringify(e));
      dispatch({
        type: userDataConstants.RESPONSE_STATE,
        response: {
          state: "ERROR",
          message: e?.response?.data?.message ?? e?.message,
        },
      });
    }
  };
};
export const UpdateLoanTempPassportImageUpload = (
  image,
  key,
  loan_id,
  place = ""
) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: userDataConstants.LOADING,
        isLoading: true,
      });

      const formData = new FormData();

      formData.append("file", image);

      const response = await axiosInstance.put(
        `/loans/apply-loan-temp-passport-image-upload/${loan_id}?key=${key}&place=${place}`,
        formData
      );

      dispatch({
        type: userDataConstants.RESPONSE_STATE,
        response: {
          state: "UPLOAD_SUCCESS",
          message: "File save successfully",
          // action: "STEP_ONE",
        },
      });
      dispatch({
        type: userDataConstants.LOADING,
        isLoading: false,
      });
    } catch (e) {
      dispatch({
        type: userDataConstants.LOADING,
        isLoading: false,
      });
      console.log("debug-e", e);
      console.log("debug-message", e.message);
      console.log("debug-string", JSON.stringify(e));
      dispatch({
        type: userDataConstants.RESPONSE_STATE,
        response: {
          state: "ERROR",
          message: e?.response?.data?.message ?? e?.message,
        },
      });
    }
  };
};
export const UpdateOrderTempPassportImageUpload = (
  image,
  key,
  order_id,
  place = ""
) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: userDataConstants.LOADING,
        isLoading: true,
      });

      const formData = new FormData();

      formData.append("file", image);

      const response = await axiosInstance.put(
        `/orders/apply-order-temp-passport-image-upload/${order_id}?key=${key}&place=${place}`,
        formData
      );

      dispatch({
        type: userDataConstants.RESPONSE_STATE,
        response: {
          state: "UPLOAD_SUCCESS",
          message: "Image saved",
          action: "STEP_ONE",
        },
      });
      dispatch({
        type: userDataConstants.LOADING,
        isLoading: false,
      });
    } catch (e) {
      dispatch({
        type: userDataConstants.LOADING,
        isLoading: false,
      });
      console.log("debug-e", e);
      console.log("debug-message", e.message);
      console.log("debug-string", JSON.stringify(e));
      dispatch({
        type: userDataConstants.RESPONSE_STATE,
        response: {
          state: "ERROR",
          message: e?.response?.data?.message ?? e?.message,
        },
      });
    }
  };
};
export const UpdateOrderTempIDCardImageUpload = (
  image,
  key,
  order_id,
  place = ""
) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: userDataConstants.LOADING,
        isLoading: true,
      });

      const formData = new FormData();

      formData.append("file", image);

      const response = await axiosInstance.put(
        `/orders/update-id-card-for-order/${order_id}?key=${key}&place=${place}`,
        formData
      );

      dispatch({
        type: userDataConstants.RESPONSE_STATE,
        response: {
          state: "UPLOAD_SUCCESS",
          message: "Image saved",
          // action: "STEP_ONE",
        },
      });
      dispatch({
        type: userDataConstants.LOADING,
        isLoading: false,
      });
    } catch (e) {
      dispatch({
        type: userDataConstants.LOADING,
        isLoading: false,
      });
      console.log("debug-e", e);
      console.log("debug-message", e.message);
      console.log("debug-string", JSON.stringify(e));
      dispatch({
        type: userDataConstants.RESPONSE_STATE,
        response: {
          state: "ERROR",
          message: e?.response?.data?.message ?? e?.message,
        },
      });
    }
  };
};

// export const SubmitLoanForm = (user) => {
//   return async (dispatch) => {
//     try {
//       dispatch({
//         type: userDataConstants.LOADING,
//         isLoading: true,
//       });

//       console.log("userLoanSubmit", user);
//       // const response = await axiosInstance.post(``, data)
//       const {
//         loanTerm,
//         IDCardNumber,
//         IdCardType,
//         RefGhanaNationIdentificationNo,
//         RefLastName,
//         affordability,
//         age,
//         bank_account_holder_name,
//         bank_account_number,
//         bank_branch,
//         bank_name,
//         dateOfBirth,
//         email,
//         employer_name,
//         employment_start_date,
//         facility,
//         firstName,
//         instalmentPerMonth,
//         interestPerMonth,
//         lastName,
//         loanAmount,
//         loantotalCollectable,
//         middleName,
//         modeOfPayment,
//         netSalary,
//         personal_phone,
//         monthlyDeduction,
//         otpNumber,
//         mandateNumber,
//         purposeForLoan,
//         refContact1,
//         refContact2,
//         refMiddleName,
//         refSurname,
//         residential_address1,
//         residential_address2,
//         ssnit,
//         staff_id,
//         backIDImage,
//         frontIDImage,
//         work_address1,
//         work_address2,
//         work_phone,
//         sector,
//         acceptTerms
//       } = user;
//       const response = await axiosInstance.post(`/loans/apply-loan`, {
//         loanTerm,
//         IDCardNumber,
//         IdCardType,
//         RefGhanaNationIdentificationNo,
//         RefLastName,
//         affordability,
//         age,
//         bank_account_holder_name,
//         bank_account_number,
//         bank_branch,
//         bank_name,
//         dateOfBirth,
//         email,
//         employer_name,
//         employment_start_date,
//         facility,
//         firstName,
//         instalmentPerMonth,
//         interestPerMonth,
//         lastName,
//         loanAmount,
//         loantotalCollectable,
//         middleName,
//         modeOfPayment,
//         netSalary,
//         personal_phone,
//         purposeForLoan,
//         refContact1,
//         refContact2,
//         refMiddleName,
//         refSurname,
//         residential_address1,
//         residential_address2,
//         ssnit,
//         staff_id,
//         monthlyDeduction,
//         otpNumber,
//         mandateNumber,
//         work_address1,
//         work_address2,
//         work_phone,
//         backIDImage,
//         frontIDImage,
//         sector,
//         acceptTerms
//       });

//       dispatch({
//         type: userDataConstants.RESPONSE_STATE,
//         response: {
//           state: "SUCCESS",
//           message: "Loan application submitted successfully",
//         },
//       });
//       dispatch({
//         type: userDataConstants.LOADING,
//         isLoading: false,
//       });
//     } catch (e) {
//       dispatch({
//         type: userDataConstants.LOADING,
//         isLoading: false,
//       });
//       console.log('debug-e',e)
//       console.log('debug-message',e.message)
//       console.log('debug-string', JSON.stringify(e))
//       dispatch({
//         type: userDataConstants.RESPONSE_STATE,
//         response: {
//           state: "ERROR",
//           message: e?.response?.data?.message ,
//         },
//       });
//     }
//   };
// };

export const ResetUserDataResponse = () => {
  return async (dispatch) => {
    dispatch({
      type: userDataConstants.RESPONSE_STATE,
      response: {
        state: null,
        message: "",
      },
    });
  };
};
