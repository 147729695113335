import React from 'react'
import { Link } from 'react-router-dom'
import '../../css/Auth/Auth.css'

function Register() {
  const handleInputChange = (e) => {
    if(e?.target){

    }
  }
  return (
    <div className='registerPage'>
        <form className='section_one' style={{
           background: "url(/images/loginbackground.png) no-repeat center center/cover",
        }}>
          <div className='overlay'>
          <h1 className='title'>Sign Up</h1>
           <div className='email-form-group form-group'>
            <label htmlFor='email'>Email</label>
            <input id='email' name='email' type="email" className='form-control' onChange={(e) => handleInputChange(e)}/>
           </div>
           <div className='phone-form-group'>
            <label htmlFor='phone'>Phone Number</label>
            <input type="tel" id='phone' name='phone' className='form-control' onChange={(e) => handleInputChange(e)}/>
           </div>
           <div className='password-form-group'>
            <label htmlFor='password'>Password</label>
            <input type="password" id='password' name='password' className='form-control' onChange={(e) => handleInputChange(e)}/>
           </div>
           <div className='password-confirm-form-group'>
            <label htmlFor='password-confirm'>Password Conlfirm</label>
            <input type="password-confirm" id='password-confirm' className='form-control' name='password-confirm' onChange={(e) => handleInputChange(e)}/>
           </div>
           <button className='button mb2 mt1'>Sign Up</button>
           
          <div className='trigger_register'>
          <Link to={'/login'}>Already have an account? <span>Login</span></Link>
          </div>
          </div>
        </form> 
          <div className='section_two' style={{
            background: "url(/images/car.jpg) no-repeat center center/cover",
            
          
          }}>
              <div className='overlay'>
                 <div className='content'>
                  <h1>Join the modern automobile platform. </h1>
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make </p>
                 </div>
              </div>
          </div>
        
    </div>
  )
}

export default Register