/* eslint-disable  */
import {
  Fab
} from "@material-ui/core";
import { Cancel } from "@material-ui/icons";
import React, { useEffect, useRef, useState } from "react";
import Resizer from "react-image-file-resizer";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { bindActionCreators } from "redux";
import {
  authActionCreators,
  userDataActionCreators,
} from "../../../../service/action-creators";
import Loader from "../../../Loader";
import RenderHome from "../../home/RenderHome";
import "./ApplyLoans.css";


function Uploads() {
  const dispatch = useDispatch();
  const history = useHistory();
  const signatureRef = useRef({});

  const {
    ResetUserDataResponse,
    GetFormOneAppliedLoanTemp,
    UpdateIDCardUpload,
    UpdateLoanTempPassportImageUpload,
    CancelLoanApplicationTemp,
  } = bindActionCreators(userDataActionCreators, dispatch);
  const { GetUserProfile } = bindActionCreators(authActionCreators, dispatch);
  const [formState, setFormState] = useState(4);
  const [previewFrontCard, setPreviewFrontCard] = useState(null);
  const [previewBackCard, setPreviewBackCard] = useState(null);
  const [previewPassportImage, setPreviewPassportImage] = useState(null);
  const [privacyChecked, setPrivacyChecked] = useState(false);

  const [formData, setFormData] = useState({});
  const [loanAmount, setLoanAmount] = useState(0);
  const [affordability, seAffordability] = useState(0);
  const [loanTerm, setLoanTerm] = useState(null);
  const [monthlyDeduction, setMonthlyDeduction] = useState(0);
  const [age, setAge] = useState(null);
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [seletedFiles, setSeletedFiles] = useState([]);

  const response = useSelector((state) => state?.user?.response);
  const isLoading = useSelector((state) => state?.user?.isLoading);
  const auth = useSelector((state) => state?.auth);
  const user = auth?.user;
  const userData = useSelector((state) => state?.user);

  console.log("userData", userData);
  console.log("formData", formData);

  const { loanTemp } = userData;
  useEffect(() => {
    window.scrollTo(0,0)
   }, [])

  
  const handleIDCardChange = (e) => {
    e.preventDefault();
    if (e?.target?.files[0]) {
      const file = e?.target?.files[0];
      const reader = new FileReader();
      reader.onload = (ev) => {
        try {
          Resizer.imageFileResizer(
            e.target.files[0],
            800,
            500,
            "png",
            100,
            0,
            (uri) => {
              UpdateIDCardUpload(
                uri,
                loanTemp?.frontIDImage_key ? loanTemp?.frontIDImage_key : "",
                loanTemp?._id
              );
            },
            "file",
            // 200,
            // 200
          );
    
          // UpdateProfileImage(resizedProfileImage, user?.profile_image_key ?? "");
        } catch (err) {
          console.log(err);
        }
        if (e?.target?.name === "backCard") {
          setPreviewBackCard(ev?.target?.result);
        } else if (e?.target?.name === "frontCard") {
          setPreviewFrontCard(ev?.target?.result);
        }
      };

  
      setSeletedFiles([...seletedFiles, file]);
      reader.readAsDataURL(file);

      // formDatas.append("file", e.target.files[0]);
    }
  };
  const handlePassportChange = (e) => {
    e.preventDefault();
    if (e?.target?.files[0]) {
      const file = e?.target?.files[0];
      const reader = new FileReader();
      reader.onload = (ev) => {
        setPreviewPassportImage(ev?.target?.result);
        try {
          Resizer.imageFileResizer(
            e.target.files[0],
            800,
            400,
            "png",
            100,
            0,
            (uri) => {
              UpdateLoanTempPassportImageUpload(
                uri,
                loanTemp?.passportImage_key ? loanTemp?.passportImage_key : "",
                loanTemp?._id
              );
             
            },
            "file",
            // 200,
            // 200
          );
    
          // UpdateProfileImage(resizedProfileImage, user?.profile_image_key ?? "");
        } catch (err) {
          console.log(err);
        }
     
      };

      reader.readAsDataURL(file);

      // formDatas.append("file", e.target.files[0]);
    }
  };
  const handleCancelLoanApplication = () => {
    CancelLoanApplicationTemp(loanTemp?._id);
  };
  const handleFormSubmit = (e) => {
    e.preventDefault();

    console.log("formData", formData);

    console.log('loan temp', loanTemp)
 
    if(loanTemp?.passportImage && loanTemp?.frontIDImage){
      history.push("/apply-loan-preview");
    }else{
      toast.error('Please make sure to upload images for Ghana Card')
    }

  };

  useEffect(() => {
    GetUserProfile();
    GetFormOneAppliedLoanTemp();
    setFormData({
      ...formData,
      // backIDImage: loanTemp?.backIDImage,
      // frontIDImage: loanTemp?.frontIDImage,
      backIDImage_key: loanTemp?.backIDImage_key,
      frontIDImage_key: loanTemp?.frontIDImage_key,

      passportImage_key: loanTemp?.passportImage_key,
    });

    setPreviewBackCard(formData?.frontIDImage);
    setPreviewFrontCard(formData?.backIDImage);

    setPreviewPassportImage(formData?.passportImage);
  }, []);
  useEffect(() => {
    GetUserProfile();
    GetFormOneAppliedLoanTemp();
    setFormData({
      ...formData,
      // backIDImage: loanTemp?.backIDImage,
      // frontIDImage: loanTemp?.frontIDImage,
      backIDImage_key: loanTemp?.backIDImage_key,
      frontIDImage_key: loanTemp?.frontIDImage_key,

      passportImage_key: loanTemp?.passportImage_key,
    });

    setPreviewBackCard(formData?.frontIDImage);
    setPreviewFrontCard(formData?.backIDImage);
    setPreviewPassportImage(formData?.passportImage);
  }, [loanTerm]);

  useEffect(() => {
    if (response?.state === "SUCCESS") {
      toast.success(response?.message);
      GetFormOneAppliedLoanTemp();
     
      // history.push("/apply-loan-5");

      setTimeout(() => {
        ResetUserDataResponse();
      }, 1500);
    } else if (response?.state === "ERROR") {
      toast.error(response?.message);
      setTimeout(() => {
        ResetUserDataResponse();
      }, 1500);
    } else if (response?.state === "DELETED") {
      toast.success(response?.message);
      history.push("/apply-loan-1");

      setTimeout(() => {
        ResetUserDataResponse();
      }, 1500);
    }else if(response?.state === "UPLOAD_SUCCESS"){
      toast.success(response?.message)
      GetFormOneAppliedLoanTemp();
      window.location.reload(false);
        setTimeout(() => {
        ResetUserDataResponse();
      }, 1500);
    }
  }, [response?.state, response?.message, response?.action]);

  return (
    <div className="ApplyLoans">
      <RenderHome title={"Apply Loan"}>
        {isLoading ?? <Loader />}
        {/* <hr /> */}
        {/* {loanTemp && (
          <div className="flex justify-end fixed right-0 mr-4">
            <Fab
              onClick={handleCancelLoanApplication}
              title="Cancel Application"
              className="cancelIcon"
              size="small"
              color="secondary"
              aria-label="add"
            >
              <Cancel />
            </Fab>
          </div>
        )} */}
        <div className="loan_chip">
          <div className="chip_container">
            <div
              className="chip_card"
              style={{
                // background: 'gray'#287bff
                background: `${formState === 1 ? "#287bff" : "gray"}`,
                boxShadow: `${
                  formState === 1 &&
                  "8px 0px 0 wheat, inset -8px 0 0 rgba(255, 255, 255, 0.25), 8px 0 0 rgba(0, 0, 0, 0.15)"
                }`,
              }}
            >
              <div className="chip_content">Personal Info</div>
            </div>
          </div>
          <div className="chip_container">
            <div
              className="chip_card"
              style={{
                // background: 'gray'#287bff
                background: `${formState === 2 ? "#287bff" : "gray"}`,
                boxShadow: `${
                  formState === 2 &&
                  "8px 0px 0 wheat, inset -8px 0 0 rgba(255, 255, 255, 0.25), 8px 0 0 rgba(0, 0, 0, 0.15)"
                }`,
              }}
            >
              <div className="chip_content">Reference & Employment</div>
            </div>
          </div>
          <div className="chip_container">
            <div
              className="chip_card"
              style={{
                // background: 'gray'#287bff
                background: `${formState === 3 ? "#287bff" : "gray"}`,
                boxShadow: `${
                  formState === 3 &&
                  "8px 0px 0 wheat, inset -8px 0 0 rgba(255, 255, 255, 0.25), 8px 0 0 rgba(0, 0, 0, 0.15)"
                }`,
              }}
            >
              <div className="chip_content">Loan Details</div>
            </div>
          </div>
          <div className="chip_container">
            <div
              className="chip_card"
              style={{
                // background: 'gray'#287bff
                background: `${formState === 4 ? "#287bff" : "gray"}`,
                boxShadow: `${
                  formState === 4 &&
                  "8px 0px 0 wheat, inset -8px 0 0 rgba(255, 255, 255, 0.25), 8px 0 0 rgba(0, 0, 0, 0.15)"
                }`,
              }}
            >
              <div className="chip_content">Uploads</div>
            </div>
          </div>
          <div className="chip_container">
            <div
              className="chip_card"
              style={{
                // background: 'gray'#287bff
                background: `${formState === 5 ? "#287bff" : "gray"}`,
                boxShadow: `${
                  formState === 5 &&
                  "8px 0px 0 wheat, inset -8px 0 0 rgba(255, 255, 255, 0.25), 8px 0 0 rgba(0, 0, 0, 0.15)"
                }`,
              }}
            >
              <div className="chip_content">Preview</div>
            </div>
          </div>
          <div className="chip_container">
            <div
              className="chip_card"
              style={{
                // background: 'gray'#287bff
                background: `${formState === 6 ? "#287bff" : "gray"}`,
                boxShadow: `${
                  formState === 6 &&
                  "8px 0px 0 wheat, inset -8px 0 0 rgba(255, 255, 255, 0.25), 8px 0 0 rgba(0, 0, 0, 0.15)"
                }`,
              }}
            >
              <div className="chip_content">Terms</div>
            </div>
          </div>
        </div>

        <form action="">
          {formState === 4 && (
            <div className="form_area form4">
              <h3 className="formTitle">Upload ID card</h3>
              <div className="description">
                Only Ghana National Identification card is accept. Other Id
                cards will be rejected.
              </div>
              <div className="card_container">
                <div className="image-card">
                  <img
                    src={
                      previewFrontCard ||
                      (loanTemp?.frontIDImage ?? loanTemp?.frontIDImage)
                    }
                    alt="front-id"
                  />
                </div>
                <div className="labelAndInput">
                  <div className="label">Upload Front</div>
                  <input
                    type={"file"}
                    name="frontCard"
                    accept=".png,.jpg,.jpeg"
                    onChange={(e) => handleIDCardChange(e)}
                  />
                </div>
              </div>

              <div className="card_container">
                <div className="image-card">
                  <img
                    src={
                      previewPassportImage ||
                      (loanTemp?.passportImage ?? loanTemp?.passportImage)
                    }
                    alt="Upload Back "
                  />
                </div>
                <div className="labelAndInput">
                  <div className="label">Upload Back</div>
                  <input
                    type={"file"}
                    accept=".png,.jpg,.jpeg"
                    name="passport"
                    onChange={(e) => handlePassportChange(e)}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "1rem",
                }}
              >
                <button
                  className="button_cancel cursor-pointer backBtn"
                  // type="button"
                  onClick={() => history.push("/apply-loan-loan_detailed")}
                >
                  Back
                </button>
                <button
                  className="button"
                  // type="button"
                  onClick={(e) => {
                    handleFormSubmit(e);
                  }}
                >
                  Preview Data
                </button>
              </div>
            </div>
          )}
        </form>
      </RenderHome>
      <ToastContainer autoClose={3000} />
    </div>
  );
}

export default Uploads;


