/* eslint-disable  */
import { Button } from "@material-ui/core";
import { Delete, Favorite } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { bindActionCreators } from "redux";
import { userDataActionCreators } from "../../../../../service/action-creators";
import { GetUserProfile } from "../../../../../service/action-creators/authAction";
import { GetNotifications } from "../../../../../service/action-creators/notificationAction";
import { ResetProductResponse } from "../../../../../service/action-creators/ProductAction";
import Modal from "../../../../Modal/Modal";
import NotFoundComponent from "../../../../NotFoundComponent";
import RenderHome from "../../../home/RenderHome";
import Order_Chips from "./components/Order_Chips";
import "./MyCart.css";
import Resizer from "react-image-file-resizer";
function MyCart() {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    GetCartProducts,
    ResetUserDataResponse,
    RemoveProductFromCart,
    AddFavoriteProducts,
    UpdateCart,
    AddOrder,
    UpdateTemporaryOrder,
    GetTemporaryOrder,
    UpdateOrderTempPassportImageUpload,
    UpdateOrderTempIDCardImageUpload,
  } = bindActionCreators(userDataActionCreators, dispatch);

  const device = () => {
    return window.innerWidth < 1080;
  };
  const [isMobile, setIsMobile] = useState(device);
  const [orderItems, setOrderItems] = useState([
    { size: "", color: "", qty: "" },
  ]);
  const [changedPrice, setChangedPrice] = useState(null);
  const [changedOldPrice, setChangedOldPrice] = useState(null);
  const [termsChecked, setTermsChecked] = useState(false);
  const [openOrderModal, setOpenOrderModal] = useState(false);
  const [formData, setFormData] = useState({});
  const [formState, setFormState] = useState(1);
  const [age, setAge] = useState(null);
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [formError, setFormError] = useState({});

  const [seletedFiles, setSeletedFiles] = useState([]);
  const [previewFrontCard, setPreviewFrontCard] = useState(null);
  const [previewBackCard, setPreviewBackCard] = useState(null);
  const [previewPassportImage, setPreviewPassportImage] = useState(null);
  // const [privacyChecked, setPrivacyChecked] = useState(false);
  const [error, setError] = useState("");

  const handleGhanaCardUploadFront = (e) => {
    e.preventDefault();
    if (e?.target?.files[0]) {
      console.log("name", e?.target?.name);
      const file = e?.target?.files[0];
      const reader = new FileReader();
      reader.onload = (ev) => {
        setPreviewBackCard(ev?.target?.result);
        setPreviewFrontCard(ev?.target?.result);

        try {
          Resizer.imageFileResizer(
            file,
            400,
            400,
            "png",
            100,
            0,
            (uri) => {
              console.log(uri);
              UpdateOrderTempIDCardImageUpload(
                uri, // file, 
                temp_order?.frontIDImage_key ? temp_order?.frontIDImage_key : "",
                temp_order?._id,
                
              );
           
            },
            "file",
            // 200,
            // 200
          );

        } catch (err) {
          console.log(err);
        }


      };
      setSeletedFiles([...seletedFiles, file]);
      reader.readAsDataURL(file);

      // formDatas.append("file", e.target.files[0]);
    }
  };
  const handleGhanaCardBackUpload = (e) => {
    e.preventDefault();
    if (e?.target?.files[0]) {
      console.log("name", e?.target?.name);
      const file = e?.target?.files[0];
      const reader = new FileReader();
      reader.onload = (ev) => {
        setPreviewPassportImage(ev?.target?.result);
        console.log("file", file);
        try {
          Resizer.imageFileResizer(
            file,
            400,
            400,
            "png",
            100,
            0,
            (uri) => {
              UpdateOrderTempPassportImageUpload(
                uri,
                temp_order?.passportImage_key ? temp_order?.passportImage_key : "",
                temp_order?._id
              );
            },
            "file",
            // 200,
            // 200
          );

        } catch (err) {
          console.log(err);
        }

      };

      reader.readAsDataURL(file);

      // formDatas.append("file", e.target.files[0]);
    }
  };



  const product = useSelector((state) => state?.product);
  // const userData = useSelector((state) => state?.user);
  // const isLoading = product?.isLoading;

  // const {products, isLoading, paginate, response} = product

  const user = useSelector((state) => state?.user);

  const response = user?.response;
  const carts = user?.cart;
  const cartGrandTotal = user?.cartGrandTotal;
  // const quantity = user?.prodQuantity;
  const quantity = useSelector((state) => state?.quantityCounter);
  const auth = useSelector((state) => state?.auth);
  const userData = auth?.user;

  const temp_order = user?.temp_order;

  console.log("formData", formData);
  console.log("temp_order", temp_order);

  const validateForm1 = () => {
    let err = {};
    if (formData.name === "" || !formData.name) {
      err.name = "Please provide your name";
    }
    if (dateOfBirth === "" || !formData.dateOfBirth) {
      err.dateOfBirth = "Date of birth is required";
    }
    if (formData.ssnit === "" || !formData.ssnit) {
      err.ssnit = "SSNIT number is required";
    }
    if (formData.IDCardNumber === "" || !formData.IDCardNumber) {
      err.IDCardNumber = "ID Card Number is required";
    }
    if (
      formData.residential_address1 === "" ||
      !formData.residential_address1
    ) {
      err.residential_address1 = "Residential address is required";
    }
    // if (formData.work_address1 === "" || !formData.work_address1) {
    //   err.work_address1 = "Work address is required";
    // }
    if (formData.gender === "" || !formData.gender) {
      err.gender = "Gender is required";
    }

    setFormError({ ...err });
    console.log("object", Object.keys(err));
    return Object.keys(err).length < 1;
  };
  const validateForm2 = () => {
    let err = {};
    if (formData.personal_phone === "" || !formData.personal_phone) {
      err.personal_phone = "Phone number is required";
    }
    if (formData.work_phone === "" || !formData.work_phone) {
      err.work_phone = "Work phone number is required";
    }
    if (formData.email === "" || !formData.email) {
      err.email = "Email address is required";
    }

    if (formData.mandate_number === "" || !formData.mandate_number) {
      err.mandate_number = "First mandate Number is required";
    }

    if (formData.otp_number === "" || !formData.otp_number) {
      err.otp_number = "First OTP number is required";
    }

    if (formData.mandate_number2 === "" || !formData.mandate_number2) {
      err.mandate_number2 = "Second mandate Number is required";
    }
    if (formData.otp_number2 === "" || !formData.otp_number2) {
      err.otp_number2 = "Second OTP number is required";
    }


    setFormError({ ...err });
    console.log("object", Object.keys(err));
    return Object.keys(err).length < 1;
  };
  const validateFormEmployment = () => {
    let err = {};
    if (
      formData.employment_start_date === "" ||
      !formData.employment_start_date
    ) {
      err.employment_start_date = "Employment start date is required";
    }

   
    if (formData.affordability === "" || !formData.affordability) {
      err.affordability = "Affordability is required";
    }


   
    setFormError({ ...err });
    console.log("object", Object.keys(err));
    return Object.keys(err).length < 1;
  };
  // const validateForm4 = () => {
  //   let err = {};



  //   setFormError({ ...err });
  //   console.log("object", Object.keys(err));
  //   return Object.keys(err).length < 1;
  // };

  const validateFormGuarantor = () => {
    let err = {};

    if (formData.mandate_number === "" || !formData.mandate_number) {
      err.mandate_number = "Mandate number is required";
    }
    if (formData.otp_number === "" || !formData.otp_number) {
      err.otp_number = "OTP Number is required";
    }
    if (formData.mandate_number2 === "" || !formData.mandate_number2) {
      err.mandate_number2 = "Second mandate number is required";
    }
    if (formData.otp_number2 === "" || !formData.otp_number2) {
      err.otp_number2 = "Second OTP Number is required";
    }
    if (formData.guarantor_name === "" || !formData.guarantor_name) {
      err.guarantor_name = "Guarantor name is required";
    }
    if (formData.guarantor_staff_id === "" || !formData.guarantor_staff_id) {
      err.guarantor_staff_id = "Guarantor Staff ID is required";
    }
    if (formData.guarantor_phone === "" || !formData.guarantor_phone) {
      err.guarantor_phone = "Guarantor phone number is required";
    }
    if (
      formData.guarantor_ghana_card === "" ||
      !formData.guarantor_ghana_card
    ) {
      err.guarantor_ghana_card = "National ID number of guarantor is required";
    } else {
      if (formData.guarantor_ghana_card?.length < 15) {
        err.guarantor_ghana_card = "National ID number is invalid";
      }
    }

    if (formData.guarantor_gps === "" || !formData.guarantor_gps) {
      err.guarantor_gps = "Guarantor GPS address is required";
    }
    if (
      formData.guarantor_occupation === "" ||
      !formData.guarantor_occupation
    ) {
      err.guarantor_occupation = "Guarantor occupation is required";
    }
    if (
      formData.guarantor_mandate_number === "" ||
      !formData.guarantor_mandate_number
    ) {
      err.guarantor_mandate_number = "Guarantor Mandate number is required";
    }
    if (
      formData.guarantor_otp_number === "" ||
      !formData.guarantor_otp_number
    ) {
      err.guarantor_otp_number = "Guarantor OTP number is required";
    }
    if (
      formData.guarantor_relationship === "" ||
      !formData.guarantor_relationship
    ) {
      err.guarantor_relationship = "Guarantor relationship is required";
    }
    // GUARANTOR 2
    if (formData.guarantor_name2 === "" || !formData.guarantor_name2) {
      err.guarantor_name2 = "Guarantor name is required";
    }
    if (formData.guarantor_staff_id2 === "" || !formData.guarantor_staff_id2) {
      err.guarantor_staff_id2 = "Guarantor Staff ID is required";
    }
    if (formData.guarantor_phone2 === "" || !formData.guarantor_phone2) {
      err.guarantor_phone2 = "Guarantor phone number is required";
    }
    if (
      formData.guarantor_ghana_card2 === "" ||
      !formData.guarantor_ghana_card2
    ) {
      err.guarantor_ghana_card2 = "National ID number of guarantor is required";
    } else {
      if (formData.guarantor_ghana_card2?.length < 15) {
        err.guarantor_ghana_card2 = "National ID number is invalid";
      }
    }

    if (formData.guarantor_gps2 === "" || !formData.guarantor_gps2) {
      err.guarantor_gps2 = "Guarantor GPS address is required";
    }
    if (
      formData.guarantor_occupation2 === "" ||
      !formData.guarantor_occupation2
    ) {
      err.guarantor_occupation2 = "Guarantor occupation is required";
    }
    if (
      formData.guarantor_mandate_number2 === "" ||
      !formData.guarantor_mandate_number2
    ) {
      err.guarantor_mandate_number2 = "Guarantor Mandate number is required";
    }
    if (
      formData.guarantor_otp_number2 === "" ||
      !formData.guarantor_otp_number2
    ) {
      err.guarantor_otp_number2 = "Guarantor OTP number is required";
    }
    if (
      formData.guarantor_relationship2 === "" ||
      !formData.guarantor_relationship2
    ) {
      err.guarantor_relationship2 = "Guarantor relationship is required";
    }

    setFormError({ ...err });
    return Object.keys(err).length < 1;
  };

  // const handlePriceChange = (e) => {
  //   let value = null;
  //   if (e.target) {
  //     value = e.target.value;
  //   }
  //   let convertedDuration = 0;
  //   if (Number(e.target.value) === 24) {
  //     convertedDuration = 2;
  //   } else if (Number(e.target.value) === 36) {
  //     convertedDuration = 3;
  //   } else if (
  //     // Number(e.target.value) == 1 ||
  //     Number(e.target.value) == 3 ||
  //     Number(e.target.value) == 6 ||
  //     Number(e.target.value) == 12
  //   ) {
  //     convertedDuration = 1;
  //   }
  //   const percentChange = 0.1;
  //   const retailPrice = Number(product?.new_price);
  //   const interestAmount = Number(retailPrice) * percentChange;
  //   const princpalCharge = Number(retailPrice) + Number(interestAmount);

  //   setChangedPrice(Number(princpalCharge / e.target.value).toFixed(2));

  //   // OLD PRICE
  //   const retailOldPrice = Number(product?.old_price);
  //   const interestOldAmount = Number(retailOldPrice) * percentChange;
  //   const princpalOldCharge =
  //     Number(retailOldPrice) + Number(interestOldAmount);

  //   setChangedOldPrice(Number(princpalOldCharge / e.target.value).toFixed(2));

  //   //Update the cart
  //   // UpdateCart(
  //   //   cart?._id,
  //   //   cart?.product_id?._id,
  //   //   body
  //   // );
  // };

  console.log("userData", userData);

  const handleDoBChange = (e) => {
    const today = new Date();
    const year = today.getFullYear();
    const dob = e?.target?.value.split("-")[0];

    const newAge = parseInt(year) - parseInt(dob);
    setAge(newAge);
    setDateOfBirth(e?.target?.value);
  };

  const handleConfirmSubmit = (e) => {
    e.preventDefault();
    console.log("carts", carts);
    let newPayload = [];
    for (let i = 0; i < carts?.length; i++) {
      const payload = {
        product_id: carts[i].product_id?._id,
        product_img: carts[i].product_id?.images,
        product_price: carts[i].product_id?.new_price,
        product_name: carts[i].product_id?.product_name,
        product_old: carts[i].product_id?.old_price,
        product_quantity: carts[i].product_id?.quantity,
        color: carts[i].color,
        size: carts[i].size,
        qty: carts[i].quantity,
        period: carts[i].period,
        period_price: carts[i].period_price,
        sub_total: carts[i].sub_total,
        user_id: carts[i].user_id,
      };
      newPayload.push(payload);
      console.log("newPayload", newPayload);
      // CreateOrder(payload)
    }
    const data = {
      ...formData,
      age: age,
      dateOfBirth: dateOfBirth,
      acceptTerms: termsChecked,
      form_completed: true,
      order: newPayload,
    };

    // let isValid = validateForm4();

    // if (temp_order.frontIDImage == "" || temp_order.passportImage == "") {
    //   setError("Please make sure to upload front  and back of your Ghana Card");
    //   setTimeout(() => {
    //     setError(null);
    //   }, 7000);
    //   return
    // }
  
    UpdateTemporaryOrder(data, temp_order?._id);
    console.log("formData", data);
    setOpenOrderModal(false);

  };

  const handleFormChange = (e) => {
    e.preventDefault();
    setFormData({ ...formData, [e?.target?.name]: e?.target?.value });

    console.log("formData.loanAmount", formData.loanAmount);
  };

  useEffect(() => {
    window.addEventListener("resize", () => {
      setIsMobile(device);
    });
    return 0;
  }, []);

  const gender = userData?.name?.split(" ")[0] === "Mr." ? "Male" : "Female";
  const employer_name = userData?.facility?.split(" ")[1].split(":")[0];
  useEffect(() => {
    GetTemporaryOrder();
    GetCartProducts();
    dispatch(GetUserProfile());
    dispatch(GetNotifications());
    setFormData({
      ...formData,
      staff_id: userData?.staff_id ?? temp_order?.staff_id,
      facility: userData?.facility ?? temp_order?.facility,
      // gender: gender,
      // name: userData?.name,
      IdCardType: "Ghana National Identification",
      employer_name: employer_name ?? temp_order?.employer_name,
      IdCardType: "Ghana National Identification",
      // employer_name: employer_name,

      name: temp_order?.name,
      IDCardNumber: temp_order?.IDCardNumber,
      age: age ?? temp_order?.age,
      dateOfBirth: userData?.dob || (dateOfBirth ?? temp_order?.dateOfBirth),
      ssnit: temp_order?.ssnit,
      gender: temp_order?.gender,
      residential_address1: temp_order?.residential_address1,
      residential_address2: temp_order?.residential_address2,
      work_phone: temp_order?.work_phone,
      work_address1: temp_order?.work_address1,
      work_address2: temp_order?.work_address2,
      email: userData?.email || temp_order?.email,
      personal_phone: userData?.phone_number || temp_order?.personal_phone,

      guarantor_name: temp_order?.guarantor_name,
      guarantor_staff_id: temp_order?.guarantor_staff_id,
      guarantor_phone: temp_order?.guarantor_phone,
      guarantor_ghana_card: temp_order?.guarantor_ghana_card,
      guarantor_gps: temp_order?.guarantor_gps,
      guarantor_occupation: temp_order?.guarantor_occupation,
      guarantor_mandate_number: temp_order?.guarantor_mandate_number,
      guarantor_otp_number: temp_order?.guarantor_otp_number,
      guarantor_relationship: temp_order?.guarantor_relationship,
    

      guarantor_name2: temp_order?.guarantor_name2,
      guarantor_staff_id2: temp_order?.guarantor_staff_id2,
      guarantor_phone2: temp_order?.guarantor_phone2,
      guarantor_ghana_card2: temp_order?.guarantor_ghana_card2,
      guarantor_gps2: temp_order?.guarantor_gps2,
      guarantor_occupation2: temp_order?.guarantor_occupation2,
      guarantor_mandate_number2: temp_order?.guarantor_mandate_number2,
      guarantor_otp_number2: temp_order?.guarantor_otp_number2,
      guarantor_relationship2: temp_order?.guarantor_relationship2,

      employment_start_date: temp_order?.employment_start_date,
      mandate_number: temp_order?.mandate_number,
      otp_number: temp_order?.otp_number,
      mandate_number2: temp_order?.mandate_number2,
      otp_number2: temp_order?.otp_number2,
      affordability: temp_order?.affordability,
      // employer_location: temp_order?.employer_location,

      frontIDImage: temp_order?.frontIDImage,
      frontIDImage_key: temp_order?.frontIDImage_key,
      passportImage: temp_order?.passportImage,
      passportImage_key: temp_order?.passportImage_key,
    });
    setDateOfBirth(temp_order?.dateOfBirth || userData?.dob);
    // setAge(temp_order?.age);

  }, []);
  useEffect(() => {
    setFormData({
      ...formData,
      staff_id: userData?.staff_id ?? temp_order?.staff_id,
      facility: userData?.facility ?? temp_order?.facility,
      // employer_location: temp_order?.employer_location,

      // gender: gender,
      // name: userData?.name,
      IdCardType: "Ghana National Identification",
      employer_name: employer_name ?? temp_order?.employer_name,

      name: userData?.name ?? temp_order?.name,
      IDCardNumber: temp_order?.IDCardNumber,
      age: age ?? temp_order?.age,
      dateOfBirth: userData?.dob || (dateOfBirth ?? temp_order?.dateOfBirth),
      ssnit: temp_order?.ssnit,
      gender: gender ?? temp_order?.gender,
      work_phone: temp_order?.work_phone,
      residential_address1: temp_order?.residential_address1,
      residential_address2: temp_order?.residential_address2,
      work_address1: temp_order?.work_address1,
      work_address2: temp_order?.work_address2,
      email: userData?.email || temp_order?.email,
      personal_phone: userData?.phone_number || temp_order?.personal_phone,

      guarantor_name: temp_order?.guarantor_name,
      guarantor_staff_id: temp_order?.guarantor_staff_id,
      guarantor_phone: temp_order?.guarantor_phone,
      guarantor_ghana_card: temp_order?.guarantor_ghana_card,
      guarantor_gps: temp_order?.guarantor_gps,
      guarantor_occupation: temp_order?.guarantor_occupation,
      guarantor_mandate_number: temp_order?.guarantor_mandate_number,
      guarantor_otp_number: temp_order?.guarantor_otp_number,
      guarantor_relationship: temp_order?.guarantor_relationship,

      guarantor_name2: temp_order?.guarantor_name2,
      guarantor_staff_id2: temp_order?.guarantor_staff_id2,
      guarantor_phone2: temp_order?.guarantor_phone2,
      guarantor_ghana_card2: temp_order?.guarantor_ghana_card2,
      guarantor_gps2: temp_order?.guarantor_gps2,
      guarantor_occupation2: temp_order?.guarantor_occupation2,
      guarantor_mandate_number2: temp_order?.guarantor_mandate_number2,
      guarantor_otp_number2: temp_order?.guarantor_otp_number2,
      guarantor_relationship2: temp_order?.guarantor_relationship2,

      employment_start_date: temp_order?.employment_start_date,
      mandate_number: temp_order?.mandate_number,
      otp_number: temp_order?.otp_number,
      mandate_number2: temp_order?.mandate_number2,
      otp_number2: temp_order?.otp_number2,
      affordability: temp_order?.affordability,

      frontIDImage: temp_order?.frontIDImage,
      frontIDImage_key: temp_order?.frontIDImage_key,
      passportImage: temp_order?.passportImage,
      passportImage_key: temp_order?.passportImage_key,
    });
    setDateOfBirth(temp_order?.dateOfBirth);
    // setAge(temp_order?.age);
  }, [userData, temp_order]);

  useEffect(() => {
    const today = new Date();
    const year = today.getFullYear();
    const dob = userData?.dob?.split("-")[0];
    console.log('dob', dob)
    const newAge = parseInt(year) - parseInt(dob);
    // console.log('newAge', newAge)
    setAge(newAge);
    // setDateOfBirth(e?.target?.value);
  }, [userData?.dob])
  console.log('newAge', age)


  useEffect(() => {
    if (
      user.response?.state === "SUCCESS" ||
      product?.response?.state === "SUCCESS"
    ) {
      // toast.success(response?.message);
      GetCartProducts();
      GetTemporaryOrder();
      setTimeout(() => {
        ResetUserDataResponse();
        dispatch(ResetProductResponse());
      }, 1500);
    } else if (
      user?.response?.state === "UPLOAD_SUCCESS" ||
      product?.response?.state === "UPLOAD_SUCCESS"
    ) {
      GetTemporaryOrder();
    } else if (
      user?.response?.state === "ERROR" ||
      product?.response?.state === "ERROR"
    ) {
      // toast.error(response?.message);
      setTimeout(() => {
        ResetUserDataResponse();
        dispatch(ResetProductResponse());
      }, 1500);
    }
  }, [user?.response?.state || product?.response.state]);

  return (
    <div className="MyCart">
      <RenderHome title={"My Cart"}>
        {carts?.length > 0 ? (
          <>
            {carts?.map((cart, index) => {
              let grandTotal = [];

              console.log("cart", cart);
              // console.log("grandTotal", grandTotal);
              return (
                <div className="cartProducts" key={index}>
                  <div className="productImage">
                    <img
                      src={cart?.product_id?.images[0]?.url}
                      alt="product-pic"
                    />
                  </div>
                  <div className="">
                    <div className="TitlePrice">
                      <div className="productTitle">
                        <h2>{cart?.product_id?.product_name.length > 50 ? cart?.product_id?.product_name.slice(0, 50) + '...' : cart?.product_id?.product_name}</h2>
                      </div>
                      <div className="ProductPrice">
                        &#8373;{cart?.sub_total} p/m
                      </div>
                    </div>
                    <div className="stock">
                      <div className="sideOne">
                        <span className="">
                          &#8373;{cart?.period_price} p/m
                          {/* &#8373;{changedPrice} */}
                        </span>{" "}
                        <span className="PriceCance">
                          {/* &#8373;{cart?.product_id?.old_price} */}
                        </span>{" "}
                        |{" "}
                        {cart?.product_id?.quantity > 0 ? (
                          <span className="inStock">In Stock</span>
                        ) : (
                          <span className="outOfStock">Out of Stock</span>
                        )}
                      </div>
                    </div>
                    <div className="FilterAndDelete">
                      <div className="quantityFilters">
                        <div className="sizesWrapper">
                          <div>size: </div>
                          {cart?.product_id?.sizes.length > 0 ? (
                            <div className="sizes">
                              <select
                                className=""
                                name="size"
                                onChange={(e) => {
                                  setOrderItems([
                                    { ...orderItems, size: e.target.value },
                                  ]);
                                  let body = {
                                    size: e.target.value,
                                    period_price: cart?.period_price,
                                  };
                                  UpdateCart(
                                    cart?._id,
                                    cart?.product_id?._id,
                                    body
                                  );
                                }}
                              >
                                <option value={cart?.size}>{cart?.size}</option>
                                {cart?.product_id?.sizes?.map((size, index) => {
                                  return (
                                    <option key={index} value={size}>
                                      {size}
                                    </option>
                                  );
                                })}

                              </select>
                            </div>
                          ) : (<div className="NA">N/A</div>)}

                        </div>
                        <div className="colorsWrapper">
                          <div>color: </div>
                          {cart?.product_id?.colors.length > 0 ? (
                            <div className="colors">
                              <select
                                className=""
                                name="color"
                                onChange={(e) => {
                                  setOrderItems([
                                    { ...orderItems, color: e.target.value },
                                  ]);
                                  let body = {
                                    color: e.target.value,
                                    period_price: cart?.period_price,
                                  };
                                  UpdateCart(
                                    cart?._id,
                                    cart?.product_id?._id,
                                    body
                                  );
                                }}
                              >
                                <option value={cart?.color}>{cart?.color}</option>
                                {cart?.product_id?.colors?.map((color, index) => {
                                  return (
                                    <option key={index} value={color}>
                                      {color}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          ) : (<div className="NA">N/A</div>)}

                        </div>
                        <div className="quantityWrapper">
                          <div>qty: </div>
                          <div className="quantity">
                            <input
                              style={{
                                width: `${isMobile ? "50%" : "30%"}`,
                                position: "relative",
                                // top: "-10px",
                                padding: '0px'
                              }}
                              type={"number"}
                              min="1"
                              name="quantity"
                              defaultValue={cart?.quantity}
                              className="form-control"
                              onChange={(e) => {
                                setOrderItems([
                                  { ...orderItems, qty: e.target.value },
                                ]);
                                let body = {
                                  quantity: e.target.value,
                                  period_price: cart?.period_price,
                                };
                                UpdateCart(
                                  cart?._id,
                                  cart?.product_id?._id,
                                  body
                                );
                              }}
                            />
                          </div>
                        </div>
                        {/* 
                        <div className="priceDuration">
                <select onChange={(e) => {
                  handlePriceChange(e);
            
                  let body = {
                    period: e.target.value,
                    period_price: cart?.period_price,
                    quantity: cart?.product_id?.quantity
                  };
                  console.log('body', body)
                    UpdateCart(
                      cart?._id,
                      cart?.product_id?._id,
                      body
                    );
                }}>
                  <option value={cart?.period}>{cart?.period} months</option>
                  <option value={3}>3 months</option>
                  <option value={6}>6 months</option>
                  <option value={12}>12 months</option>
                  <option value={24}>24 months</option>
                  <option value={36}>36 months</option>
                </select>
              </div> */}
                      </div>
                      <div className="sandAndDeleteButtons">
                        {isMobile ? (
                          <Favorite
                            className="icon phoneIcon"
                            onClick={(e) => {
                              AddFavoriteProducts(cart?.product_id?._id);
                            }}
                          />
                        ) : (
                          <Button
                            className="btn"
                            onClick={(e) => {
                              AddFavoriteProducts(cart?.product_id?._id);
                            }}
                          >
                            <Favorite className="icon" />
                            Saved
                          </Button>
                        )}
                        {isMobile ? (
                          <Delete
                            className="icon phoneIcon"
                            onClick={(e) => {
                              RemoveProductFromCart(cart?.product_id?._id);
                            }}
                          />
                        ) : (
                          <Button
                            className="btn"
                            onClick={(e) => {
                              RemoveProductFromCart(cart?.product_id?._id);
                            }}
                          >
                            <Delete className="icon" />
                            Delete
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}

            <div className="TotalProducts">
              <div className="item">
                <div className="totalText">Total</div>
                <div className="totalPrice">&#8373;{cartGrandTotal?.toFixed(2)}</div>
              </div>
            </div>
            <div className="confirmBtn">
              <button
                className="button mt4"
                // onClick={(e) => history.push('/mart-form-personal-info')}
                onClick={(e) => setOpenOrderModal(true)}
              >
                Confirm Order
              </button>
            </div>
          </>
        ) : (
          <>
            <NotFoundComponent title={"No Cart Available"} />
          </>
        )}

        {openOrderModal && (
          <Modal
            title={"Confirm Order"}
            width={isMobile ? "95%" : "70%"}
            closeModal={setOpenOrderModal}
            height="90vh"
          >
            {error && (
              <div className="messgeContainer flex items-center justify-center">
                <p
                  className="message px-2 py-2"
                  style={{
                    border: "2px dotted rgb(248 113 113)",
                    borderRadius: "10px",
                    color: "red",
                    fontSize: ".9rem",
                  }}
                >
                  {error}
                </p>
              </div>
            )}

            <Order_Chips formState={formState} />
            <div className="form">
              <form action="">
                {formState === 1 && (
                  <div className="form_area form1">
                    <h3 className="formTitle">Personal Information</h3>
                    <div className="form-group row">
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <label htmlFor="fullname">
                          Full Name<span className="bto-lb-required">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="name"
                          placeholder=""
                          readOnly
                          defaultValue={userData?.name}
                          id="fullname"
                        />
                        <span className="form-error">{formError.name}</span>
                      </div>

                      {/* <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <label htmlFor="fullname">
                          Choose Your Region
                          <span className="bto-lb-required">*</span>
                        </label>
                        <select
                          name="sector"
                          className="form-control"
                          onChange={handleFormChange}
                        >
                         
                          <option value={""}></option>
                          <option value={"Southern"}>Greater Accra</option>
                          <option value={"Southern"}>Eastern</option>
                          <option value={"Southern"}>Central</option>
                          <option value={"Southern"}>Bono</option>
                          <option value={"Southern"}>Bono East</option>
                          <option value={"Southern"}>Ashanti</option>
                          <option value={"Southern"}>Ahafo</option>

                          <option value={"Northern"}>Western</option>
                          <option value={"Northern"}>Western North</option>
                          <option value={"Northern"}>Volta</option>
                          <option value={"Northern"}>Upper West</option>
                          <option value={"Northern"}>Upper East</option>
                          <option value={"Northern"}>Savannah</option>
                          <option value={"Northern"}>Oti</option>
                          <option value={"Northern"}>Northern</option>
                          <option value={"Northern"}>North East</option>
                        </select>
                      </div> */}
                    </div>

                    <div className="form-group row">
                      <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                        <label htmlFor="dateOfBirth">
                          Date of Birth
                          <span className="bto-lb-required">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="date"
                          name="dateOfBirth"
                          id="dateOfBirth"
                          defaultValue={
                            dateOfBirth ||
                            (temp_order?.dateOfBirth ?? temp_order?.dateOfBirth) || formData.dateOfBirth
                          }
                          placeholder="Date of Birth"
                          required
                          aria-required="true"
                          onChange={(e) => handleDoBChange(e)}
                        />
                        <span className="form-error">
                          {formError.dateOfBirth}
                        </span>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                        <label htmlFor="age">
                          Age<span className="bto-lb-required"></span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="age"
                          defaultValue={
                            age || (temp_order?.age ?? temp_order?.age)
                          }
                          id="age"
                          required
                          // value={age}
                          readOnly
                          placeholder=""
                          aria-required="true"
                          onChange={(e) => handleFormChange(e)}
                        />
                        <span className="form-error">{formError.age}</span>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                        <label htmlFor="ssnit">
                          SSNIT<span className="bto-lb-required">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="ssnit"
                          id="ssnit"
                          placeholder=""
                          defaultValue={
                            formData?.ssnit ||
                            (temp_order?.ssnit ?? temp_order?.ssnit)
                          }
                          required
                          aria-required="true"
                          onChange={(e) => handleFormChange(e)}
                        />
                        <span className="form-error">{formError.ssnit}</span>
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                        <label htmlFor="IdCardType">
                          ID Type<span className="bto-lb-required">*</span>
                        </label>
                        <select
                          name="IdCardType"
                          id="IdCardType"
                          value={"Ghana National Identification"}
                          className="form-control"
                          onChange={(e) => handleFormChange(e)}
                        >
                          <option value="Ghana National Identification">
                            Ghana National Identification{" "}
                          </option>
                        </select>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                        <label htmlFor="id_number">
                          ID Number<span className="bto-lb-required">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="IDCardNumber"
                          placeholder=""
                          defaultValue={
                            formData?.IDCardNumber ||
                            (temp_order?.IDCardNumber ??
                              temp_order?.IDCardNumber)
                          }
                          id="id_number"
                          aria-required="true"
                          onChange={(e) => handleFormChange(e)}
                        />
                        <span className="form-error">
                          {formError.IDCardNumber}
                        </span>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                        <label htmlFor="gender">
                          Gender<span className="bto-lb-required">*</span>
                        </label>
                        <select
                          name="gender"
                          id="gender"
                          // value={gender}
                          onChange={(e) => handleFormChange(e)}
                          className="form-control"
                        >
                          <option value={gender}>
                            {gender ||
                              (temp_order?.gender ?? temp_order?.gender)}
                          </option>
                          <option value="Female">Female</option>
                          <option value="Female">Male</option>
                          <option value="Other">Other</option>
                        </select>
                        <span className="form-error">{formError.gender}</span>
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                        <label>
                          Residential Address
                          <span className="bto-lb-required">*</span>
                        </label>

                        <input
                          className="form-control"
                          type="text"
                          name="residential_address1"
                          id="residential_address1"
                          defaultValue={
                            formData?.residential_address1 ||
                            (temp_order?.residential_address1 ??
                              temp_order?.residential_address1)
                          }
                          aria-required="true"
                          onChange={(e) => handleFormChange(e)}
                        />
                        <input
                          className="form-control"
                          type="text"
                          name="residential_address2"
                          id="residential_address2"
                          aria-required="true"
                          defaultValue={
                            formData?.residential_address2 ||
                            (temp_order?.residential_address2 ??
                              temp_order?.residential_address2)
                          }
                          onChange={(e) => handleFormChange(e)}
                        />
                        <span className="form-error">
                          {formError.residential_address1}
                        </span>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                        <label htmlFor="work_address1">
                          Work Address<span className="bto-lb-required"></span>
                        </label>
                        <input
                          className="form-control form-group"
                          type="text"
                          name="work_address1"
                          id="work_address1"
                          defaultValue={
                            formData?.work_address1 ||
                            (temp_order?.work_address1 ??
                              temp_order?.work_address1)
                          }
                          aria-required="true"
                          onChange={(e) => handleFormChange(e)}
                        />
                        <input
                          className="form-control form-group"
                          type="text"
                          name="work_address2"
                          defaultValue={
                            formData?.work_address2 ||
                            (temp_order?.work_address2 ??
                              temp_order?.work_address2)
                          }
                          aria-required="true"
                          onChange={(e) => handleFormChange(e)}
                        />
                        <span className="form-error">
                          {formError.work_address1}
                        </span>
                      </div>
                    </div>

                    <div className="navigationBtn">
                      <div></div>
                      <button
                        className="nextBtn"
                        name=""
                        // type="submit"
                        onClick={(e) => {
                          e.preventDefault();
                          let isValid = validateForm1();
                          if (isValid) {
                            // setFormState(2);
                            const data = {
                              ...formData,
                              age: age,
                              // dateOfBirth: dateOfBirth,
                              staff_id: userData?.staff_id,
                            };
                            // AddOrder(data)
                            if (!temp_order) {
                              console.log("formData", formData);
                              AddOrder(data);
                              setFormState(2);
                            } else {
                              UpdateTemporaryOrder(data, temp_order?._id);
                              setFormState(2);
                              // alert('update')
                            }
                          }
                        }}
                      >
                        Save and continue
                      </button>
                    </div>
                  </div>
                )}

                {formState === 2 && (
                  <>
                    {/* THE CONTACT DETAILS SECTION */}
                    <h3 className="formTitle">Contact Details</h3>

                    <div className="form-group row">
                      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <label>
                          Phone Number<span className="bto-lb-required">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="personal_phone"
                          defaultValue={
                            formData?.personal_phone ??
                            temp_order?.personal_phone ??
                            temp_order?.personal_phone
                          }
                          aria-required="true"
                          onChange={(e) => handleFormChange(e)}
                        />
                        <span className="form-error">
                          {formError.personal_phone}
                        </span>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <label>
                          Work Phone<span className="bto-lb-required"></span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          defaultValue={
                            formData?.work_phone ??
                            temp_order?.work_phone ??
                            temp_order?.work_phone
                          }
                          name="work_phone"
                          aria-required="true"
                          onChange={(e) => handleFormChange(e)}
                        />
                        <span className="form-error">
                          {formError.work_phone}
                        </span>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <label>
                          Email Address
                          <span className="bto-lb-required">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="email"
                          name="email"
                          defaultValue={
                            formData?.email ??
                            temp_order?.email ??
                            temp_order?.email
                          }
                          placeholder="example@gmail.com"
                          aria-required="true"
                          onChange={(e) => handleFormChange(e)}
                        />
                        <span className="form-error">{formError.email}</span>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <label>
                          Mandate Number 1
                          <span className="bto-lb-required">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="mandate_number"
                          defaultValue={
                            formData?.mandate_number ??
                            temp_order?.mandate_number ??
                            temp_order?.mandate_number
                          }
                          placeholder=""
                          required
                          aria-required="true"
                          onChange={(e) => handleFormChange(e)}
                        />
                        <span className="form-error">{formError.mandate_number}</span>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <label>
                          OTP Number 1
                          <span className="bto-lb-required">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="number"
                          name="otp_number"
                          defaultValue={
                            formData?.otp_number ??
                            temp_order?.otp_number ??
                            temp_order?.otp_number
                          }
                          placeholder=""
                          required
                          aria-required="true"
                          onChange={(e) => handleFormChange(e)}
                        />
                        <span className="form-error">{formError.otp_number}</span>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <label>
                          Mandate Number 2
                          <span className="bto-lb-required">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="mandate_number2"
                          defaultValue={
                            formData?.mandate_number2 ??
                            temp_order?.mandate_number2 ??
                            temp_order?.mandate_number2
                          }
                          placeholder=""
                          required
                          aria-required="true"
                          onChange={(e) => handleFormChange(e)}
                        />
                        <span className="form-error">{formError.mandate_number2}</span>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <label>
                          OTP Number 2
                          <span className="bto-lb-required">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="number"
                          name="otp_number2"
                          defaultValue={
                            formData?.otp_number2 ??
                            temp_order?.otp_number2 ??
                            temp_order?.otp_number2
                          }
                          placeholder=""
                          required
                          aria-required="true"
                          onChange={(e) => handleFormChange(e)}
                        />
                        <span className="form-error">{formError.otp_number2}</span>
                      </div>
                    </div>

                    <div className="navigationBtn">
                      <button
                        className="button_cancel backBtn"
                        name=""
                        onClick={() => setFormState(1)}
                      >
                        Back
                      </button>
                      <button
                        className="nextBtn"
                        name=""
                        onClick={(e) => {
                          e.preventDefault();
                          let isValid = validateForm2();
                          if (isValid) {
                            UpdateTemporaryOrder(formData, temp_order?._id);
                            setFormState(3);
                          }
                        }}
                      >
                        Save and continue
                      </button>
                    </div>
                  </>
                )}

                {formState === 3 && (
                  <div className="form_area form2">
                    <div className="form-group row">
                      <h3 className="formTitle">Guarantors 1</h3>
                      <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                        <label>
                          Name<span className="bto-lb-required">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="guarantor_name"
                          defaultValue={
                            formData?.guarantor_name ||
                            (temp_order?.guarantor_name ??
                              temp_order?.guarantor_name)
                          }
                          required
                          aria-required="true"
                          onChange={(e) => handleFormChange(e)}
                        />
                        <span className="error-message">
                          {formError.guarantor_name}
                        </span>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                        <label>
                          Staff ID<span className="bto-lb-required">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="guarantor_staff_id"
                          defaultValue={
                            formData?.guarantor_staff_id ||
                            (temp_order?.guarantor_staff_id ??
                              temp_order?.guarantor_staff_id)
                          }
                          lettersonly=""
                          aria-required="true"
                          onChange={(e) => handleFormChange(e)}
                        />
                        <span className="error-message">
                          {formError.guarantor_staff_id}
                        </span>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                        <label>
                          Phone Number<span className="bto-lb-required">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          required
                          name="guarantor_phone"
                          placeholder=""
                          defaultValue={
                            formData?.guarantor_phone ||
                            (temp_order?.guarantor_phone ??
                              temp_order?.guarantor_phone)
                          }
                          aria-required="true"
                          onChange={(e) => handleFormChange(e)}
                        />
                        <span className="error-message">
                          {formError.guarantor_phone}
                        </span>
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                        <label>
                          Ghana Card Number
                          <span className="bto-lb-required">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="guarantor_ghana_card"
                          defaultValue={
                            formData?.guarantor_ghana_card ||
                            (temp_order?.guarantor_ghana_card ??
                              temp_order?.guarantor_ghana_card)
                          }
                          required
                          aria-required="true"
                          onChange={(e) => handleFormChange(e)}
                        />
                        <span className="error-message">
                          {formError.guarantor_ghana_card}
                        </span>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                        <label>
                          GPS Address<span className="bto-lb-required">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="guarantor_gps"
                          required
                          defaultValue={
                            formData?.guarantor_gps ||
                            (temp_order?.guarantor_gps ??
                              temp_order?.guarantor_gps)
                          }
                          aria-required="true"
                          onChange={(e) => handleFormChange(e)}
                        />
                        <span className="error-message">
                          {formError.guarantor_gps}
                        </span>
                      </div>

                      <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                        <label>
                          Occupation
                          <span className="bto-lb-required">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          required
                          name="guarantor_occupation"
                          defaultValue={
                            formData?.guarantor_occupation ||
                            (temp_order?.guarantor_occupation ??
                              temp_order?.guarantor_occupation)
                          }
                          aria-required="true"
                          onChange={(e) => handleFormChange(e)}
                        />
                        <span className="error-message">
                          {formError.guarantor_occupation}
                        </span>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                        <label>
                          Mandate Number
                          <span className="bto-lb-required">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="guarantor_mandate_number"
                          defaultValue={
                            formData?.guarantor_mandate_number ||
                            (temp_order?.guarantor_mandate_number ??
                              temp_order?.guarantor_mandate_number)
                          }
                          required
                          aria-required="true"
                          onChange={(e) => handleFormChange(e)}
                        />
                        <span className="error-message">
                          {formError.guarantor_mandate_number}
                        </span>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                        <label>
                          OTP Number<span className="bto-lb-required">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          required
                          name="guarantor_otp_number"
                          defaultValue={
                            formData?.guarantor_otp_number ||
                            (temp_order?.guarantor_otp_number ??
                              temp_order?.guarantor_otp_number)
                          }
                          aria-required="true"
                          onChange={(e) => handleFormChange(e)}
                        />
                        <span className="error-message">
                          {formError.guarantor_otp_number}
                        </span>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                        <label>
                          Relationship
                          <span className="bto-lb-required">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          required
                          name="guarantor_relationship"
                          defaultValue={
                            formData?.guarantor_relationship ||
                            (temp_order?.guarantor_relationship ??
                              temp_order?.guarantor_relationship)
                          }
                          aria-required="true"
                          onChange={(e) => handleFormChange(e)}
                        />
                        <span className="error-message">
                          {formError.guarantor_relationship}
                        </span>
                      </div>

                    </div>

                    <div>
                      <p className="py-2">I <span className="font-bold underline">{`${formData?.guarantor_name ||
                        (temp_order?.guarantor_name ??
                          temp_order?.guarantor_name) || ''}`}</span> hereby declare that in the event the borrower fails to make payments
                        or honor the contractual agreement herein signed, I guarantee to make full payment to UPNMG-FUND in the same way as if I were the original entity of the said
                        agreement except the default is in respect of death. (GUARANTOR MUST BE A MEMBER OF UPNMG IN GOOD STANDING)</p>
                    </div>

                    {/* SECOND GUARANTOR SECTION */}
                    <div className="form-group row">
                      <h3 className="formTitle">Guarantors 2</h3>
                      <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                        <label>
                          Name<span className="bto-lb-required">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="guarantor_name2"
                          defaultValue={
                            formData?.guarantor_name2 ||
                            (temp_order?.guarantor_name2 ??
                              temp_order?.guarantor_name2)
                          }
                          required
                          aria-required="true"
                          onChange={(e) => handleFormChange(e)}
                        />
                        <span className="error-message">
                          {formError.guarantor_name2}
                        </span>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                        <label>
                          Staff ID<span className="bto-lb-required">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="guarantor_staff_id2"
                          defaultValue={
                            formData?.guarantor_staff_id2 ||
                            (temp_order?.guarantor_staff_id2 ??
                              temp_order?.guarantor_staff_id2)
                          }
                          aria-required="true"
                          onChange={(e) => handleFormChange(e)}
                        />
                        <span className="error-message">
                          {formError.guarantor_staff_id2}
                        </span>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                        <label>
                          Phone Number<span className="bto-lb-required">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          required
                          name="guarantor_phone2"
                          placeholder=""
                          defaultValue={
                            formData?.guarantor_phone2 ||
                            (temp_order?.guarantor_phone2 ??
                              temp_order?.guarantor_phone2)
                          }
                          aria-required="true"
                          onChange={(e) => handleFormChange(e)}
                        />
                        <span className="error-message">
                          {formError.guarantor_phone2}
                        </span>
                      </div>
                    </div>


                    <div className="form-group row">
                      <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                        <label>
                          Ghana Card Number
                          <span className="bto-lb-required">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="guarantor_ghana_card2"
                          defaultValue={
                            formData?.guarantor_ghana_card2 ||
                            (temp_order?.guarantor_ghana_card2 ??
                              temp_order?.guarantor_ghana_card2)
                          }
                          required
                          aria-required="true"
                          onChange={(e) => handleFormChange(e)}
                        />
                        <span className="error-message">
                          {formError.guarantor_ghana_card2}
                        </span>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                        <label>
                          GPS Address<span className="bto-lb-required">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="guarantor_gps2"
                          required
                          defaultValue={
                            formData?.guarantor_gps2 ||
                            (temp_order?.guarantor_gps2 ??
                              temp_order?.guarantor_gps2)
                          }
                          aria-required="true"
                          onChange={(e) => handleFormChange(e)}
                        />
                        <span className="error-message">
                          {formError.guarantor_gps2}
                        </span>
                      </div>

                      <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                        <label>
                          Occupation
                          <span className="bto-lb-required">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          required
                          name="guarantor_occupation2"
                          defaultValue={
                            formData?.guarantor_occupation2 ||
                            (temp_order?.guarantor_occupation2 ??
                              temp_order?.guarantor_occupation2)
                          }
                          aria-required="true"
                          onChange={(e) => handleFormChange(e)}
                        />
                        <span className="error-message">
                          {formError.guarantor_occupation2}
                        </span>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                        <label>
                          Mandate Number
                          <span className="bto-lb-required">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="guarantor_mandate_number2"
                          defaultValue={
                            formData?.guarantor_mandate_number2 ||
                            (temp_order?.guarantor_mandate_number2 ??
                              temp_order?.guarantor_mandate_number2)
                          }
                          required
                          aria-required="true"
                          onChange={(e) => handleFormChange(e)}
                        />
                        <span className="error-message">
                          {formError.guarantor_mandate_number2}
                        </span>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                        <label>
                          OTP Number<span className="bto-lb-required">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          required
                          name="guarantor_otp_number2"
                          defaultValue={
                            formData?.guarantor_otp_number2 ||
                            (temp_order?.guarantor_otp_number2 ??
                              temp_order?.guarantor_otp_number2)
                          }
                          aria-required="true"
                          onChange={(e) => handleFormChange(e)}
                        />
                        <span className="error-message">
                          {formError.guarantor_otp_number2}
                        </span>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                        <label>
                          Relationship
                          <span className="bto-lb-required">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          required
                          name="guarantor_relationship2"
                          defaultValue={
                            formData?.guarantor_relationship2 ||
                            (temp_order?.guarantor_relationship2 ??
                              temp_order?.guarantor_relationship2)
                          }
                          aria-required="true"
                          onChange={(e) => handleFormChange(e)}
                        />
                        <span className="error-message">
                          {formError.guarantor_relationship2}
                        </span>
                      </div>
                    </div>

                    <div>
                      <p className="py-2">I <span className="font-bold underline">{`${formData?.guarantor_name2 ||
                        (temp_order?.guarantor_name2 ??
                          temp_order?.guarantor_name2) || ''}`}</span> hereby declare that in the event the borrower fails to make payments
                        or honor the contractual agreement herein signed, I guarantee to make full payment to UPNMG-FUND in the same way as if I were the original entity of the said
                        agreement except the default is in respect of death.</p>
                    </div>

                    <div className="navigationBtn">
                      <div
                        className="button_cancel backBtn"
                        onClick={() => setFormState(2)}
                      >
                        Back
                      </div>
                      <button
                        className="nextBtn"
                        type="submit"
                        onClick={(e) => {
                          e.preventDefault();
                          let isValid = validateFormGuarantor();
                          if (isValid) {
                            UpdateTemporaryOrder(formData, temp_order?._id);
                            setFormState(4);
                          }
                        }}
                      >
                        Save and continue
                      </button>
                    </div>

                  </div>
                )}
                {formState === 4 && (
                  <div>
                    {/* THE CONTACT DETAILS SECTION */}

                    <div className="form-group ">
                      <h3 className="formTitle">Employment Details</h3>
                      <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                          <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                              <label>
                                Employer
                                <span className="bto-lb-required">*</span>
                              </label>

                              <select
                                className="form-control"
                                type="text"
                                name="employer_name"
                                aria-required="true"
                                onChange={(e) => handleFormChange(e)}
                              >
                                <option
                                  value={
                                    employer_name ||
                                    (temp_order?.employer_name ??
                                      temp_order?.employer_name)
                                  }
                                >
                                  {employer_name ||
                                    (temp_order?.employer_name ??
                                      temp_order?.employer_name)}
                                </option>
                                <option value={"MOH"}>MOH</option>
                                <option value={"GHS"}>GHS</option>
                                <option value={"CHAG"}>CHAG</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                          <label>
                            Facility<span className="bto-lb-required">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="facility"
                            required
                            readOnly
                            value={
                              userData?.facility ||
                              (temp_order?.facility ?? temp_order?.facility)
                            }
                            aria-required="true"
                            onChange={(e) => handleFormChange(e)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                        <label>
                          Staff ID<span className="bto-lb-required">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="staff_id"
                          readOnly
                          value={
                            userData?.staff_id ||
                            (temp_order?.staff_id ?? temp_order?.staff_id)
                          }
                          required
                          aria-required="true"
                          onChange={(e) => handleFormChange(e)}
                        />
                      </div>
                      <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                        <label>
                          Employment Start Date
                          <span className="bto-lb-required">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="date"
                          name="employment_start_date"
                          defaultValue={
                            formData?.employment_start_date ||
                            (temp_order?.employment_start_date ??
                              temp_order?.employment_start_date)
                          }
                          required
                          aria-required="true"
                          onChange={(e) => handleFormChange(e)}
                        />
                        <span className="form-error">
                          {formError.employment_start_date}
                        </span>
                      </div>
                    </div>

                    {/* <div className="form-group row">
                      <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                        <label>
                          Mandate Number<span className="bto-lb-required">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="mandate_number"
                          defaultValue={
                            formData?.mandate_number ||
                            (temp_order?.mandate_number ?? temp_order?.mandate_number)
                          }
                          required
                          aria-required="true"
                          onChange={(e) => handleFormChange(e)}
                        />
                        <span className="form-error">{formError.mandate_number}</span>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                        <label>
                          OTP.
                          <span className="bto-lb-required">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          required
                          name="otp_number"
                          defaultValue={
                            formData?.otp_number ||
                            (temp_order?.otp_number ??
                              temp_order?.otp_number)
                          }
                          aria-required="true"
                          onChange={(e) => handleFormChange(e)}
                        />
                        <span className="form-error">
                          {formError.otp_number}
                        </span>
                      </div>
                      
                    </div> */}
                    <div className="row">
                      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <label>
                          Affordablility
                          <span className="bto-lb-required">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="affordability"
                          required
                          defaultValue={formData.affordability ||
                            (temp_order?.affordability ?? temp_order?.affordability)}
                          //  value={user?.facility}
                          aria-required="true"
                          onChange={(e) => handleFormChange(e)}
                        />
                        <span className="form-error">
                          {formError.affordability}
                        </span>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <label>
                          Occupation<span className="bto-lb-required"></span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="occupation"
                          defaultValue={
                            formData?.occupation ||
                            (temp_order?.occupation ?? temp_order?.occupation)
                          }
                          aria-required="true"
                          onChange={(e) => handleFormChange(e)}
                        />
                      </div>
                    </div>
                    <div className="navigationBtn">
                      <div
                        className="button_cancel backBtn"
                        onClick={() => setFormState(3)}
                      >
                        Back
                      </div>
                      <button
                        className="nextBtn"
                        type="submit"
                        onClick={(e) => {
                          e.preventDefault();
                          let isValid = validateFormEmployment();
                          // alert(1)
                          if (isValid) {
                            UpdateTemporaryOrder(formData, temp_order?._id);
                            setFormState(5);
                          }
                        }}
                      >
                        save and continue
                      </button>
                    </div>
                  </div>
                )}

                {formState === 5 && (
                  <>
                    <div className="form_area form4 id-cards-upload">
                      <h3 className="formTitle">Upload ID card</h3>
                      <div className="description">
                        Only Ghana National Identification card is accept. Other
                        ID cards will be rejected.
                      </div>
                      <div className="id_cards_wrapper">
                        <div className="card_container">
                          <div className="image-card">
                            <img
                              src={
                                previewFrontCard ||
                                (temp_order?.frontIDImage ??
                                  temp_order?.frontIDImage)
                              }
                              alt="front-id"
                            />
                          </div>
                          <div className="labelAndInput">
                            <div className="label">
                              Upload Front
                            </div>
                            <input
                              type={"file"}
                              name="frontCard"
                              accept=".png,.jpg,.jpeg"
                              onChange={(e) => handleGhanaCardUploadFront(e)}
                            />
                          </div>
                        </div>

                        <div className="card_container">
                          <div className="image-card">
                            <img
                              src={
                                previewPassportImage ||
                                (temp_order?.passportImage ??
                                  temp_order?.passportImage)
                              }
                              alt="Ghana Card Back"
                            />
                          </div>
                          <div className="labelAndInput">
                            <div className="label">Upload Back</div>
                            <input
                              type={"file"}
                              name="passport"
                              accept=".png,.jpg,.jpeg"
                              onChange={(e) => handleGhanaCardBackUpload(e)}
                            />
                          </div>
                        </div>
                      </div>

                    </div>
                    <div className="navigationBtn">
                      <button
                        className="button_cancel backBtn"
                        name=""
                        onClick={() => setFormState(4)}
                      >
                        Back
                      </button>
                      <button
                        className="nextBtn"
                        name=""
                        onClick={(e) => {
                          e.preventDefault();
                          setFormState(6);
                          // UpdateTemporaryOrder(formData, temp_order?._id)
                          // if (
                          //   !formData.frontIDImage ||
                          //   !formData.passportImage
                          // ) {
                          //   setError("Please make sure upload both passport and Ghana Card")
                          //   setTimeout(() => {
                          //     setError(null)
                          //   }, 3000)
                          //   // toast.error(

                          //   // );
                          // } else {
                          //   setFormState(6);
                          // }
                        }}
                      >
                        Save and continue
                      </button>
                    </div>
                  </>
                )}
                {formState === 6 && (
                  <>
                    <h3 className="formTitle">Item Details </h3>

                    <div className="row ">
                      <h3 className="formTitle">Product Info</h3>
                      <div className="productContainer">
                        <table className="table table-sm ">
                          <thead>
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">Product name</th>
                              <th scope="col">Qty</th>
                              <th scope="col">Terms (m)</th>
                              <th scope="col">instalment p/m</th>
                            </tr>
                          </thead>
                          <tbody>
                            {carts?.map((cart, index) => {
                              console.log("cart", cart);
                              return (
                                <tr key={index}>
                                  <th scope="row">{index + 1}</th>
                                  <td>{cart?.product_id?.product_name}</td>
                                  <td>{cart?.quantity}</td>
                                  <td>{cart?.period}</td>
                                  <td>{cart?.period_price}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="acceptTerms">
                      <input
                        type="checkbox"
                        checked={termsChecked}
                        onChange={() => {
                          setTermsChecked(!termsChecked);
                        }}
                      />{" "}
                      <span className="terms">
                        I have read and agreed to all terms and conditions.{" "}
                        <Link to={"/mart_terms"} target="_blank">
                          Terms and conditions
                        </Link>
                      </span>
                    </div>

                    <div className="navigationBtn">
                      <div
                        className="button_cancel backBtn"
                        onClick={() => setFormState(5)}
                      >
                        Back
                      </div>
                      <div></div>
                    </div>
                  </>
                )}
              </form>
            </div>
            <div className="modalButton">
              <div className="btnCover">
                <button
                  type="button"
                  onClick={handleConfirmSubmit}
                  disabled={termsChecked === false}
                  style={{
                    background: `${termsChecked ? "var(--primaryColor)" : "gray"
                      }`,
                    color: `${termsChecked ? "white" : "black"}`,
                  }}
                >
                  Confirm Order
                </button>

                <button
                  data-modal-toggle=""
                  type="button"
                  onClick={() => setOpenOrderModal(false)}
                  className="cancelBtn"
                >
                  Cancel
                </button>
              </div>
            </div>
          </Modal>
        )}
      </RenderHome>
    </div>
  );
}

export default MyCart;
