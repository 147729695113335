/* eslint-disable  */
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { BiSupport } from "react-icons/bi";
import { BsCalendar2DateFill } from "react-icons/bs";
import { GrStatusInfo } from "react-icons/gr";
import { MdOutlineUpdate } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { bindActionCreators } from "redux";
import { messagesActionCreators } from "../../../service/action-creators";
import { GetUserProfile } from "../../../service/action-creators/authAction";
import { GetNotifications } from "../../../service/action-creators/notificationAction";
import { GetCartProducts } from "../../../service/action-creators/userDataAction";
import RenderHome from "../home/RenderHome";
import "./MessageAdmin.css";

function MessageDetailed() {
  const dispatch = useDispatch();
  const history = useHistory();
  const scrollRef = useRef();
  const chat_id = history.location?.state?.chat_id;
  const {
    ResetMessagesResponse,
    addMessages,
    DeleteMessage,
    CloseMessage,
    getMessageDetailed,
    GetTotalUnreadMessages
  } = bindActionCreators(messagesActionCreators, dispatch);
  const [formData, setFormData] = useState({ message: "" });

  useEffect(() => {
    dispatch(GetUserProfile());
    dispatch(GetNotifications());
    dispatch(GetCartProducts());
    GetTotalUnreadMessages()
    // GetUsersForChat()
    // getMessages('authhere new');
    // GetConversations(auth?.user?._id);
  }, []);

  useEffect(() => {
    getMessageDetailed(chat_id);
  }, [chat_id]);

  const auth = useSelector((state) => state.auth);
  const chat = useSelector((state) => state.messages);

  const { messageDetailed, response, isLoading } = chat;

  console.log("chat_id", chat_id);
  console.log("chat", chat);
  console.log("auth", auth);

  const handleChange = (e) => {
    setFormData({ ...formData, [e?.target?.name]: e?.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.message === "") {
      toast.success("Message box is empty");
    } else {
      const payload = {
        ...formData,
        user_id: auth?.user?._id,
        // member_profile: auth?.user?.profile_image,
        reference: messageDetailed?.reference,
      };
      addMessages(payload);
      setFormData({ ...formData, message: "" });
    }
  };

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messageDetailed?.chat]);

  useEffect(() => {
    if (response.state === "SUCCESS") {
      getMessageDetailed(chat_id);
      // toast.success(response?.message ?? "Notifications loaded");
      setTimeout(() => {
        ResetMessagesResponse();
      }, 1500);
    } else if (response.state === "ERROR") {
      toast.error(response?.message ?? "unable to get notification");
      setTimeout(() => {
        ResetMessagesResponse();
      }, 1500);
    } else if (response.state === "DELETED") {
      toast.success(response?.message ?? "deleted successfully");
      setTimeout(() => {
        ResetMessagesResponse();
        history.goBack();
      }, 1500);
    }
  }, [response.state, response?.message]);

  return (
    <RenderHome>
      <div className="MessageDetailed">
        {/* {isLoading && <Loader />} */}
        <button className="backbtn" onClick={() => history.goBack()}>
          back
        </button>
        <div className="messageShowCase">
          <div className="header ">
            <h2>{messageDetailed?.subject}</h2>
            <div className="flex">
              <button
                className="closeBtn mr-2"
                onClick={() => CloseMessage(messageDetailed?._id)}
              >
                close
              </button>
              <button
                className="delBtn"
                onClick={() => DeleteMessage(messageDetailed?._id)}
              >
                delete
              </button>
            </div>
          </div>
          <div className="itemsConainer">
            <div className="item">
              <div>
                <GrStatusInfo className="icon" />
              </div>
              <div>status</div>
              <div>{messageDetailed?.isOpen ? "Open" : "Closed"}</div>
            </div>
            <div className="item">
              <div>
                <BiSupport className="icon" />
              </div>
              <div>Department</div>
              <div>{messageDetailed?.system_user}</div>
            </div>
            <div className="item">
              <div>
                <MdOutlineUpdate className="icon" />
              </div>
              <div>Last Updated</div>
              <div>
                {moment(messageDetailed?.updated_at).format("MMM Do YY")}
              </div>
            </div>
            <div className="item">
              <div>
                <BsCalendar2DateFill className="icon" />
              </div>
              <div>Submitted on</div>
              <div>
                {moment(messageDetailed?.created_at).format("MMM Do YY")}
              </div>
            </div>
          </div>
        </div>

        <div className="messageBox ">
          <h2>Message Area</h2>
          <section className="chat">
            <div className="messages-chat">
              {messageDetailed?.chat?.map((ch, index) => {
                return ch?.user_type === "member" ? (
                  <div ref={scrollRef} key={index}>
                    <div className="message text-only ">
                      <div className="response">
                        <p className="text"> {ch?.message}</p>
                        <p className="response-time">
                          {" "}
                          {moment(ch?.date).format("MMM Do YY")}
                        </p>
                      </div>
                      <div
                        // className="photo"
                        // style={{
                        //   backgroundImage:
                        //     // "url(https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80)",
                        //     `url(${auth?.user?.profile_image})`,
                        // }}
                      >
                        <img className="rounded-full w-10 h-10" src={auth?.user?.profile_image} alt="profile-image"/>
                        {/* <div className="online"></div> */}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div ref={scrollRef} key={index}>
                    <div className="message">
                      <div
                        className="photo"
                        style={{
                          backgroundImage: `url(${ch?.system_user_profile})`,
                        }}
                      >
                        {/* <div className="online"></div> */}
                      </div>
                      <p className="text"> {ch?.message}</p>
                    </div>
                    <p className="time">
                      {`${ch?.system_user?.split(" ")[1]} - (${moment(
                        ch?.date
                      ).format("MMM Do YY")})`}
                      {/* {moment(ch?.date).format("MMM Do YY")}  */}
                      {/* {" "} {ch?.system_user.split(' ')[1]} */}
                    </p>
                  </div>
                );
              })}
            </div>
          </section>
        </div>
        <div className="footer-chat">
          <form>
            <input
              type="text"
              onChange={handleChange}
              name="message"
              value={formData.message}
              className="write-message"
              placeholder="Type your message here"
            ></input>
            <button type="submit" className="button" onClick={handleSubmit}>
              Send
            </button>
          
          </form>
        </div>
      </div>
      <ToastContainer autoClose={3000} />
    </RenderHome>
  );
}

export default MessageDetailed;
