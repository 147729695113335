/* eslint-disable  */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { bindActionCreators } from "redux";
import "../../../../css/Dashboard/home/Home.css";
import { authActionCreators } from "../../../../service/action-creators";
import Loader from "../../../Loader";
import SmallNavBar from "./SmallNav";

function MainPage(props) {
  const dispatch = useDispatch();
  const { ResetResponse } = bindActionCreators(authActionCreators, dispatch);
  const auth = useSelector((state) => state?.auth);
  const isLoading = auth?.isLoading;
  const response = auth?.response;
  useEffect(() => {
    if (response.state === "SUCCESS") {
      toast.success(response?.message);
      setTimeout(() => {
        ResetResponse();
      }, 1500);
    } else if (response?.state === "ERROR") {
      toast.error(response?.message);
      setTimeout(() => {
        ResetResponse();
      }, 1500);
    }
  }, [response?.state]);
  return (
    <>
      <div className="MainPage">
        {isLoading && <Loader />}
        <SmallNavBar />

        <div className="MainPageItem" style={{ padding: "0rem .8rem" }}>
          {props.children}
        </div>
      </div>
      <ToastContainer autoClose={3000}/>
    </>
  );
}

export default MainPage;
