/* eslint-disable  */
import { Avatar, Badge, IconButton, Menu, MenuItem } from "@material-ui/core";
import {
  ArrowDropDown,
  MenuOutlined,
  MessageSharp,
  NotificationImportant,
  ShoppingCartOutlined,
} from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
import "../../../../css/Dashboard/home/Home.css";
import {
  messagesActionCreators,
  navigationActionCreators,
} from "../../../../service/action-creators";

function SmallNavBar() {
  const dispatch = useDispatch();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const auth = useSelector((state) => state?.auth);
  const messages = useSelector((state) => state?.messages);
  // unreadMessagesTotal
  const user = auth?.user;
  let timestamp = new Date().getTime();
  console.log("messages", messages);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { openSideBarOnMobile } = bindActionCreators(
    navigationActionCreators,
    dispatch
  );
  const {  GetTotalUnreadMessages } = bindActionCreators(
    messagesActionCreators,
    dispatch
  );

  const device = () => {
    return window.innerWidth < 1080;
  };
  const [isMobile, setIsMobile] = useState(device);
  const cart = useSelector((state) => state?.user?.cart);
  const notifications = useSelector(
    (state) => state?.notification?.notifications
  );
  console.log("notifications", notifications.length);
  useEffect(() => {
    window.addEventListener("resize", () => {
      setIsMobile(device);
    });
    return 0;
  }, []);

  useEffect(() => {
    GetTotalUnreadMessages();
  }, []);

  return (
    <div className="SmallNavBar">
      <div className="menuLinks">
        <div className="title hide_on_small">Nurse Portal</div>
        <div className="hide_on_large">
          <MenuOutlined
            // className="x-2"
            style={{
              fontSize: "2rem",
              border: "1px solid var(--primaryColor)",
              borderRadius: "4px",
              color: "white",
              background: "var(--primaryColor)",
            }}
            onClick={() => {
              openSideBarOnMobile();
            }}
          />
        </div>
        <div>
          <Link to={"/home"}>Overview</Link>
          {/* <Link to={"/support"}>Support</Link> */}
          <Link to={"/supports"}>Support</Link>
          <a href="https://upnmg.com" target="_blank">UPNMG WEBSITE</a>
        </div>
      </div>
      <div className="userProfile">
        <div>
          <IconButton onClick={() => history.push("/message")}>
            <Badge
              overlap="rectangular"
              color="secondary"
              badgeContent={`${messages?.unreadMessagesTotal}`}
            >
              <MessageSharp style={{ fontSize: isMobile ? "1.1rem" : "" }} />
            </Badge>
          </IconButton>
        </div>
        <div>
          <IconButton onClick={() => history.push("/notifications")}>
            <Badge
              overlap="rectangular"
              color="secondary"
              badgeContent={notifications?.length}
            >
              <NotificationImportant
                style={{ fontSize: isMobile ? "1.1rem" : "" }}
              />
            </Badge>
          </IconButton>
        </div>
        <div>
          <IconButton onClick={() => history.push("/my-cart")}>
            <Badge
              overlap="rectangular"
              color="secondary"
              badgeContent={cart?.length}
            >
              <ShoppingCartOutlined
                style={{ fontSize: isMobile ? "1.1rem" : "" }}
              />
            </Badge>
          </IconButton>
        </div>
        <div className="NavImageCover">
          {isMobile ? (
            <img
              className="w-7 h-7 rounded-full ring-2 ring-gray-300 dark:ring-gray-500"
              src={user?.profile_image+'?'+timestamp}
              style={{ padding: "0.9px" }}
              alt=""
              id="demo-positioned-button"
              aria-controls={open ? "demo-positioned-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            />
          ) : (
            <img
              className="p-1 w-10 h-10 rounded-full ring-2 ring-gray-300 dark:ring-gray-500"
              src={user?.profile_image+'?'+timestamp}
              alt=""
              id="demo-positioned-button"
              aria-controls={open ? "demo-positioned-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            />
          )}

          {/* <Avatar sx={{ width: 24, height: 24 }} alt="Remy Sharp" src={user?.profile_image} /> */}
        </div>
        <div
          id="demo-positioned-button"
          aria-controls={open ? "demo-positioned-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          {isMobile ? "" : user?.name?.split(" ")[1]}

          <ArrowDropDown style={{ color: "var(--primaryColor)" }} />
        </div>

        <Menu
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <MenuItem
            onClick={() => {
              history.push("/profile");
              handleClose();
            }}
          >
            My Profile
          </MenuItem>
          <MenuItem
            onClick={() => {
              history.push("/my-cart");
              handleClose();
            }}
          >
            My Cart
          </MenuItem>
          <MenuItem
            onClick={() => {
              history.push("/settings");
              handleClose();
            }}
          >
            Settings
          </MenuItem>
          <MenuItem
            onClick={(e) => {
              history.push("/logout");
            }}
          >
            Logout
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
}

export default SmallNavBar;
