/* eslint-disable  */
import { FavoriteBorderOutlined, ShoppingBasket } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { FaHandHoldingUsd } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
import {
  authActionCreators,
  userDataActionCreators,
} from "../../../../../service/action-creators";
import CidiSymbol from "../../../../CidiSymbol";
import Modal from "../../../../Modal/Modal";

function SectionOne() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [formData, setFormData] = React.useState(null);

  const { ChangePassword } = bindActionCreators(authActionCreators, dispatch);
  const { GetTotalFunds, GetAppliedLoans } = bindActionCreators(
    userDataActionCreators,
    dispatch
  );
  const user = useSelector((state) => state.user);
  // const funds = useSelector((state) => state.user.funds);
  // const totalFunds = useSelector((state) => state.user);
  // const cart = useSelector(state => state?.user?.cart)
  // const favouriteProducts = useSelector(state => state?.user?.favoriteProducts);

  const { funds, cart, totalFunds, favoriteProducts, myAppliedLoans, orders } = user;

  console.log("totalFunds", totalFunds);
  const [open, setOpen] = React.useState(false);
  const [openRejectedLoan, setRejectedLoan] = React.useState(false);
  const [openRejectedOrder, setRejectedOrder] = React.useState(false);
  const [loanMessage, setLoanMessage] = React.useState('');
  const [loanSelected, setLoanSelected] = React.useState('');
  const [orderSelected, setOrderSelected] = React.useState('');
  

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e?.target?.name]: e?.target.value });
  };

  // console.log('myAppliedLoans', myAppliedLoans)
  // console.log('loans', loans)

  const handleSubmit = (e) => {
    e?.preventDefault();
    if (!formData?.confirmPassword || formData?.password !== "", !formData?.old_password !== "") {
      ChangePassword(formData);
      setOpen(false)
    } else {
      toast.error("Make sure to enter new password and confirm password");
    }
    setOpen(false)

  };
  useEffect(() => {
    GetTotalFunds();
    GetAppliedLoans();
  }, []);
  useEffect(() => {
    const loans = myAppliedLoans.map((loan) => {
      if(loan?.rejected.length > 0 && loan?.rejected[0].editFormChecked){
        setRejectedLoan(true)
        setLoanMessage(loan?.rejected[0]?.message)
        setLoanSelected(loan)
      }
      // return loan
    })
    const orderArr = orders.map((order) => {
      if(order?.rejected.length > 0 && order?.rejected[0].editFormChecked){
        setRejectedOrder(true)
        setLoanMessage(order?.rejected[0]?.message)
        setOrderSelected(order)
      }
      // return loan
    })
  }, [myAppliedLoans]);




  const device = () => {
    return window.innerWidth < 1080;
  };
  const [isMobile, setIsMobile] = useState(device);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setIsMobile(device);
    });
    return 0;
  }, []);

  return (
    <div className="SectionOneMainPageDisplay">
      {!isMobile && (
        <div className="statisticsDisplay ">
          <div className="box">
            <div
              className="iconContainer"
              style={{
                background:
                  "linear-gradient(to bottom, var(--grayColor) 20%, var(--grayDeep) 100%)",
              }}
            >
              <FaHandHoldingUsd size={25} className="icon" />
            </div>
            <div>
              <div>Total Contributions</div>
              <h3 className="font-bold mt-1">
                <CidiSymbol /> {Number(totalFunds).toFixed(2)}
              </h3>
            </div>
          </div>
          <div className="box active" onClick={() => history.push({})}>
            <div
              className="iconContainer"
              style={{
                // background:
                //   "linear-gradient(to bottom, var(--success) 20%, var(--successDeep) 100%)",
                background: 'white'
              }}
            >
              <FavoriteBorderOutlined size={25} className="icon" />
            </div>
            <div>
              <div>favourite Products</div>
              <h3 className="font-bold mt-1">{favoriteProducts?.length}</h3>
            </div>
          </div>
          <div className="box">
            <div
              className="iconContainer"
              style={{
                background:
                  "linear-gradient(to bottom, var(--primary) 20%, var(--primaryLite) 100%)",
              }}
            >
              <ShoppingBasket size={25} className="icon" />
            </div>
            <div>
              <div>Shopping Cart</div>
              <h3 className="font-bold mt-1">{cart?.length}</h3>
            </div>
          </div>
        </div>
      )}

      {
        isMobile && (<div className="" style={{ display: 'flex', justifyContent: 'flex-end', position: 'absolute', right: '20px', top: '65px' }}>
          <h3 className="" style={{ background: 'var(--primaryColor)', color: 'white', fontSize: '.7rem', padding: '2px 20px', borderRadius: '4pc' }}>
            <div style={{}}>Contribution</div>
            <span style={{ fontWeight: 'bolder' }}> <CidiSymbol /> {Number(totalFunds).toFixed(2)}</span>
          </h3>
        </div>)
      }

      <div className="activityLinksCards">
        <div
          className="box"
          style={{ cursor: "pointer" }}
          onClick={() => history.push("/upnmg-shopping-mall")}
        >
          <div className="content">
            <div
              className="imgCover"
              style={{
                backgroundPosition: "100%",
              }}
            >
              <img src="/images/shopping-cart.png" alt="car" />
            </div>
            <h2>UPNMG Mart</h2>
            <p>Visit our shop</p>
          </div>
        </div>
        <div className="box" onClick={() => history.push("/apply-loan-personal_info")}>
          <div className="content">
            <div className="imgCover">
              <img src="/images/loan.png" alt="car" />
            </div>
            <h2>Apply loan</h2>
            <p>Apply for loan today</p>
          </div>
        </div>
        <div
          className="box"
          style={{ cursor: "pointer" }}
          onClick={() => history.push("/profile")}
        >
          <div className="content">
            <div className="imgCover">
              <img src="/images/profile.png" alt="car" />
            </div>
            <h2>My Profile</h2>
            <p>Edit your profile</p>
          </div>
        </div>
        <div
          className="box"
          style={{ cursor: "pointer" }}
          onClick={() => history.push("/fund-increment")}
        >
          <div className="content">
            <div className="imgCover">
              <img src="/images/fund-increase.png" alt="car" />
            </div>
            <h2>Increase / Decrease</h2>
            <p>Increase or decrease fund</p>
          </div>
        </div>
        <div
          className="box"
          style={{ cursor: "pointer" }}
          onClick={() => history.push("/my-orders-and-purchase")}
        >
          <div className="content">
            <div className="imgCover">
              <img src="/images/order-more.png" alt="car" />
            </div>
            <h2>Orders & More</h2>
            <p>View your items</p>
          </div>
        </div>
        <div
          className="box"
          style={{ cursor: "pointer" }}
          onClick={() => history.push("/request-order")}
        >
          <div className="content">
            <div className="imgCover">
              <img src="/images/dev/request_order.png" alt="car" />
            </div>
            <h2>Request Order</h2>
            <p>Request for your order</p>
          </div>
        </div>

        <div
          className="box"
          style={{ cursor: "pointer" }}
          onClick={() => history.push("/my-applied-loans")}
        >
          <div className="content">
            <div className="imgCover">
              <img src="/images/applied-loan.png" alt="car" />
            </div>
            <h2>Applied Loans</h2>
            <p>View your loans</p>
          </div>
        </div>

        <div className="box" onClick={() => history.push("/message")}>
          <div className="content">
            <div className="imgCover">
              <img src="/images/chat.png" alt="car" />
            </div>
            <h2>Messenger</h2>
            <p>Message UPNMG </p>
          </div>
        </div>

        <div
          className="box"
          style={{ cursor: "pointer" }}
          onClick={() => history.push("/my-cart")}
        >
          <div className="content">
            <div
              className="imgCover"
              style={{
                backgroundPosition: "100%",
              }}
            >
              <img src="/images/grocery-cart.png" alt="car" />
            </div>
            <h2>My Cart</h2>
            <p>Your saved products</p>
          </div>
        </div>
        <div
          className="box"
          style={{ cursor: "pointer" }}
          onClick={() => history.push("/favourites")}
        >
          <div className="content">
            <div
              className="imgCover"
              style={{
                backgroundPosition: "100%",
              }}
            >
              <img src="/images/favourite.png" alt="car" />
            </div>
            <h2>Favorites</h2>
            <p>Your saved products</p>
          </div>
        </div>
        <div
          className="box"
          style={{ cursor: "pointer" }}
          onClick={handleClickOpen}
        >
          <div className="content">
            <div
              className="imgCover"
              style={{
                backgroundPosition: "100%",
              }}
            >
              <img src="/images/reset-password.png" alt="car" />
            </div>
            <h2>Change Password</h2>
            <p>Change your Password</p>
          </div>
        </div>
      </div>

      {open && (
        <Modal
          title={"Change Password"}
          width={isMobile ? "85%" : "35%"}
          closeModal={setOpen}
        >
          <div className="modalBody">
            <div>
              <label htmlFor="old_password">Old Password</label>
              <input
                type={"password"}
                placeholder={"Old password"}
                name="old_password"
                id="old_password"
                onChange={handleChange}
                className="form-control mb-4"
              />
            </div>
            <div>
              <label htmlFor="newPassword">New Password</label>
              <input
                type={"password"}
                placeholder={"new password"}
                name="password"
                id="newPassword"
                onChange={handleChange}
                className="form-control mb-4"
              />
            </div>
            <div>
              <label htmlFor="confirmPassword">Confirm Password</label>
              <input
                type={"password"}
                placeholder={"confirm new password"}
                name="confirmPassword"
                id="confirmPassword"
                onChange={handleChange}
                className="form-control"
              />
            </div>
          </div>
          <div className="modalButton">
            <div className="btnCover">
              <button type="button" className="modalBtn" onClick={handleSubmit}>
                Change
              </button>

              <button
                data-modal-toggle=""
                type="button"
                onClick={() => setOpen(false)}
                className="cancelBtn"
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal>
      )}
      {openRejectedLoan && (
        <Modal
          title={"Rejected Loan"}
          width={isMobile ? "85%" : "35%"}
          closeModal={setRejectedLoan}
        >
          <div className="modalBody">
            <div>
              {loanSelected?.rejected[0]?.message}
            </div>
          </div>
          <div className="modalButton">
            <div className="btnCover">
              <button type="button" className="modalBtn" onClick={() => history.push({
                        pathname: "/edit-loan-form",
                        state: { loan_id: loanSelected?._id },
                      })}>
                Edit Loan Form
              </button>

              <button
                data-modal-toggle=""
                type="button"
                onClick={() => setRejectedLoan(false)}
                className="cancelBtn"
              >
                Close
              </button>
            </div>
          </div>
        </Modal>
      )}
      {openRejectedOrder && (
        <Modal
          title={"Rejected Order"}
          width={isMobile ? "85%" : "35%"}
          closeModal={setRejectedLoan}
        >
          <div className="modalBody">
            <div>
              {orderSelected?.rejected[0]?.message}
            </div>
          </div>
          <div className="modalButton">
            <div className="btnCover">
              <button type="button" className="modalBtn" onClick={() => history.push({
                        pathname: "/edit-order-form",
                        state: { order_id: orderSelected?._id },
                      })}>
                Edit Mart Form
              </button>

              <button
                data-modal-toggle=""
                type="button"
                onClick={() => setRejectedOrder(false)}
                className="cancelBtn"
              >
                Close
              </button>
            </div>
          </div>
        </Modal>
      )}

      {/* <ChangePasswordDialog open={open} close={handleClose}/> */}
    </div>
  );
}

export default SectionOne;
