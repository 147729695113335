export const ProductContants = {
    RESPONSE_STATE: "RESPONSE_STATE",
    LOADING: "LOADING",
    GET_CATEGORY: "GET_CATEGORY",
    GET_COMPUTING_PRODUCTS: "GET_COMPUTING_PRODUCTS",
    GET_TELEVISION_PRODUCTS: "GET_TELEVISION_PRODUCTS",
    GET_ELECTRONIC_CATEGORY: "GET_ELECTRONIC_CATEGORY",
    GET_HOME_AND_APPLIANCES_PRODUCTS: "GET_HOME_AND_APPLIANCES_PRODUCTS",
    GET_PRODUCTS: "GET_PRODUCTS",
    GET_PRODUCT_DETAILED: "GET_PRODUCT_DETAILED",
    GET_FEATURED_PRODUCTS: "GET_FEATURED_PRODUCTS",
    GET_BANNER_PRODUCTS: "GET_BANNER_PRODUCTS",
    GET_PRODUCT_CATEGORY_BY_CAT_NAME: "GET_PRODUCT_CATEGORY_BY_CAT_NAME",
    REQUEST_ORDER: "REQUEST_ORDER",
}