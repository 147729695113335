import React from "react";
import { useHistory } from "react-router-dom";
import './Auth.css'
function VerifyEmailError() {
  const history = useHistory();
  return (
    <>
      <div className="VerifyEmailSuccess" style={{
        background: 'url(/images/dev/background.jpg) no-repeat center center/cover'
      }}>
        <div className="overlay">
        <img src="/images/dev/cancel.png" alt="error" />
        <h2>Email verification failed</h2>
        <p>Your email address had been not verify yet. Thank you for joing UPNMG</p>
        <button
        className="btn"
          onClick={() => {
            history.push("/");
          }}
        >
          Login
        </button>
        </div>
      </div>
    </>
  );
}

export default VerifyEmailError;
