import { navigationConstant } from "../constants/navigationConstant"

const initiaState = {
    openSideNav: false
}

const navigationReducer = (state = initiaState, action) => {
    console.log('action', action)
    switch(action.type){
        case navigationConstant.OPEN_SIDE_BAR:
            return {
                ...state,
                openSideNav: true
            }
        case navigationConstant.CLOSE_SIDE_BAR:
            return {
                ...state,
                openSideNav: false
            }
        default:
            return state
    }
}

export default navigationReducer