import { navigationConstant } from "../constants/navigationConstant"

export const openSideBarOnMobile = () => {
    return async (dispatch) => {
        dispatch({
            type: navigationConstant.OPEN_SIDE_BAR
        })
    }
}

export const closeSideBarOnMobile = () => {
    return async (dispatch) => {
        dispatch({
            type: navigationConstant.CLOSE_SIDE_BAR
        })
    }
}