/* eslint-disable  */
import { IconButton } from "@material-ui/core";
import {
  DeleteForeverOutlined,
  FavoriteBorderOutlined,
  ShoppingBasketOutlined,
} from "@material-ui/icons";
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
import "../../../../../css/Pages/Shopping.css";
import './ProductCard.css'
import { userDataActionCreators } from "../../../../../service/action-creators";
import { AddProductToCart } from "../../../../../service/action-creators/userDataAction";
function ProductCard({ product }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const { RemoveFavoriteProducts, } = bindActionCreators(
    userDataActionCreators,
    dispatch
  );

  const handleAddToBasket = (e, product) => {
    e.preventDefault();

    if(carts?.length > 0){
      // alert(carts[0]?.period)
      let selectedDuration;
      let percentChange;

      if (Number(carts[0]?.period) === 24) {
        percentChange = 1.2
        selectedDuration = Number(carts[0]?.period);
      } else if (Number(carts[0]?.period) === 18) {
        percentChange = 1.145
        selectedDuration = Number(carts[0]?.period);
      } else if (
        // Number(carts[0].period) == 1 ||
        Number(carts[0]?.period) === 3 ||
        Number(carts[0]?.period) === 6 ||
        Number(carts[0]?.period) === 12
      ) {
        percentChange = 1.1
        selectedDuration = Number(carts[0]?.period); // 10%
      }

      const adminCharge = 3 / 100;
      const retailPrice = Number(product?.new_price)
      const adminAmount = retailPrice * adminCharge;
      const initialAmount = (retailPrice * percentChange) + adminAmount;

      const selectedPeriod = Number(initialAmount / selectedDuration).toFixed(2)
   
      const payload = {
          size: "",
          color: "",
          quantity: 1,
          product_id: product?._id,
          period: selectedDuration,
          period_price: selectedPeriod,
        };
        // alert(product_id)
        dispatch(AddProductToCart(payload))

    }else{
    const defaultDuration = 3
    const percentChange = 1.1
    const adminCharge = 3 / 100;
    const retailPrice = Number(product?.new_price)
    const adminAmount = retailPrice * adminCharge;
    const initialAmount = (retailPrice * percentChange) + adminAmount;
    const selectedPeriod = Number(initialAmount / defaultDuration).toFixed(2)
   
    const payload = {
      size: "",
      color: "",
      quantity: 1,
      product_id: product?._id,
      period: defaultDuration,
      period_price: selectedPeriod,
    };
    // alert(product_id)
    dispatch(AddProductToCart(payload))

  }
  };

  return (
    <div className="ProductCard">
      <div className="card">
        <div className="productInfo">
          <div
            className="productImage"
            onClick={() => {
              history.push({
                pathname: "/product-detailed",
                state: { product: product?.product_id },
              });
            }}
          >
            <img
              className="img"
              src={product?.product_id?.images[0]?.url}
              alt=""
            />
          </div>
          <div
            className="productDetailes"
            onClick={() => {
              history.push({
                pathname: "/product-detailed",
                state: { product: product?.product_id },
              });
            }}
          >
            <h3>{product?.product_id?.product_name.length > 50 ? product?.product_id?.product_name.slice(0, 50) + '...' : product?.product_id?.product_name}</h3>
            {/* <p className="">{product?.product_id?.short_description.length > 100 ? product?.product_id?.short_description.slice(0, 100) + '...' : product?.product_id?.short_description} </p> */}
          </div>
          <div className="ProductPrice">
            <div className="price">Price:</div>
            <div className="priceContent">
              <h3>
                {" "}
                <span> &#8373;{product?.product_id?.new_price}</span>{" "}
                <small>&#8373;{product?.product_id?.old_price} </small>
              </h3>
              {/* <div
                className="addToCatBtn"
              
              >
                <IconButton
                  onClick={(e) =>
                    handleAddToBasket(e, product?.product_id)
                  }
                >
                  <ShoppingBasketOutlined className="icon" />
                </IconButton>
              </div> */}
            </div>
          </div>
        </div>
        <div
          className="addFavourite"
          onClick={() => {
            RemoveFavoriteProducts(product?._id);
          }}
        >
          <DeleteForeverOutlined className="icon" />
        </div>
      </div>
    </div>
  );
}

export default ProductCard;
