/* eslint-disable  */
import { DoubleArrowOutlined } from "@material-ui/icons";
import React from "react";
import "../../../../../css/Pages/Shopping.css";
import RenderHome from "../../../home/RenderHome";


function AllProductPage() {
  const nextPage = () => {};
  const previousPage = () => {};
  return (
    <div className="AllProductPage">
      <RenderHome title={"Shopping Center"}>
      <div className="ProductCover">
        <div className="prodctsSection">
          <div className="searchBar">
            <input type={'search'} name="searchProduct" placeholder="search by name"/>
            <button>Search</button>
          </div>
          <div className="productsContainer">
            {/* <ProductCard />
            <ProductCard />
            <ProductCard />
            <ProductCard />
            <ProductCard />
            <ProductCard />
            <ProductCard /> */}

   <section class="vehicles_section">
      <div class=" vehicles_section_cover">
         <div class="header">
           <div> <h3>Most Purchased</h3></div>
      
         </div>
              <div class="">
            <div class="box">
              <div class="imgCover">
                  <span class="percent_off">-10%</span>
                 <div class="imgMainCover"><img src="images/products/10-2-samsung-mobile-phone-png-image.png" alt="product"/></div>
                 <div class="descr_price">
                  <a href="#">
                     <div class="descr">Brand new sumsung phone 4gb...</div>
                     <div class="price">Gh 400.00</div>
                    </a>
                 </div>
              </div>
            </div>
            <div class="box">
              <div class="imgCover">
                  <span class="percent_off">-10%</span>
                 <div class="imgMainCover"><img src="images/products/10-2-samsung-mobile-phone-png-image.png" alt="product"/></div>
                 <div class="descr_price">
                  <a href="#">
                     <div class="descr">Brand new sumsung phone 4gb...</div>
                     <div class="price">Gh 400.00</div>
                    </a>
                 </div>
              </div>
            </div>
            <div class="box">
               <div class="box">
                  <div class="imgCover">
                     <span class="percent_off">-20%</span>
                     <div class="imgMainCover"><img src="images/products/Dell-Laptop-PNG-Transparent.png" alt="product"/></div>
                     <div class="descr_price">
                        <a href="#">
                           <div class="descr">Brand new laptop for sale 4gb...</div>
                           <div class="price">Gh 5000.00</div>
                          </a>
                       </div>
                  </div>
                </div>
            </div>
            <div class="box">
               <div class="box">
                  <div class="imgCover">
                        <span class="percent_off">-15%</span>
                     <div class="imgMainCover"><img src="images/products/round neck.png" alt="product"/></div>
                     <div class="descr_price">
                        <a href="#">
                           <div class="descr">Brand new  polo shirt boys...</div>
                           <div class="price">Gh 50.00</div>
                          </a>
                       </div>
                  </div>
                </div>
            </div>
            <div class="box">
               <div class="box">
                  <div class="imgCover">
                        <span class="percent_off">-19%</span>
                     <div class="imgMainCover"><img src="images/products/Refrigerator-Transparent-PNG.png" alt="product"/></div>
                     <div class="descr_price">
                        <a href="#">
                           <div class="descr"> new sumsung Refrigerator 4gb...</div>
                           <div class="price">Gh 2000.00</div>
                          </a>
                       </div>
                  </div>
                </div>
            </div>
            <div class="box">
               <div class="box">
                  <div class="imgCover">
                        <span class="percent_off">-60%</span>
                     <div class="imgMainCover"><img src="images/products/long sleeves.png" alt="product"/></div>
                     <div class="descr_price">
                        <a href="#">
                           <div class="descr">Men long sleeve shirt cotton...</div>
                           <div class="price">Gh 60.00</div>
                          </a>
                       </div>
                  </div>
                </div>
            </div>
        </div>
      </div>
         </section>

          </div>
          <div className="paginations">
            <div
              onClick={() => previousPage(1)}
              // className={paginate.previousNumber ? "" : "PaginateDisabled"}
            >
              {/* <img src={Ico_ChevronLeft} alt="" /> */}
              {/* <ArrowBackIosSharp/> */}
              <span>Previous</span>
            </div>
            <div className="numbers">
              {/* {range(
              pageRange.page,
              paginate.total > 4 ? pageRange.totalToShow : paginate.total,
              1
            )?.map((item, idx) => {
              return (
                <div
                  className={paginate.page === item ? "Active" : ""}
                  onClick={() => movePage(item)}
                  key={item}
                >
                  {item}
                </div>
              );
            })} */}
              <div className="number active">1</div>
              <div className="number">2</div>
              <div className="number">3</div>
              <div className="number">4</div>
              <div className="number">5</div>
              <div className="number">6</div>
              <div className="number">7</div>
              <div className="number">8</div>
              <div className="number">9</div>
              <div className="number">9</div>
              <div className="number">10</div>
            </div>
            <div
              onClick={() => nextPage(1)}
              // className={paginate.nextNumber ? "" : "PaginateDisabled"}
            >
              <span>Next</span>
              <DoubleArrowOutlined />
              {/* <img src={Ico_ChevronRight} alt="" /> */}
            </div>
          </div>
        </div>
        <div className="ProductFilters">

        </div>
       </div>
      </RenderHome>
    </div>
  );
}

export default AllProductPage;
