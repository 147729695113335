import axiosInstance from "../axios/axios";
import { ProductContants } from "../constants/ProductContants";

export const GetProducts = (isPaginated, size, page, category, search) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ProductContants.LOADING,
        isLoading: true,
      });
      const response = await axiosInstance.get(
        `/products?isPaginated=${isPaginated}&size=${size}&page=${page}${search ? `&search=${search}` : ''} ${category ? `&category=${category}` : ''}`
      );
      if (response) {
        dispatch({
          type: ProductContants.GET_PRODUCTS,
          payLoad: {
            products: response.data.docs,
          },
        });
        dispatch({
          type: ProductContants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: ProductContants.RESPONSE_STATE,
        response: {
          state: "ERROR",
          message: e?.response?.data.message ?? "Opps something bad happend",
        },
      });
      dispatch({
        type: ProductContants.LOADING,
        isLoading: false,
      });
    }
  };
};

export const SearchProducts = (search, isPaginated, page, size) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ProductContants.LOADING,
        isLoading: true,
      });
      const response = await axiosInstance.get(
        `/products/search/${search}?isPaginated=${isPaginated}&size=${size}&page=${page}`
      );
      if (response) {
        const {
          docs,
          totalItems,
          totalPages,
          size,
          page,
          currentPageSize,
          links
        } = response.data
        dispatch({
          type: ProductContants.GET_PRODUCTS,
          payLoad: {
            products: docs,
            paginate: {
              size: size,
              page: page,
              totalItems: totalItems,
              total: totalPages,
              currentPageSize,
              previousPage: links.previousPage,
              nextPage: links.nextPage,
              previousNumber: links.previous,
              nextNumber: links.next,
            }
          },
        });
        dispatch({
          type: ProductContants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: ProductContants.RESPONSE_STATE,
        response: {
          state: "ERROR",
          message: e?.response?.data.message ?? "Opps something bad happend",
        },
      });
      dispatch({
        type: ProductContants.LOADING,
        isLoading: false,
      });
    }
  };
};
export const GetProductsByCategorName = (isPaginated, size, page, categoryName, search) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ProductContants.LOADING,
        isLoading: true,
      });
      // products/get-product-by-category/Electronics
      const response = await axiosInstance.get(
        `/products/get-product-by-category/${categoryName}?isPaginated=${isPaginated}&size=${size}&page=${page}${search}`
      );
      if (response) {
        const {
          docs,
          totalItems,
          totalPages,
          size,
          page,
          currentPageSize,
          links
        } = response.data
        dispatch({
          type: ProductContants.GET_PRODUCTS,
          payLoad: {
            products: docs,
            paginate: {
              size: size,
              page: page,
              totalItems: totalItems,
              total: totalPages,
              currentPageSize,
              previousPage: links.previousPage,
              nextPage: links.nextPage,
              previousNumber: links.previous,
              nextNumber: links.next,
            }
          },
        });
        dispatch({
          type: ProductContants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: ProductContants.RESPONSE_STATE,
        response: {
          state: "ERROR",
          message: e?.response?.data.message ?? "Opps something bad happend",
        },
      });
      dispatch({
        type: ProductContants.LOADING,
        isLoading: false,
      });
    }
  };
};

export const GetProductCategory = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ProductContants.LOADING,
        isLoading: true,
      });
      const response = await axiosInstance.get(
        `/products/category`
      );
      if (response) {
        dispatch({
          type: ProductContants.GET_CATEGORY,
          payLoad: {
            category: response.data,
          },
        });
        dispatch({
          type: ProductContants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: ProductContants.RESPONSE_STATE,
        response: {
          state: "ERROR",
          message: e?.response?.data.message ?? "Opps something bad happend",
        },
      });
      dispatch({
        type: ProductContants.LOADING,
        isLoading: false,
      });
    }
  };
};


export const GetElectronicesCategory = (categoryId, size) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ProductContants.LOADING,
        isLoading: true,
      });
      const response = await axiosInstance.get(
        `/products/get-product-by-category/${categoryId}?size=${size}`
      );
      if (response) {
        dispatch({
          type: ProductContants.GET_ELECTRONIC_CATEGORY,
          payLoad: {
            electronicsCategory: response.data,
          },
        });
        dispatch({
          type: ProductContants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: ProductContants.RESPONSE_STATE,
        response: {
          state: "ERROR",
          message: e?.response?.data.message ?? "Opps something bad happend",
        },
      });
      dispatch({
        type: ProductContants.LOADING,
        isLoading: false,
      });
    }
  };
};
export const GetComputingCategory = (category, size) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ProductContants.LOADING,
        isLoading: true,
      });
      const response = await axiosInstance.get(
        `/products/get-product-by-category/${category}?size=${size}`
      );
      if (response) {
        dispatch({
          type: ProductContants.GET_COMPUTING_PRODUCTS,
          payLoad: {
            computingProducts: response.data,
          },
        });
        dispatch({
          type: ProductContants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: ProductContants.RESPONSE_STATE,
        response: {
          state: "ERROR",
          message: e?.response?.data.message ?? "Opps something bad happend",
        },
      });
      dispatch({
        type: ProductContants.LOADING,
        isLoading: false,
      });
    }
  };
};
export const GetTelevisionCategory = (category, size) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ProductContants.LOADING,
        isLoading: true,
      });
      const response = await axiosInstance.get(
        `/products/get-product-by-category/${category}?size=${size}`
      );
      if (response) {
        dispatch({
          type: ProductContants.GET_TELEVISION_PRODUCTS,
          payLoad: {
            televisionProducts: response.data,
          },
        });
        dispatch({
          type: ProductContants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: ProductContants.RESPONSE_STATE,
        response: {
          state: "ERROR",
          message: e?.response?.data.message ?? "Opps something bad happend",
        },
      });
      dispatch({
        type: ProductContants.LOADING,
        isLoading: false,
      });
    }
  };
};
export const GetHomeAndAppliancesCategory = (category, size) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ProductContants.LOADING,
        isLoading: true,
      });
      const response = await axiosInstance.get(
        `/products/get-product-by-category/${category}?size=${size}`
      );
      if (response) {
        console.log('Applresponse', response)
        dispatch({
          type: ProductContants.GET_HOME_AND_APPLIANCES_PRODUCTS,
          payLoad: {
            homeAndApplianceProducts: response.data,
          },
        });
        dispatch({
          type: ProductContants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: ProductContants.RESPONSE_STATE,
        response: {
          state: "ERROR",
          message: e?.response?.data.message ?? "Opps something bad happend",
        },
      });
      dispatch({
        type: ProductContants.LOADING,
        isLoading: false,
      });
    }
  };
};

export const GetFeaturedProduct = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ProductContants.LOADING,
        isLoading: true,
      });
      const response = await axiosInstance.get(
        `/products/featured/get?featured=true`
      );
      if (response) {
        dispatch({
          type: ProductContants.GET_FEATURED_PRODUCTS,
          payLoad: {
            featured: response.data,
          },
        });
        dispatch({
          type: ProductContants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: ProductContants.RESPONSE_STATE,
        response: {
          state: "ERROR",
          message: e?.response?.data.message ?? "Opps something bad happend",
        },
      });
      dispatch({
        type: ProductContants.LOADING,
        isLoading: false,
      });
    }
  };
};

export const GetProductDetailed = (product_id) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ProductContants.LOADING,
        isLoading: true,
      });
      const response = await axiosInstance.get(
        `/products/${product_id}`
      );
      if (response) {
        dispatch({
          type: ProductContants.GET_PRODUCT_DETAILED,
          payLoad: {
            product_detailed: response.data,
          },
        });
        dispatch({
          type: ProductContants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: ProductContants.RESPONSE_STATE,
        response: {
          state: "ERROR",
          message: e?.response?.data.message ?? "Opps something bad happend",
        },
      });
      dispatch({
        type: ProductContants.LOADING,
        isLoading: false,
      });
    }
  };
};
export const GetBannerProducts = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ProductContants.LOADING,
        isLoading: true,
      });
      const response = await axiosInstance.get(
        `/products/banner/get`
      );
      if (response) {
        dispatch({
          type: ProductContants.GET_BANNER_PRODUCTS,
          payLoad: {
            banner: response.data,
          },
        });
        dispatch({
          type: ProductContants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: ProductContants.RESPONSE_STATE,
        response: {
          state: "ERROR",
          message: e?.response?.data.message ?? "Opps something bad happend",
        },
      });
      dispatch({
        type: ProductContants.LOADING,
        isLoading: false,
      });
    }
  };
};
export const GetRequestOrder = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ProductContants.LOADING,
        isLoading: true,
      });
      const response = await axiosInstance.get(
        `/orders/user/request-order`
      );
      if (response) {
        dispatch({
          type: ProductContants.REQUEST_ORDER,
          payLoad: {
            request_order: response.data,
          },
        });
        dispatch({
          type: ProductContants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: ProductContants.RESPONSE_STATE,
        response: {
          state: "ERROR",
          message: e?.response?.data.message ?? "Opps something bad happend",
        },
      });
      dispatch({
        type: ProductContants.LOADING,
        isLoading: false,
      });
    }
  };
};
export const DeletedRequestOrder = (order_id) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ProductContants.LOADING,
        isLoading: true,
      });
      const response = await axiosInstance.delete(
        `/orders/user/request-order?order_id=${order_id}`
      );
      if (response) {
        dispatch({
          type: ProductContants.RESPONSE_STATE,
          response: {
            state: "SUCCESS",
            message: "Request order deleted",
          },
        });
        dispatch({
          type: ProductContants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: ProductContants.RESPONSE_STATE,
        response: {
          state: "ERROR",
          message: e?.response?.data.message ?? "Opps something bad happend",
        },
      });
      dispatch({
        type: ProductContants.LOADING,
        isLoading: false,
      });
    }
  };
};
export const RequestOrder = (data, images) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ProductContants.LOADING,
        isLoading: true,
      });
      const formData = new FormData();

      formData.append("product_name", data?.product_name);
      formData.append("description", data?.description);

      for (const image of images) {
        formData.append("files", image, image?.name);
      }


      const response = await axiosInstance.post(
        `/orders/request-order`, formData
      );
      if (response) {
        dispatch({
          type: ProductContants.RESPONSE_STATE,
          response: {
            state: "SUCCESS",
            message:  "Your request for a product sent",
          },
        });
        dispatch({
          type: ProductContants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: ProductContants.RESPONSE_STATE,
        response: {
          state: "ERROR",
          message: e?.response?.data?.message ?? "Opps something bad happend",
        },
      });
      dispatch({
        type: ProductContants.LOADING,
        isLoading: false,
      });
    }
  };
};


export const ResetProductResponse = () => {
  return async (dispatch) => {
    dispatch({
      type: ProductContants.RESPONSE_STATE,
      response: {
        state: null,
        message: "",
      },
    });
  };
};