/* eslint-disable  */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { GetUserProfile } from "../../../service/action-creators/authAction";
import { GetNotifications } from "../../../service/action-creators/notificationAction";
import { GetCartProducts } from "../../../service/action-creators/userDataAction";
import RenderHome from "../home/RenderHome";

import "./Faq.css";
function Faq() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(GetUserProfile());
    dispatch(GetNotifications());
    dispatch(GetCartProducts());
  }, []);

  const faqs = [
    {
      title: "How long do I have to be a member before qualifying for a loan ?",
      details:
        "<p>A new member must contribute consistently for a minimum of <strong>four (4)</strong> months before qualifying for a loan</p>",
    },
    {
      title: "How long can I get my loan ?",
      details:
        "<p>You will get your requested loan within a duration of 1 to 30 days.</p>",
    },
    {
      title: "How can I generate my mandate number ?",
      details:
        "<p>You have to log onto you payslip portal and click on '<strong>generate mandate form</strong>' to generate it. </p>",
    },
    {
      title: "How can I generate my OTP Number",
      details:
        "<p>The OTP comes together with the mandate number in a form of text message after generating the mandate number.</p>",
    },
    {
      title:
        "What is the maximum loan amount can I request with a fund contribution of 50 cedis ?",
      details:
        "<p>The maximum loan amount you will receive is 7,000.00 cedis. Unless you want to request more than 7,000.00 you will have to increase your fund contribution.</p>",
    },
    {
      title: "What are the steps for increasing my fund contribution ?",
      details:
        "<p><ul><li>Fill fund increament form.</li><li>Generate mandate number and OTP and write it on the form.</li><li>Include a picture of your Ghana Card or voters ID.</li><li>Scan and forward documents to upnmgfund@gmail.com</li></ul></p>",
    },
  ];
  return (
    <div className="Faq">
      <RenderHome>
        <div className="faqTop">
          <div className="items">
            <div>
              {/* <h1>FAQ</h1> */}
              <h1>Help Centre</h1>
              <p>What do you need an assistant with?</p>
            </div>
          </div>
        </div>
        <div className="faqBottom">
          <div className="section1">
            <h1 className="title">FAQ</h1>
            <p className="description">
              Your friends, colleagues and people often ask the same questions.
              Maybe you will find what you want know here without any asistance.
              {/* Everything you need to know about UPNMG and these are what members
              mostly ask. Get to know more about you join UPNMG */}
            </p>
          </div>
          <div className="section2">
            {faqs.map((faq, index) => {
              return (
                // <div className="item">
                //   <div className="faqDetails">
                //     <h3>{faq?.title} </h3>
                //     <p className={isOpen ? "details active" : "details"}>
                //       {faq?.details}{" "}
                //     </p>
                //   </div>
                //   <div className="icon">
                //     <FaPlus onClick={() => setIsOpen(true)} />
                //   </div>
                // </div>
                <Accordion>
                  <AccordionSummary
                    //   expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>
                      <h3
                        style={{
                          color: "var(--successDeep2)",
                          fontWeight: "bold",
                        }}
                      >
                        {faq.title}
                      </h3>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <div dangerouslySetInnerHTML={{ __html: faq.details }} />
                      {/* {faq.details} */}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </div>
        </div>
      </RenderHome>
    </div>
  );
}

export default Faq;
