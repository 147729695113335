/* eslint-disable  */
import axiosInstance from "../axios/axios";
import { authConstants } from "../constants/AuthConstants";

export const loginUser = (user) => {
  console.log(user);
  return async (dispatch) => {
    try {
      dispatch({
        type: authConstants.LOADING,
        isLoading: true,
      });
      const response = await axiosInstance.post(`/auth/login`, {
        username: user?.email.toLowerCase(),
        password: user?.password,
      });
      if (response) {
        // console.log(response.data)
        const { access_token } = response.data;
        localStorage.setItem("token", access_token);

        //get user data
        const user = await axiosInstance.get(`/users/profile/me`);
        const { password, ...rest } = user.data;
        localStorage.setItem("user", JSON.stringify(rest));

        dispatch({
          type: authConstants.LOGIN_SUCCESS,
          payLoad: { token: access_token, user: rest },
        });
        dispatch({
          type: authConstants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: authConstants.RESPONSE_STATE,
        response: {
          state: "ERROR",
          message: e?.response?.data?.message ?? "Opps something bad happend",
        },
      });
      dispatch({
        type: authConstants.LOADING,
        isLoading: false,
      });
    }
  };
};
export const getUserById = (user_id) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: authConstants.LOADING,
        isLoading: true,
      });
      //get user data
      const response = await axiosInstance.get(`/users/${user_id}`);
      if (response) {
        const { password, ...rest } = response.data;

        dispatch({
          type: authConstants.GET_USER_BY_ID,
          payLoad: { userById: rest },
        });
        dispatch({
          type: authConstants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: authConstants.RESPONSE_STATE,
        response: {
          state: "ERROR",
          message: e?.response?.data?.message ?? "Opps something bad happend",
        },
      });
      dispatch({
        type: authConstants.LOADING,
        isLoading: false,
      });
    }
  };
};

export const GetUserProfile = () => {
  return async (dispatch) => {
    try {
      const user = JSON.parse(window.localStorage.getItem("user"));
      const access_token = window.localStorage.getItem("token");
      console.log("user", user);
      console.log("access_token", access_token);

      dispatch({
        type: authConstants.LOADING,
        isLoading: true,
      });
      const response = await axiosInstance.get(`/users/profile/me`);

      if (response) {
        const { password, ...rest } = response.data;
        dispatch({
          type: authConstants.LOGIN_SUCCESS,
          payLoad: { token: access_token, user: rest },
        });
        dispatch({
          type: authConstants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: authConstants.RESPONSE_STATE,
        response: {
          state: "ERROR",
          message: e?.response?.data?.message ?? "Opps something bad happend",
        },
      });
      dispatch({
        type: authConstants.LOADING,
        isLoading: false,
      });
    }
  };
};

export const UpdateProfile = (user, userImages) => {
  return async (dispatch) => {
    try {
      console.log("userDisp", user);
      console.log("userDisp", userImages);
      dispatch({
        type: authConstants.LOADING,
        isLoading: true,
      });
      const { phone_number, email, username, description, dob } = user;
      const data = {
        phone_number: phone_number,
        email: email,
        username: username,
        description: description,
        dob: dob,
        ...(userImages?.profileImage !== null && {
          profile_image: userImages?.profileImage,
        }),
        ...(userImages?.coverImage !== null && {
          cover_image: userImages?.coverImage,
        }),
      };
      
      const response = await axiosInstance.put(`/users/profile/me`, data);
      if (response) {
        dispatch({
          type: authConstants.RESPONSE_STATE,
          response: {
            state: "SUCCESS",
            message: "Profile updated successfully",
          },
        });
        dispatch({
          type: authConstants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: authConstants.RESPONSE_STATE,
        response: {
          state: "ERROR",
          message:
            e?.response?.data?.message ?? "Failed while updating business",
        },
      });
      dispatch({
        type: authConstants.LOADING,
        isLoading: false,
      });
    }
  };
};

export const UpdateProfileImage = (image, key) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: authConstants.LOADING,
        isLoading: true,
      });

      let formData = new FormData();
      formData.append("file", image);

      const data = {
        profile_image: image,
      };
      const response = await axiosInstance.put(
        `/users/profile/me/profile_image?key=${key}`,
        formData
      );
      if (response) {
        dispatch({
          type: authConstants.RESPONSE_STATE,
          response: {
            state: "UPLOADED",
            message: "Profile updated successfully",
          },
        });
        dispatch({
          type: authConstants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: authConstants.RESPONSE_STATE,
        response: {
          state: "ERROR",
          message: e?.response?.data?.message ?? "Profile image update failed",
        },
      });
      dispatch({
        type: authConstants.LOADING,
        isLoading: false,
      });
    }
  };
};
export const UpdateCoverImage = (image, key) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: authConstants.LOADING,
        isLoading: true,
      });

      let formData = new FormData();
      formData.append("file", image);

      const data = {
        cover_image: image,
      };
      const response = await axiosInstance.put(
        `/users/profile/me/cover_image?key=${key}`,
        formData
      );
      if (response) {
        dispatch({
          type: authConstants.RESPONSE_STATE,
          response: {
            state: "SUCCESS",
            message: "Cover image updated",
          },
        });
        dispatch({
          type: authConstants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: authConstants.RESPONSE_STATE,
        response: {
          state: "ERROR",
          message: e?.response?.data?.message ?? "Cover image update failed",
        },
      });
      dispatch({
        type: authConstants.LOADING,
        isLoading: false,
      });
    }
  };
};

export const ChangePasswordAndEmail = (user, image) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: authConstants.LOADING,
        isLoading: true,
      });
      
      let formData = new FormData();
      formData.append("file", image);
      const UploadImageResponse = await axiosInstance.put(
        `/users/profile/me/profile_image`,
        formData
      );

      const response = await axiosInstance.put(`/auth/change-email-password`, {
        password: user?.password,
        confirmPassword: user?.confirmPassword,
        email: user?.email,
        dob: user?.dob,
        phone_number: user?.phone_number
      });


      if (response && UploadImageResponse) {
        console.log(response.data);
        dispatch({
          type: authConstants.RESPONSE_STATE,
          response: {
            state: "SUCCESS",
            message: "Password and email changed. Verify your email account",
          },
        });
        dispatch({
          type: authConstants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: authConstants.RESPONSE_STATE,
        response: {
          state: "ERROR",
          message: e?.response?.data?.message ?? "Opps something bad happend",
        },
      });
      dispatch({
        type: authConstants.LOADING,
        isLoading: false,
      });
    }
  };
};
export const VerifyEmailAddress = (token) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: authConstants.LOADING,
        isLoading: true,
      });
      const response = await axiosInstance.post(`users/verify/${token}`);

      if (response) {
        console.log(response.data);
        dispatch({
          type: authConstants.RESPONSE_STATE,
          response: {
            state: "SUCCESS",
            message: "Email verified",
          },
        });
        dispatch({
          type: authConstants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: authConstants.RESPONSE_STATE,
        response: {
          state: "ERROR",
          message: e?.response?.data?.message ?? "Opps something bad happend",
        },
      });
      dispatch({
        type: authConstants.LOADING,
        isLoading: false,
      });
    }
  };
};

export const SignOut = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: authConstants.LOADING,
        isLoading: true,
      });
      localStorage.clear();
      dispatch({
        type: authConstants.LOG_OUT,
      });
    } catch (e) {
      dispatch({
        type: authConstants.RESPONSE_STATE,
        response: {
          state: "ERROR",
          message: e?.response?.data?.message ?? "Opps something bad happend",
        },
      });
      dispatch({
        type: authConstants.LOADING,
        isLoading: false,
      });
    }
  };
};

export const ChangePassword = (user) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: authConstants.LOADING,
        isLoading: true,
      });

      console.log("user", user);
      const response = await axiosInstance.put(`/auth/change-password`, user);

      if (response) {
        dispatch({
          type: authConstants.RESPONSE_STATE,
          response: {
            state: "SUCCESS",
            message: "Password changed successfully",
          },
        });
        dispatch({
          type: authConstants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: authConstants.RESPONSE_STATE,
        response: {
          state: "ERROR",
          message: e?.response?.data.message ?? "Opps something bad happend",
        },
      });
      dispatch({
        type: authConstants.LOADING,
        isLoading: false,
      });
    }
  };
};

export const ForgotPassword = (email) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: authConstants.LOADING,
        isLoading: true,
      });
      console.log("email", email);
      const response = await axiosInstance.post("/auth/forgot-password", email);
      if (response) {
        dispatch({
          type: authConstants.RESPONSE_STATE,
          response: {
            state: "SUCCESS",
            message: "Check your email address to reset your password",
          },
        });
        dispatch({
          type: authConstants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: authConstants.RESPONSE_STATE,
        response: {
          state: "ERROR",
          message: e?.response?.data.message ?? "Opps something bad happend",
        },
      });
      dispatch({
        type: authConstants.LOADING,
        isLoading: false,
      });
    }
  };
};
export const ResendEmailTokenToUser = (email) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: authConstants.LOADING,
        isLoading: true,
      });
      console.log("email", email);
      const response = await axiosInstance.post(
        `/auth/verify_account/${email}`
      );
      if (response) {
        dispatch({
          type: authConstants.RESPONSE_STATE,
          response: {
            state: "SUCCESS",
            message: "Check your email address to confirm your account.",
          },
        });
        dispatch({
          type: authConstants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: authConstants.RESPONSE_STATE,
        response: {
          state: "ERROR",
          message: e?.response?.data.message ?? "Opps something bad happend",
        },
      });
      dispatch({
        type: authConstants.LOADING,
        isLoading: false,
      });
    }
  };
};

export const ResetPassword = (payLoad) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: authConstants.LOADING,
        isLoading: true,
      });
      console.log("Payload", payLoad);

      const response = await axiosInstance.post(
        "/auth/reset-password",
        payLoad
      );
      if (response) {
        dispatch({
          type: authConstants.RESPONSE_STATE,
          response: {
            state: "SUCCESS",
            message: "Password reset successfull.",
          },
        });
        dispatch({
          type: authConstants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: authConstants.RESPONSE_STATE,
        response: {
          state: "ERROR",
          message: e?.response?.data.message ?? "Opps something bad happend",
        },
      });
      dispatch({
        type: authConstants.LOADING,
        isLoading: false,
      });
    }
  };
};

export const ResetResponse = () => {
  return async (dispatch) => {
    dispatch({
      type: authConstants.RESPONSE_STATE,
      response: {
        state: null,
        message: "",
      },
    });
  };
};
