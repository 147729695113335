/* eslint-disable  */
import React, { useEffect } from "react";
import MainPage from "./sub-comp/MainPage";
import SideBarMenu from "./sub-comp/SideBarMenu";
import "../../../css/Dashboard/home/Home.css";
import { bindActionCreators } from "redux";
import {
  authActionCreators,
  messagesActionCreators,
  navigationActionCreators,
  notificationsActionCreators,
  userDataActionCreators,
} from "../../../service/action-creators";
import { useDispatch, useSelector } from "react-redux";
function RenderHome(props) {
  const dispatch = useDispatch();
  const { closeSideBarOnMobile } = bindActionCreators(
    navigationActionCreators,
    dispatch
  );
  const { GetUserProfile } = bindActionCreators(authActionCreators, dispatch);
  const { GetConversations } = bindActionCreators(
    messagesActionCreators,
    dispatch
  );
  const auth = useSelector((state) => state.auth);

  const { GetNotifications } = bindActionCreators(
    notificationsActionCreators,
    dispatch
  );
  const { GetCartProducts } = bindActionCreators(
    userDataActionCreators,
    dispatch
  );

  useEffect(() => {
    closeSideBarOnMobile();
    GetUserProfile();
    // dispatch( GetNotifications())
    // dispatch(GetCartProducts())
    GetCartProducts();
    GetNotifications();
    GetConversations()
    // GetConversations(auth?.user?._id);
   
  }, []);

  useEffect(() => {
    window.scrollTo(0,0)
   }, [])

  return (
    <div className="home_dashboard">
      <SideBarMenu />
      <MainPage>
        <div className="DashBoardTitle">
          <h4 className="pageTitle ">{props.title}</h4>
          {auth?.user?.is_email_verified ===  false && (
            <div className="email_message">
              Please verify your email address
            </div>
          )}
        </div>

        <div className="pb-6">{props.children}</div>
      </MainPage>
    </div>
  );
}

export default RenderHome;
