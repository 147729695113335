/* eslint-disable  */
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { GetUserProfile } from '../../../service/action-creators/authAction'
import RenderHome from '../home/RenderHome'
import './NotFound404.css'
function NotFound404() {
    const dispatch = useDispatch()
    const history = useHistory()

    const {authenticated, token} = useSelector(state => state.auth)

    const handleRedirect = () => {
      
      if(authenticated && token){
        history.goBack()
      }else {
        history.push('/')
      }
    }

    useEffect(() => {
        dispatch(GetUserProfile())
    }, [])
  return (
    <RenderHome>
        <div className='NotFound404'>
           <div className='imgCover'>
           <img src='/images/dev/not-found-rmb.png' alt='not-found-img'/>
           </div>
            <div className=''>
          <p>Your page was not found. Go back to home</p>
          <div className='btnCover'>
          <button className='button' onClick={handleRedirect}>Home</button>
          </div>
            </div>
        </div>
    </RenderHome>
  )
}

export default NotFound404