import React from 'react'

function NotFoundComponent({title, width = '240px', img=`/images/not-found.png`}) {
  return (
    <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
        // height: '100vh',
        textAlign: 'center'
       
    }}>
       <div style={{width: '', margin: '0 auto'}}>
       <img src={img} alt="not-found" style={{width: width}}/>
        <p style={{fontSize: '1.4rem', color: 'var(--primaryColor)'}}>{title}</p>
       </div>
    </div>
  )
}


export default NotFoundComponent