import React from 'react'

function Loan_chip({formState}) {
  return (
    <div className="loan_chip">
    <div className="chip_container">
      <div
        className="chip_card"
        style={{
          // background: 'gray'#287bff
          background: `${formState === 1 ? "#287bff" : "gray"}`,
          boxShadow: `${
            formState === 1 &&
            "8px 0px 0 wheat, inset -8px 0 0 rgba(255, 255, 255, 0.25), 8px 0 0 rgba(0, 0, 0, 0.15)"
          }`,
        }}
      >
        <div className="chip_content">Personal Info</div>
      </div>
    </div>
    <div className="chip_container">
      <div
        className="chip_card"
        style={{
          // background: 'gray'#287bff
          background: `${formState === 2 ? "#287bff" : "gray"}`,
          boxShadow: `${
            formState === 2 &&
            "8px 0px 0 wheat, inset -8px 0 0 rgba(255, 255, 255, 0.25), 8px 0 0 rgba(0, 0, 0, 0.15)"
          }`,
        }}
      >
        <div className="chip_content">Guarantors</div>
      </div>
    </div>
    <div className="chip_container">
      <div
        className="chip_card"
        style={{
          // background: 'gray'#287bff
          background: `${formState === 3 ? "#287bff" : "gray"}`,
          boxShadow: `${
            formState === 3 &&
            "8px 0px 0 wheat, inset -8px 0 0 rgba(255, 255, 255, 0.25), 8px 0 0 rgba(0, 0, 0, 0.15)"
          }`,
        }}
      >
        <div className="chip_content"> Employment</div>
      </div>
    </div>
    <div className="chip_container">
      <div
        className="chip_card"
        style={{
          // background: 'gray'#287bff
          background: `${formState === 4 ? "#287bff" : "gray"}`,
          boxShadow: `${
            formState === 4 &&
            "8px 0px 0 wheat, inset -8px 0 0 rgba(255, 255, 255, 0.25), 8px 0 0 rgba(0, 0, 0, 0.15)"
          }`,
        }}
      >
        <div className="chip_content">Loan Details</div>
      </div>
    </div>
    <div className="chip_container">
      <div
        className="chip_card"
        style={{
          // background: 'gray'#287bff
          background: `${formState === 5 ? "#287bff" : "gray"}`,
          boxShadow: `${
            formState === 5 &&
            "8px 0px 0 wheat, inset -8px 0 0 rgba(255, 255, 255, 0.25), 8px 0 0 rgba(0, 0, 0, 0.15)"
          }`,
        }}
      >
        <div className="chip_content">Uploads</div>
      </div>
    </div>
    <div className="chip_container">
      <div
        className="chip_card"
        style={{
          // background: 'gray'#287bff
          background: `${formState === 6 ? "#287bff" : "gray"}`,
          boxShadow: `${
            formState === 6 &&
            "8px 0px 0 wheat, inset -8px 0 0 rgba(255, 255, 255, 0.25), 8px 0 0 rgba(0, 0, 0, 0.15)"
          }`,
        }}
      >
        <div className="chip_content">Preview</div>
      </div>
    </div>
    <div className="chip_container">
      <div
        className="chip_card"
        style={{
          // background: 'gray'#287bff
          background: `${formState === 7 ? "#287bff" : "gray"}`,
          boxShadow: `${
            formState === 7 &&
            "8px 0px 0 wheat, inset -8px 0 0 rgba(255, 255, 255, 0.25), 8px 0 0 rgba(0, 0, 0, 0.15)"
          }`,
        }}
      >
        <div className="chip_content">Terms</div>
      </div>
    </div>
  </div>
  )
}

export default Loan_chip