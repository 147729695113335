/* eslint-disable  */
import { Fab } from "@material-ui/core";
import { Cancel } from "@material-ui/icons";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { bindActionCreators } from "redux";
import {
  authActionCreators,
  userDataActionCreators,
} from "../../../../service/action-creators";
import Loader from "../../../Loader";
import RenderHome from "../../home/RenderHome";
import "./ApplyLoans.css";
import Loan_chip from "./components/Loan_chip";

function PersonalInfo() {
  const dispatch = useDispatch();
  const history = useHistory();
  const signatureRef = useRef({});

  const {
    SubmitLoanFormOne,
    SubmitUpdateLoanFormOne,
    ResetUserDataResponse,
    GetFormOneAppliedLoanTemp,
    CancelLoanApplicationTemp,
  } = bindActionCreators(userDataActionCreators, dispatch);
  const { GetUserProfile } = bindActionCreators(authActionCreators, dispatch);
  const [formState, setFormState] = useState(1);
  const [previewFrontCard, setPreviewFrontCard] = useState(null);
  const [previewBackCard, setPreviewBackCard] = useState(null);
  const [privacyChecked, setPrivacyChecked] = useState(false);

  const [formData, setFormData] = useState({});
  const [loanAmount, setLoanAmount] = useState(0);
  const [affordability, seAffordability] = useState(0);
  const [loanTerm, setLoanTerm] = useState(null);
  const [monthlyDeduction, setMonthlyDeduction] = useState(0);
  const [age, setAge] = useState(null);
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [formError, setFormError] = useState({});

  const response = useSelector((state) => state?.user?.response);
  const isLoading = useSelector((state) => state?.user?.isLoading);
  const auth = useSelector((state) => state?.auth);
  const user = auth?.user;
  const userData = useSelector((state) => state?.user);

  console.log("user", user);
  console.log("formData", formData);
  console.log("userData", userData);

  const { loanTemp } = userData;
  useEffect(() => {
    window.scrollTo(0,0)
   }, [])
   
  useEffect(() => {
    GetFormOneAppliedLoanTemp();
    // clo
  }, []);

  const handleFormChange = (e) => {
    e.preventDefault();
    setFormData({ ...formData, [e?.target?.name]: e?.target?.value });

    console.log("formData.loanAmount", formData.loanAmount);
  };

  const handleDoBChange = (e) => {
    const today = new Date();
    const year = today.getFullYear();
    const dob = e?.target?.value?.split("-")[0];

    const newAge = parseInt(year) - parseInt(dob);
    setAge(newAge);
    setDateOfBirth(e?.target?.value);
  };

  console.log("dateOfBirth", dateOfBirth);
  const pattern = /^GHA-\d{9}-\d$/;
  const validateForm = () => {
    let err = {};

    if (formData.sector === "" || !formData.sector) {
      err.sector = "Please select your region";
    }
    if (formData.ssnit === "" || !formData.ssnit) {
      err.ssnit = "SNNIT is required";
    }

    // if (formData.email === "" || !formData.email) {
    //   err.email = "Email is required";
    // }
    // else {
    //   let regex = /^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/;
    //   if (!regex.test(formData.email)) {
    //     err.email = "Please your email is not valid";
    //   }
    // }

    if (dateOfBirth === "" || !dateOfBirth) {
      err.dateOfBirth = "Date of birth is required";
    }
    if (formData.IDCardNumber === "" || !formData.IDCardNumber) {
      err.IDCardNumber = "Ghana identification number required";
    }
    // if (!pattern.test(formData.IDCardNumber)) {
    //   err.IDCardNumber = "National ID number is invalid";
    // }
    if (
      formData.residential_address1 === "" ||
      !formData.residential_address1
    ) {
      err.residential_address1 = "Residential address is required";
    }
    if (formData.work_address1 === "" || !formData.work_address1) {
      err.work_address1 = "Work address is required";
    }
    if (formData.personal_phone === "" || !formData.personal_phone) {
      err.personal_phone = "Phone number is required";
    }
    if (formData.work_phone === "" || !formData.work_phone) {
      err.work_phone = "Work phone is required";
    }
    if (formData.gender === "" || !formData.gender) {
      err.gender = "Gender is required";
    }

    setFormError({ ...err });
    const keys = Object.keys(err);
    return Object.keys(err).length < 1;
    // return false
  };

  let gender = "";
  if (user?.name?.split(" ")[0] === "Mr.") {
    gender = "Male";
  } else if (
    user?.name?.split(" ")[0] === "Miss" ||
    user?.name?.split(" ")[0] === "Miss." ||
    user?.name?.split(" ")[0] === "Ms." ||
    user?.name?.split(" ")[0] === "Mrs." 
  ) {
    gender = "Female";
  }else{
    gender = null
  }

  // const gender = user?.name?.split(" ")[0] === "Mr." ? "Male" : "Female";
  const employer_name = user?.facility?.split(" ")[1]?.split(":")[0];
  useEffect(() => {
    GetUserProfile();

    const today = new Date();
    const year = today.getFullYear();
    const dob = user?.dob?.split("-")[0];

    const newAge = parseInt(year) - parseInt(dob);

    setFormData({
      ...formData,
      staff_id: user?.staff_id,
      facility: user?.facility,
      email: user?.email || loanTemp?.email,
      gender: gender,
      fullname: user?.name,
      IdCardType: "Ghana National Identification",
      employer_name: employer_name,

      dateOfBirth: user?.dob || loanTemp?.dateOfBirth ,
      age: newAge || loanTemp?.age,
      sector: loanTemp?.sector,
      IDCardNumber: loanTemp?.IDCardNumber,
      // email: loanTemp?.email,
      personal_phone: user?.phone_number || loanTemp?.personal_phone ,
      residential_address1: loanTemp?.residential_address1,
      residential_address2: loanTemp?.residential_address2,
      ssnit: loanTemp?.ssnit,
      work_address1: loanTemp?.work_address1,
      work_address2: loanTemp?.work_address2,
      work_phone: loanTemp?.work_phone,
    });
    setDateOfBirth(loanTemp?.dateOfBirth);
    setAge(newAge || loanTemp?.age);
  }, []);

  useEffect(() => {
    const today = new Date();
    const year = today.getFullYear();
    const dob = user?.dob?.split("-")[0];
    const newAge = parseInt(year) - parseInt(dob);
    
    setFormData({
      ...formData,
      staff_id: user?.staff_id,
      facility: user?.facility,
      gender: gender,
      fullname: user?.name,
      email: user?.email || loanTemp?.email,
      IdCardType: "Ghana National Identification",
      employer_name: employer_name,

      dateOfBirth: user?.dob || loanTemp?.dateOfBirth,
      age: age || loanTemp?.age,
      sector: loanTemp?.sector,
      IDCardNumber: loanTemp?.IDCardNumber,
      // email: loanTemp?.email,
      personal_phone: user?.phone_number || loanTemp?.personal_phone,
      residential_address1: loanTemp?.residential_address1,
      residential_address2: loanTemp?.residential_address2,
      ssnit: loanTemp?.ssnit,
      work_address1: loanTemp?.work_address1,
      work_address2: loanTemp?.work_address2,
      work_phone: loanTemp?.work_phone,
    });
    setDateOfBirth(user?.dob || loanTemp?.dateOfBirth);
    setAge(newAge || loanTemp?.age);
  }, [loanTemp]);

  const handleCancelLoanApplication = () => {
    console.log('cancel id', loanTemp?._id)
    CancelLoanApplicationTemp(loanTemp?._id);
  };

  const handleSubmitFormOne = () => {
    const newFormData = {
      ...formData,
      age: age,
      gender,
      dateOfBirth,
    };
    console.log("newFormData", newFormData);
    console.log("formDataNew", formData);
    console.log("dateOfBirth", dateOfBirth);
    console.log("age", age);
    let isValid = validateForm();
    if (isValid) {
      if (!loanTemp) {
        console.log("loanTemp true", {
            ...formData,
            age: age,
            gender,
            dateOfBirth,
            sector: formData.sector?.split(":")[0],
            region: formData.sector?.split(":")[1],
            staff_id: user?.staff_id,
          });
        SubmitLoanFormOne({
          ...formData,
          age: age,
          gender,
          dateOfBirth,
          sector: formData.sector?.split(":")[0],
          region: formData.sector?.split(":")[1],
          staff_id: user?.staff_id,
        });
      } else {
        console.log("loantemp false");
        SubmitUpdateLoanFormOne(
          {
            ...formData,
            age: age,
            gender,
            sector: formData.sector?.split(":")[0],
            region: formData.sector?.split(":")[1],
            dateOfBirth,
          },
          loanTemp?._id
        );
      }
    }
  };

  useEffect(() => {
    if (response?.state === "SUCCESS") {
      // toast.success(response?.message);
      // history.push("/my-orders-and-purchase");
      ResetUserDataResponse();
      history.push("/apply-loan-guarantor");

      // setTimeout(() => {
      // }, 1500);
    } else if (response?.state === "ERROR") {
      toast.error(response?.message);
      setTimeout(() => {
        ResetUserDataResponse();
      }, 1500);
    } else if (response?.state === "DELETED") {
      toast.success(response?.message);
      history.push("/apply-loan-personal_info");

      setTimeout(() => {
        ResetUserDataResponse();
      }, 1500);
    }
  }, [response?.state]);

  return (
    <div className="ApplyLoans">
      <RenderHome title={"Apply Loan"}>
        {isLoading ?? <Loader />}
        {/* <hr /> */}
        {/* {loanTemp && (
          <div className="flex justify-end fixed right-0 mr-4">
            <Fab
              onClick={() => handleCancelLoanApplication()}
              title="Cancel Application"
              className="cancelIcon"
              size="small"
              color="secondary"
              aria-label="add"
            >
              <Cancel />
            </Fab>
          </div>
        )} */}

        <Loan_chip formState={formState} />

        <form action="">
          {formState && (
            <div className="form_area form1">
              <h3 className="formTitle">Personal Information</h3>
              <div className="form-group row">
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                  <label htmlFor="fullname">
                    Full Name<span className="bto-lb-required">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    name="fullname"
                    placeholder=""
                    readOnly
                    defaultValue={user?.name}
                    id="fullname"
                  />
                </div>

                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                  <label htmlFor="fullname">
                    Choose Your Region<span className="bto-lb-required">*</span>
                  </label>
                  <select
                    name="sector"
                    className="form-control"
                    onChange={handleFormChange}
                  >
                    {/* {formData.sector && <option value={""}></option>} */}
                    <option value={loanTemp?.sector ?? loanTemp?.sector}>
                      {" "}
                      {loanTemp?.sector ?? loanTemp?.sector?.split(":")[1]}
                    </option>
                    <option value={"Southern:Greater Accra"}>
                      Greater Accra
                    </option>
                    <option value={"Southern:Eastern"}>Eastern</option>
                    <option value={"Southern:Central"}>Central</option>
                    <option value={"Southern:Bono"}>Bono</option>
                    <option value={"Southern:Bono East"}>Bono East</option>
                    <option value={"Southern:Ashanti"}>Ashanti</option>
                    <option value={"Southern:Ahafo"}>Ahafo</option>

                    <option value={"Northern:Western"}>Western</option>
                    <option value={"Northern:Western North"}>
                      Western North
                    </option>
                    <option value={"Northern:Volta"}>Volta</option>
                    <option value={"Northern:Upper West"}>Upper West</option>
                    <option value={"Northern:Upper East"}>Upper East</option>
                    <option value={"Northern:Savannah"}>Savannah</option>
                    <option value={"Northern:Oti"}>Oti</option>
                    <option value={"Northern:Northern"}>Northern</option>
                    <option value={"Northern:North East"}>North East</option>
                  </select>
                  <span className="error-message">{formError.sector}</span>
                </div>
              </div>

              <div className="form-group row">
                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                  <label htmlFor="dateOfBirth">
                    Date of Birth<span className="bto-lb-required">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="date"
                    name="dateOfBirth"
                    id="dateOfBirth"
                    defaultValue={dateOfBirth}
                    placeholder="Date of Birth"
                    required
                    aria-required="true"
                    onChange={(e) => {
                      handleDoBChange(e);
                    }}
                  />
                  <span className="error-message">{formError.dateOfBirth}</span>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                  <label htmlFor="age">
                    Age<span className="bto-lb-required"></span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    name="age"
                    defaultValue={age || (loanTemp?.age ?? loanAmount?.age)}
                    id="age"
                    required
                    // value={age}
                    readOnly
                    placeholder=""
                    aria-required="true"
                    onChange={(e) => handleFormChange(e)}
                  />
                </div>
                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                  <label htmlFor="ssnit">
                    SSNIT<span className="bto-lb-required">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    name="ssnit"
                    id="ssnit"
                    placeholder=""
                    defaultValue={
                      formData?.ssnit || (loanTemp?.ssnit ?? loanTemp?.ssnit)
                    }
                    required
                    aria-required="true"
                    onChange={(e) => handleFormChange(e)}
                  />
                  <span className="error-message">{formError.ssnit}</span>
                </div>
              </div>

              <div className="form-group row">
                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                  <label htmlFor="IdCardType">
                    ID Type<span className="bto-lb-required">*</span>
                  </label>
                  <select
                    name="IdCardType"
                    id="IdCardType"
                    
                    value={"Ghana National Identification"}
                    className="form-control"
                    onChange={(e) => handleFormChange(e)}
                  >
                    <option value="Ghana National Identification">
                      Ghana National Identification{" "}
                    </option>
                  </select>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                  <label htmlFor="id_number">
                    ID Number<span className="bto-lb-required">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    name="IDCardNumber"
                    placeholder=""
                    defaultValue={
                      formData?.IDCardNumber ||
                      (loanTemp?.IDCardNumber ?? loanTemp?.IDCardNumber)
                    }
                    id="id_number"
                    aria-required="true"
                    onChange={(e) => handleFormChange(e)}
                  />
                  <span className="error-message">
                    {formError.IDCardNumber}
                  </span>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                  <label htmlFor="gender">
                    Gender<span className="bto-lb-required">*</span>
                  </label>
                  <select
                    name="gender"
                    id="gender"
                    defaultValue={
                      gender || (loanTemp?.gender ?? loanTemp?.gender)
                    }
                    onChange={(e) => handleFormChange(e)}
                    className="form-control"
                  >
                    <option value={gender}>
                      {gender || (loanTemp?.gender ?? loanTemp?.gender)}
                    </option>
                    {gender || (loanTemp?.gender ?? loanTemp?.gender) == "Female" ? (
                      <option value="Male">Male</option>
                    ):(<></>)}
                    {gender || (loanTemp?.gender ?? loanTemp?.gender) == "Male" ? (
                       <option value="Female">Female</option>
                    ): (<></>) }
                    
                    {!gender &&  (<>
                      <option value="Male">Male</option> 
                      <option value="Female">Female</option> 
                    </>)}

                    <option value="Other">Other</option>
                  </select>
                  <span className="error-message">{formError.gender}</span>
                </div>
              </div>

              <div className="form-group row">
                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                  <label>
                    Residential Address
                    <span className="bto-lb-required">*</span>
                  </label>

                  <input
                    className="form-control"
                    type="text"
                    name="residential_address1"
                    id="residential_address1"
                    defaultValue={
                      formData?.residential_address1 ||
                      (loanTemp?.residential_address1 ??
                        loanTemp?.residential_address1)
                    }
                    aria-required="true"
                    onChange={(e) => handleFormChange(e)}
                  />
                  <input
                    className="form-control"
                    type="text"
                    name="residential_address2"
                    id="residential_address2"
                    aria-required="true"
                    defaultValue={
                      formData?.residential_address2 ||
                      (loanTemp?.residential_address2 ??
                        loanTemp?.residential_address2)
                    }
                    onChange={(e) => handleFormChange(e)}
                  />
                  <span className="error-message">
                    {formError.residential_address1}
                  </span>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                  <label htmlFor="work_address1">
                    Work Address<span className="bto-lb-required">*</span>
                  </label>
                  <input
                    className="form-control form-group"
                    type="text"
                    name="work_address1"
                    id="work_address1"
                    defaultValue={
                      formData?.work_address1 ||
                      (loanTemp?.work_address1 ?? loanTemp?.work_address1)
                    }
                    aria-required="true"
                    onChange={(e) => handleFormChange(e)}
                  />
                  <input
                    className="form-control form-group"
                    type="text"
                    name="work_address2"
                    defaultValue={
                      formData?.work_address2 ||
                      (loanTemp?.work_address2 ?? loanTemp?.work_address2)
                    }
                    aria-required="true"
                    onChange={(e) => handleFormChange(e)}
                  />
                  <span className="error-message">
                    {formError.work_address1}
                  </span>
                </div>
              </div>

              {/* THE CONTACT DETAILS SECTION */}
              <h3 className="formTitle">Contact Details</h3>

              <div className="form-group row">
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                  <label>
                    Phone Number<span className="bto-lb-required">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    name="personal_phone"
                    defaultValue={user?.phone_number}
                    // defaultValue={
                    //   formData?.personal_phone ||
                    //   (loanTemp?.personal_phone ?? loanTemp?.personal_phone)
                    // }
                    aria-required="true"
                    onChange={(e) => handleFormChange(e)}
                  />
                  <span className="error-message">
                    {formError.personal_phone}
                  </span>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                  <label>
                    Work Phone<span className="bto-lb-required">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    defaultValue={
                      formData?.work_phone ||
                      (loanTemp?.work_phone ?? loanTemp?.work_phone)
                    }
                    name="work_phone"
                    aria-required="true"
                    onChange={(e) => handleFormChange(e)}
                  />
                  <span className="error-message">{formError.work_phone}</span>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <label>
                    Email Address<span className="bto-lb-required">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="email"
                    name="email"
                    // defaultValue={
                    //   formData?.email || (loanTemp?.email ?? loanTemp?.email)
                    // }
                    defaultValue={user?.email}
                    readOnly
                    aria-required="true"
                    onChange={(e) => handleFormChange(e)}
                  />
                  <span className="error-message">{formError.email}</span>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "right",
                  marginTop: "1rem",
                }}
              >
                <button
                  className="button"
                  name=""
                  // type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    handleSubmitFormOne();
                  }}
                >
                  Save and continue
                </button>
              </div>
            </div>
          )}
        </form>
       
      </RenderHome>
      <ToastContainer autoClose={3000} />
    </div>
  );
}

export default PersonalInfo;
