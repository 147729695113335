/* eslint-disable  */
import { IconButton } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { TbMessageCircle } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
import "../../../css/Pages/Pages.css";
import { userDataActionCreators } from "../../../service/action-creators";
import { GetUserProfile } from "../../../service/action-creators/authAction";
import { GetNotifications } from "../../../service/action-creators/notificationAction";
import Loader from "../../Loader";
import Modal from "../../Modal/Modal";
import NotFoundComponent from "../../NotFoundComponent";
import RenderHome from "../home/RenderHome";
import { ToastContainer, toast } from "react-toastify";

function MyOrdersNdPurchases() {
  const dispatch = useDispatch();
  const history = useHistory()
  const device = () => {
    return window.innerWidth < 1080;
  };

  const {
  
    GetMyOrders,
    DeleteMyAppliedOrder,
    GetCartProducts,
    ResetUserDataResponse,
  } = bindActionCreators(userDataActionCreators, dispatch);

  
  const orders = useSelector((state) => state.user?.orders);
  const isLoading = useSelector((state) => state.user?.isLoading);
  const response = useSelector((state) => state?.user?.response);
  const [statusAvailable, setStatusAvailable] = useState(false);
  const [openMessageModal, setOpenMessageModal] = useState(false);
  const [messageEditDetailed, setMessageEditDetailed] = useState("");
  const [isMobile, setIsMobile] = useState(device);

  console.log("orders", orders);
  console.log("statusAvailable", statusAvailable);
  useEffect(() => {
    // GetAppliedLoans();
    dispatch(GetUserProfile());
    GetMyOrders();
    dispatch(GetNotifications());
    GetCartProducts();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setIsMobile(device);
    });
    return 0;
  }, []);

  // useEffect(() => {
  //   if (myAppliedLoans?.length > 0) {
  //     setStatusAvailable(true);
  //   }
  // }, [myAppliedLoans, dispatch]);

  useEffect(() => {
    if (response?.state === "SUCCESS") {
      toast.success(response?.message)
      GetMyOrders();
      setTimeout(() => {
        ResetUserDataResponse();
      }, 1500);
    } else if (response?.state === "ERROR") {
      toast.error(response?.message)
      setTimeout(() => {
        ResetUserDataResponse();
      }, 1500);
    }
  }, [response?.state]);

  return (
    <div className="MyOrdersNdPurchases">
      <RenderHome>
        {isLoading ?? <Loader />}
      
        <div className="MyOrders">
          <h3 className="pageTitle">Orders</h3>
          {orders?.length > 0 ? (
            <div className="summary">
              {orders?.map((order, index) => {
                return (
                  <div key={index} className="item">
                    <div className="item1">
                      {/* <div className="flex">
                      <div>Amount Requested:</div>
                      <div>&#8373; {order?.orderAmount}</div>
                    </div> */}
                      {/* <div className="flex">
                      <div>Monthly Deduction:</div>
                      <div>&#8373; {order?.monthlyDeduction}</div>
                    </div> */}

                      {/* <div className="flex">
                      <div>Loan Term:</div>
                      <div>{order?.loanTerm} months</div>
                    </div> */}

                      <div className="status">
                        <div className="card">
                          <div className="status_name">Submitted</div>
                          {order?.submitted?.map((submit) => {
                            if (submit.status) {
                              return (
                                <>
                                  <div className="status_date">
                                    {moment(submit.date).format("MMM Do YY")}{" "}
                                  </div>
                                  <div
                                    className="status_tracker"
                                    style={{ border: "5px solid #01a94b" }}
                                  ></div>
                                </>
                              );
                            } else {
                              return (
                                <>
                                  <div className="status_date"> N/A</div>
                                  <div className="status_tracker"></div>
                                </>
                              );
                            }
                          })}
                        </div>

                        <div className="card">
                          <div className="status_name">Initiated</div>
                          {order?.initiated?.map((initiate) => {
                            if (initiate.status) {
                              return (
                                <>
                                  <div className="status_date">
                                    {moment(initiate.date).format("MMM Do YY")}{" "}
                                  </div>
                                  <div
                                    className="status_tracker"
                                    style={{ border: "5px solid #01a94b" }}
                                  ></div>
                                </>
                              );
                            } else {
                              return (
                                <>
                                  <div className="status_date"> N/A</div>
                                  <div className="status_tracker"></div>
                                </>
                              );
                            }
                          })}
                        </div>
                        <div className="card">
                          <div className="status_name">Approved</div>
                          {order?.approved?.map((approve, i) => {
                            if (approve.status) {
                              return (
                                <>
                                  <div key={i} className="status_date">
                                    {moment(approve.date).format("MMM Do YY")}{" "}
                                  </div>
                                  <div
                                    className="status_tracker"
                                    style={{ border: "5px solid #01a94b" }}
                                  ></div>
                                </>
                              );
                            } else {
                              return (
                                <>
                                  <div className="status_date"> N/A</div>
                                  <div className="status_tracker"></div>
                                </>
                              );
                            }
                          })}
                        </div>
                        <div className="card">
                          <div className="status_name">Shipped</div>
                          {order?.shipping?.map((ship) => {
                            if (ship.status) {
                              return (
                                <>
                                  <div className="status_date">
                                    {moment(ship.date).format("MMM Do YY")}{" "}
                                  </div>
                                  <div
                                    className="status_tracker"
                                    style={{ border: "5px solid #01a94b" }}
                                  ></div>
                                </>
                              );
                            } else {
                              return (
                                <>
                                  <div className="status_date"> N/A</div>
                                  <div className="status_tracker"></div>
                                </>
                              );
                            }
                          })}
                        </div>

                        {order?.status == "paid" ? (
                        ""
                      ) : (
                        <div
                          className="card"
                          onClick={() => {
                            order?.rejected?.map((rej) => {
                              if (rej.status) {
                                setMessageEditDetailed(order);
                                setOpenMessageModal(true);
                              }
                            });
                          }}
                        >
                          <div className="status_name">Rejected</div>
                          {order?.rejected?.map((reject) => {
                            if (reject.status) {
                              return (
                                <>
                                  <div className="readMessageContainer">
                                    <TbMessageCircle className="messageIcon" />
                                  </div>
                                  <div className="readMsg">Read message</div>
                                  <div className="status_date cursor-pointer">
                                    {moment(reject.date).format("MMM Do YY")}{" "}
                                  </div>
                                  <div
                                    className="status_tracker cursor-pointer"
                                    style={{ border: "5px solid red" }}
                                  ></div>
                                </>
                              );
                            } else {
                              return (
                                <>
                                  <div className="status_date"> N/A</div>
                                  <div className="status_tracker"></div>
                                </>
                              );
                            }
                          })}
                        </div>
                      )}

                    
                      </div>
                    </div>
                    <div className="deleteBtn">
                      {order?.status === "submitted" && (
                        <IconButton
                        
                          onClick={() => DeleteMyAppliedOrder(order?._id)}
                        >
                          <Delete color="secondary" />
                        </IconButton>
                      )}
                      {/* {order?.status === "rejected" && (
                        <IconButton
                        
                          onClick={() => DeleteMyAppliedOrder(order?._id)}
                        >
                          <Delete color="secondary" />
                        </IconButton>
                      )} */}
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <NotFoundComponent title={"No orders available"} />
          )}
        </div>

        {openMessageModal && (
          <Modal
            title={"Rejected Message"}
            width={isMobile ? "85%" : "35%"}
            closeModal={setOpenMessageModal}
          >
            <div className="modalBody">
              <div className="message">
                {/* {JSON.stringify(messageEditDetailed)} */}
                <p>{messageEditDetailed?.rejected[0]?.message}</p>
                {messageEditDetailed?.rejected[0]?.editFormChecked && (
                  <button
                    onClick={() =>
                      history.push({
                        pathname: "/edit-order-form",
                        state: { order_id: messageEditDetailed?._id },
                      })
                    }
                    type="button"
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 mt-4 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                  >
                    Click here to edit form
                  </button>
                )}
              </div>
            </div>
            <div className="modalButton">
              <div className="btnCover">
                {/* <button type="button" className="modalBtn" onClick={handleSubmit}>
                Change
              </button> */}

                <button
                  data-modal-toggle=""
                  type="button"
                  onClick={() => setOpenMessageModal(false)}
                  className="cancelBtn"
                >
                  Close
                </button>
              </div>
            </div>
          </Modal>
        )}
      </RenderHome>
      <ToastContainer autoClose={3000}/>
    </div>
  );
}

export default MyOrdersNdPurchases;
