import { quantityCounterConstants } from "../constants/quantityCounterConstants";

const quantityCounterReducer = (state = 1, action) => {
  switch (action.type) {
    case quantityCounterConstants.INCREASE_QUANTITY:
      return state + 1;
    case quantityCounterConstants.DECREASE_QUANTITY:
      return state - 1;
    default:
      return state;
  }
};

export default quantityCounterReducer;
