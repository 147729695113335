
/* eslint-disable  */
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast, ToastContainer } from 'react-toastify'
import { bindActionCreators } from 'redux'
import { authActionCreators } from '../../service/action-creators'
import Loader from '../Loader'
import { useHistory, useLocation,  } from 'react-router-dom'

function EmailVerify() {
    const dispatch = useDispatch()
    const history = useHistory()
    const {VerifyEmailAddress, ResetResponse} = bindActionCreators(authActionCreators, dispatch)
    const auth = useSelector(state => state?.auth)
    const isLoading = auth?.isLoading
    const response = auth?.response
    const query = new URLSearchParams(useLocation()?.search)

    // console.log('query', query.get('token'))

    const token = query?.get('token');

    useEffect(() => {
        VerifyEmailAddress(token)
    }, [token])

    useEffect(() => {
      if (response?.state === "SUCCESS") {
        toast.success(response?.message);
        setTimeout(() => {
          ResetResponse();
          history.push('/account/email-verify-success')
        }, 1500);
      } else if (response?.state === "ERROR") {
        toast.error(response?.message);
        setTimeout(() => {
          ResetResponse();
          history.push('/account/email-verify-error')
        }, 1500);
      }
    }, [response?.state]);
  return (
    <div>
        {isLoading && <Loader/>}
        <ToastContainer autoClose={3000}/>
    </div>
  )
}

export default EmailVerify