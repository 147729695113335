/* eslint-disable  */
import { Fab } from "@material-ui/core";
import { Cancel } from "@material-ui/icons";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { bindActionCreators } from "redux";
import {
  authActionCreators,
  userDataActionCreators,
} from "../../../../service/action-creators";
import Loader from "../../../Loader";
import RenderHome from "../../home/RenderHome";
import "./ApplyLoans.css";
import Loan_chip from "./components/Loan_chip";

function LoanTerms() {
  const dispatch = useDispatch();
  const history = useHistory();
  const signatureRef = useRef({});

  const {
    SubmitUpdateLoanFormOne,
    CancelLoanApplicationTemp,
    ResetUserDataResponse,
  } = bindActionCreators(userDataActionCreators, dispatch);
  const { GetUserProfile } = bindActionCreators(authActionCreators, dispatch);
  const [formState, setFormState] = useState(7);
  const [privacyChecked, setPrivacyChecked] = useState(false);

  const [formData, setFormData] = useState({});

  const response = useSelector((state) => state?.user?.response);
  const isLoading = useSelector((state) => state?.user?.isLoading);
  const auth = useSelector((state) => state?.auth);
  const user = auth?.user;
  const userData = useSelector((state) => state?.user);

  const { loanTemp } = userData;
  useEffect(() => {
    window.scrollTo(0,0)
   }, [])
  const handleCancelLoanApplication = () => {
    CancelLoanApplicationTemp(loanTemp?._id);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (!privacyChecked) {
      toast.error("Please read and accept terms first");
      return;
    } else {
      SubmitUpdateLoanFormOne(
        {
          ...formData,
          formCompleted: true,
          acceptTerms: privacyChecked,
          // submitted: {
          //   status: true,
          //   date: Date.now(),
          // }
        },
        loanTemp?._id
      );
      console.log(formData)
    }
  };

  useEffect(() => {
    GetUserProfile();
  }, []);

  useEffect(() => {
    if (response?.state === "SUCCESS") {
      toast.success(response?.message);
      history.push("/my-applied-loans");
      ResetUserDataResponse();

      // setTimeout(() => {
      //   ResetUserDataResponse();
      // }, 1500);
    } else if (response?.state === "ERROR") {
      toast.error(response?.message);
      setTimeout(() => {
        ResetUserDataResponse();
      }, 1500);
    } else if (response?.state === "DELETED") {
      toast.success(response?.message);
      history.push("/apply-loan-personal_info");

      setTimeout(() => {
        ResetUserDataResponse();
      }, 1500);
    }
  }, [response?.state, response?.message, response?.action]);

  return (
    <div className="ApplyLoans">
      <RenderHome title={"Apply Loan"}>
        {isLoading ?? <Loader />}
        {/* <hr /> */}
        {/* {loanTemp && (
          <div className="flex justify-end fixed right-0 mr-4">
            <Fab
              onClick={handleCancelLoanApplication}
              title="Cancel Application"
              className="cancelIcon"
              size="small"
              color="secondary"
              aria-label="add"
            >
              <Cancel />
            </Fab>
          </div>
        )} */}
        
        <Loan_chip formState={formState}/>

        <form action="">
          {formState  && (
            <div className="loanTerms">
              <h3 className="title">PART A - GENERAL TERMS AND CONDITIONS</h3>
              <p>Declaration and authorization given by applicant</p>
              <p>
                I{" "}
                <span
                  style={{
                    fontWeight: "bold",
                    textDecoration: "underline",
                  }}
                >
                  {user?.name}
                </span>{" "}
                (The applicant), declare and agree that, 1. The information I
                have provided in the application form is correct. 2. I have
                reviewed all the information on Loan agreement Form A; setting
                out the full details of my repayments if the Loan is granted and
                is the same as what I was shown on the chart before I signed
                this agreement. 3. I understand the terms of the Agreement as
                explained to me in English. 4. I have read the Agreement or it
                has been read to me. 5. Where the instalments are deducted from
                my salary, I acknowledge that I will not unilaterally cancel
                that deduction until the loan has been repaid for in full.
              </p>
              <p>
                I agree that by signing this declaration, I give UPNMG-FUND
                permission to: 1. Contract anyone to check that the information
                which I have given on the loan application Form A is correct. 2.
                Obtain details from any party about my financial status and
                banking details including a credit record and payment history 3.
                Given information about this loan to any party, including a
                Credit Bureau(s). 4. Assign its right tittle and interest herein
                to any party or entity nominated by UPNMG-FUND. 5. Report to the
                Ghana Police or similar organization any details where fraud
                is/or has been committed regard to this applicant. 6. Forward
                marketing materials and offerings of other UPNMG-FUND products
                to me.
              </p>
              <p>
                Application and approval: You apply for cash loan by completing
                and signing this document. Only when UPNMG-FUND approves the
                loan and gives it out to you will this document become a binding
                agreement between UPNMG-FUND and you. Payment and approval:
                After UPNMG-FUND has approved the application for a loan; the
                loan will be sent to you.
              </p>
              <p>
                Interest Rate: The interest charged on the loan will be at a
                fixed rate, calculated and amortized over the repayment period.
                Should you fail to make a payment on the due date or where
                grants you an extension for payment, the interest that accrues
                on the full outstanding amount will be capitalized monthly at
                the discretion of and interest will be charged of total amount
                then outstanding at the fixed percentage. Should it become
                necessary for institute legal action for the recovery of any
                loan, UPNMG-FUND shall be entitled to claim the entire amount
                outstanding at the time legal proceedings commences.
              </p>
              <p>
                Cooling off period: Once the loan has been given to you, this
                contract is in effect and you are obliged to pay the full
                contractual amount to UPNMG-FUND. Insurance: There is no
                insurance cover and in the instances of death, disability,
                retrenchment, dismissal, loss of employment etc. the borrower or
                his estate will be liable for the settlement of outstanding
                amount. Changes: This agreement will be the only agreement
                between you and changes must be effected in writing.
              </p>
              <p>
                Repayment: After the loan has been given to you according to the
                agreement between UPNMG-FUND and you, the full contractual
                amount set out in part A must be repaid in equal instalments as
                shown. Despite agreeing to have instalment deducted from your
                monthly salary, you remain responsible for making sure that the
                payments are paid to UPNMG-FUND on time. Repayments will be
                used firstly to pay legal costs (if any) and thereafter
                additional/penalty interest (if any), then the total cost of
                credit and lastly to reduce the balance of the outstanding
                amount.
              </p>
              <p>
                Credit Check: I consent by this Loan Application form, that
                UPNMG-FUND is entitled to use the service of a Credit Reference
                Bureau as part of the checks on my credit worthiness to
                determine my suitability to be granted a loan.
              </p>
              <p>
                Early Settlement Calculation: Early settlement Calculation will
                be at the discretion of UPNMG-FUND. The administration fee is a
                non-refundable fee and will not be discounted on early
                settlement.
              </p>
              <p>
                Default and Acceleration: UPNMG-FUND may immediately demand
                payment of the whole amount outstanding at any time if you: 1.
                Commit any breach of this agreement or become insolvent. 2. Die.
                3. Made a false representation at the time of applying for the
                loan. 4. Do anything that may prejudice UPNMG-FUND rights in
                terms of the Agreement. 5. Lose your employment at where
                employed at the time the loan was given out.
              </p>
              <p>
                When an applicant’s employment is terminated, UPNMG-FUND may
                deduct all amounts outstanding from the applicant’s benefit. By
                exercising its right to accelerate the repayment of the loan
                UPNMG-FUND will not lose or be limited in any way from
                exercising any other rights that the law may give it.
                Jurisdiction: You and UPNMG-FUND agree that this agreement is
                subject to the jurisdiction of the laws of Ghana.
              </p>
              <p>
                Addresses: All notice or letters that UPNMG-FUND may want or
                have to send to you will be sent to your address as reflected in
                Part A of this LOAN Application and Agreement Form. These will
                be deemed received by you within 3 working days after being sent
                by registered post. If you change your address, as stipulated in
                the LOAN Agreement and Applicant Form (Part A), you have to
                inform UPNMG-FUND in writing within 7 working days.
              </p>
              <p>
                Loan: in this agreement refers to any loan(s) UPNMG-FUND has
                agreed to give to you at an agreed fee paid over an agreed
                period of time.
              </p>
              <div className="payroll_instructions">
                <h3 className="title"> PART B - PAYROLL INSTRUCTION</h3>
                <p>
                  In pursuance of the conditions on which the loan was granted,
                  I hereby irrevocably instruct the Payroll Department of my
                  Employer at the date of signing this Agreement, deduct the
                  instalments as reflected in this Agreement form my
                  remuneration until the contractual amount has been repaid in
                  full. The instalment amount may be varied upon the request of
                  UPNMG-FUND in the event of a general increase or decrease in
                  the interest rates applicable to the loan, or where the
                  instalments are rescheduled as a result of default or other
                  arrangements. A variation as aforementioned will result in the
                  total contractual amount being adjusted accordingly. I
                  acknowledge that the loan would not have been granted to me
                  had my employer not conducted an agreement with UPNMG-FUND in
                  terms whereof my employer is contractually bound to make the
                  aforementioned deductions. Again, I further acknowledge that I
                  will pay for the loan in full or alert UPNMG-FUND in writing
                  if I have any misgiving concerning the transaction thereof.
                </p>
                <p>
                  Should my employment be terminated before the loan has been
                  paid for in full, I authorize my association (UPNMG) to deduct
                  the outstanding balance of the loan from all amounts that
                  become payable to me as a result of the termination of my
                  employment. I also commit to remain with the union till the
                  payment of my total loan amount is completed.
                </p>
              </div>
                <div className="" style={{display: 'flex', alignItems: 'center', justifyContent: 'start', gap: '10px'}}>
                <input
                type="checkbox"
                id="checkTermsBox"
                className="checkboxData"
                checked={privacyChecked}
                onChange={() => {
                  setPrivacyChecked(!privacyChecked);
                }}
              />
              <label className="cursor-pointer" htmlFor="checkTermsBox" style={{ fontWeight: "bold", position:'relative', top:'-8px',  }}>
                I have read and agreed to all terms and conditions.
              </label>
                </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "1rem",
                }}
              >
                <button
                  className="button_cancel cursor-pointer backBtn"
                  name=""
                  onClick={() => history.push("/apply-loan-preview")}
                >
                  Back
                </button>
                <button className="button" name="" onClick={handleFormSubmit}>
                  Submit Loan Form
                </button>
              </div>
            </div>
          )}
        </form>
      </RenderHome>
      <ToastContainer autoClose={3000} />
    </div>
  );
}

export default LoanTerms;
