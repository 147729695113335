/* eslint-disable  */
import React from "react";
import { useHistory } from "react-router-dom";
// import './Auth.css'
import './VerifyEmailSuccess.css'
function VerifyEmailSuccess() {
  const history = useHistory();
  return (
    <div className="VerifyEmailSuccessWrapper">
       <div
          className="side-1"
          style={{
            background:
              "url(/images/dev/background.jpg) no-repeat center center/cover",
          }}
        >
          <div className="overlay"></div>
        </div>

      <div className="side-2" style={{
        background: 'url(/images/dev/background.jpg) no-repeat center center/cover'
      }}>
        <div className="overlay">
        <img src="/images/dev/checked.png" alt="success" />
        <h2>Email verification success</h2>
        <p>Your email address had been verified. </p>
        <p>You can now login with your (<strong>email or staff id</strong>) and your updated <strong>password</strong> to login your member dashboard</p>
        <p>Thank you for joing UPNMG</p>
        <button
        className="btn"
          onClick={() => {
            history.push("/");
          }}
        >
          Home
        </button>
        </div>
      </div>
    </div>
  );
}

export default VerifyEmailSuccess;
