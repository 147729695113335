import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide } from '@material-ui/core';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import { ForgotPassword } from '../../service/action-creators/authAction';
import Loader from '../Loader';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide(props) {
const dispatch = useDispatch()
const auth = useSelector(state => state?.auth)
const isLoading = auth?.isLoading



const [formData, setFormData] = React.useState('')

  const handleChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value})
    
  };


  const handleSubmit = (e) => {
    e.preventDefault()
    if(formData === ""){
      toast.error('Please email field cannot be empty')
    }else{
      dispatch(ForgotPassword(formData))

    }
    setFormData(null)
    handleClose()
  };

  const handleClose = () => {
    props.close(false);
  };

  // React.useEffect(() =>{
  //   if(response?.state === "SUCCESS"){
  //     toast.success(response?.message)
  //     setTimeout(() => {
  //       dispatch(ResetResponse())
  //     },1500)
  //   }else if(response?.state === "ERROR"){
  //     toast.error(response?.message)
  //     setTimeout(() => {
  //       dispatch(ResetResponse())
  //     },1500)
  //   }
  // },[response?.state])

  return (
    <div>
     
      {isLoading && <Loader/>}
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Slide in alert dialog
      </Button> */}
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Forgot Your Password?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
          We'll email you instructions to reset your password
          </DialogContentText>
          <label htmlFor='email'>Email Address</label>
          <input type={'email'} placeholder={'example@gmail.com'} name="email" id='email' onChange={handleChange} className='form-control'/>
        </DialogContent>
        <DialogActions>
          <Button style={{background: 'var(--primaryColor)', color: 'white'}}  onClick={handleSubmit}>Continue </Button>
          <Button style={{background: 'var(--grayColor)', color: 'black'}} onClick={handleClose}>Cancel Reset</Button>
        </DialogActions>
      </Dialog>
      <ToastContainer autoClose={3000}/>
    </div>
  );
}
