/* eslint-disable  */
import {
  Fab,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { Cancel } from "@material-ui/icons";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { bindActionCreators } from "redux";
import {
  authActionCreators,
  userDataActionCreators,
} from "../../../../service/action-creators";
import Loader from "../../../Loader";
import RenderHome from "../../home/RenderHome";
import "./ApplyLoans.css";
import Loan_chip from "./components/Loan_chip";

function LoanDetailed() {
  const dispatch = useDispatch();
  const history = useHistory();
  const signatureRef = useRef({});

  const {
    SubmitUpdateLoanFormOne,
    ResetUserDataResponse,
    GetFormOneAppliedLoanTemp,
    CancelLoanApplicationTemp,
  } = bindActionCreators(userDataActionCreators, dispatch);

  const { GetUserProfile } = bindActionCreators(authActionCreators, dispatch);
  const [formState, setFormState] = useState(4);

  const [formData, setFormData] = useState({});
  const [loanAmount, setLoanAmount] = useState(0);
  const [affordability, seAffordability] = useState(0);
  const [loanTerm, setLoanTerm] = useState(null);
  const [monthlyDeduction, setMonthlyDeduction] = useState(0);
  const [formError, setFormError] = useState({});

  const response = useSelector((state) => state?.user?.response);
  const isLoading = useSelector((state) => state?.user?.isLoading);
  const auth = useSelector((state) => state?.auth);
  const user = auth?.user;
  const userData = useSelector((state) => state?.user);

  console.log("formData", formData);
  console.log("userData", userData);

  const { loanTemp } = userData;

  useEffect(() => {
    window.scrollTo(0,0)
   }, [])
   
  console.log("formState", formState);
  console.log("loanTerm", loanTerm);

  //   console.log("previewFrontCard", previewFrontCard);
  console.log("formData", formData);

  const handleFormChange = (e) => {
    e.preventDefault();
    setFormData({ ...formData, [e?.target?.name]: e?.target?.value });

    console.log("formData.loanAmount", formData.loanAmount);
  };

  const handleCancelLoanApplication = () => {
    CancelLoanApplicationTemp(loanTemp?._id);
  };
  const numberRegex = /^[0-9]+$/;

  const validateForm = () => {
    let err = {};
    if (formData.loanAmount === "" || !formData.loanAmount) {
      err.loanAmount = "Loan amount is required";
    }
    if (!numberRegex.test(formData.loanAmount)) {
      err.loanAmount = "Please only enter the amount to be received";
    }
    if (formData.affordability === "" || !formData.affordability) {
      err.affordability = "affordability is required";
    }
    // if (Number(monthlyDeduction) > Number(formData?.affordability)) {
    //   err.affordability = `Your afford is low to apply for a loan of ${formData.loanAmount} for ${formData.loanTerm} months. Loan will be rejected if wrong afford provided`;
    // }
    // if (Number(monthlyDeduction) > Number(loanTemp?.affordability)) {
    //   err.affordability = `Your afford is low to apply for a loan of ${loanTemp.loanAmount} for ${loanTemp.loanTerm} months. Loan will be rejected if wrong afford provided`;
    // }
    if (formData.loanTerm === "" || !formData.loanTerm) {
      err.loanTerm = "Loan term is required";
    }
    if (formData.mandateNumber === "" || !formData.mandateNumber) {
      err.mandateNumber = "Mandate number is required";
    }
    if (formData.otpNumber === "" || !formData.otpNumber) {
      err.otpNumber = "OTP is required";
    }
    if (formData.purposeForLoan === "" || !formData.purposeForLoan) {
      err.purposeForLoan = "Loan purpose is required";
    }
    if (
      formData.bank_account_holder_name === "" ||
      !formData.bank_account_holder_name
    ) {
      err.bank_account_holder_name = "Account holder name is required";
    }
    if (formData.bank_account_number === "" || !formData.bank_account_number) {
      err.bank_account_number = "Account number is required";
    }
    if (formData.bank_name === "" || !formData.bank_name) {
      err.bank_name = "Name of bank is required";
    }
    if (formData.bank_branch === "" || !formData.bank_branch) {
      err.bank_branch = "Bank branch is required";
    }

    setFormError({ ...err });
    return Object.keys(err).length < 1;
  };

  // const FEE_CHARGE = 15;
  // const FEE_CHARGE = 15;
  const INTEREST_RATE = 11 / 100; //11%

  useEffect(() => {
    if (Number(formData.loanAmount) && Number(formData?.affordability) && Number(formData?.loanTerm)) {
      const FEE_CHARGE = (3 / 100) * Number(formData.loanAmount);
   

      let convertedDuration = 0;
      if (Number(formData.loanTerm) === 18) {
        convertedDuration = 1.5;
      } else if (Number(formData.loanTerm) === 24) {
        convertedDuration = 2;
      } else if (Number(formData.loanTerm) === 36) {
        convertedDuration = 3;
      } else if (
        Number(formData.loanTerm) == 1 ||
        Number(formData.loanTerm) == 3 ||
        Number(formData.loanTerm) == 6 ||
        Number(formData.loanTerm) == 12
      ) {
        convertedDuration = 1;
      }

      console.log("FEE_CHARGE", FEE_CHARGE);
      console.log("INTEREST_RATE", INTEREST_RATE);

      const loanPeriod = (Number(convertedDuration) * INTEREST_RATE) + 1;
      console.log("loanPeriod", loanPeriod);


      const calc = Number(formData.loanAmount) * loanPeriod;
      console.log("calc", calc);

      const initialAmount = calc + FEE_CHARGE;
      console.log("initialAmount", initialAmount);

      const monthlyDed = initialAmount / Number(formData.loanTerm);
      console.log("monthlyDed", monthlyDed);

      // const totalCollectable = initialAmount;

      setMonthlyDeduction(Number(monthlyDed).toFixed(2));

    }
  }, [formData.loanTerm, formData.loanAmount, formData?.affordability]);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    let isValid = validateForm();
    if (isValid) {
      SubmitUpdateLoanFormOne(
        {
          ...formData,
          monthlyDeduction: monthlyDeduction,
        },
        loanTemp?._id
      );
      // if(Number(monthlyDeduction) > Number(formData?.affordability)){
      //   toast.error(`Your afford is low to apply for a loan of ${formData.loanAmount}. Your loan will be rejected if wrong afford provided`);
      // }else{
      //   SubmitUpdateLoanFormOne(
      //     {
      //       ...formData,
      //       monthlyDeduction: monthlyDeduction,
      //     },
      //     loanTemp?._id
      //   );

      // }
    }
  };

  useEffect(() => {
    GetUserProfile();
    GetFormOneAppliedLoanTemp();
    setFormData({
      ...formData,
      loanAmount: loanTemp?.loanAmount,
      monthlyDeduction: loanTemp?.monthlyDeduction,
      loanTerm: loanTemp?.loanTerm,
      affordability: loanTemp?.affordability,
      mandateNumber: loanTemp?.mandateNumber,
      otpNumber: loanTemp?.otpNumber,
      purposeForLoan: loanTemp?.purposeForLoan,
      bank_account_holder_name: loanTemp?.bank_account_holder_name,
      bank_account_number: loanTemp?.bank_account_number,
      bank_branch: loanTemp?.bank_branch,
      bank_name: loanTemp?.bank_name,
    });
  }, []);

  useEffect(() => {
    setFormData({
      ...formData,
      loanAmount: loanTemp?.loanAmount,
      monthlyDeduction: loanTemp?.monthlyDeduction,
      loanTerm: loanTemp?.loanTerm,
      affordability: loanTemp?.affordability,
      mandateNumber: loanTemp?.mandateNumber,
      otpNumber: loanTemp?.otpNumber,
      purposeForLoan: loanTemp?.purposeForLoan,
      bank_account_holder_name: loanTemp?.bank_account_holder_name,
      bank_account_number: loanTemp?.bank_account_number,
      bank_branch: loanTemp?.bank_branch,
      bank_name: loanTemp?.bank_name,
    });
  }, [loanTemp]);

  useEffect(() => {
    if (response?.state === "SUCCESS") {
      toast.success(response?.message);
      ResetUserDataResponse();
      history.push("/apply-loan-uploads");
      // setTimeout(() => {
      //   ResetUserDataResponse();
      // }, 1500);
    } else if (response?.state === "ERROR") {
      toast.error(response?.message);
      setTimeout(() => {
        ResetUserDataResponse();
      }, 1500);
    } else if (response?.state === "DELETED") {
      toast.success(response?.message);
      history.push("/apply-loan-personal_info");

      setTimeout(() => {
        ResetUserDataResponse();
      }, 1500);
    }
  }, [response?.state, response?.message, response?.action]);

  return (
    <div className="ApplyLoans">
      <RenderHome title={"Apply Loan"}>
        {isLoading ?? <Loader />}
        {/* <hr /> */}
        {/* {loanTemp && (
          <div className="flex justify-end fixed right-0 mr-4">
            <Fab
              onClick={handleCancelLoanApplication}
              title="Cancel Application"
              className="cancelIcon"
              size="small"
              color="secondary"
              aria-label="add"
            >
              <Cancel />
            </Fab>
          </div>
        )}
         */}
        <Loan_chip formState={formState}/>

        <form action="">
          {formState && (
            <div className="form_area form3">
              <div className="form-group row">
                <h3 className="formTitle">
                  Loan (please provide your loan details)
                </h3>

                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-6">
                  <label htmlFor="loanAmount">
                    Loan Amount &#8373;
                    <span className="bto-lb-required">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="number"
                    name="loanAmount"
                    id="loanAmount"
                    defaultValue={
                      formData?.loanAmount ||
                      (loanTemp?.loanAmount ?? loanTemp?.loanAmount)
                    }
                    required
                    placeholder=""
                    aria-required="true"
                    onChange={(e) => handleFormChange(e)}
                  />
                  <span className="error-message">{formError.loanAmount}</span>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-6">
                  <label htmlFor="affordability">
                    Remaining Afordability &#8373;
                    <span className="bto-lb-required">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="number"
                    name="affordability"
                    defaultValue={
                      formData?.affordability ||
                      (loanTemp?.affordability ?? loanTemp?.affordability)
                    }
                    id="affordability"
                    required
                    aria-required="true"
                    onChange={(e) => {
                      handleFormChange(e);
                    }}
                  />
                  <span className="error-message">
                    {formError.affordability}
                  </span>
                </div>
              </div>

              <div className="form-group row">
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                  <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      Loan Terms (Months)
                      <span className="bto-lb-required">*</span>
                      {loanTemp?.loanTerm !== "" && <span className="ml-4"> selected: <span className="bg-green-500 text-white px-3 py-1 rounded-md">{loanTemp?.loanTerm ?? loanTerm} mths</span></span>}
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      className="flex justify-evenly"
                    >
                      <FormControlLabel
                        value="6"
                        control={<Radio />}
                        label="6"
                        name="loanTerm"
                        defaultChecked={
                          loanTemp?.loanTerm === "6" ? true : false
                        }
                        onChange={(e) => {
                          handleFormChange(e);
                          setLoanTerm(e.target.value);
                        }}
                      />
                      <FormControlLabel
                        value="12"
                        control={<Radio />}
                        label="12"
                        defaultChecked={
                          loanTemp?.loanTerm === "12" ? true : false
                        }
                        name="loanTerm"
                        onChange={(e) => {
                          handleFormChange(e);
                          setLoanTerm(e.target.value);
                        }}
                      />
                      <FormControlLabel
                        value="18"
                        control={<Radio />}
                        label="18"
                        defaultChecked={
                          loanTemp?.loanTerm === "18" ? true : false
                        }
                        name="loanTerm"
                        onChange={(e) => {
                          handleFormChange(e);
                          setLoanTerm(e.target.value);
                        }}
                      />
                      <FormControlLabel
                        value="24"
                        control={<Radio />}
                        label="24"
                        name="loanTerm"
                        defaultChecked={
                          loanTemp?.loanTerm === "24" ? true : false
                        }
                        onChange={(e) => {
                          handleFormChange(e);
                          setLoanTerm(e.target.value);
                        }}
                      />

                      <FormControlLabel
                        value="36"
                        // disabled
                        name="loanTerm"
                        control={<Radio />}
                        label="36"
                        defaultChecked={
                          loanTemp?.loanTerm === "36" ? true : false
                        }
                        onChange={(e) => {
                          handleFormChange(e);
                          setLoanTerm(e.target.value);
                        }}
                      />
                    </RadioGroup>
                  </FormControl>
                  <span className="error-message">{formError.loanTerm}</span>
                </div>

                <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2"></div>
                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                  <label htmlFor="monthlyDeduction">
                    Monthly Deduction &#8373;
                    <span className="bto-lb-required">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    name="monthlyDeduction"
                    id="monthlyDeduction"
                    required
                    value={monthlyDeduction}
                    readOnly
                    aria-required="true"
                    onChange={(e) => {
                      handleFormChange(e);
                      setLoanAmount(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                  <label htmlFor="loantotalCollectable">
                    Mandate Number
                    <span className="bto-lb-required">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    name="mandateNumber"
                    id="mandateNumber"
                    defaultValue={
                      formData.mandateNumber ||
                      (loanTemp?.mandateNumber ?? loanTemp?.mandateNumber)
                    }
                    required
                    aria-required="true"
                    onChange={(e) => handleFormChange(e)}
                  />
                  <span className="error-message">
                    {formError.mandateNumber}
                  </span>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                  <label htmlFor="otpNumber">
                    OTP Number
                    <span className="bto-lb-required">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="number"
                    name="otpNumber"
                    id="otpNumber"
                    defaultValue={
                      formData.otpNumber ||
                      (loanTemp?.otpNumber ?? loanTemp?.otpNumber)
                    }
                    required
                    aria-required="true"
                    onChange={(e) => handleFormChange(e)}
                  />
                  <span className="error-message">{formError.otpNumber}</span>
                </div>

                {/* Drop purpose of loan here */}
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                  <label htmlFor="purposeForLoan">
                    Purpose of loan
                    <span className="bto-lb-required">*</span>
                  </label>
                  <textarea
                    className="form-control"
                    name="purposeForLoan"
                    defaultValue={
                      formData?.purposeForLoan ||
                      (loanTemp?.purposeForLoan ?? loanTemp?.purposeForLoan)
                    }
                    required
                    id="purposeForLoan"
                    onChange={(e) => handleFormChange(e)}
                  ></textarea>
                   <span className="error-message">
                    {formError.purposeForLoan}
                  </span>
                </div>
              </div>
              <div className="form-group">
                <>
                  <div className="form-group row">
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                      <label htmlFor="bank_account_holder_name">
                        Name of Account Holder{" "}
                        <span className="bto-lb-required">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        name="bank_account_holder_name"
                        id="bank_account_holder_name"
                        defaultValue={
                          formData?.bank_account_holder_name ||
                          (loanTemp?.bank_account_holder_name ??
                            loanTemp?.bank_account_holder_name)
                        }
                        required
                        aria-required="true"
                        onChange={(e) => handleFormChange(e)}
                      />
                      <span className="error-message">
                        {formError.bank_account_holder_name}
                      </span>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                      <label htmlFor="bank_name">
                        Name of Bank <span className="bto-lb-required">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        name="bank_name"
                        defaultValue={
                          formData?.bank_name ||
                          (loanTemp?.bank_name ?? loanTemp?.bank_name)
                        }
                        required
                        id="bank_name"
                        aria-required="true"
                        onChange={(e) => handleFormChange(e)}
                      />
                      <span className="error-message">
                        {formError.bank_name}
                      </span>
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                      <label htmlFor="bank_account_number">
                        Account Number{" "}
                        <span className="bto-lb-required">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        name="bank_account_number"
                        id="bank_account_number"
                        defaultValue={
                          formData.bank_account_number ||
                          (loanTemp?.bank_account_number ??
                            loanTemp?.bank_account_number)
                        }
                        required
                        aria-required="true"
                        onChange={(e) => handleFormChange(e)}
                      />
                      <span className="error-message">
                        {formError.bank_account_number}
                      </span>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                      <label htmlFor="bank_branch">
                        Branch <span className="bto-lb-required">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        name="bank_branch"
                        defaultValue={
                          formData.bank_branch ||
                          (loanTemp?.bank_branch ?? loanTemp?.bank_branch)
                        }
                        required
                        id="bank_branch"
                        aria-required="true"
                        onChange={(e) => handleFormChange(e)}
                      />
                      <span className="error-message">
                        {formError.bank_branch}
                      </span>
                    </div>
                  </div>
                </>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "1rem",
                }}
              >
                <button
                  className="button_cancel cursor-pointer backBtn"
                  onClick={() => history.push("/apply-loan-employment")}
                >
                  Back
                </button>
                <button
                  className="button"
                  name=""
                  onClick={(e) => {
                    e.preventDefault();
                    handleFormSubmit(e);
                  }}
                >
                  Save and continue
                </button>
              </div>
            </div>
          )}
        </form>
      </RenderHome>
      <ToastContainer autoClose={3000} />
    </div>
  );
}

export default LoanDetailed;
