import React from 'react'
import RenderHome from '../home/RenderHome'

function Support() {
  return (
    <div className='Support'>
        <RenderHome title={""}>
            <div className='ticket mt-10' >
                <h3>Open Ticket</h3>
                <select className='form-control form-group' style={{marginBottom: '1rem'}}>
                    <option>Technical</option>
                    <option>Loans</option>
                    <option>Hire purchase</option>
                    <option>Other</option>
                </select>
                <textarea className='form-control' placeholder='Write your message here'></textarea>
                <div style={{
                    display: 'flex',
                    justifyContent: 'right',
                    marginTop: '1rem'
                }}>
                <button className='button'>Send messgae</button>
                </div>

                <div className='my-ticket flex items-center bg-white px-3 py-3 rounded-sm mt-4'>
                    <div className='flex-1'>
                    <h4 className='text-back font-bold'>Technical Issue</h4>
                    <p className='pr-20 text-gray-500'> <span>Me:</span>  <span>By using a DocumeD elements we can do this without having to worry about opening and closing tags for data that doesn't divide evenly by the number of row elements.</span></p>
                    </div>
                    <div className=''>
                        <p className='text-gray-500'> Remove</p>
                    </div>
                </div>
            </div>
        </RenderHome>
    </div>
  )
}

export default Support