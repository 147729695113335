/* eslint-disable  */
import {
    Fab,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
  } from "@material-ui/core";
  import { Cancel } from "@material-ui/icons";
  import React, { useEffect, useState, useRef } from "react";
  import { useDispatch, useSelector } from "react-redux";
  import { useHistory } from "react-router-dom";
  import { toast, ToastContainer } from "react-toastify";
  import { bindActionCreators } from "redux";
  import {
    authActionCreators,
    userDataActionCreators,
  } from "../../../../service/action-creators";
  import Loader from "../../../Loader";
  import RenderHome from "../../home/RenderHome";
  import "./ApplyLoans.css";
import Loan_chip from "./components/Loan_chip";
  
  function Employment() {
    const dispatch = useDispatch();
    const history = useHistory();
    const signatureRef = useRef({});
  
    const {
      SubmitLoanFormOne,
      SubmitUpdateLoanFormOne,
      ResetUserDataResponse,
      GetFormOneAppliedLoanTemp,
      CancelLoanApplicationTemp,
    } = bindActionCreators(userDataActionCreators, dispatch);
  
    const { GetUserProfile } = bindActionCreators(authActionCreators, dispatch);
    const [formState, setFormState] = useState(3);
    const [previewFrontCard, setPreviewFrontCard] = useState(null);
    const [previewBackCard, setPreviewBackCard] = useState(null);
    const [privacyChecked, setPrivacyChecked] = useState(false);
  
    const [formData, setFormData] = useState({});
    const [loanAmount, setLoanAmount] = useState(0);
    const [affordability, seAffordability] = useState(0);
    const [loanTerm, setLoanTerm] = useState(null);
    const [monthlyDeduction, setMonthlyDeduction] = useState(0);
    const [age, setAge] = useState(null);
    const [dateOfBirth, setDateOfBirth] = useState(null);
  
    const [formError, setFormError] = useState({})
  
    const response = useSelector((state) => state?.user?.response);
    const isLoading = useSelector((state) => state?.user?.isLoading);
    const auth = useSelector((state) => state?.auth);
    const user = auth?.user;
    const userData = useSelector((state) => state?.user);
  
    console.log("formData", formData);
    console.log("userData", userData);
  
    const { loanTemp } = userData;
  
    useEffect(() => {
      window.scrollTo(0,0)
     }, [])
     
    //   console.log("previewFrontCard", previewFrontCard);
    console.log("formData", formData);
  
    const handleFormChange = (e) => {
      e.preventDefault();
      setFormData({ ...formData, [e?.target?.name]: e?.target?.value });
    };
  
    const handleCancelLoanApplication = () => {
      CancelLoanApplicationTemp(loanTemp?._id);
    };
  
  
    const validateForm = () => {
      let err = {}
  
      if(formData.employment_start_date === '' || !formData.employment_start_date ){
        err.employment_start_date =  'Employment start date is required'
      }

  
      setFormError({...err})
      return Object.keys(err).length < 1
  
    }
    
  
    const handleFormSubmitHandler = (e) => {
      e.preventDefault();
      
        console.log("formData sub", formData);
     
        let isValid = validateForm()
        if(isValid){
          SubmitUpdateLoanFormOne({
            ...formData,
           
          }, loanTemp?._id);
          console.log('formData', formData)
        }
  
      
     
    };
  
    const employer_name = user?.facility?.split(" ")[1].split(":")[0];
    useEffect(() => {
      GetUserProfile();
      GetFormOneAppliedLoanTemp()
  
      setFormData({
        ...formData,
        staff_id: user?.staff_id,
        facility: user?.facility,
        employer_name: employer_name,
  
        employment_start_date: loanTemp?.employment_start_date,
      });
    }, []);
  
    useEffect(() => {
      setFormData({
        ...formData,
        staff_id: user?.staff_id,
        facility: user?.facility,
        employer_name: employer_name,
  
        employment_start_date: loanTemp?.employment_start_date,
      });
    }, [loanTemp])
  
    
  
    useEffect(() => {
      if (response?.state === "SUCCESS") {
        toast.success(response?.message);
        ResetUserDataResponse();
        history.push("/apply-loan-loan_detailed");
        // setTimeout(() => {
        //   ResetUserDataResponse();
        // }, 1500);
      } else if (response?.state === "ERROR") {
        toast.error(response?.message);
        setTimeout(() => {
          ResetUserDataResponse();
        }, 1500);
      }else if (response?.state === "DELETED") {
        toast.success(response?.message);
        history.push("/apply-loan-personal_info");
  
        setTimeout(() => {
          ResetUserDataResponse();
        }, 1500);
      }
    }, [response?.state, response?.message, response?.action]);
  
    return (
      <div className="ApplyLoans">
        <RenderHome title={"Apply Loan"}>
          {isLoading ?? <Loader />}
          {/* <hr /> */}
          {/* {loanTemp && (
            <div className="flex justify-end fixed right-0 mr-4">
              <Fab
                onClick={handleCancelLoanApplication}
                title="Cancel Application"
                className="cancelIcon"
                size="small"
                color="primary"
                aria-label="add"
              >
                <Cancel />
              </Fab>
            </div>
          )} */}
          <Loan_chip formState={formState} />
  
          <form action="">
            {formState && (
              <div className="form_area form2">
                {/* THE CONTACT DETAILS SECTION */}
  
                <div className="form-group row">
                  <h3 className="formTitle">Employment Details</h3>
                  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <label>
                      Employer<span className="bto-lb-required">*</span>
                    </label>
  
                    <select
                      className="form-control"
                      type="text"
                      name="employer_name"
                      aria-required="true"
                      // onChange={(e) => handleFormChange(e)}
                    >
                      <option>{employer_name}</option>
                      {/* <option value={"MOH"}>MOH</option>
                        <option value={"GHS"}>GHS</option>
                        <option value={"CHAG"}>CHAG</option> */}
                    </select>
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <label>
                      Facility<span className="bto-lb-required">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      name="facility"
                      required
                      readOnly
                      value={user?.facility}
                      aria-required="true"
                      onChange={(e) => handleFormChange(e)}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-12">
                    <label>
                      Staff ID<span className="bto-lb-required">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      name="staff_id"
                      readOnly
                      value={user?.staff_id}
                      required
                      aria-required="true"
                      onChange={(e) => handleFormChange(e)}
                    />
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-12">
                    <label>
                      Employment Start Date
                      <span className="bto-lb-required">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="date"
                      name="employment_start_date"
                      defaultValue={formData?.employment_start_date || (loanTemp?.employment_start_date ?? loanTemp?.employment_start_date)}
                      required
                      aria-required="true"
                      onChange={(e) => handleFormChange(e)}
                    />
                    <span className="error-message">
                    {formError.employment_start_date}
                  </span>
                  </div>
                </div>
  
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "1rem",
                  }}
                >
                  <div
                    className="button_cancel cursor-pointer backBtn"
                    onClick={() =>  history.push("/apply-loan-guarantor")}
                  >
                    Back
                  </div>
                  <button
                    className="button"
                    // type="button"
  
                    onClick={(e) => {
                      e.preventDefault();
                      handleFormSubmitHandler(e)
                   
                    }}
                  >
                     Save and continue
                  </button>
                </div>
              </div>
            )}
          </form>
        </RenderHome>
        <ToastContainer autoClose={3000} />
      </div>
    );
  }
  
  export default Employment;
  