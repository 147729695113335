/* eslint-disable  */
import {
  Fab
} from "@material-ui/core";
import { Cancel } from "@material-ui/icons";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { bindActionCreators } from "redux";
import {
  authActionCreators,
  userDataActionCreators,
} from "../../../../service/action-creators";
import Loader from "../../../Loader";
import RenderHome from "../../home/RenderHome";
import "./ApplyLoans.css";
import Loan_chip from "./components/Loan_chip";

function Preview() {
  const dispatch = useDispatch();
  const history = useHistory();
  const signatureRef = useRef({});

  const {
    GetFormOneAppliedLoanTemp,
    ResetUserDataResponse,
    CancelLoanApplicationTemp,
  } = bindActionCreators(userDataActionCreators, dispatch);
  const { GetUserProfile } = bindActionCreators(authActionCreators, dispatch);
  const [formState, setFormState] = useState(6);
  const [previewFrontCard, setPreviewFrontCard] = useState(null);
  const [previewBackCard, setPreviewBackCard] = useState(null);
  const [privacyChecked, setPrivacyChecked] = useState(false);

  const [formData, setFormData] = useState({});
  const [loanAmount, setLoanAmount] = useState(0);
  const [affordability, seAffordability] = useState(0);
  const [loanTerm, setLoanTerm] = useState(null);
  const [monthlyDeduction, setMonthlyDeduction] = useState(0);
  const [age, setAge] = useState(null);
  const [dateOfBirth, setDateOfBirth] = useState(null);

  const response = useSelector((state) => state?.user?.response);
  const isLoading = useSelector((state) => state?.user?.isLoading);
  const auth = useSelector((state) => state?.auth);
  const user = auth?.user;
  const userData = useSelector((state) => state?.user);

  console.log("userData", userData);
  console.log("formData", formData);

  const { loanTemp } = userData;
  useEffect(() => {
    window.scrollTo(0,0)
   }, [])
  const handleCancelLoanApplication = () => {
    CancelLoanApplicationTemp(loanTemp?._id);
  };

  useEffect(() => {
    GetUserProfile();
    GetFormOneAppliedLoanTemp();

    // setFormData({
    //   ...formData,
    //   staff_id: user?.staff_id,
    //   facility: user?.facility,
    //   gender: gender,
    //   fullname: user?.name,
    //   IdCardType: "Ghana National Identification",
    //   employer_name: employer_name,
    // });
  }, []);
  // useEffect(() => {
  //   GetUserProfile();
  //   GetFormOneAppliedLoanTemp()
  //   // setFormData({
  //   //   ...formData,
  //   //   staff_id: user?.staff_id,
  //   //   facility: user?.facility,
  //   //   gender: gender,
  //   //   fullname: user?.name,
  //   //   IdCardType: "Ghana National Identification",
  //   //   employer_name: employer_name,
  //   // });
  // }, [loanTemp]);

  useEffect(() => {
    if (response?.state === "SUCCESS") {
      toast.success(response?.message);
      ResetUserDataResponse();
      // history.push("/my-orders-and-purchase");

      // setTimeout(() => {
      //   ResetUserDataResponse();
      // }, 1500);
    } else if (response?.state === "ERROR") {
      toast.error(response?.message);
      setTimeout(() => {
        ResetUserDataResponse();
      }, 1500);
    } else if (response?.state === "DELETED") {
      toast.success(response?.message);
      history.push("/apply-loan-personal_info");

      setTimeout(() => {
        ResetUserDataResponse();
      }, 1500);
    }
  }, [response?.state, response?.message, response?.action]);

  return (
    <div className="ApplyLoans">
      <RenderHome title={"Apply Loan"}>
        {isLoading ?? <Loader />}
        {/* <hr /> */}
        {/* {loanTemp && (
          <div className="flex justify-end fixed right-0 mr-4">
            <Fab
              onClick={handleCancelLoanApplication}
              title="Cancel Application"
              className="cancelIcon"
              size="small"
              color="primary"
              aria-label="add"
            >
              <Cancel />
            </Fab>
          </div>
        )} */}
        <Loan_chip formState={formState} />

        <form action="">
          {formState && (
            <div>
              <h3>Preview loan application</h3>
              <div className="form_area form1">
                <h3 className="formTitle">Personal Information</h3>
                <div className="form-group row">
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <label htmlFor="prFirst">Full Name</label>
                    <input
                      className="form-control"
                      type="text"
                      readOnly
                      defaultValue={user?.name}
                      id="prFirst"
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                    <label htmlFor="">Date of Birth</label>
                    <input
                      className="form-control"
                      type="text"
                      readOnly
                      defaultValue={
                        dateOfBirth ||
                        (loanTemp?.dateOfBirth ?? loanTemp?.dateOfBirth)
                      }
                    />
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                    <label htmlFor="age">Age</label>
                    <input
                      className="form-control"
                      type="text"
                      readOnly
                      defaultValue={
                        formData?.age || (loanTemp?.age ?? loanTemp?.age)
                      }
                    />
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                    <label htmlFor="">SSNIT</label>
                    <input
                      className="form-control"
                      type="text"
                      readOnly
                      defaultValue={
                        formData?.ssnit || (loanTemp?.ssnit ?? loanTemp?.ssnit)
                      }
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                    <label htmlFor="">ID Type</label>
                    <input
                      className="form-control"
                      type="text"
                      readOnly
                      defaultValue={
                        formData?.IdCardType ||
                        (loanTemp?.IdCardType ?? loanTemp?.IdCardType)
                      }
                    />
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                    <label htmlFor="">ID Number</label>
                    <input
                      className="form-control"
                      type="text"
                      readOnly
                      defaultValue={
                        loanTemp?.IDCardNumber ?? loanTemp?.IDCardNumber
                      }
                    />
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                    <label htmlFor="">Gender</label>
                    <input
                      className="form-control"
                      type="text"
                      readOnly
                      defaultValue={
                        user?.name?.split(" ")[0] === "Mr." ? "Male" : "Female"
                      }
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                    <label>Residential Address</label>
                    {/* <FormInput name={'res_address1'} type={'text'} value={''}/> */}
                    <input
                      className="form-control"
                      type="text"
                      readOnly
                      defaultValue={
                        loanTemp?.residential_address1 ??
                        loanTemp?.residential_address1
                      }
                    />
                    <input
                      className="form-control"
                      type="text"
                      readOnly
                      defaultValue={
                        loanTemp?.residential_address2 ??
                        loanTemp?.residential_address2
                      }
                    />
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                    <label htmlFor="">Work Address</label>
                    <input
                      className="form-control"
                      type="text"
                      readOnly
                      defaultValue={
                        loanTemp?.work_address1 ?? loanTemp?.work_address1
                      }
                    />
                    <input
                      className="form-control"
                      type="text"
                      readOnly
                      defaultValue={
                        loanTemp?.work_address2 ?? loanTemp?.work_address2
                      }
                    />
                  </div>
                </div>

                {/* THE CONTACT DETAILS SECTION */}
                <h3 className="formTitle">Contact Details</h3>

                <div className="form-group row">
                  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <label>Phone Number</label>
                    <input
                      className="form-control"
                      type="text"
                      readOnly
                      defaultValue={
                        loanTemp?.personal_phone ?? loanTemp?.personal_phone
                      }
                    />
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <label>Work Phone</label>
                    <input
                      className="form-control"
                      type="text"
                      readOnly
                      defaultValue={
                        loanTemp?.work_phone ?? loanTemp?.work_phone
                      }
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <label>Email Address</label>
                    <input
                      className="form-control"
                      type="text"
                      readOnly
                      defaultValue={loanTemp?.email ?? loanTemp?.email}
                    />
                  </div>
                </div>
              </div>

              <div className="form_area form2">
                <div className="form-group row">
                  <h3 className="formTitle">Guarantors 1</h3>
                  <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                    <label>Name</label>
                    <input
                      className="form-control"
                      type="text"
                      readOnly
                      name="guarantor_name"
                      defaultValue={
                        loanTemp?.guarantor_name ?? loanTemp?.guarantor_name
                      }
                      aria-required="true"
                    />
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                    <label>Staff ID</label>
                    <input
                      className="form-control"
                      type="text"
                      name="guarantor_staff_id"
                      defaultValue={
                        loanTemp?.guarantor_staff_id ??
                        loanTemp?.guarantor_staff_id
                      }
                      readOnly
                    />
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                    <label>Phone Number</label>
                    <input
                      className="form-control"
                      type="text"
                      name="guarantor_phone"
                      placeholder=""
                      defaultValue={
                        loanTemp?.guarantor_phone ?? loanTemp?.guarantor_phone
                      }
                     readOnly
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                    <label>Ghana Card Number</label>
                    <input
                      className="form-control"
                      type="text"
                      readOnly
                      name="guarantor_ghana_card"
                      defaultValue={
                        loanTemp?.guarantor_ghana_card ??
                        loanTemp?.guarantor_ghana_card
                      }
                      aria-required="true"
                    />
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                    <label>GPS Address</label>
                    <input
                      className="form-control"
                      type="text"
                      name="guarantor_gps"
                      readOnly
                      defaultValue={
                        loanTemp?.guarantor_gps ?? loanTemp?.guarantor_gps
                      }
                      aria-required="true"
                    />
                  </div>

                  <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                    <label>Occupation</label>
                    <input
                      className="form-control"
                      type="text"
                      readOnly
                      name="guarantor_occupation"
                      defaultValue={
                        loanTemp?.guarantor_occupation ??
                        loanTemp?.guarantor_occupation
                      }
                      aria-required="true"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                    <label>Mandate Number</label>
                    <input
                      className="form-control"
                      type="text"
                      name="guarantor_mandate_number"
                      defaultValue={
                        loanTemp?.guarantor_mandate_number ??
                        loanTemp?.guarantor_mandate_number
                      }
                      readOnly
                      aria-required="true"
                      onChange={(e) => handleFormChange(e)}
                    />
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                    <label>
                      OTP Number
                    </label>
                    <input
                      className="form-control"
                      type="number"
                      readOnly
                      name="guarantor_otp_number"
                      defaultValue={
                        loanTemp?.guarantor_otp_number ??
                        loanTemp?.guarantor_otp_number
                      }
                      
                    />
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                    <label>Relationship</label>
                    <input
                      className="form-control"
                      type="text"
                      readOnly
                      name="guarantor_relationship"
                      defaultValue={
                        loanTemp?.guarantor_relationship ??
                        loanTemp?.guarantor_relationship
                      }
                      aria-required="true"
                      onChange={(e) => handleFormChange(e)}
                    />
                  </div>
                </div>
                {/* SECOND GUARANTOR SECTION */}
                <div className="form-group row">
                  <h3 className="formTitle">Guarantors 2</h3>
                  <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                    <label>Name</label>
                    <input
                      className="form-control"
                      type="text"
                      name="guarantor_name2"
                      defaultValue={
                        loanTemp?.guarantor_name2 ?? loanTemp?.guarantor_name2
                      }
                      readOnly
                      aria-required="true"
                    />
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                    <label>Staff ID</label>
                    <input
                      className="form-control"
                      type="text"
                      name="guarantor_staff_id2"
                      defaultValue={
                        loanTemp?.guarantor_staff_id2 ??
                        loanTemp?.guarantor_staff_id2
                      }
                      aria-required="true"
                      readOnly
                    />
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                    <label>Phone Number</label>
                    <input
                      className="form-control"
                      type="text"
                      readOnly
                      name="guarantor_phone2"
                      placeholder=""
                      defaultValue={
                        loanTemp?.guarantor_phone2 ?? loanTemp?.guarantor_phone2
                      }
                     
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                    <label>Ghana Card Number</label>
                    <input
                      className="form-control"
                      type="text"
                      name="guarantor_ghana_card2"
                      defaultValue={
                        loanTemp?.guarantor_ghana_card2 ??
                        loanTemp?.guarantor_ghana_card2
                      }
                      readOnly
                    
                    />
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                    <label>GPS Address</label>
                    <input
                      className="form-control"
                      type="text"
                      name="guarantor_gps2"
                      readOnly
                      defaultValue={
                        loanTemp?.guarantor_gps2 ?? loanTemp?.guarantor_gps2
                      }
                      
                    />
                  </div>

                  <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                    <label>
                      Occupation
                    
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      readOnly
                      name="guarantor_occupation2"
                      defaultValue={
                        loanTemp?.guarantor_occupation2 ??
                        loanTemp?.guarantor_occupation2
                      }
                      aria-required="true"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                    <label>Mandate Number</label>
                    <input
                      className="form-control"
                      type="text"
                      name="guarantor_mandate_number2"
                      defaultValue={
                        loanTemp?.guarantor_mandate_number2 ??
                        loanTemp?.guarantor_mandate_number2
                      }
                      readOnly
                    />
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                    <label>
                      OTP Number
                    </label>
                    <input
                      className="form-control"
                      type="number"
                      readOnly
                      name="guarantor_otp_number2"
                      defaultValue={
                        loanTemp?.guarantor_otp_number2 ??
                        loanTemp?.guarantor_otp_number2
                      }
                    />
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                    <label>Relationship</label>
                    <input
                      className="form-control"
                      type="text"
                      readOnly
                      name="guarantor_relationship2"
                      defaultValue={
                        loanTemp?.guarantor_relationship2 ??
                        loanTemp?.guarantor_relationship2
                      }
                    />
                  </div>
                </div>

                {/* THE CONTACT DETAILS SECTION */}

                <div className="form-group row">
                  <h3 className="formTitle">Employment Details</h3>
                  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <label>Employer</label>
                    <input
                      className="form-control"
                      type="text"
                      readOnly
                      defaultValue={
                        loanTemp?.employer_name ?? loanTemp?.employer_name
                      }
                    />
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <label>Facility</label>
                    <input
                      className="form-control"
                      type="text"
                      readOnly
                      defaultValue={loanTemp?.facility ?? loanTemp?.facility}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-12">
                    <label>Staff ID</label>
                    <input
                      className="form-control"
                      type="text"
                      readOnly
                      defaultValue={user?.staff_id}
                    />
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-12">
                    <label>Employment Start Date</label>
                    <input
                      className="form-control"
                      type="text"
                      readOnly
                      defaultValue={
                        loanTemp?.employment_start_date ??
                        loanTemp?.employment_start_date
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="form_area form3">
                <div className="form-group row">
                  <h3 className="formTitle">
                    Loan (please provide your loan details)
                  </h3>
                  {/* <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                      <label>Net Salary &#8373;</label>
                      <input
                        className="form-control"
                        type="text"
                        readOnly
                        defaultValue={formData?.netSalary}
                      />
                    </div> */}
                  <div className="col-xs-12 col-sm-12 col-md-4 col-lg-6">
                    <label htmlFor="affordability">Afordability &#8373;</label>
                    <input
                      className="form-control"
                      type="text"
                      readOnly
                      defaultValue={
                        loanTemp?.affordability ?? loanTemp?.affordability
                      }
                    />
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-4 col-lg-6">
                    <label htmlFor="loanAmount">Loan Amount &#8373;</label>
                    <input
                      className="form-control"
                      type="text"
                      readOnly
                      defaultValue={
                        loanTemp?.loanAmount ?? loanTemp?.loanAmount
                      }
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                    <label htmlFor="instalmentPerMonth">
                      Monthly Deduction &#8373;
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      readOnly
                      defaultValue={
                        loanTemp?.monthlyDeduction ?? loanTemp?.monthlyDeduction
                      }
                    />
                  </div>

                  <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                    <label htmlFor="purposeForLoan">
                      Purpose of loan
                      <span className="bto-lb-required"></span>
                    </label>
                    <textarea
                      className="form-control"
                      readOnly
                      defaultValue={
                        loanTemp?.purposeForLoan ?? loanTemp?.purposeForLoan
                      }
                    ></textarea>
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                    <label>Loan Terms (Months)</label>{" "}
                    <input
                      className="form-control"
                      type="text"
                      readOnly
                      defaultValue={loanTemp?.loanTerm ?? loanTemp?.loanTerm}
                    />
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                    <label htmlFor="mandateNumber">Mandate Number</label>
                    <input
                      className="form-control"
                      type="text"
                      readOnly
                      defaultValue={
                        loanTemp?.mandateNumber ?? loanTemp?.mandateNumber
                      }
                    />
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                    <label htmlFor="interestPerMonth">OTP Number</label>
                    <input
                      className="form-control"
                      type="text"
                      readOnly
                      defaultValue={loanTemp?.otpNumber ?? loanTemp?.otpNumber}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <>
                    <div className="form-group row">
                      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <label htmlFor="bank_account_holder_name">
                          Name of Account Holder{" "}
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          readOnly
                          defaultValue={
                            loanTemp?.bank_account_holder_name ??
                            loanTemp?.bank_account_holder_name
                          }
                        />
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <label htmlFor="bank_name">Name of Bank</label>
                        <input
                          className="form-control"
                          type="text"
                          readOnly
                          defaultValue={
                            loanTemp?.bank_name ?? loanTemp?.bank_name
                          }
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <label htmlFor="bank_account_number">
                          Account Number{" "}
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          readOnly
                          defaultValue={
                            loanTemp?.bank_account_number ??
                            loanTemp?.bank_account_number
                          }
                        />
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <label htmlFor="bank_branch">Branch</label>
                        <input
                          className="form-control"
                          type="text"
                          readOnly
                          defaultValue={
                            loanTemp?.bank_branch ?? loanTemp?.bank_branch
                          }
                        />
                      </div>
                    </div>
                  </>
                </div>
              </div>

              <div className="form_area form4">
                <h3 className="formTitle">Uploaded ID card</h3>
                <div className="preview_card_container">
                  <div className="preview_image-card">
                    <img
                      src={loanTemp?.frontIDImage ?? loanTemp?.frontIDImage}
                      alt="front-id"
                    />
                  </div>
                  <div className="preview_image-card">
                    <img
                      src={loanTemp?.passportImage ?? loanTemp?.passportImage}
                      alt="passort"
                    />
                  </div>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "1rem",
                }}
              >
                <div
                  className="button_cancel cursor-pointer backBtn"
                  // type="button"
                  onClick={() => {
                    history.push("/apply-loan-uploads");
                  }}
                >
                  Back
                </div>
                <button
                  className="button"
                  name=""
                  onClick={() => history.push("/apply-loan-terms")}
                >
                  Next
                </button>
              </div>
            </div>
          )}
        </form>
      </RenderHome>
      <ToastContainer autoClose={3000} />
    </div>
  );
}

export default Preview;
