/* eslint-disable  */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import "../../../css/Dashboard/home/Home.css";
import {
  authActionCreators,
  messagesActionCreators,
  navigationActionCreators,
  notificationsActionCreators,
  userDataActionCreators,
} from "../../../service/action-creators";
import SectionOne from "./sub-comp/home_sub/SectionOne";
import SectionTwo from "./sub-comp/home_sub/SectionTwo";
import MainPage from "./sub-comp/MainPage";
import SideBarMenu from "./sub-comp/SideBarMenu";
function Home() {
  const dispatch = useDispatch();
  const { closeSideBarOnMobile } = bindActionCreators(
    navigationActionCreators,
    dispatch
  );
  const { GetNotifications, ResetResponse } = bindActionCreators(
    notificationsActionCreators,
    dispatch
  );
  const { GetConversations, GetTotalUnreadMessages } = bindActionCreators(
    messagesActionCreators,
    dispatch
  );
  const { GetUserProfile } = bindActionCreators(authActionCreators, dispatch);

  const { GetCartProducts } = bindActionCreators(
    userDataActionCreators,
    dispatch
  );

  const auth = useSelector((state) => state?.auth);
  console.log("auth", auth);
  console.log("auth?.user?.is_email_verified", auth?.user?.is_email_verified);

  useEffect(() => {
    closeSideBarOnMobile();
    GetCartProducts();
    GetNotifications();
    GetUserProfile();
    GetConversations(auth?.user?._id);
    GetTotalUnreadMessages()

    console.log('render')
  }, []);

  useEffect(() => {
    setTimeout(() => {
      ResetResponse();
    }, 1500);
  }, []);

  useEffect(() => {
    window.scrollTo(0,0)
   }, [])


  return (
    <div className="home_dashboard">
      <SideBarMenu />
      <MainPage>
        <div className="DashBoardTitle">
          <h4 className="pageTitle">Dashboard</h4>
          {auth?.user?.is_email_verified === false && (
            <div className="email_message">
              Please verify your email address
            </div>
          )}
        </div>
        <div className="MainPageSections">
          <SectionOne />
          <SectionTwo />
        </div>
      </MainPage>
    </div>
  );
}

export default Home;
