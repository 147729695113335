/* eslint-disable  */
import { ArrowBackIos } from "@material-ui/icons";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { bindActionCreators } from "redux";
import { io } from "socket.io-client";
import {
  authActionCreators,
  messagesActionCreators,
} from "../../../service/action-creators";
import axiosInstance from "../../../service/axios/axios";
import Loader from "../../Loader";
import Modal from "../../Modal/Modal";
import RenderHome from "../home/RenderHome";
import Conversation from "./Conversation";
import Message from "./Message";
import "./Messenger.css";
function Messenger() {
  const dispatch = useDispatch();
  const scrollRef = useRef();
  const auth = useSelector((state) => state.auth);
  const chat = useSelector((state) => state.messages);
  const [openChatModal, setChatOpenModal] = useState(false);

  const { usersForChat, response } = chat;

  const isLoading = chat?.isLoading;
  const {
    GetConversations,
    GetUsersForChat,
    CreateConversation,
    ResetMessagesResponse,
    addMessages,
  } = bindActionCreators(messagesActionCreators, dispatch);
  const { GetUserProfile } = bindActionCreators(authActionCreators, dispatch);
  const [message, setMessage] = useState("");

  const [conversations, setConversations] = useState(
    chat?.conversations
      ? chat?.conversations
      : JSON.parse(localStorage.getItem("conversations"))
  );
  const [currentChat, setCurrentChat] = useState(null);
  const [messages, setMessages] = useState({});
  const [chats, setChats] = useState([]);
  const [newChat, setNewChat] = useState({});
  const [arrivalMessage, setArrivalMessage] = useState(null);

  const [showMobile, setShowMobile] = useState(false);
  const [changeConversationUser, setChangeConversationUser] = useState("0");
  const [conversationTitle, setConvertionTitle] = useState("");

  const disp = showMobile ? "block" : "none";
  const hide = showMobile ? "none" : "block";
  //   const [socket, setSocket] = useState(null);
  const socket = useRef();

  console.log("currentChat", currentChat);
  console.log("usersForChat", usersForChat);
  console.log("chats", chats);

  const device = () => {
    return window.innerWidth < 1080;
  };
  const [isMobile, setIsMobile] = useState(device);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setIsMobile(device);
    });
    return 0;
  }, []);

  const handleChangeUserConversation = (e) => {
    setChangeConversationUser(e.target.value);
  };

  const handleCreateConversation = (e) => {
    e.preventDefault()
    if (changeConversationUser === "0") {
      toast.error("Please select user first");
      return;
    }
    if (conversationTitle === "") {
      toast.error("Please conversation title is required");
      return;
    }
    const payload = [auth?.user?._id, changeConversationUser];
    CreateConversation(payload, conversationTitle);
    setChatOpenModal(false)
  };

  useEffect(() => {
    socket.current = io("ws://localhost:8900");
    socket.current.on("getUserMessages", (data) => {
      setArrivalMessage({
        sender_id: data.sender,
        message: data.text,
        created_at: Date.now(),
      });
    });
    GetUserProfile();
    GetConversations(auth?.user?._id);
    GetUsersForChat();
  }, []);

  //update messages if there is new arrival message
  useEffect(() => {
    arrivalMessage &&
      currentChat?.members.includes(arrivalMessage?.sender_id) &&
      setChats((prev) => [...prev, arrivalMessage]);
  }, [arrivalMessage]);

  useEffect(() => {
    //send user details to the server
    socket.current.emit("addUser", auth?.user?._id);

    //get users from the socket server
    socket.current.on("getUsers", (users) => {
      console.log("usersConnected to socket", users);
    });
  }, [auth?.user]);

  console.log("arrivalMessage", arrivalMessage);
  console.log("conversations new", conversations);
  // console.log("conversations new", JSON.parse(localStorage.getItem('conversations')));



  useEffect(() => {
    const getMessage = async () => {
      try {
        const res = await axiosInstance.get(
          `/messenger/messages/${currentChat?._id}`
        );
        setMessages(res.data);
        // setChats(prev => [...prev, res.data.chat]);
        setChats(res.data.chat);
        console.log("res.data", res.data);
      } catch (error) {
        console.log("error", error);
      }
    };
    getMessage();
  }, [currentChat]);

  console.log("showMobile when clicked", showMobile);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      sender: auth?.user?._id,
      text: message,
      conversationId: currentChat?._id,
    };

    if(!arrivalMessage){
      setNewChat(data);
    }

    const recieverId = currentChat.members.find(
      (mem) => mem !== auth?.user?._id
    );
    //send this message to the socket server
    socket.current.emit("sendMessage", {
      sender: auth?.user?._id,
      recieverId,
      text: message,
    });

    //set chat here
    // setChats((prev) => [...prev, message]);

    console.log("The current auth user", auth);

    try {
      const res = await axiosInstance.post("/messenger/messages", data);
      if (res) {
        // setMessages(res.data);
        // setChats((prev) => [...prev, res.data.chat])
        // setChats((prev) => [...prev, res.data.chat])
        setMessage("");
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    if (response?.state === "SUCCESS") {
      // toast.success(response?.message);
      setTimeout(() => {
        ResetMessagesResponse();
        GetConversations();
      }, 1500);
    } else if (response?.state === "ERROR") {
      // toast.error(response?.message);
      setTimeout(() => {
        ResetMessagesResponse();
      }, 1500);
    }
  }, [response?.state]);

  return (
    <div className="">
      <RenderHome>
        {isLoading && <Loader />}
        <div className="messenger">
          {isMobile ? (
            <div className="chatConversation" style={{ display: hide }}>
              {chat?.conversations.map((conversation, index) => (
                <div
                key={index}
                  onClick={() => {
                    setCurrentChat(conversation);
                    setArrivalMessage(null)
                    isMobile && setShowMobile(true);
                  }}
                >
                  <Conversation conversation={conversation} />
                </div>
              ))}
            </div>
          ) : (
            <div className="chatConversation">
              {chat?.conversations.map((conversation, index) => (
                <div
                key={index}
                  onClick={() => {
                    setCurrentChat(conversation);
                    setArrivalMessage(null)
                    isMobile && setShowMobile(true);
                  }}
                >
                  <Conversation conversation={conversation} />
                </div>
              ))}
              <button
                className="custom_btn"
                onClick={() => setChatOpenModal(true)}
              >
                Create Chat
              </button>

              {/* <button>Open Chat</button> */}
            </div>
          )}

          {isMobile ? (
            <div
              className="chatBox"
              style={{ display: disp }}
              onClick={() => {}}
            >
              <div className="chatBoxWrapper">
                {isMobile && (
                  <>
                    <div
                      onClick={() => {
                        setShowMobile(false);
                      }}
                      className="backArrow"
                    >
                      <ArrowBackIos style={{ fontSize: ".7rem" }} />{" "}
                      <span>Back</span>
                    </div>
                  </>
                )}
                {currentChat ? (
                  <>
                    <div className="chatBoxTop">
                      {chats.map((data, index) => (
                        <div ref={scrollRef}>
                          <Message
                            key={index}
                            own={data.sender_id === auth?.user?._id}
                            mesgs={data}
                            auth={auth}
                          />
                        </div>
                      ))}
                    </div>
                    <div className="chatBoxBottom">
                      <textarea
                        className="chatMessageInput"
                        name="message"
                        value={message}
                        onChange={(e) => setMessage(e?.target?.value)}
                        placeholder="Start writing your message here..."
                      ></textarea>
                      <button className="button" onClick={handleSubmit}>
                        Send
                      </button>
                    </div>
                  </>
                ) : (
                  <span className="openConversationText">
                    Open conversation for new chat
                  </span>
                )}
              </div>
            </div>
          ) : (
            <div className="chatBox" style={{ display: "" }} onClick={() => {}}>
              <div className="chatBoxWrapper">
                {isMobile && (
                  <>
                    <div
                      onClick={() => {
                        setShowMobile(false);
                      }}
                      className="backArrow"
                    >
                      <ArrowBackIos style={{ fontSize: ".7rem" }} />{" "}
                      <span>Back</span>
                    </div>
                  </>
                )}
                {currentChat ? (
                  <>
                    <div className="chatBoxTop">
                      {chats?.map((ch, i) => {
                        // console.log("Messages", data);
                        return (
                          <div ref={scrollRef}>
                            <Message
                              key={i}
                              own={ch.sender_id === auth?.user?._id}
                              mesgs={ch}
                              auth={auth}
                            />
                          </div>
                        );
                      })}
                    </div>
                    <div className="chatBoxBottom">
                      <textarea
                        className="chatMessageInput"
                        name="message"
                        value={message}
                        onChange={(e) => setMessage(e?.target?.value)}
                        placeholder="Start writing your message here..."
                      ></textarea>
                      <button className="button" onClick={handleSubmit}>
                        Send
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="">
                      <p className="openConversationText">
                        Open new conversation for new chat
                      </p>
                      <div className="createChatArrea">
                        <p>
                          {" "}
                          <button onClick={() => setChatOpenModal(true)}>
                            create chat
                          </button>
                        </p>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
        </div>

        {openChatModal && (
          <Modal width={"30%"} closeModal={setChatOpenModal}>
            <form>
              <div>
                {/* <select onChange={handleChangeUserConversation}>
                  <option value={"0"}>Choose user</option>
                  {usersForChat?.map((user, i) => {
                    return (
                      <option key={i} value={user?._id}>
                        {user?.name.split(" ")[1]}
                      </option>
                    );
                  })}
                </select> */}
                <div className="mb-6">
                  <label
                    htmlFor="title"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Chat Title
                  </label>
                  <input
                    type="title"
                    name="title"
                    id="title"
                    onChange={(e) => setConvertionTitle(e.target.value)}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Enter the title of your conversation"
                    required
                  />
                </div>

                <label
                  htmlFor="countries"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
                >
                  Select Officer
                </label>
                <select
                  onChange={handleChangeUserConversation}
                  id="countries"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  <option value={"0"}>Choose user</option>
                  {usersForChat?.map((user, i) => {
                    console.log('userForChat', user)
                    return (
                      <option key={i} value={user?._id}>
                        {`${user?.name.split(" ")[1]} (${user?.officer})` }
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="modalButton">
                <div className="flex items-center p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">
                  {/* handleCreateConversation */}

                  <div className="">
                    <button
                      className="custom_btn"
                      onClick={handleCreateConversation}
                    >
                      create chat
                    </button>
                  </div>

                  <button
                    data-modal-toggle="defaultModal"
                    type="button"
                    onClick={() => setChatOpenModal(false)}
                    className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </Modal>
        )}

        {/* <div id="popup-modal" tabindex="-1" className=" overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 md:inset-0 h-modal md:h-full">
    <div className="relative p-4 w-full max-w-md h-full md:h-auto">
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <button type="button" className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white" data-modal-toggle="popup-modal">
                <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                <span className="sr-only">Close modal</span>
            </button>
            <div className="p-6 text-center">
                <svg aria-hidden="true" className="mx-auto mb-4 w-14 h-14 text-gray-400 dark:text-gray-200" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Are you sure you want to delete this product?</h3>
                <button data-modal-toggle="popup-modal" type="button" className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2">
                    Yes, I'm sure
                </button>
                <button data-modal-toggle="popup-modal" type="button" className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">No, cancel</button>
            </div>
        </div>
    </div>
</div> */}
      </RenderHome>
    </div>
  );
}

export default Messenger;
