/* eslint-disable  */
import React, { useEffect, useState } from 'react'
import RenderHome from '../home/RenderHome'
import Resizer from "react-image-file-resizer";
import './RequestOrder.css'
import { ToastContainer, toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import { productActionCreators } from '../../../service/action-creators';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../Loader';
import { GetUserProfile } from '../../../service/action-creators/authAction';
import { GetNotifications } from '../../../service/action-creators/notificationAction';
import { GetCartProducts } from '../../../service/action-creators/userDataAction';
function RequestOrder() {
  const device = () => {
    return window.innerWidth < 1080;
  };
  const [isMobile, setIsMobile] = useState(device);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setIsMobile(device);
    });
    return 0;
  }, []);

  const dispatch = useDispatch()
  const { RequestOrder, GetRequestOrder, DeletedRequestOrder, ResetProductResponse } = bindActionCreators(productActionCreators, dispatch)
  const {isLoading, request_order, response} = useSelector(
    (state) => state?.product
  );
  const [formData, setFormData] = useState({});
  const [images, setImages] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleRequestSubmit = (e) => {
    e.preventDefault()
  
    if(!formData.product_name){
      toast.error("Please enter the product name");
    }else if(!formData.description){
      toast.error("Description of the product is required");
    }else{
      console.log(formData)
      console.log('selectedImages', selectedImages)
      RequestOrder(formData, selectedImages)
    }
  };

  const handleCancelOrder = (order) => {
    DeletedRequestOrder(order)
    
  }

  useEffect(() => {
    dispatch(GetUserProfile())
    // dispatch( GetNotifications())
    // dispatch(GetCartProducts())
    GetRequestOrder()

  }, [])

  console.log('request_order', request_order)


  const handleProfileChange = (e) => {
    if (e?.target.files[0]) {
      const file = e?.target.files[0];
      const files = e.target.files;

      const reader = new FileReader();

      reader.onload = (ev) => {
        // setProfileImagePreview(ev?.target?.result);
        console.log('prof', ev?.target?.result)
        setImages(prevState => [...prevState, ev.target.result]);
        for (const fil of files) {
          setSelectedImages(prevState => [...prevState, fil]);
        }
        // try {
        //   Resizer.imageFileResizer(
        //     e.target.files[0],
        //     100,
        //     100,
        //     "png",
        //     100,
        //     0,
        //     (uri) => {
        //       console.log(uri);
        //         selectedImages([...selectedImages, uri])
        //     },
        //     "file",
        //     200,
        //     200
        //   );

        // } catch (err) {
        //   console.log(err);
        // }

      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    if (response.state === "SUCCESS") {
      GetRequestOrder()
      // toast.success(response?.message ?? "Notifications loaded");
      setTimeout(() => {
        ResetProductResponse();
      }, 1500);
    } else if (response.state === "ERROR") {
      toast.error(response?.message ?? "unable to get requested orders");
      setTimeout(() => {
        ResetProductResponse();
      }, 1500);
    }
  }, [response.state]);

 

  return (
    <RenderHome title={''}>
{isLoading ? <Loader /> : ""}
    {request_order?.length > 0 &&(
      <>
      <h1>Requested Orders</h1>
      {request_order?.map((order, index) => {
        return (
          <div key={index +1} className='requestOrderList'>
          <div className='imageNdText'>
            <div className='imageCover'>
              {order?.images?.length > 0 ?  <img src={order?.images[0].url} /> :  <img src="/images/dev/default-profile-image.png" /> }
              </div>
            <div>
              {isMobile ? ( <strong>{order?.product_name?.length > 30 ? order?.product_name.slice(0, 30) + '...': order?.product_name }</strong>) : ( <strong>{order?.product_name }</strong>)}
             
              <p>{order?.description}</p>
            </div>
          </div>
          <div className='cancel_btn'>
            <button onClick={() => {
              handleCancelOrder(order?._id);
              
            }}>Cancel Request</button>
          </div>
        </div>
        )
      })}
     
      </>
    )}
      


      <div className='RequestOrder-wrapper'>
        <form>
          <h2 className='title'>Request Order</h2>
          <div className="form-group">
            <label htmlFor="product_name">Product name</label>
            <input
              id="product_name"
              name="product_name"
              required
              type="text"
              className="form-control"
              onChange={(e) => handleInputChange(e)}
            />
          </div>
          <div className='description'>
            <label htmlFor="description">Detailed Description:</label>
            <textarea id='description' name='description' onChange={handleInputChange} rows={4} className='form-control' placeholder='Type the description including product specification here'>

            </textarea>
          </div>
          <div>
            <div className='imagesContainer'>
              <label htmlFor="">Selected Images:</label>
              <div className='imagesWrapper '>
                {images.length > 0 && (
                  images.map((img, i) => <div key={i + 1} className='imgCoveer'><img src={img} alt='request prod' /></div>)
                )}
              </div>
              {/* <img src="/images/dev/default-profile-image.png" /> */}
            </div>
            <div className="flex items-center justify-center w-full">
              {/* <label htmlFor="email">Upload product Images:</label> */}
              <label
                htmlFor="dropzone-file"
                className="flex flex-col items-center justify-center w-full h-20 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
              >
                <div className="flex flex-col items-center justify-center pt-0 pb-0">
                  <svg
                    aria-hidden="true"
                    className="w-7 h-6 mb-1 text-gray-400"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                    ></path>
                  </svg>
                  <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                    <span className="font-small text-xs">
                      Click to upload product images for your request
                    </span>
                  </p>
                  {/* <p className="text-xs text-gray-500 dark:text-gray-400">SVG, PNG, JPG or GIF (MAX. 800x400px)</p> */}
                </div>
                <input
                  id="dropzone-file"
                  onChange={handleProfileChange}
                  type="file"
                  multiple={true}
                  className="hidden"
                />
              </label>

            </div>
          </div>
          <button className="button mb2 mt1" onClick={handleRequestSubmit}>
            Request Order
          </button>
        </form>
      </div>
      <ToastContainer autoClose={3000} />
    </RenderHome>
  )
}

export default RequestOrder
