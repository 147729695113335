/* eslint-disable  */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetUserProfile } from "../../../../service/action-creators/authAction";
import RenderHome from "../../home/RenderHome";
import './MartTerms.css'
function MartTerms() {
    const dispatch = useDispatch()
    
    const auth = useSelector((state) => state?.auth);
    // console.log('user', user)

    useEffect(() => {
        dispatch(GetUserProfile())
    }, [])
  return (
    <RenderHome>
      <div className="terms_container">
          <h2 className="title">Mart Terms</h2>
        <p>Declaration and authorization given by applicant I</p>
        <p>
          <span style={{fontWeight: 'bold', textDecoration: 'underline'}}>{auth?.user?.name}</span> (The applicant), declare and
          agree that 1. The information I have provided in the application form
          is correct 2. I have reviewed all the information on Hire Purchase
          Form A; setting out the full details of my repayments if the item is
          granted and they are the same as those that I was shown before I
          signed this agreement 3. I understand the terms of the Agreement as
          explained to me in English 4. I have read the Agreement or it has been
          read to me. 5. Where the installments are deducted from my salary I
          acknowledge that I may not unilaterally cancel that deduction until
          the item has been repaid for in full.
        </p>
        <p>
          I agree that by signing this declaration, I give UPNMG MART permission
          to: 1. Contract anyone to check that the information which I have
          given on Hire Purchase Form A is correct 2. Obtain details from any
          party about my financial status and banking details including a credit
          record and payment history 3. Given information about this agreement
          to any party, including a Credit Bureau(s) 4. Assign its right tittle
          and interest herein to any party or entity nominated by UPNMG MART 5.
          Report to the Ghana Police or similar organization any details where
          fraud is/or has been committed regard to this applicant. 6. Forward
          marketing materials and offerings of other UPNMG MART products to me.
        </p>
        <p>
          Application and approval: You apply for an item on hire purchase by
          completing and signing this document. Only when UPNMG MART approves
          the item and gives it out to you will this document become a binding
          agreement between UPNMG MART and you. Payment and approval: After
          UPNMG MART has approved the application for an item; the item will be
          delivered to you
        </p>
        <p>
          Interest Rate: The interest charged on the item will be at a fixed
          rate, calculated and capitalized over the repayment period. Should you
          fail to make a payment on the due date or where grants you an
          extension for payment, the interest that accrues on the full
          outstanding amount will be capitalized monthly at the discretion of
          and interest will be charged of total amount then outstanding at the
          fixed percentage. Should it become necessary for institute legal
          action for the recovery of any item, UPNMG MART shall be entitled to
          claim the entire amount outstanding at the time legal proceedings
          commences.
        </p>
        <p>
          Cooling off period: Once the item has been given to you, this contract
          is in effect and you are obliged to pay the full contractual amount to
          UPNMG MART. Insurance: There is no insurance cover and in the
          instances of death, disability, retrenchment, dismissal, loss of
          employment etc. the borrower or his estate will be liable for the
          settlement of outstanding amount. Changes: This agreement will be the
          only agreement between you and changes must be effected in writing.
        </p>
        <p>
          Repayment: After the item has been given to you according to the
          agreement between UPNMG MART and you, the full contractual amount set
          out in part A must be repaid in equal installments as shown. Despite
          agreeing to have installment deducted from your monthly salary, you
          remain responsible for making sure that the payments are paid to UPNMG
          MART on time. Repayments will be used firstly to pay legal costs (if
          any) and thereafter additional/penalty interest (if any), then the
          total cost of credit and lastly to reduce the balance of the
          outstanding amount.
        </p>
        <p>
          Credit Check: I consent by this Item Application form, that UPNMG MART
          is entitled to use the service of a Credit Reference Bureau as part of
          the checks on my credit worthiness to determine my suitability to be
          granted an item.
        </p>
        <p>
          Early Settlement Calculation: Early settlement Calculation will be at
          the discretion of UPNMG MART. The administration fee is a
          non-refundable fee and will not be discounted on early settlement.
        </p>
        <p>
          Default and Acceleration: UPNMG MART may immediately demand payment of
          the whole amount outstanding at any time if you: 1. Commit any breach
          of this agreement or become insolvent. 2. Die. 3. Made a false
          representation at the time of applying for the item. 4. Do anything
          that may prejudice UPNMG MART rights in terms of the Agreement. 5.
          Lose your employment at where employed at the time the item was given
          out.
        </p>
        <p>
          When an applicant’s employment is terminated, UPNMG MART may deduct
          all amounts outstanding from the applicant’s benefit. By exercising
          its right to accelerate the repayment of the item UPNMG MART will not
          lose or be limited in any way from exercising any other rights that
          the law may give it. Jurisdiction: You and UPNMG MART agree that this
          agreement is subject to the jurisdiction of the laws of Ghana.
        </p>
        <p>
          Addresses: All notice or letters that UPNMG MART may want or have to
          send to you will be sent to your address as reflected in Part A of
          this Hire Purchase Application and Agreement Form. These will be
          deemed received by you within 3 working days after being sent by
          registered post. If you change your address, as stipulated in the Hire
          Purchase Agreement and Applicant Form (Part A), you have to inform
          UPNMG MART in writing within 7 working days.
        </p>
        <p>
          Item: in this agreement refers to any item(s) UPNMG MART has agreed to
          give to you at an agreed fee paid over an agreed period of time.
        </p>

        <h3>PART C-PAYROLL INSTRUCTION</h3>
        <p>
          In pursuance of the conditions on which the item was granted, I hereby
          irrevocably instruct the Payroll Department of any Employer at the
          date of signing this Agreement, deduct the installments as reflected
          in this Agreement from my remuneration until the contractual amount
          has been repaid in full. The installment amount may be varied upon the
          request of UPNMG MART in the event of a general increase or decrease
          in the interest rates applicable to the item, or where the
          installments are rescheduled as a result of default or other
          arrangements. A variation as aforementioned will result in the total
          contractual amount being adjusted accordingly. I acknowledge that the
          item would not have been granted to me had my employer not conducted
          an agreement with UPNMG MART in terms whereof my employer is
          contractually bound to make the aforementioned deductions. Again, I
          further acknowledge that I will pay for the item in full or alert
          UPNMG MART in writing if I have any misgiving concerning the
          transaction thereof.
        </p>
        <p>
          Should my employment be terminated before the item has been paid for
          in full, I authorize my employer to deduct the outstanding balance of
          the item from all amounts that become payable to me as a result of the
          termination of my employment.
        </p>
      </div>
    </RenderHome>
  );
}

export default MartTerms;
